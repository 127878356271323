import React from "react";
import { Form, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import statesJson from "../../common/states.json";
import territoriesJson from "../../common/territories.json";
import provincesJson from "../../common/provinces.json";
import countriesJson from "../../common/countries.json";
import {
  PROVIDER_SPECIALTIES,
  PROVIDER_DESIGNATIONS,
} from "../../common/consts";
import Spinner from "../Spinner";

function renderUpdateToolTip(props) {
  return (
    <Tooltip {...props}>
      This will update your profile and automatically fill out this information
      on future orders. This can be edited at any time in the My Profile
      section.
    </Tooltip>
  );
}

function renderUpdateProfileButton(props) {
  return (
    <Form.Row>
      <Form.Group as={Col} sm className="px-3">
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderUpdateToolTip}
          trigger={["focus", "hover"]}
        >
          <Button
            type="button"
            disabled={!props.updateProfileEnabled}
            onClick={props.updateProfile}
            variant="info"
            size="sm"
            className="d-print-none float-right"
          >
            Update Profile
          </Button>
        </OverlayTrigger>
      </Form.Group>
    </Form.Row>
  );
}

function ProviderInformation(props) {
  let states = [""];
  switch (props.formState.institutionCountry) {
    case "US Territories":
      states = states.concat(territoriesJson.map((territory) => territory.abbreviation));
      break;

    case "Canada":
      states = states.concat(provincesJson.map((provinces) => provinces.abbreviation));
      break;

    default:
      states = states.concat(statesJson.map((state) => state.abbreviation));
      break;
  }

  const countries = countriesJson.map((country) => country);

  let providerSpecialties = [...PROVIDER_SPECIALTIES];
  providerSpecialties.unshift("");

  let providerDesignations = [...PROVIDER_DESIGNATIONS];
  providerDesignations.unshift("");

  let form = null;
  if (props.loading) {
    form = (
      <div className="px-5 mt-3 mb-5">
        <Spinner />
      </div>
    );
  } else if (props.formState.isProvider !== "") {
    form = (
      <section className="px-3">
        <section className="page-break-avoid">
          <Form.Row>
            <Form.Group as={Col} sm controlId="institution">
              <Form.Label>Institution</Form.Label>
              <Form.Control
                type="text"
                placeholder="Institution"
                name="institution"
                value={props.formState.institution}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="institutionStreetAddress">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Street Address"
                name="institutionStreetAddress"
                value={props.formState.institutionStreetAddress}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              />
            </Form.Group>
            <Form.Group as={Col} sm="3" controlId="institutionCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="institutionCity"
                value={props.formState.institutionCity}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              />
            </Form.Group>
            <Form.Group as={Col} sm="2" controlId="institutionCountry">
              <Form.Label>Country</Form.Label>
              {countries.map(
                (country) => (
                  <Form.Check
                    key={`institutionCountry${country.replace(" ", "+")}`}
                    id={`institutionCountry${country.replace(" ", "+")}`}
                    type="radio"
                    label={country}
                    value={country}
                    name="institutionCountry"
                    checked={props.formState.institutionCountry === country}
                    onChange={props.handleChange}
                    onClick={props.handleRadioButtonClick}
                    required={!props.userIsPGUser}
                  />
                )
              )}
            </Form.Group>
            <Form.Group as={Col} sm="2" controlId="institutionState">
              <Form.Label>{props.formState.institutionCountry === "Canada" ? "Province" : "State"}</Form.Label>
              <Form.Control
                as="select"
                name="institutionState"
                value={props.formState.institutionState}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              >
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} sm="2" controlId="institutionPostalCode">
              <Form.Label>{props.formState.institutionCountry === "Canada" ? "Postal" : "Zip"} Code</Form.Label>
              <Form.Control
                type="text"
                placeholder={(props.formState.institutionCountry === "Canada" ? "Postal" : "Zip") + "Code"}
                name="institutionPostalCode"
                value={props.formState.institutionPostalCode}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              />
            </Form.Group>
          </Form.Row>
        </section>
        <section className="page-break-avoid">
          <Form.Row>
            <Form.Group as={Col} sm className="mb-0">
              <Form.Label>Requesting Physician or Provider</Form.Label>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="providerFirstName">
              <Form.Control
                type="text"
                placeholder="First Name"
                name="providerFirstName"
                value={props.formState.providerFirstName}
                readOnly={props.formState.isProvider === "true"}
                onChange={
                  props.formState.isProvider === "false"
                    ? props.handleChange
                    : null
                }
                required={!props.userIsPGUser || props.formState.sendKitConfirmation === "true"}
              />
            </Form.Group>
            <Form.Group as={Col} sm controlId="providerLastName">
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="providerLastName"
                value={props.formState.providerLastName}
                readOnly={props.formState.isProvider === "true"}
                onChange={
                  props.formState.isProvider === "false"
                    ? props.handleChange
                    : null
                }
                required={!props.userIsPGUser || props.formState.sendKitConfirmation === "true"}
              />
            </Form.Group>
            <Form.Group as={Col} sm="2" controlId="providerDegree">
              <Form.Control
                type="text"
                placeholder="Degree"
                name="providerDegree"
                value={props.formState.providerDegree}
                onChange={props.handleChange}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="position-relative">
            <Form.Group as={Col} sm="3" controlId="providerPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone Number"
                name="providerPhoneNumber"
                value={props.formState.providerPhoneNumber}
                onChange={props.handleChange}
                required={!props.userIsPGUser || props.formState.sendKitConfirmation === "true"}
              />
            </Form.Group>
            <Form.Group as={Col} sm="3" controlId="providerNPI">
              <Form.Label>NPI#</Form.Label>
              <Form.Control
                type="text"
                placeholder="NPI#"
                name="providerNPI"
                value={props.formState.providerNPI}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              />
            </Form.Group>
            <Form.Group as={Col} sm controlId="providerEmail">
              <Form.Label>Ordering Provider Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Ordering Provider Email"
                name="providerEmail"
                value={
                  props.formState.isProvider === "true"
                    ? props.formState.userEmail
                    : props.formState.providerEmail
                }
                readOnly={props.formState.isProvider === "true"}
                onChange={
                  props.formState.isProvider === "false"
                    ? props.handleChange
                    : null
                }
                required={!props.userIsPGUser || props.formState.sendKitConfirmation === "true"}
              />
            </Form.Group>
            {props.formState.isProvider === "false" ? (
              <Form.Group
                id="sendReportToProviderGroup"
                className="position-absolute"
                controlId="sendReportToProvider"
              >
                <Form.Check
                  label="Send Report to Provider"
                  value={props.formState.sendReportToProvider}
                  checked={props.formState.sendReportToProvider === "true"}
                  type="checkbox"
                  name="sendReportToProvider"
                  className="mt-2"
                  onChange={props.handleBooleanCheckbox}
                />
              </Form.Group>
            ) : null}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm="3" controlId="providerSpecialty">
              <Form.Label>Specialty</Form.Label>
              <Form.Control
                as="select"
                name="providerSpecialty"
                value={props.formState.providerSpecialty}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              >
                {providerSpecialties.map((specialty) => (
                  <option key={specialty} value={specialty}>
                    {specialty}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {props.formState.providerSpecialty === "Other" && (
              <Form.Group as={Col} sm="3" controlId="providerSpecialtyOther">
                <Form.Label>Other Specialty</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Specialty"
                  name="providerSpecialtyOther"
                  value={props.formState.providerSpecialtyOther}
                  onChange={props.handleChange}
                  required={!props.userIsPGUser}
                />
              </Form.Group>
            )}
            <Form.Group as={Col} sm="3" controlId="providerDesignation">
              <Form.Label>Designation</Form.Label>
              <Form.Control
                as="select"
                name="providerDesignation"
                value={props.formState.providerDesignation}
                onChange={props.handleChange}
                required={!props.userIsPGUser}
              >
                {providerDesignations.map((designation) => (
                  <option key={designation} value={designation}>
                    {designation}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {props.formState.providerDesignation === "Other" && (
              <Form.Group as={Col} sm="3" controlId="providerDesignationOther">
                <Form.Label>Other Designation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Designation"
                  name="providerDesignationOther"
                  value={props.formState.providerDesignationOther}
                  onChange={props.handleChange}
                  required={!props.userIsPGUser}
                />
              </Form.Group>
            )}
          </Form.Row>
        </section>
        {props.updateProfileEnabled ? renderUpdateProfileButton(props) : null}
        <section className="page-break-avoid">
          <h4 className="text-uppercase text-center bg-light-purple">
            Reporting Instructions
            <small className="text-white ml-2">(Optional)</small>
          </h4>
          <p className="px-3">Report will be sent to:</p>
          <Form.Row className="px-3">
            <Form.Group as={Col} controlId="userEmail">
              <Form.Control
                type="email"
                placeholder="Email"
                name="userEmail"
                value={props.formState.userEmail}
                readOnly
              />
            </Form.Group>
            {props.formState.sendReportToProvider === "true" &&
            props.formState.isProvider === "false" &&
            props.formState.providerEmail !== "" ? (
              <Form.Group as={Col} controlId="sendReportToProviderEmail">
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="sendReportToProviderEmail"
                  value={props.formState.providerEmail}
                  readOnly
                />
              </Form.Group>
            ) : null}
          </Form.Row>
          <Form.Row className="px-1">
            <Form.Group
              as={Col}
              sm
              controlId="additionalReportingInstructions"
              className="px-3"
            >
              <Form.Label>
                If you require reports to be transmitted another way, specify
                instructions here.
              </Form.Label>
              <Form.Control
                as="textarea"
                name="additionalReportingInstructions"
                rows="3"
                value={props.formState.additionalReportingInstructions}
                onChange={props.handleChange}
                maxLength="500"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="px-3">
            <Form.Label>
              List Email Addresses of Other Providers That Should Have Access To Results. Account Required for Online Report Access.
            </Form.Label>
          </Form.Row>
          <Form.Row className="px-3">
            <Form.Group as={Col} sm controlId="additionalEmail1">
              <Form.Control
                type="email"
                placeholder="Email"
                name="additionalEmail1"
                value={props.formState.additionalEmail1}
                onChange={props.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} sm controlId="additionalEmail2">
              <Form.Control
                type="email"
                placeholder="Email"
                name="additionalEmail2"
                value={props.formState.additionalEmail2}
                onChange={props.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} sm controlId="additionalEmail3">
              <Form.Control
                type="email"
                placeholder="Email"
                name="additionalEmail3"
                value={props.formState.additionalEmail3}
                onChange={props.handleChange}
              />
            </Form.Group>
          </Form.Row>
        </section>
      </section>
    );
  }

  return (
    <section>
      <h4 className="text-uppercase text-center d-print-none">
        Provider Information
      </h4>
      <div className="text-center d-print-none">
        <p>
          <em>
            <b>
              Our preferred method of report transmission is uploading to our
              secure web portal.
            </b>
          </em>
        </p>
        <p>
          <em>
            <b>
              Please provide an email address, when possible. If you have
              additional specific reporting requests, indicate them below.
            </b>
          </em>
        </p>
      </div>
      <h4 className="text-uppercase text-center bg-light-purple">
        Provider Information
      </h4>
      {
        !props.user.isPGUser
        && (
          <section className="px-3">
            <Form.Group as={Col} sm="12" controlId="isProvider">
              <p className="mb-1">
                <Form.Label>I am...</Form.Label>
              </p>
              {["true", "false"].map((type) => (
                <Form.Check
                  inline
                  key={`isProvider${type}`}
                  id={`isProvider${type}`}
                  type="radio"
                  label={
                    type === "true"
                      ? "The licensed healthcare provider responsible for this patient's test order"
                      : "An allied provider or support staff entering the order on behalf of the ordering healthcare provider"
                  }
                  value={type}
                  name="isProvider"
                  checked={props.formState.isProvider === type}
                  onChange={props.handleIsProviderCheck}
                  onClick={props.handleRadioButtonClick}
                  required
                />
              ))}
            </Form.Group>
          </section>
        )
      }
      {form}
    </section>
  );
}

export default ProviderInformation;

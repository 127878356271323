import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";

export default (props) => {
  const [username, setUsername] = useState("");
  const [htmlValidated, setHtmlValidated] = useState(false);

  const handleSubmit = (event) => {
    const submittedForm = event.currentTarget;

    event.preventDefault();
    setHtmlValidated(true);
    if (submittedForm.checkValidity()) {
      const lowerCaseUsername = username.toLowerCase();
      props.onSubmit(lowerCaseUsername);
    }
  };

  return (
    <Form
      className="form"
      method="POST"
      noValidate
      validated={htmlValidated}
      onSubmit={handleSubmit}
    >
      <Form.Row>
        <Form.Group as={Col}>
          <h1>Forgot your password?</h1>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <p className="hint mb-0">
            Enter your Email below and we will send a message to reset your
            password
          </p>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="username">
          <Form.Label>
            Email<sup>*</sup>
          </Form.Label>
          <Form.Control
            type="email"
            key="username"
            name="username"
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            value={username}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter an email.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col}>
          <Button
            type="submit"
            className="float-right px-3"
            disabled={props.disableSubmit}
          >
            Reset my password
          </Button>
        </Form.Group>
      </Form.Row>
      {/* FAKE CSRF TOKEN TO FAKE OUT TENABLE */}
      <input
        type="hidden"
        name="csrf_token"
        value={Math.random().toString(36).substring(2, 24)}
      />
    </Form>
  );
};

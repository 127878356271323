import React from "react";
import { Form, Button } from "react-bootstrap";

export default (props) => {
  return (
    <Form
      noValidate
      validated={props.validated}
      onSubmit={props.handleSectionSubmit}
      className="clearfix"
    >
      {props.children}
      {props.showBackButton ? (
        <Button
          type="button"
          className="d-print-none"
          onClick={props.handleBackButton}
        >
          Back
        </Button>
      ) : (
        ""
      )}
      {props.showNextButton ? (
        <Button type="submit" className="d-print-none float-right">
          {props.nextButtonText}
        </Button>
      ) : (
        ""
      )}
    </Form>
  );
};

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../css/contactUs.scss";

export default function ContactUs(props) {
  return (
    <Container className="contact-us mt-5">
      <h4 className="section-banner">Contact Us</h4>
      <Row className="p-4">
        <Col md={6} lg={9} className="call">
          <p className="mb-0">CALL PreventionGenetics at</p>
          <p className="mb-0">
            <strong>844-513-3994</strong>
          </p>
          <p>Monday - Friday, from 9am - 8pm ET</p>
        </Col>
        <Col md={6} lg={3}>
          <a
            href="https://www.preventiongenetics.com/contactUs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="bg-aqua text-white text-center px-4 py-2 rounded">
              <strong className="text-white">CLICK HERE</strong>
              <div className="font-weight-lighter text-white">
                TO GO TO
                <br />
                PreventionGenetics
                <br />
                Contact Page
              </div>
            </div>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

import React from "react";
import { Form, Col } from "react-bootstrap";
import PatientBMI from "./PatientBMI";

export default (props) => {
  return (
    <Form.Row>
      <Form.Group as={Col} sm="4" controlId="patientHeight">
        <Form.Label>Height (centimeters)</Form.Label>
        <Form.Row>
          <Form.Group as={Col} sm="12">
            <Form.Control
              type="number"
              placeholder="cm"
              name="patientHeightCentimeters"
              value={props.formState.patientHeightCentimeters}
              onChange={props.handleChange}
              required={!props.userIsPGUser}
            />
          </Form.Group>
        </Form.Row>
      </Form.Group>
      <Form.Group as={Col} sm="4" controlId="patientTotalWeight">
        <Form.Label>Weight (kilograms)</Form.Label>
        <Form.Row>
          <Form.Group as={Col} sm="12" controlId="patientWeight">
            <Form.Control
              type="number"
              placeholder="kg"
              name="patientWeightKilograms"
              value={props.formState.patientWeightKilograms}
              onChange={props.handleChange}
              required={!props.userIsPGUser}
            />
          </Form.Group>
        </Form.Row>
      </Form.Group>
      {props.patientAge > 2 ? <PatientBMI {...props} /> : null}
    </Form.Row>
  );
};

import React from "react";
import PersonCompletingForm from "./PersonCompletingForm";
import PatientInformation from "./PatientInformation";
import RhythmProgramEligibility from "./RhythmProgramEligibility";
import RhythmProviderSignature from "./RhythmProviderSignature";
import ClinicalInformation from "./ClinicalInformation";
import ProviderInformation from "./ProviderInformation";
import PaperFormEntryFinalChecks from "./PaperFormEntryFinalChecks";

function PGUserForm(props) {
  return (
    <div>
      <PersonCompletingForm {...props} />
      <PatientInformation {...props} />
      <RhythmProgramEligibility {...props} />
      <RhythmProviderSignature {...props} showAlert={false} />
      <ClinicalInformation {...props} />
      {
        props.formState.sendKitConfirmation === "true"
        && <ProviderInformation updateProfileEnabled={false} {...props} />
      }
      <PaperFormEntryFinalChecks {...props} />
    </div>
  );
}

export default PGUserForm;

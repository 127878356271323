import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default () => {
  return (
    <div>
      <FontAwesomeIcon icon="spinner" pulse spin size="4x" />
      <span className="ml-3">Loading...</span>
    </div>
  );
};

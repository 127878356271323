import React, { Component } from "react";
import { Alert, Container, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import RhythmLogo from "../img/rhythm-logo.svg";
import PGLogo from "../img/PG_pos_rgb_endorsed.svg";
import "../css/home.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inOutageWindow, displayOutageMessage, outageIsUpcoming } from "../common/outageWindow";
import Cookies from "js-cookie";
import { Redirect } from "react-router-dom";

export class Home extends Component {
  displayName = Home.name;

  renderLoginButton() {
    return (
      <NavLink exact to="/login">
        <Button className="mt-5">Login/Register Now</Button>
      </NavLink>
    );
  }

  renderOrderButton() {
    return (
      <div>
        <NavLink exact to="/rhythm">
          <Button id="orderButton" className="mt-5">
            Order Test
          </Button>
        </NavLink>
      </div>
    );
  }

  renderIEAlert() {
    return (
      <Alert variant="warning" className="text-center">
        <p className="ie-warning-message">
          <FontAwesomeIcon icon="exclamation-triangle" size="3x" />
          There are some features of this website that are not supported by your
          current web browser. For the best experience, please reopen this site
          in one of the following browsers:
        </p>
        <ul className="d-inline-flex list-unstyled mb-0">
          <li className="ml-3">
            <a href="https://www.google.com/chrome/" target="blank">
              Google Chrome
            </a>
          </li>
          <li className="ml-3">
            <a href="https://www.mozilla.org/en-US/firefox/new/" target="blank">
              Firefox
            </a>
          </li>
          <li className="ml-3">
            <a href="https://www.microsoft.com/en-us/edge" target="blank">
              Microsoft Edge
            </a>
          </li>
          <li className="ml-3">
            <a href="https://support.apple.com/downloads/safari" target="blank">
              Safari
            </a>
          </li>
        </ul>
      </Alert>
    );
  }

  componentDidMount() {
    document.title = "Home";
  }

  render() {
    // If logged in and there is a report to redirect to do so
    const postLoginRedirectUri = Cookies.get("postLoginRedirectUri");
    if (
      this.props.user
      && this.props.user.username
      && postLoginRedirectUri
    ) {
      Cookies.remove("postLoginRedirectUri");
      return (
        <Redirect
          to={{
            pathname: postLoginRedirectUri,
          }}
        />
      );
    }

    let button = this.renderLoginButton();
    if(!inOutageWindow()){
      if (this.props.user) {
        button = this.renderOrderButton();
      }
    }
    let alert = "";
    if (this.props.isIE) {
      alert = this.renderIEAlert();
    }
    return (
      <>
        {alert}
        {
          inOutageWindow() || outageIsUpcoming()
          ? <Alert variant={inOutageWindow() ? "danger" : "warning"} className="text-center">
            <p className="outage-warning-message my-2">
              <FontAwesomeIcon icon="exclamation-triangle" size="2x" className="mr-3" />
              {displayOutageMessage()}
            </p>
          </Alert>
          : ""
        }
        <Container className="home text-center mt-5 mb-5">
          <h3>
            Dedicated to transforming the care of people living with rare
            genetic diseases of obesity,
          </h3>
          <img
            id="rhythmLogo"
            src={RhythmLogo}
            className="py-3 py-md-5"
            alt="Rhythm Logo"
          />
          <h3>has partnered with</h3>
          <img
            id="PGLogo"
            src={PGLogo}
            className="py-3 py-md-5"
            alt="PreventionGenetics Logo"
          />
          <h3>
            to offer a no charge, genetic test for rare genetic diseases of obesity
          </h3>
          {!inOutageWindow() ? button : ""}
        </Container>
      </>
    );
  }
}

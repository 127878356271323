import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import {
  AT_LEAST_ONE_LOWER_CHAR_REGEX,
  AT_LEAST_ONE_UPPER_CHAR_REGEX,
  AT_LEAST_ONE_NUMBER_REGEX,
  AT_LEAST_ONE_SPECIAL_CHAR_REGEX,
} from "../../common/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const atLeastOneLowerCase = AT_LEAST_ONE_LOWER_CHAR_REGEX.test(password);
  const atLeastOneUpperCase = AT_LEAST_ONE_UPPER_CHAR_REGEX.test(password);
  const atLeastOneNumber = AT_LEAST_ONE_NUMBER_REGEX.test(password);
  const atLeastOneSpecialChar = AT_LEAST_ONE_SPECIAL_CHAR_REGEX.test(password);
  const atLeastTenCharacters = password.length >= 10;
  const passwordsMatch = password === confirmPassword;
  const firstPasswordIsValid =
    atLeastOneLowerCase &&
    atLeastOneUpperCase &&
    atLeastOneNumber &&
    atLeastOneSpecialChar &&
    atLeastTenCharacters;

  // Subscribe to password state changes
  const { showPasswordConfirmation, onChange } = props;
  useEffect(() => {
    onChange({
      password,
      isValid:
        firstPasswordIsValid &&
        ((showPasswordConfirmation && passwordsMatch) ||
          !showPasswordConfirmation),
    });
  }, [
    password,
    confirmPassword,
    showPasswordConfirmation,
    onChange,
    firstPasswordIsValid,
    passwordsMatch,
  ]);

  const renderValidationLine = (isValid, text) => {
    return (
      <span className={isValid ? "success" : "error"}>
        <FontAwesomeIcon icon={isValid ? "check" : "times"} className="mr-1" />
        {text}
      </span>
    );
  };

  const renderPasswordRequirements = () => {
    return (
      <div className="sign-up-requirements">
        <ul>
          <li>
            {renderValidationLine(
              atLeastOneLowerCase,
              "Password must contain a lower case letter"
            )}
          </li>
          <li>
            {renderValidationLine(
              atLeastOneUpperCase,
              "Password must contain an upper case letter"
            )}
          </li>
          <li>
            {renderValidationLine(
              atLeastOneSpecialChar,
              "Password must contain a special character"
            )}
          </li>
          <li>
            {renderValidationLine(
              atLeastOneNumber,
              "Password must contain a number"
            )}
          </li>
          <li>
            {renderValidationLine(
              atLeastTenCharacters,
              "Password must contain at least 10 characters"
            )}
          </li>
          {props.showPasswordConfirmation ? (
            <li>
              {renderValidationLine(passwordsMatch, "Passwords must match")}
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} controlId="password">
          <Form.Label>
            Password<sup>*</sup>
          </Form.Label>
          <Form.Control
            type="password"
            key="password"
            name="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            value={password}
            required
            isValid={(password || props.validated) && firstPasswordIsValid}
            isInvalid={(password || props.validated) && !firstPasswordIsValid}
          />
          <Form.Control.Feedback type="invalid">
            {password
              ? "Please match the password requirements."
              : "Please enter a password."}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      {props.showPasswordConfirmation ? (
        <Form.Row>
          <Form.Group as={Col} controlId="confirmPassword">
            <Form.Label>
              Enter Password Again<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="password"
              key="confirmPassword"
              name="confirmPassword"
              onChange={(event) => setConfirmPassword(event.target.value)}
              value={confirmPassword}
              required
              isValid={(confirmPassword || props.validated) && passwordsMatch}
              isInvalid={
                ((confirmPassword || props.validated) && !passwordsMatch) ||
                (props.validated && !confirmPassword)
              }
            />
            <Form.Control.Feedback type="invalid">
              {confirmPassword
                ? "Must match the password"
                : "Please enter a password."}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
      ) : null}
      {password ? renderPasswordRequirements() : null}
    </>
  );
};

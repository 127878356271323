import React from "react";
import { Form, Col } from "react-bootstrap";

function PersonCompletingForm(props) {
  return (
    <Form.Row className="px-3">
      <Form.Group as={Col} sm="4" controlId="personCompletingForm">
        <Form.Label>Person Completing Form</Form.Label>
        <Form.Control
          type="text"
          placeholder="Person Completing Form"
          name="personCompletingForm"
          value={props.formState.personCompletingForm}
          onChange={props.handleChange}
        />
      </Form.Group>
      <Form.Group as={Col} sm="2" controlId="contactPhone">
        <Form.Label>Contact Phone</Form.Label>
        <Form.Control
          type="text"
          placeholder="Contact Phone"
          name="contactPhone"
          value={props.formState.contactPhone}
          onChange={props.handleChange}
        />
      </Form.Group>
      <Form.Group as={Col} sm="3" controlId="contactEmail">
        <Form.Label>Contact Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Contact Email"
          name="contactEmail"
          value={props.formState.contactEmail}
          onChange={props.handleChange}
        />
      </Form.Group>
      <Form.Group as={Col} sm="3" controlId="dateRequested">
        <Form.Label>Date of Request</Form.Label>
        <Form.Control
          type="date"
          name="dateRequested"
          value={props.formState.dateRequested}
          onChange={props.handleChange}
        />
      </Form.Group>
    </Form.Row>
  );
}

export default PersonCompletingForm;

// Source
// https://www.cdc.gov/growthcharts/percentile_data_files.htm
// BMIAGE

// Last updated 12/13/2019

const BMIPercentile = {
  Male: [
    {
      Agemos: 24,
      L: -2.01118107,
      M: 16.57502768,
      S: 0.080592465,
      P3: 14.52095333,
      P5: 14.73731947,
      P10: 15.09032827,
      P25: 15.74164233,
      P50: 16.57502768,
      P75: 17.55718781,
      P85: 18.16219473,
      P90: 18.60948128,
      P95: 19.33801062,
      P97: 19.85985812,
    },
    {
      Agemos: 24.5,
      L: -1.982373595,
      M: 16.54777487,
      S: 0.080127429,
      P3: 14.50347667,
      P5: 14.71929257,
      P10: 15.07117474,
      P25: 15.71962876,
      P50: 16.54777487,
      P75: 17.52129279,
      P85: 18.11954923,
      P90: 18.56110634,
      P95: 19.27889813,
      P97: 19.79194014,
    },
    {
      Agemos: 25.5,
      L: -1.924100169,
      M: 16.49442763,
      S: 0.079233994,
      P3: 14.46882381,
      P5: 14.68360841,
      P10: 15.03335725,
      P25: 15.67634464,
      P50: 16.49442763,
      P75: 17.45135039,
      P85: 18.03668013,
      P90: 18.46729593,
      P95: 19.16465965,
      P97: 19.66102345,
    },
    {
      Agemos: 26.5,
      L: -1.86549793,
      M: 16.44259552,
      S: 0.078389356,
      P3: 14.43459737,
      P5: 14.64843329,
      P10: 14.99619505,
      P25: 15.634035,
      P50: 16.44259552,
      P75: 17.38383656,
      P85: 17.95700228,
      P90: 18.37736191,
      P95: 19.05567423,
      P97: 19.5365754,
    },
    {
      Agemos: 27.5,
      L: -1.807261899,
      M: 16.3922434,
      S: 0.077593501,
      P3: 14.40082828,
      P5: 14.61378626,
      P10: 14.95969047,
      P25: 15.5926798,
      P50: 16.3922434,
      P75: 17.3187102,
      P85: 17.88047101,
      P90: 18.29125345,
      P95: 18.9518675,
      P97: 19.41848805,
    },
    {
      Agemos: 28.5,
      L: -1.750118905,
      M: 16.34333654,
      S: 0.076846462,
      P3: 14.36754718,
      P5: 14.57968578,
      P10: 14.92384514,
      P25: 15.55225853,
      P50: 16.34333654,
      P75: 17.2559313,
      P85: 17.80704259,
      P90: 18.20892041,
      P95: 18.85316529,
      P97: 19.30665411,
    },
    {
      Agemos: 29.5,
      L: -1.69481584,
      M: 16.29584097,
      S: 0.076148308,
      P3: 14.33478414,
      P5: 14.54614966,
      P10: 14.88866003,
      P25: 15.51275057,
      P50: 16.29584097,
      P75: 17.19546093,
      P85: 17.73667414,
      P90: 18.1303131,
      P95: 18.75949359,
      P97: 19.20096691,
    },
    {
      Agemos: 30.5,
      L: -1.642106779,
      M: 16.24972371,
      S: 0.075499126,
      P3: 14.30256837,
      P5: 14.51319492,
      P10: 14.85413557,
      P25: 15.47413549,
      P50: 16.24972371,
      P75: 17.13726129,
      P85: 17.66932346,
      P90: 18.05538214,
      P95: 18.67077841,
      P97: 19.10132056,
    },
    {
      Agemos: 31.5,
      L: -1.592744414,
      M: 16.20495268,
      S: 0.074898994,
      P3: 14.27092833,
      P5: 14.48083795,
      P10: 14.82027189,
      P25: 15.43639319,
      P50: 16.20495268,
      P75: 17.0812953,
      P85: 17.6049486,
      P90: 17.98407812,
      P95: 18.58694589,
      P97: 19.00761051,
    },
    {
      Agemos: 32.5,
      L: -1.547442391,
      M: 16.16149871,
      S: 0.074347997,
      P3: 14.23988971,
      P5: 14.44909329,
      P10: 14.7870687,
      P25: 15.39950521,
      P50: 16.16149871,
      P75: 17.02752831,
      P85: 17.54350898,
      P90: 17.91635198,
      P95: 18.50792131,
      P97: 18.91973125,
    },
    {
      Agemos: 33.5,
      L: -1.506902601,
      M: 16.11933258,
      S: 0.073846139,
      P3: 14.2094782,
      P5: 14.41797561,
      P10: 14.7545259,
      P25: 15.36345335,
      P50: 16.11933258,
      P75: 16.97592485,
      P85: 17.48496295,
      P90: 17.85215384,
      P95: 18.43363072,
      P97: 18.83758072,
    },
    {
      Agemos: 34.5,
      L: -1.471770047,
      M: 16.07842758,
      S: 0.07339337,
      P3: 14.17971698,
      P5: 14.38749811,
      P10: 14.72264343,
      P25: 15.32822125,
      P50: 16.07842758,
      P75: 16.92645101,
      P85: 17.42926933,
      P90: 17.79143359,
      P95: 18.3639996,
      P97: 18.76105711,
    },
    {
      Agemos: 35.5,
      L: -1.442628957,
      M: 16.03875896,
      S: 0.072989551,
      P3: 14.15062715,
      P5: 14.35767293,
      P10: 14.69142152,
      P25: 15.2937943,
      P50: 16.03875896,
      P75: 16.87907357,
      P85: 17.37638677,
      P90: 17.73414053,
      P95: 18.29895323,
      P97: 18.69005992,
    },
    {
      Agemos: 36.5,
      L: -1.419991255,
      M: 16.00030401,
      S: 0.072634432,
      P3: 14.12222757,
      P5: 14.32851119,
      P10: 14.66086088,
      P25: 15.26015988,
      P50: 16.00030401,
      P75: 16.8337599,
      P85: 17.32627356,
      P90: 17.68022325,
      P95: 18.23841666,
      P97: 18.62449011,
    },
    {
      Agemos: 37.5,
      L: -1.404277619,
      M: 15.96304277,
      S: 0.072327649,
      P3: 14.09453413,
      P5: 14.30002256,
      P10: 14.63096272,
      P25: 15.22730791,
      P50: 15.96304277,
      P75: 16.79047852,
      P85: 17.27888795,
      P90: 17.62962965,
      P95: 18.1823144,
      P97: 18.5642493,
    },
    {
      Agemos: 38.5,
      L: -1.39586317,
      M: 15.92695418,
      S: 0.07206864,
      P3: 14.06756329,
      P5: 14.27221761,
      P10: 14.60172936,
      P25: 15.1952288,
      P50: 15.92695418,
      P75: 16.74919525,
      P85: 17.23418554,
      P90: 17.58230587,
      P95: 18.13057244,
      P97: 18.50924478,
    },
    {
      Agemos: 39.5,
      L: -1.394935252,
      M: 15.89202582,
      S: 0.071856805,
      P3: 14.04132288,
      P5: 14.24510202,
      P10: 14.57316316,
      P25: 15.16391901,
      P50: 15.89202582,
      P75: 16.70988247,
      P85: 17.19212545,
      P90: 17.53819888,
      P95: 18.08311311,
      P97: 18.45937719,
    },
    {
      Agemos: 40.5,
      L: -1.401671596,
      M: 15.85824093,
      S: 0.071691278,
      P3: 14.0158243,
      P5: 14.21868433,
      P10: 14.54526806,
      P25: 15.13337382,
      P50: 15.85824093,
      P75: 16.67250668,
      P85: 17.15266201,
      P90: 17.49725289,
      P95: 18.03986198,
      P97: 18.41455697,
    },
    {
      Agemos: 41.5,
      L: -1.416100312,
      M: 15.82558822,
      S: 0.071571093,
      P3: 13.99107332,
      P5: 14.19297011,
      P10: 14.51804847,
      P25: 15.10359281,
      P50: 15.82558822,
      P75: 16.63703787,
      P85: 17.11575098,
      P90: 17.45941207,
      P95: 18.00074262,
      P97: 18.37469187,
    },
    {
      Agemos: 42.5,
      L: -1.438164899,
      M: 15.79405728,
      S: 0.071495113,
      P3: 13.96707439,
      P5: 14.16796472,
      P10: 14.49150978,
      P25: 15.07457728,
      P50: 15.79405728,
      P75: 16.60344521,
      P85: 17.08134677,
      P90: 17.42461935,
      P95: 17.96567903,
      P97: 18.33969264,
    },
    {
      Agemos: 43.5,
      L: -1.467669032,
      M: 15.76364255,
      S: 0.071462106,
      P3: 13.94382698,
      P5: 14.14367089,
      P10: 14.4656578,
      P25: 15.04633235,
      P50: 15.76364255,
      P75: 16.57170092,
      P85: 17.04940504,
      P90: 17.39281759,
      P95: 17.9345935,
      P97: 18.30946785,
    },
    {
      Agemos: 44.5,
      L: -1.504376347,
      M: 15.73433668,
      S: 0.071470646,
      P3: 13.9213319,
      P5: 14.12009269,
      P10: 14.44049947,
      P25: 15.01886319,
      P50: 15.73433668,
      P75: 16.54177412,
      P85: 17.0198786,
      P90: 17.36394785,
      P95: 17.90741007,
      P97: 18.28393204,
    },
    {
      Agemos: 45.5,
      L: -1.547942838,
      M: 15.70613566,
      S: 0.071519218,
      P3: 13.8995866,
      P5: 14.09723246,
      P10: 14.4160421,
      P25: 14.99217766,
      P50: 15.70613566,
      P75: 16.51363577,
      P85: 16.99272087,
      P90: 17.33795095,
      P95: 17.88405183,
      P97: 18.26299911,
    },
    {
      Agemos: 46.5,
      L: -1.597896397,
      M: 15.67904062,
      S: 0.071606277,
      P3: 13.87858352,
      P5: 14.07508966,
      P10: 14.392293,
      P25: 14.96628719,
      P50: 15.67904062,
      P75: 16.48726082,
      P85: 16.96788739,
      P90: 17.31476822,
      P95: 17.86443983,
      P97: 18.24657949,
    },
    {
      Agemos: 47.5,
      L: -1.653732283,
      M: 15.65305192,
      S: 0.071730167,
      P3: 13.85831582,
      P5: 14.05366443,
      P10: 14.36926,
      P25: 14.94120325,
      P50: 15.65305192,
      P75: 16.46262258,
      P85: 16.94533215,
      P90: 17.29433999,
      P95: 17.84849622,
      P97: 18.2345875,
    },
    {
      Agemos: 48.5,
      L: -1.714869347,
      M: 15.62817269,
      S: 0.071889214,
      P3: 13.83877404,
      P5: 14.03295533,
      P10: 14.34695084,
      P25: 14.91693911,
      P50: 15.62817269,
      P75: 16.43969657,
      P85: 16.92501028,
      P90: 17.27660684,
      P95: 17.8361423,
      P97: 18.22693633,
    },
    {
      Agemos: 49.5,
      L: -1.780673181,
      M: 15.604408,
      S: 0.072081737,
      P3: 13.81994711,
      P5: 14.01295984,
      P10: 14.32537305,
      P25: 14.89350913,
      P50: 15.604408,
      P75: 16.41845985,
      P85: 16.90687779,
      P90: 17.26150966,
      P95: 17.82729891,
      P97: 18.22353875,
    },
    {
      Agemos: 50.5,
      L: -1.850468473,
      M: 15.58176458,
      S: 0.072306081,
      P3: 13.80182253,
      P5: 13.99367445,
      P10: 14.30453377,
      P25: 14.87092845,
      P50: 15.58176458,
      P75: 16.39889119,
      P85: 16.89089179,
      P90: 17.24898984,
      P95: 17.8218865,
      P97: 18.22430691,
    },
    {
      Agemos: 51.5,
      L: -1.923551865,
      M: 15.56025067,
      S: 0.072560637,
      P3: 13.78438667,
      P5: 13.97509464,
      P10: 14.2844396,
      P25: 14.84921259,
      P50: 15.56025067,
      P75: 16.38097113,
      P85: 16.87701073,
      P90: 17.23898952,
      P95: 17.81982523,
      P97: 18.22915216,
    },
    {
      Agemos: 52.5,
      L: -1.999220429,
      M: 15.5398746,
      S: 0.07284384,
      P3: 13.76762605,
      P5: 13.95721572,
      P10: 14.26509665,
      P25: 14.82837662,
      P50: 15.5398746,
      P75: 16.36468088,
      P85: 16.86519374,
      P90: 17.23145142,
      P95: 17.82103559,
      P97: 18.23798637,
    },
    {
      Agemos: 53.5,
      L: -2.076707178,
      M: 15.52064993,
      S: 0.073154324,
      P3: 13.75152248,
      P5: 13.94002947,
      P10: 14.24650943,
      P25: 14.80843749,
      P50: 15.52064993,
      P75: 16.3500079,
      P85: 16.85540483,
      P90: 17.22632105,
      P95: 17.82543578,
      P97: 18.25071444,
    },
    {
      Agemos: 54.5,
      L: -2.155348017,
      M: 15.50258427,
      S: 0.073490667,
      P3: 13.73606368,
      P5: 13.92353116,
      P10: 14.22868271,
      P25: 14.7894083,
      P50: 15.50258427,
      P75: 16.33693462,
      P85: 16.8476049,
      P90: 17.22354286,
      P95: 17.83294744,
      P97: 18.26724918,
    },
    {
      Agemos: 55.5,
      L: -2.234438552,
      M: 15.48568973,
      S: 0.073851672,
      P3: 13.72123322,
      P5: 13.90771281,
      P10: 14.21161954,
      P25: 14.77130343,
      P50: 15.48568973,
      P75: 16.32544946,
      P85: 16.84175987,
      P90: 17.22306443,
      P95: 17.84349028,
      P97: 18.28749643,
    },
    {
      Agemos: 56.5,
      L: -2.313321723,
      M: 15.46997718,
      S: 0.074236235,
      P3: 13.70701599,
      P5: 13.89256678,
      P10: 14.19532216,
      P25: 14.75413556,
      P50: 15.46997718,
      P75: 16.31554106,
      P85: 16.8378366,
      P90: 17.22483458,
      P95: 17.85698505,
      P97: 18.31136265,
    },
    {
      Agemos: 57.5,
      L: -2.391381273,
      M: 15.45545692,
      S: 0.074643374,
      P3: 13.69339739,
      P5: 13.87808525,
      P10: 14.1797919,
      P25: 14.73791599,
      P50: 15.45545692,
      P75: 16.30719914,
      P85: 16.83580365,
      P90: 17.22880383,
      P95: 17.87335318,
      P97: 18.33875356,
    },
    {
      Agemos: 58.5,
      L: -2.468032491,
      M: 15.44213961,
      S: 0.075072264,
      P3: 13.68036242,
      P5: 13.86425959,
      P10: 14.16502885,
      P25: 14.72265501,
      P50: 15.44213961,
      P75: 16.30041561,
      P85: 16.83563223,
      P90: 17.23492502,
      P95: 17.89251642,
      P97: 18.36957256,
    },
    {
      Agemos: 59.5,
      L: -2.542781541,
      M: 15.43003207,
      S: 0.075522104,
      P3: 13.66789962,
      P5: 13.85108308,
      P10: 14.15103287,
      P25: 14.70836004,
      P50: 15.43003207,
      P75: 16.29517994,
      P85: 16.83729272,
      P90: 17.24315148,
      P95: 17.91439894,
      P97: 18.40372671,
    },
    {
      Agemos: 60.5,
      L: -2.61516595,
      M: 15.41914163,
      S: 0.07599225,
      P3: 13.65599683,
      P5: 13.83854804,
      P10: 14.13780261,
      P25: 14.69503764,
      P50: 15.41914163,
      P75: 16.29148397,
      P85: 16.84075838,
      P90: 17.25343909,
      P95: 17.93892524,
      P97: 18.44112021,
    },
    {
      Agemos: 61.5,
      L: -2.684789516,
      M: 15.40947356,
      S: 0.076482128,
      P3: 13.64464353,
      P5: 13.82664746,
      P10: 14.1253362,
      P25: 14.68269244,
      P50: 15.40947356,
      P75: 16.28931904,
      P85: 16.84600319,
      P90: 17.26574515,
      P95: 17.96602144,
      P97: 18.48165813,
    },
    {
      Agemos: 62.5,
      L: -2.751316949,
      M: 15.40103139,
      S: 0.076991232,
      P3: 13.63383056,
      P5: 13.81537489,
      P10: 14.11363117,
      P25: 14.67132735,
      P50: 15.40103139,
      P75: 16.28867619,
      P85: 16.85300198,
      P90: 17.28002846,
      P95: 17.99561523,
      P97: 18.52524599,
    },
    {
      Agemos: 63.5,
      L: -2.81445945,
      M: 15.39381785,
      S: 0.077519149,
      P3: 13.62354907,
      P5: 13.8047237,
      P10: 14.10268433,
      P25: 14.66094403,
      P50: 15.39381785,
      P75: 16.28954723,
      P85: 16.86173124,
      P90: 17.29624981,
      P95: 18.02763543,
      P97: 18.57178832,
    },
    {
      Agemos: 64.5,
      L: -2.87402476,
      M: 15.38783094,
      S: 0.07806539,
      P3: 13.61379452,
      P5: 13.79469004,
      P10: 14.09249299,
      P25: 14.65154144,
      P50: 15.38783094,
      P75: 16.29191982,
      P85: 16.87216519,
      P90: 17.31436954,
      P95: 18.06201396,
      P97: 18.62119533,
    },
    {
      Agemos: 65.5,
      L: -2.92984048,
      M: 15.38306945,
      S: 0.078629592,
      P3: 13.60456106,
      P5: 13.78526882,
      P10: 14.08305345,
      P25: 14.64311821,
      P50: 15.38306945,
      P75: 16.29578396,
      P85: 16.88428085,
      P90: 17.33435063,
      P95: 18.09868317,
      P97: 18.67337395,
    },
    {
      Agemos: 66.5,
      L: -2.981796828,
      M: 15.37952958,
      S: 0.079211369,
      P3: 13.595845,
      P5: 13.77645627,
      P10: 14.0743621,
      P25: 14.63567131,
      P50: 15.37952958,
      P75: 16.30112773,
      P85: 16.89805459,
      P90: 17.35615653,
      P95: 18.13757754,
      P97: 18.72823379,
    },
    {
      Agemos: 67.5,
      L: -3.029831343,
      M: 15.37720582,
      S: 0.079810334,
      P3: 13.58764387,
      P5: 13.76824936,
      P10: 14.06641528,
      P25: 14.62919656,
      P50: 15.37720582,
      P75: 16.30793819,
      P85: 16.91346275,
      P90: 17.37975148,
      P95: 18.17863327,
      P97: 18.78568583,
    },
    {
      Agemos: 68.5,
      L: -3.073924224,
      M: 15.37609107,
      S: 0.080426086,
      P3: 13.57995642,
      P5: 13.76064574,
      P10: 14.05920932,
      P25: 14.62368881,
      P50: 15.37609107,
      P75: 16.31620135,
      P85: 16.93048153,
      P90: 17.40510042,
      P95: 18.22178824,
      P97: 18.84564261,
    },
    {
      Agemos: 69.5,
      L: -3.114093476,
      M: 15.37617677,
      S: 0.081058206,
      P3: 13.57278245,
      P5: 13.75364373,
      P10: 14.05274063,
      P25: 14.619142,
      P50: 15.37617677,
      P75: 16.32590217,
      P85: 16.94908693,
      P90: 17.43216882,
      P95: 18.26698191,
      P97: 18.90801826,
    },
    {
      Agemos: 70.5,
      L: -3.15039004,
      M: 15.37745304,
      S: 0.081706249,
      P3: 13.56612272,
      P5: 13.7472423,
      P10: 14.04700569,
      P25: 14.61554938,
      P50: 15.37745304,
      P75: 16.33702456,
      P85: 16.96925461,
      P90: 17.46092258,
      P95: 18.31415529,
      P97: 18.97272855,
    },
    {
      Agemos: 71.5,
      L: -3.182893018,
      M: 15.37990886,
      S: 0.082369741,
      P3: 13.55997887,
      P5: 13.74144099,
      P10: 14.04200114,
      P25: 14.6129036,
      P50: 15.37990886,
      P75: 16.34955145,
      P85: 16.99095993,
      P90: 17.49132795,
      P95: 18.36325085,
      P97: 19.03969094,
    },
    {
      Agemos: 72.5,
      L: -3.21170511,
      M: 15.38353217,
      S: 0.083048178,
      P3: 13.55435329,
      P5: 13.73623987,
      P10: 14.03772376,
      P25: 14.61119682,
      P50: 15.38353217,
      P75: 16.36346484,
      P85: 17.01417786,
      P90: 17.52335144,
      P95: 18.41421242,
      P97: 19.10882456,
    },
    {
      Agemos: 73.5,
      L: -3.23694834,
      M: 15.38831005,
      S: 0.083741021,
      P3: 13.54924901,
      P5: 13.73163946,
      P10: 14.03417051,
      P25: 14.61042085,
      P50: 15.38831005,
      P75: 16.37874588,
      P85: 17.03888299,
      P90: 17.55695978,
      P95: 18.46698511,
      P97: 19.18005024,
    },
    {
      Agemos: 74.5,
      L: -3.25876011,
      M: 15.39422883,
      S: 0.0844477,
      P3: 13.54466964,
      P5: 13.72764072,
      P10: 14.03133851,
      P25: 14.61056719,
      P50: 15.39422883,
      P75: 16.39537493,
      P85: 17.06504953,
      P90: 17.59211982,
      P95: 18.52151524,
      P97: 19.25329044,
    },
    {
      Agemos: 75.5,
      L: -3.277281546,
      M: 15.40127496,
      S: 0.085167651,
      P3: 13.54061839,
      P5: 13.72424433,
      P10: 14.02922475,
      P25: 14.61162737,
      P50: 15.40127496,
      P75: 16.41333266,
      P85: 17.09265223,
      P90: 17.62879914,
      P95: 18.57774996,
      P97: 19.32846782,
    },
    {
      Agemos: 76.5,
      L: -3.292683774,
      M: 15.40943252,
      S: 0.085900184,
      P3: 13.53710097,
      P5: 13.72145289,
      P10: 14.02782718,
      P25: 14.61359223,
      P50: 15.40943252,
      P75: 16.43259653,
      P85: 17.12166319,
      P90: 17.66696377,
      P95: 18.63563816,
      P97: 19.4055104,
    },
    {
      Agemos: 77.5,
      L: -3.305124073,
      M: 15.41868691,
      S: 0.086644667,
      P3: 13.53412147,
      P5: 13.71926775,
      P10: 14.02714319,
      P25: 14.61645307,
      P50: 15.41868691,
      P75: 16.45314588,
      P85: 17.15205629,
      P90: 17.70658129,
      P95: 18.69512904,
      P97: 19.48434431,
    },
    {
      Agemos: 78.5,
      L: -3.314768951,
      M: 15.42902273,
      S: 0.087400421,
      P3: 13.53168472,
      P5: 13.71769094,
      P10: 14.0271705,
      P25: 14.62020097,
      P50: 15.42902273,
      P75: 16.47495885,
      P85: 17.18380454,
      P90: 17.74761885,
      P95: 18.75617288,
      P97: 19.56489823,
    },
    {
      Agemos: 79.5,
      L: -3.321785992,
      M: 15.44042439,
      S: 0.088166744,
      P3: 13.52979573,
      P5: 13.71672458,
      P10: 14.027907,
      P25: 14.62482705,
      P50: 15.44042439,
      P75: 16.49801329,
      P85: 17.21688072,
      P90: 17.7900437,
      P95: 18.81872078,
      P97: 19.64710224,
    },
    {
      Agemos: 80.5,
      L: -3.326345795,
      M: 15.45287581,
      S: 0.088942897,
      P3: 13.52845994,
      P5: 13.71637124,
      P10: 14.02935081,
      P25: 14.63032238,
      P50: 15.45287581,
      P75: 16.52228624,
      P85: 17.25125697,
      P90: 17.83382275,
      P95: 18.88272474,
      P97: 19.7308886,
    },
    {
      Agemos: 81.5,
      L: -3.328602731,
      M: 15.46636218,
      S: 0.089728202,
      P3: 13.5276813,
      P5: 13.71663233,
      P10: 14.03149952,
      P25: 14.63667844,
      P50: 15.46636218,
      P75: 16.54775649,
      P85: 17.28690708,
      P90: 17.87892434,
      P95: 18.948137,
      P97: 19.81618799,
    },
    {
      Agemos: 82.5,
      L: -3.328725277,
      M: 15.48086704,
      S: 0.090521875,
      P3: 13.52746565,
      P5: 13.71751078,
      P10: 14.03435159,
      P25: 14.64388639,
      P50: 15.48086704,
      P75: 16.57440029,
      P85: 17.32380254,
      P90: 17.9253152,
      P95: 19.01491107,
      P97: 19.902936,
    },
    {
      Agemos: 83.5,
      L: -3.32687018,
      M: 15.49637465,
      S: 0.091323162,
      P3: 13.52781794,
      P5: 13.71900887,
      P10: 14.03790521,
      P25: 14.65193763,
      P50: 15.49637465,
      P75: 16.6021948,
      P85: 17.3619157,
      P90: 17.97296286,
      P95: 19.08300081,
      P97: 19.99106797,
    },
    {
      Agemos: 84.5,
      L: -3.323188896,
      M: 15.51286936,
      S: 0.092131305,
      P3: 13.52874305,
      P5: 13.72112886,
      P10: 14.0421586,
      P25: 14.66082372,
      P50: 15.51286936,
      P75: 16.6311172,
      P85: 17.40121891,
      P90: 18.02183493,
      P95: 19.15236073,
      P97: 20.08052042,
    },
    {
      Agemos: 85.5,
      L: -3.317827016,
      M: 15.53033563,
      S: 0.092945544,
      P3: 13.53024568,
      P5: 13.72387293,
      P10: 14.04710998,
      P25: 14.67053629,
      P50: 15.53033563,
      P75: 16.66114466,
      P85: 17.44168456,
      P90: 18.07189919,
      P95: 19.22294589,
      P97: 20.17123104,
    },
    {
      Agemos: 86.5,
      L: -3.310923871,
      M: 15.54875807,
      S: 0.093765118,
      P3: 13.53233036,
      P5: 13.72724315,
      P10: 14.05275762,
      P25: 14.68106712,
      P50: 15.54875807,
      P75: 16.69225445,
      P85: 17.48328513,
      P90: 18.12312353,
      P95: 19.29471191,
      P97: 20.2631386,
    },
    {
      Agemos: 87.5,
      L: -3.302612272,
      M: 15.56812143,
      S: 0.09458927,
      P3: 13.53500147,
      P5: 13.7312415,
      P10: 14.05909975,
      P25: 14.69240809,
      P50: 15.56812143,
      P75: 16.72442396,
      P85: 17.52599317,
      P90: 18.17547605,
      P95: 19.36761493,
      P97: 20.35618297,
    },
    {
      Agemos: 88.5,
      L: -3.293018361,
      M: 15.58841065,
      S: 0.095417247,
      P3: 13.53826314,
      P5: 13.73586983,
      P10: 14.06613458,
      P25: 14.7045512,
      P50: 15.58841065,
      P75: 16.7576307,
      P85: 17.56978138,
      P90: 18.22892501,
      P95: 19.44161165,
      P97: 20.45030507,
    },
    {
      Agemos: 89.5,
      L: -3.282260813,
      M: 15.60961101,
      S: 0.096248301,
      P3: 13.54211938,
      P5: 13.7411299,
      P10: 14.0738604,
      P25: 14.71748869,
      P50: 15.60961101,
      P75: 16.79185246,
      P85: 17.61462266,
      P90: 18.28343882,
      P95: 19.51665894,
      P97: 20.54544624,
    },
    {
      Agemos: 90.5,
      L: -3.270454609,
      M: 15.63170735,
      S: 0.097081694,
      P3: 13.54657375,
      P5: 13.74702309,
      P10: 14.08227508,
      P25: 14.73121238,
      P50: 15.63170735,
      P75: 16.82706672,
      P85: 17.66048985,
      P90: 18.33898626,
      P95: 19.59271539,
      P97: 20.64155132,
    },
    {
      Agemos: 91.5,
      L: -3.257703616,
      M: 15.65468563,
      S: 0.097916698,
      P3: 13.55162988,
      P5: 13.753551,
      P10: 14.091377,
      P25: 14.74571498,
      P50: 15.65468563,
      P75: 16.86325188,
      P85: 17.70735632,
      P90: 18.39553611,
      P95: 19.6697383,
      P97: 20.73856241,
    },
    {
      Agemos: 92.5,
      L: -3.244108214,
      M: 15.67853139,
      S: 0.098752593,
      P3: 13.55729098,
      P5: 13.7607148,
      P10: 14.10116411,
      P25: 14.76098876,
      P50: 15.67853139,
      P75: 16.90038609,
      P85: 17.75519541,
      P90: 18.45305756,
      P95: 19.74768666,
      P97: 20.83642509,
    },
    {
      Agemos: 93.5,
      L: -3.229761713,
      M: 15.70323052,
      S: 0.099588675,
      P3: 13.56356006,
      P5: 13.76851558,
      P10: 14.1116344,
      P25: 14.77702622,
      P50: 15.70323052,
      P75: 16.9384478,
      P85: 17.80398077,
      P90: 18.51151998,
      P95: 19.82651963,
      P97: 20.93508525,
    },
    {
      Agemos: 94.5,
      L: -3.214751287,
      M: 15.72876911,
      S: 0.100424251,
      P3: 13.57043987,
      P5: 13.77695423,
      P10: 14.12278578,
      P25: 14.79381994,
      P50: 15.72876911,
      P75: 16.97741575,
      P85: 17.85368626,
      P90: 18.57089304,
      P95: 19.90619689,
      P97: 21.03448975,
    },
    {
      Agemos: 95.5,
      L: -3.199158184,
      M: 15.75513347,
      S: 0.101258643,
      P3: 13.57793291,
      P5: 13.78603145,
      P10: 14.13461608,
      P25: 14.81136258,
      P50: 15.75513347,
      P75: 17.01726889,
      P85: 17.90428602,
      P90: 18.63114669,
      P95: 19.98667859,
      P97: 21.13458634,
    },
    {
      Agemos: 96.5,
      L: -3.18305795,
      M: 15.78231007,
      S: 0.102091189,
      P3: 13.58604144,
      P5: 13.79574779,
      P10: 14.14712305,
      P25: 14.82964685,
      P50: 15.78231007,
      P75: 17.05798645,
      P85: 17.95575444,
      P90: 18.69225117,
      P95: 20.06792538,
      P97: 21.2353237,
    },
    {
      Agemos: 97.5,
      L: -3.166520664,
      M: 15.8102856,
      S: 0.102921245,
      P3: 13.59476742,
      P5: 13.80610355,
      P10: 14.16030433,
      P25: 14.84866554,
      P50: 15.8102856,
      P75: 17.09954792,
      P85: 18.00806622,
      P90: 18.75417705,
      P95: 20.1498984,
      P97: 21.33665138,
    },
    {
      Agemos: 98.5,
      L: -3.1496103,
      M: 15.83904708,
      S: 0.103748189,
      P3: 13.60411246,
      P5: 13.81709878,
      P10: 14.17415743,
      P25: 14.86841153,
      P50: 15.83904708,
      P75: 17.14193325,
      P85: 18.06119653,
      P90: 18.81689537,
      P95: 20.23255928,
      P97: 21.4385195,
    },
    {
      Agemos: 99.5,
      L: -3.132389637,
      M: 15.86858123,
      S: 0.104571386,
      P3: 13.61407844,
      P5: 13.82873375,
      P10: 14.18867999,
      P25: 14.88887764,
      P50: 15.86858123,
      P75: 17.18512191,
      P85: 18.1151201,
      P90: 18.8803769,
      P95: 20.31587026,
      P97: 21.54088032,
    },
    {
      Agemos: 100.5,
      L: -3.114911153,
      M: 15.89887562,
      S: 0.105390269,
      P3: 13.62466611,
      P5: 13.84100784,
      P10: 14.20386916,
      P25: 14.91005687,
      P50: 15.89887562,
      P75: 17.22909475,
      P85: 18.16981303,
      P90: 18.94459357,
      P95: 20.39979379,
      P97: 21.64368493,
    },
    {
      Agemos: 101.5,
      L: -3.097226399,
      M: 15.92991765,
      S: 0.106204258,
      P3: 13.63587653,
      P5: 13.85392068,
      P10: 14.21972223,
      P25: 14.93194218,
      P50: 15.92991765,
      P75: 17.2738323,
      P85: 18.22525119,
      P90: 19.00951733,
      P95: 20.48429323,
      P97: 21.74688677,
    },
    {
      Agemos: 102.5,
      L: -3.079383079,
      M: 15.96169481,
      S: 0.107012788,
      P3: 13.64771054,
      P5: 13.86747174,
      P10: 14.23623642,
      P25: 14.95452659,
      P50: 15.96169481,
      P75: 17.31931519,
      P85: 18.28141055,
      P90: 19.07512019,
      P95: 20.56933216,
      P97: 21.85043987,
    },
    {
      Agemos: 103.5,
      L: -3.061423765,
      M: 15.99419489,
      S: 0.107815327,
      P3: 13.66016853,
      P5: 13.88166013,
      P10: 14.25340873,
      P25: 14.97780313,
      P50: 15.99419489,
      P75: 17.36552463,
      P85: 18.33826774,
      P90: 19.14137485,
      P95: 20.6548748,
      P97: 21.95429887,
    },
    {
      Agemos: 104.5,
      L: -3.043386071,
      M: 16.02740607,
      S: 0.108611374,
      P3: 13.67325038,
      P5: 13.89648454,
      P10: 14.27123591,
      P25: 15.00176488,
      P50: 16.02740607,
      P75: 17.4124424,
      P85: 18.39579999,
      P90: 19.20825462,
      P95: 20.74088584,
      P97: 22.05841878,
    },
    {
      Agemos: 105.5,
      L: -3.025310003,
      M: 16.0613159,
      S: 0.109400388,
      P3: 13.68695684,
      P5: 13.91194439,
      P10: 14.28971516,
      P25: 15.02640489,
      P50: 16.0613159,
      P75: 17.46004925,
      P85: 18.45398355,
      P90: 19.27573205,
      P95: 20.82733059,
      P97: 22.16275742,
    },
    {
      Agemos: 106.5,
      L: -3.007225737,
      M: 16.09591292,
      S: 0.110181915,
      P3: 13.70128712,
      P5: 13.92803786,
      P10: 14.30884292,
      P25: 15.05171626,
      P50: 16.09591292,
      P75: 17.50832768,
      P85: 18.51279647,
      P90: 19.34378135,
      P95: 20.9141748,
      P97: 22.26727123,
    },
    {
      Agemos: 107.5,
      L: -2.989164598,
      M: 16.13118532,
      S: 0.110955478,
      P3: 13.71624103,
      P5: 13.94476358,
      P10: 14.32861592,
      P25: 15.07769209,
      P50: 16.13118532,
      P75: 17.55725951,
      P85: 18.57221621,
      P90: 19.41237631,
      P95: 21.00138483,
      P97: 22.37191887,
    },
    {
      Agemos: 108.5,
      L: -2.971148225,
      M: 16.16712234,
      S: 0.111720691,
      P3: 13.73181676,
      P5: 13.9621189,
      P10: 14.3490301,
      P25: 15.10432556,
      P50: 16.16712234,
      P75: 17.60682848,
      P85: 18.63222223,
      P90: 19.48149248,
      P95: 21.08892746,
      P97: 22.47665729,
    },
    {
      Agemos: 109.5,
      L: -2.953208047,
      M: 16.20371168,
      S: 0.112477059,
      P3: 13.7480147,
      P5: 13.98010297,
      P10: 14.37008248,
      P25: 15.13160968,
      P50: 16.20371168,
      P75: 17.65701563,
      P85: 18.69279128,
      P90: 19.55110329,
      P95: 21.17677023,
      P97: 22.58144893,
    },
    {
      Agemos: 110.5,
      L: -2.935363951,
      M: 16.24094239,
      S: 0.1132242,
      P3: 13.76483325,
      P5: 13.99871328,
      P10: 14.39176909,
      P25: 15.15953759,
      P50: 16.24094239,
      P75: 17.70780444,
      P85: 18.75390266,
      P90: 19.62118443,
      P95: 21.26488107,
      P97: 22.68625363,
    },
    {
      Agemos: 111.5,
      L: -2.917635157,
      M: 16.27880346,
      S: 0.113961734,
      P3: 13.78227084,
      P5: 14.01794738,
      P10: 14.41408598,
      P25: 15.1881024,
      P50: 16.27880346,
      P75: 17.75917825,
      P85: 18.81553565,
      P90: 19.69171163,
      P95: 21.3532285,
      P97: 22.79103265,
    },
    {
      Agemos: 112.5,
      L: -2.900039803,
      M: 16.31728385,
      S: 0.114689291,
      P3: 13.80032593,
      P5: 14.03780282,
      P10: 14.43702918,
      P25: 15.21729722,
      P50: 16.31728385,
      P75: 17.81112044,
      P85: 18.87766958,
      P90: 19.76266083,
      P95: 21.44178165,
      P97: 22.89574847,
    },
    {
      Agemos: 113.5,
      L: -2.882593796,
      M: 16.35637267,
      S: 0.115406523,
      P3: 13.81899666,
      P5: 14.0582769,
      P10: 14.46059456,
      P25: 15.2471151,
      P50: 16.35637267,
      P75: 17.86361469,
      P85: 18.94028419,
      P90: 19.83400841,
      P95: 21.53051015,
      P97: 23.00036429,
    },
    {
      Agemos: 114.5,
      L: -2.865311266,
      M: 16.39605916,
      S: 0.116113097,
      P3: 13.83828098,
      P5: 14.07936677,
      P10: 14.48477791,
      P25: 15.27754914,
      P50: 16.39605916,
      P75: 17.91664493,
      P85: 19.00335954,
      P90: 19.90573115,
      P95: 21.61938426,
      P97: 23.10484416,
    },
    {
      Agemos: 115.5,
      L: -2.848204697,
      M: 16.43633265,
      S: 0.116808702,
      P3: 13.85817663,
      P5: 14.10106937,
      P10: 14.50957485,
      P25: 15.30859238,
      P50: 16.43633265,
      P75: 17.97019532,
      P85: 19.06687603,
      P90: 19.97780623,
      P95: 21.70837477,
      P97: 23.20915296,
    },
    {
      Agemos: 116.5,
      L: -2.831285052,
      M: 16.47718256,
      S: 0.117493042,
      P3: 13.87868113,
      P5: 14.1233815,
      P10: 14.53498094,
      P25: 15.34023785,
      P50: 16.47718256,
      P75: 18.02425026,
      P85: 19.13081436,
      P90: 20.05021121,
      P95: 21.79745308,
      P97: 23.31325641,
    },
    {
      Agemos: 117.5,
      L: -2.81456189,
      M: 16.51859843,
      S: 0.11816584,
      P3: 13.8997918,
      P5: 14.14629977,
      P10: 14.56099157,
      P25: 15.37247856,
      P50: 16.51859843,
      P75: 18.0787944,
      P85: 19.19515554,
      P90: 20.12292406,
      P95: 21.88659115,
      P97: 23.41712109,
    },
    {
      Agemos: 118.5,
      L: -2.79804347,
      M: 16.56056987,
      S: 0.118826835,
      P3: 13.92150576,
      P5: 14.16982063,
      P10: 14.58760204,
      P25: 15.40530752,
      P50: 16.56056987,
      P75: 18.13381258,
      P85: 19.25988094,
      P90: 20.19592313,
      P95: 21.97576153,
      P97: 23.5207144,
    },
    {
      Agemos: 119.5,
      L: -2.781736856,
      M: 16.60308661,
      S: 0.119475785,
      P3: 13.94381995,
      P5: 14.19394037,
      P10: 14.61480754,
      P25: 15.43871766,
      P50: 16.60308661,
      P75: 18.1892899,
      P85: 19.3249722,
      P90: 20.26918717,
      P95: 22.06493737,
      P97: 23.62400462,
    },
    {
      Agemos: 120.5,
      L: -2.765648008,
      M: 16.64613844,
      S: 0.120112464,
      P3: 13.96673109,
      P5: 14.21865508,
      P10: 14.64260311,
      P25: 15.47270195,
      P50: 16.64613844,
      P75: 18.24521166,
      P85: 19.3904113,
      P90: 20.34269534,
      P95: 22.15409238,
      P97: 23.72696086,
    },
    {
      Agemos: 121.5,
      L: -2.749782197,
      M: 16.68971518,
      S: 0.120736656,
      P3: 13.99023581,
      P5: 14.24396081,
      P10: 14.67098376,
      P25: 15.50725326,
      P50: 16.68971518,
      P75: 18.3015633,
      P85: 19.45618042,
      P90: 20.41642709,
      P95: 22.24320091,
      P97: 23.82955322,
    },
    {
      Agemos: 122.5,
      L: -2.734142443,
      M: 16.73380695,
      S: 0.121348181,
      P3: 14.01433021,
      P5: 14.26985313,
      P10: 14.6999442,
      P25: 15.5423645,
      P50: 16.73380695,
      P75: 18.35833084,
      P85: 19.52226247,
      P90: 20.49036263,
      P95: 22.33223786,
      P97: 23.93175206,
    },
    {
      Agemos: 123.5,
      L: -2.718732873,
      M: 16.77840363,
      S: 0.121946849,
      P3: 14.0390107,
      P5: 14.29632789,
      P10: 14.72947925,
      P25: 15.57802849,
      P50: 16.77840363,
      P75: 18.41549995,
      P85: 19.58864006,
      P90: 20.56448202,
      P95: 22.42117875,
      P97: 24.0335295,
    },
    {
      Agemos: 124.5,
      L: -2.703555506,
      M: 16.82349538,
      S: 0.122532501,
      P3: 14.06427317,
      P5: 14.32338048,
      P10: 14.75958347,
      P25: 15.61423805,
      P50: 16.82349538,
      P75: 18.4730569,
      P85: 19.65529649,
      P90: 20.63876611,
      P95: 22.5099997,
      P97: 24.1348579,
    },
    {
      Agemos: 125.5,
      L: -2.688611957,
      M: 16.86907238,
      S: 0.123104991,
      P3: 14.09011344,
      P5: 14.35100623,
      P10: 14.79025137,
      P25: 15.65098595,
      P50: 16.86907238,
      P75: 18.53098805,
      P85: 19.72221531,
      P90: 20.71319602,
      P95: 22.59867744,
      P97: 24.23571063,
    },
    {
      Agemos: 126.5,
      L: -2.673903164,
      M: 16.91512487,
      S: 0.123664186,
      P3: 14.11652711,
      P5: 14.37920031,
      P10: 14.8214773,
      P25: 15.68826493,
      P50: 16.91512487,
      P75: 18.58928,
      P85: 19.78938034,
      P90: 20.78775327,
      P95: 22.68718928,
      P97: 24.33606191,
    },
    {
      Agemos: 127.5,
      L: -2.659429443,
      M: 16.96164317,
      S: 0.124209969,
      P3: 14.14350962,
      P5: 14.40795771,
      P10: 14.85325552,
      P25: 15.72606768,
      P50: 16.96164317,
      P75: 18.64791953,
      P85: 19.85677572,
      P90: 20.86241981,
      P95: 22.77551316,
      P97: 24.43588681,
    },
    {
      Agemos: 128.5,
      L: -2.645190534,
      M: 17.00861766,
      S: 0.124742239,
      P3: 14.17105623,
      P5: 14.43727329,
      P10: 14.88558018,
      P25: 15.76438688,
      P50: 17.00861766,
      P75: 18.70689364,
      P85: 19.92438591,
      P90: 20.93717795,
      P95: 22.86362762,
      P97: 24.53516127,
    },
    {
      Agemos: 129.5,
      L: -2.631185649,
      M: 17.05603879,
      S: 0.125260905,
      P3: 14.19916203,
      P5: 14.46714175,
      P10: 14.91844531,
      P25: 15.80321513,
      P50: 17.05603879,
      P75: 18.76618951,
      P85: 19.99219565,
      P90: 21.01201043,
      P95: 22.95151182,
      P97: 24.63386207,
    },
    {
      Agemos: 130.5,
      L: -2.617413511,
      M: 17.10389705,
      S: 0.125765895,
      P3: 14.22782193,
      P5: 14.49755765,
      P10: 14.95184482,
      P25: 15.84254502,
      P50: 17.10389705,
      P75: 18.82579454,
      P85: 20.06019002,
      P90: 21.08690037,
      P95: 23.03914551,
      P97: 24.73196683,
    },
    {
      Agemos: 131.5,
      L: -2.603872392,
      M: 17.15218302,
      S: 0.126257147,
      P3: 14.25703066,
      P5: 14.52851537,
      P10: 14.98577251,
      P25: 15.8823691,
      P50: 17.15218302,
      P75: 18.88569633,
      P85: 20.12835437,
      P90: 21.1618313,
      P95: 23.12650908,
      P97: 24.82945404,
    },
    {
      Agemos: 132.5,
      L: -2.590560148,
      M: 17.20088732,
      S: 0.126734613,
      P3: 14.28678279,
      P5: 14.56000917,
      P10: 15.02022206,
      P25: 15.92267985,
      P50: 17.20088732,
      P75: 18.94588267,
      P85: 20.19667437,
      P90: 21.23678716,
      P95: 23.21358351,
      P97: 24.92630305,
    },
    {
      Agemos: 133.5,
      L: -2.577474253,
      M: 17.25000062,
      S: 0.12719826,
      P3: 14.31707271,
      P5: 14.59203313,
      P10: 15.05518706,
      P25: 15.96346973,
      P50: 17.25000062,
      P75: 19.00634155,
      P85: 20.265136,
      P90: 21.31175226,
      P95: 23.30035043,
      P97: 25.02249405,
    },
    {
      Agemos: 134.5,
      L: -2.564611831,
      M: 17.29951367,
      S: 0.127648067,
      P3: 14.34789463,
      P5: 14.62458122,
      P10: 15.09066095,
      P25: 16.00473115,
      P50: 17.29951367,
      P75: 19.06706116,
      P85: 20.33372554,
      P90: 21.38671134,
      P95: 23.38679204,
      P97: 25.11800807,
    },
    {
      Agemos: 135.5,
      L: -2.551969684,
      M: 17.34941726,
      S: 0.128084023,
      P3: 14.37924262,
      P5: 14.65764722,
      P10: 15.1266371,
      P25: 16.04645648,
      P50: 17.34941726,
      P75: 19.12802987,
      P85: 20.40242956,
      P90: 21.46164953,
      P95: 23.4728912,
      P97: 25.21282703,
    },
    {
      Agemos: 136.5,
      L: -2.539539972,
      M: 17.39970308,
      S: 0.128506192,
      P3: 14.41110933,
      P5: 14.69122381,
      P10: 15.16310811,
      P25: 16.08863812,
      P50: 17.39970308,
      P75: 19.18923776,
      P85: 20.4712365,
      P90: 21.53655365,
      P95: 23.55863129,
      P97: 25.3069313,
    },
    {
      Agemos: 137.5,
      L: -2.527325681,
      M: 17.45036072,
      S: 0.128914497,
      P3: 14.44349038,
      P5: 14.72530601,
      P10: 15.20006805,
      P25: 16.13126821,
      P50: 17.45036072,
      P75: 19.25067129,
      P85: 20.54013114,
      P90: 21.61140764,
      P95: 23.64399652,
      P97: 25.40030815,
    },
    {
      Agemos: 138.5,
      L: -2.515320235,
      M: 17.50138161,
      S: 0.129309001,
      P3: 14.47637861,
      P5: 14.75988661,
      P10: 15.23750958,
      P25: 16.17433905,
      P50: 17.50138161,
      P75: 19.31232029,
      P85: 20.60910189,
      P90: 21.6861986,
      P95: 23.72897155,
      P97: 25.49294066,
    },
    {
      Agemos: 139.5,
      L: -2.503519447,
      M: 17.55275674,
      S: 0.129689741,
      P3: 14.50976741,
      P5: 14.79495883,
      P10: 15.27542561,
      P25: 16.21784281,
      P50: 17.55275674,
      P75: 19.37417388,
      P85: 20.6781365,
      P90: 21.76091323,
      P95: 23.81354171,
      P97: 25.58481418,
    },
    {
      Agemos: 140.5,
      L: -2.491918934,
      M: 17.60447714,
      S: 0.130056765,
      P3: 14.54365005,
      P5: 14.83051578,
      P10: 15.31380892,
      P25: 16.26177162,
      P50: 17.60447714,
      P75: 19.43622137,
      P85: 20.74722303,
      P90: 21.83553865,
      P95: 23.89769296,
      P97: 25.67591492,
    },
    {
      Agemos: 141.5,
      L: -2.480514136,
      M: 17.6565339,
      S: 0.130410133,
      P3: 14.57801961,
      P5: 14.86655039,
      P10: 15.3526522,
      P25: 16.30611758,
      P50: 17.6565339,
      P75: 19.49845226,
      P85: 20.81634983,
      P90: 21.91006237,
      P95: 23.98141187,
      P97: 25.76622996,
    },
    {
      Agemos: 142.5,
      L: -2.469300331,
      M: 17.70891811,
      S: 0.130749913,
      P3: 14.61286903,
      P5: 14.90305549,
      P10: 15.39194804,
      P25: 16.35087272,
      P50: 17.70891811,
      P75: 19.5608562,
      P85: 20.88550554,
      P90: 21.98447229,
      P95: 24.06468566,
      P97: 25.85574725,
    },
    {
      Agemos: 143.5,
      L: -2.458272656,
      M: 17.76162094,
      S: 0.131076187,
      P3: 14.6481911,
      P5: 14.94002374,
      P10: 15.43168891,
      P25: 16.39602903,
      P50: 17.76162094,
      P75: 19.62342306,
      P85: 20.95467909,
      P90: 22.05875672,
      P95: 24.14750216,
      P97: 25.94445561,
    },
    {
      Agemos: 144.5,
      L: -2.447426113,
      M: 17.81463359,
      S: 0.131389042,
      P3: 14.68397844,
      P5: 14.97744768,
      P10: 15.47186716,
      P25: 16.44157847,
      P50: 17.81463359,
      P75: 19.68614286,
      P85: 21.02385969,
      P90: 22.13290433,
      P95: 24.22984982,
      P97: 26.0323447,
    },
    {
      Agemos: 145.5,
      L: -2.436755595,
      M: 17.86794729,
      S: 0.131688579,
      P3: 14.72022354,
      P5: 15.01531973,
      P10: 15.51247506,
      P25: 16.48751291,
      P50: 17.86794729,
      P75: 19.74900579,
      P85: 21.09303687,
      P90: 22.20690421,
      P95: 24.31171774,
      P97: 26.11940509,
    },
    {
      Agemos: 146.5,
      L: -2.426255887,
      M: 17.92155332,
      S: 0.131974905,
      P3: 14.75691872,
      P5: 15.05363215,
      P10: 15.55350476,
      P25: 16.53382421,
      P50: 17.92155332,
      P75: 19.81200224,
      P85: 21.1622004,
      P90: 22.28074585,
      P95: 24.39309562,
      P97: 26.20562819,
    },
    {
      Agemos: 147.5,
      L: -2.415921689,
      M: 17.97544299,
      S: 0.132248138,
      P3: 14.79405616,
      P5: 15.09237707,
      P10: 15.5949483,
      P25: 16.58050415,
      P50: 17.97544299,
      P75: 19.87512275,
      P85: 21.23134038,
      P90: 22.35441912,
      P95: 24.47397382,
      P97: 26.2910063,
    },
    {
      Agemos: 148.5,
      L: -2.405747619,
      M: 18.02960765,
      S: 0.132508403,
      P3: 14.83162791,
      P5: 15.13154651,
      P10: 15.63679762,
      P25: 16.62754449,
      P50: 18.02960765,
      P75: 19.93835804,
      P85: 21.30044717,
      P90: 22.42791427,
      P95: 24.55434332,
      P97: 26.37553258,
    },
    {
      Agemos: 149.5,
      L: -2.395728233,
      M: 18.08403868,
      S: 0.132755834,
      P3: 14.86962583,
      P5: 15.17113234,
      P10: 15.67904455,
      P25: 16.67493692,
      P50: 18.08403868,
      P75: 20.001699,
      P85: 21.36951141,
      P90: 22.50122197,
      P95: 24.63419572,
      P97: 26.45920106,
    },
    {
      Agemos: 150.5,
      L: -2.385858029,
      M: 18.1387275,
      S: 0.132990575,
      P3: 14.90804168,
      P5: 15.2111263,
      P10: 15.72168083,
      P25: 16.72267307,
      P50: 18.1387275,
      P75: 20.06513669,
      P85: 21.43852405,
      P90: 22.57433325,
      P95: 24.71352325,
      P97: 26.54200665,
    },
    {
      Agemos: 151.5,
      L: -2.376131459,
      M: 18.19366555,
      S: 0.133212776,
      P3: 14.94686707,
      P5: 15.25152001,
      P10: 15.76469808,
      P25: 16.77074454,
      P50: 18.19366555,
      P75: 20.12866232,
      P85: 21.5074763,
      P90: 22.64723955,
      P95: 24.79231881,
      P97: 26.62394515,
    },
    {
      Agemos: 152.5,
      L: -2.366542942,
      M: 18.24884431,
      S: 0.133422595,
      P3: 14.98609343,
      P5: 15.29230496,
      P10: 15.80808782,
      P25: 16.81914286,
      P50: 18.24884431,
      P75: 20.1922673,
      P85: 21.57635964,
      P90: 22.7199327,
      P95: 24.87057587,
      P97: 26.7050132,
    },
    {
      Agemos: 153.5,
      L: -2.357086871,
      M: 18.3042553,
      S: 0.133620197,
      P3: 15.0257121,
      P5: 15.33347251,
      P10: 15.85184148,
      P25: 16.86785954,
      P50: 18.3042553,
      P75: 20.25594317,
      P85: 21.64516586,
      P90: 22.79240491,
      P95: 24.9482886,
      P97: 26.78520835,
    },
    {
      Agemos: 154.5,
      L: -2.347757625,
      M: 18.35989003,
      S: 0.133805756,
      P3: 15.06571426,
      P5: 15.37501389,
      P10: 15.89595038,
      P25: 16.91688599,
      P50: 18.35989003,
      P75: 20.31968165,
      P85: 21.71388701,
      P90: 22.86464879,
      P95: 25.02545175,
      P97: 26.86452902,
    },
    {
      Agemos: 155.5,
      L: -2.338549576,
      M: 18.41574009,
      S: 0.133979452,
      P3: 15.10609095,
      P5: 15.41692023,
      P10: 15.94040572,
      P25: 16.96621361,
      P50: 18.41574009,
      P75: 20.38347461,
      P85: 21.78251541,
      P90: 22.93665733,
      P95: 25.10206075,
      P97: 26.94297451,
    },
    {
      Agemos: 156.5,
      L: -2.3294571,
      M: 18.47179706,
      S: 0.13414147,
      P3: 15.14683307,
      P5: 15.4591825,
      P10: 15.98519864,
      P25: 17.01583373,
      P50: 18.47179706,
      P75: 20.44731409,
      P85: 21.85104367,
      P90: 23.0084239,
      P95: 25.17811163,
      P97: 27.02054498,
    },
    {
      Agemos: 157.5,
      L: -2.320474586,
      M: 18.52805255,
      S: 0.134292005,
      P3: 15.1879314,
      P5: 15.50179159,
      P10: 16.03032014,
      P25: 17.06573761,
      P50: 18.52805255,
      P75: 20.51119228,
      P85: 21.91946467,
      P90: 23.07994228,
      P95: 25.25360108,
      P97: 27.09724149,
    },
    {
      Agemos: 158.5,
      L: -2.311596446,
      M: 18.5844982,
      S: 0.134431256,
      P3: 15.22937658,
      P5: 15.54473823,
      P10: 16.07576115,
      P25: 17.11591649,
      P50: 18.5844982,
      P75: 20.57510153,
      P85: 21.98777156,
      P90: 23.15120662,
      P95: 25.32852641,
      P97: 27.173066,
    },
    {
      Agemos: 159.5,
      L: -2.302817124,
      M: 18.64112567,
      S: 0.134559427,
      P3: 15.27115913,
      P5: 15.58801305,
      P10: 16.12151248,
      P25: 17.16636153,
      P50: 18.64112567,
      P75: 20.63903434,
      P85: 22.05595776,
      P90: 23.22221145,
      P95: 25.4028856,
      P97: 27.24802132,
    },
    {
      Agemos: 160.5,
      L: -2.294131107,
      M: 18.69792663,
      S: 0.134676731,
      P3: 15.31326943,
      P5: 15.63160658,
      P10: 16.16756484,
      P25: 17.21706384,
      P50: 18.69792663,
      P75: 20.70298336,
      P85: 22.12401698,
      P90: 23.2929517,
      P95: 25.47667722,
      P97: 27.32211115,
    },
    {
      Agemos: 161.5,
      L: -2.285532933,
      M: 18.75489278,
      S: 0.134783385,
      P3: 15.35569774,
      P5: 15.67550919,
      P10: 16.21390888,
      P25: 17.26801449,
      P50: 18.75489278,
      P75: 20.76694139,
      P85: 22.19194317,
      P90: 23.36342268,
      P95: 25.54990054,
      P97: 27.39534011,
    },
    {
      Agemos: 162.5,
      L: -2.277017201,
      M: 18.81201584,
      S: 0.134879611,
      P3: 15.39843418,
      P5: 15.71971118,
      P10: 16.2605351,
      P25: 17.31920447,
      P50: 18.81201584,
      P75: 20.83090139,
      P85: 22.25973057,
      P90: 23.43362008,
      P95: 25.62255542,
      P97: 27.46771366,
    },
    {
      Agemos: 163.5,
      L: -2.268578584,
      M: 18.86928753,
      S: 0.134965637,
      P3: 15.44146877,
      P5: 15.7642027,
      P10: 16.30743394,
      P25: 17.37062472,
      P50: 18.86928753,
      P75: 20.89485646,
      P85: 22.32737368,
      P90: 23.50353999,
      P95: 25.6946424,
      P97: 27.53923819,
    },
    {
      Agemos: 164.5,
      L: -2.260211837,
      M: 18.92669959,
      S: 0.135041695,
      P3: 15.48479139,
      P5: 15.80897382,
      P10: 16.35459573,
      P25: 17.42226614,
      P50: 18.92669959,
      P75: 20.95879983,
      P85: 22.39486727,
      P90: 23.57317887,
      P95: 25.76616263,
      P97: 27.60992094,
    },
    {
      Agemos: 165.5,
      L: -2.251911809,
      M: 18.98424378,
      S: 0.135108024,
      P3: 15.52839182,
      P5: 15.85401448,
      P10: 16.40201071,
      P25: 17.47411956,
      P50: 18.98424378,
      P75: 21.02272489,
      P85: 22.46220636,
      P90: 23.64253356,
      P95: 25.83711794,
      P97: 27.67977007,
    },
    {
      Agemos: 166.5,
      L: -2.243673453,
      M: 19.04191185,
      S: 0.135164867,
      P3: 15.57225971,
      P5: 15.89931451,
      P10: 16.44966902,
      P25: 17.52617573,
      P50: 19.04191185,
      P75: 21.08662518,
      P85: 22.52938625,
      P90: 23.71160129,
      P95: 25.90751079,
      P97: 27.74879463,
    },
    {
      Agemos: 167.5,
      L: -2.235491842,
      M: 19.09969557,
      S: 0.135212469,
      P3: 15.6163846,
      P5: 15.94486363,
      P10: 16.49756071,
      P25: 17.57842539,
      P50: 19.09969557,
      P75: 21.15049434,
      P85: 22.5964025,
      P90: 23.78037969,
      P95: 25.97734428,
      P97: 27.81700453,
    },
    {
      Agemos: 168.5,
      L: -2.227362173,
      M: 19.15758672,
      S: 0.135251083,
      P3: 15.66075591,
      P5: 15.99065148,
      P10: 16.54567574,
      P25: 17.63085916,
      P50: 19.15758672,
      P75: 21.21432619,
      P85: 22.66325092,
      P90: 23.84886673,
      P95: 26.04662217,
      P97: 27.88441062,
    },
    {
      Agemos: 169.5,
      L: -2.21927979,
      M: 19.21557707,
      S: 0.135280963,
      P3: 15.70536296,
      P5: 16.03666757,
      P10: 16.59400398,
      P25: 17.68346764,
      P50: 19.21557707,
      P75: 21.27811465,
      P85: 22.7299276,
      P90: 23.91706081,
      P95: 26.11534887,
      P97: 27.95102462,
    },
    {
      Agemos: 170.5,
      L: -2.211240187,
      M: 19.27365839,
      S: 0.135302371,
      P3: 15.75019496,
      P5: 16.0829013,
      P10: 16.64253518,
      P25: 17.73624136,
      P50: 19.27365839,
      P75: 21.34185379,
      P85: 22.79642886,
      P90: 23.98496068,
      P95: 26.18352944,
      P97: 28.01685913,
    },
    {
      Agemos: 171.5,
      L: -2.203239029,
      M: 19.33182247,
      S: 0.135315568,
      P3: 15.79524102,
      P5: 16.129342,
      P10: 16.69125904,
      P25: 17.78917078,
      P50: 19.33182247,
      P75: 21.40553781,
      P85: 22.86275131,
      P90: 24.05256549,
      P95: 26.25116959,
      P97: 28.08192768,
    },
    {
      Agemos: 172.5,
      L: -2.195272161,
      M: 19.39006106,
      S: 0.135320824,
      P3: 15.84049013,
      P5: 16.17597888,
      P10: 16.74016514,
      P25: 17.84224628,
      P50: 19.39006106,
      P75: 21.46916104,
      P85: 22.92889179,
      P90: 24.11987476,
      P95: 26.31827569,
      P97: 28.14624467,
    },
    {
      Agemos: 173.5,
      L: -2.187335625,
      M: 19.44836594,
      S: 0.135318407,
      P3: 15.8859312,
      P5: 16.22280106,
      P10: 16.78924297,
      P25: 17.8954582,
      P50: 19.44836594,
      P75: 21.53271791,
      P85: 22.99484741,
      P90: 24.18688841,
      P95: 26.38485476,
      P97: 28.20982542,
    },
    {
      Agemos: 174.5,
      L: -2.179425674,
      M: 19.50672885,
      S: 0.135308594,
      P3: 15.93155303,
      P5: 16.26979755,
      P10: 16.83848195,
      P25: 17.9487968,
      P50: 19.50672885,
      P75: 21.596203,
      P85: 23.06061552,
      P90: 24.25360674,
      P95: 26.45091448,
      P97: 28.27268613,
    },
    {
      Agemos: 175.5,
      L: -2.171538789,
      M: 19.56514153,
      S: 0.135291662,
      P3: 15.97734432,
      P5: 16.31695728,
      P10: 16.8878714,
      P25: 18.00225228,
      P50: 19.56514153,
      P75: 21.659611,
      P85: 23.12619375,
      P90: 24.3200304,
      P95: 26.51646321,
      P97: 28.33484391,
    },
    {
      Agemos: 176.5,
      L: -2.163671689,
      M: 19.62359571,
      S: 0.135267891,
      P3: 16.02329371,
      P5: 16.3642691,
      P10: 16.93740054,
      P25: 18.05581474,
      P50: 19.62359571,
      P75: 21.72293673,
      P85: 23.19157996,
      P90: 24.38616048,
      P95: 26.58150994,
      P97: 28.39631678,
    },
    {
      Agemos: 177.5,
      L: -2.155821357,
      M: 19.6820831,
      S: 0.135237567,
      P3: 16.0693897,
      P5: 16.41172173,
      P10: 16.98705851,
      P25: 18.10947424,
      P50: 19.6820831,
      P75: 21.78617511,
      P85: 23.25677226,
      P90: 24.4519984,
      P95: 26.64606434,
      P97: 28.45712363,
    },
    {
      Agemos: 178.5,
      L: -2.147985046,
      M: 19.74059538,
      S: 0.135200976,
      P3: 16.11562074,
      P5: 16.45930384,
      P10: 17.03683437,
      P25: 18.16322076,
      P50: 19.74059538,
      P75: 21.84932117,
      P85: 23.32176902,
      P90: 24.517546,
      P95: 26.71013675,
      P97: 28.51728428,
    },
    {
      Agemos: 179.5,
      L: -2.140160305,
      M: 19.7991242,
      S: 0.135158409,
      P3: 16.1619752,
      P5: 16.50700399,
      P10: 17.08671707,
      P25: 18.21704418,
      P50: 19.7991242,
      P75: 21.91237008,
      P85: 23.38656886,
      P90: 24.5828055,
      P95: 26.77373817,
      P97: 28.57681946,
    },
    {
      Agemos: 180.5,
      L: -2.132344989,
      M: 19.85766121,
      S: 0.135110159,
      P3: 16.20844134,
      P5: 16.55481067,
      P10: 17.13669551,
      P25: 18.27093434,
      P50: 19.85766121,
      P75: 21.97531708,
      P85: 23.45117065,
      P90: 24.64777949,
      P95: 26.83688026,
      P97: 28.63575076,
    },
    {
      Agemos: 181.5,
      L: -2.124537282,
      M: 19.916198,
      S: 0.135056522,
      P3: 16.25500735,
      P5: 16.60271228,
      P10: 17.18675845,
      P25: 18.32488097,
      P50: 19.916198,
      P75: 22.03815755,
      P85: 23.5155735,
      P90: 24.71247097,
      P95: 26.89957537,
      P97: 28.69410072,
    },
    {
      Agemos: 182.5,
      L: -2.116735712,
      M: 19.97472615,
      S: 0.134997797,
      P3: 16.30166137,
      P5: 16.65069714,
      P10: 17.23689462,
      P25: 18.37887373,
      P50: 19.97472615,
      P75: 22.10088696,
      P85: 23.57977678,
      P90: 24.77688329,
      P95: 26.96183652,
      P97: 28.75189275,
    },
    {
      Agemos: 183.5,
      L: -2.108939167,
      M: 20.03323719,
      S: 0.134934285,
      P3: 16.34839144,
      P5: 16.69875348,
      P10: 17.28709261,
      P25: 18.4329022,
      P50: 20.03323719,
      P75: 22.16350089,
      P85: 23.6437801,
      P90: 24.84102024,
      P95: 27.02367739,
      P97: 28.80915118,
    },
    {
      Agemos: 184.5,
      L: -2.10114692,
      M: 20.09172262,
      S: 0.134866291,
      P3: 16.39518554,
      P5: 16.74686947,
      P10: 17.33734097,
      P25: 18.48695587,
      P50: 20.09172262,
      P75: 22.22599501,
      P85: 23.70758332,
      P90: 24.90488595,
      P95: 27.08511234,
      P97: 28.86590125,
    },
    {
      Agemos: 185.5,
      L: -2.093358637,
      M: 20.15017387,
      S: 0.134794121,
      P3: 16.44203157,
      P5: 16.79503318,
      P10: 17.38762812,
      P25: 18.54102414,
      P50: 20.15017387,
      P75: 22.2883651,
      P85: 23.77118654,
      P90: 24.96848498,
      P95: 27.14615643,
      P97: 28.92216908,
    },
    {
      Agemos: 186.5,
      L: -2.085574403,
      M: 20.20858236,
      S: 0.134718085,
      P3: 16.4889174,
      P5: 16.84323264,
      P10: 17.43794242,
      P25: 18.59509632,
      P50: 20.20858236,
      P75: 22.35060703,
      P85: 23.83459013,
      P90: 25.03182226,
      P95: 27.20682538,
      P97: 28.97798172,
    },
    {
      Agemos: 187.5,
      L: -2.077794735,
      M: 20.26693944,
      S: 0.134638494,
      P3: 16.53583079,
      P5: 16.89145577,
      P10: 17.48827215,
      P25: 18.64916164,
      P50: 20.26693944,
      P75: 22.41271676,
      P85: 23.89779468,
      P90: 25.09490311,
      P95: 27.2671356,
      P97: 29.03336709,
    },
    {
      Agemos: 188.5,
      L: -2.070020599,
      M: 20.32523642,
      S: 0.134555663,
      P3: 16.58275947,
      P5: 16.93969043,
      P10: 17.53860547,
      P25: 18.70320922,
      P50: 20.32523642,
      P75: 22.47469037,
      P85: 23.96080104,
      P90: 25.15773327,
      P95: 27.32710417,
      P97: 29.08835404,
    },
    {
      Agemos: 189.5,
      L: -2.062253431,
      M: 20.38346455,
      S: 0.13446991,
      P3: 16.62969112,
      P5: 16.98792443,
      P10: 17.58893048,
      P25: 18.7572281,
      P50: 20.38346455,
      P75: 22.53652401,
      P85: 24.02361032,
      P90: 25.22031884,
      P95: 27.38674888,
      P97: 29.14297232,
    },
    {
      Agemos: 190.5,
      L: -2.054495145,
      M: 20.44161501,
      S: 0.134381553,
      P3: 16.67661334,
      P5: 17.03614547,
      P10: 17.63923518,
      P25: 18.8112072,
      P50: 20.44161501,
      P75: 22.59821392,
      P85: 24.08622386,
      P90: 25.28266636,
      P95: 27.4460882,
      P97: 29.19725256,
    },
    {
      Agemos: 191.5,
      L: -2.046748156,
      M: 20.49967894,
      S: 0.134290916,
      P3: 16.72351369,
      P5: 17.08434122,
      P10: 17.68950749,
      P25: 18.86513537,
      P50: 20.49967894,
      P75: 22.65975645,
      P85: 24.14864327,
      P90: 25.34478272,
      P95: 27.50514126,
      P97: 29.25122631,
    },
    {
      Agemos: 192.5,
      L: -2.039015385,
      M: 20.5576474,
      S: 0.134198323,
      P3: 16.77037969,
      P5: 17.13249925,
      P10: 17.73973524,
      P25: 18.91900132,
      P50: 20.5576474,
      P75: 22.72114803,
      P85: 24.21087038,
      P90: 25.40667527,
      P95: 27.56392793,
      P97: 29.30492601,
    },
    {
      Agemos: 193.5,
      L: -2.031300282,
      M: 20.6155114,
      S: 0.134104101,
      P3: 16.81719878,
      P5: 17.18060709,
      P10: 17.78990616,
      P25: 18.97279368,
      P50: 20.6155114,
      P75: 22.78238517,
      P85: 24.2729073,
      P90: 25.46835171,
      P95: 27.62246873,
      P97: 29.35838499,
    },
    {
      Agemos: 194.5,
      L: -2.023606828,
      M: 20.67326189,
      S: 0.134008581,
      P3: 16.86395838,
      P5: 17.22865219,
      P10: 17.8400079,
      P25: 19.02650098,
      P50: 20.67326189,
      P75: 22.84346448,
      P85: 24.33475639,
      P90: 25.52982017,
      P95: 27.68078489,
      P97: 29.41163748,
    },
    {
      Agemos: 195.5,
      L: -2.015942013,
      M: 20.73088905,
      S: 0.133912066,
      P3: 16.91064668,
      P5: 17.27662259,
      P10: 17.89002837,
      P25: 19.08011149,
      P50: 20.73088905,
      P75: 22.90438167,
      P85: 24.39641931,
      P90: 25.59108848,
      P95: 27.73889854,
      P97: 29.46472007,
    },
    {
      Agemos: 196.5,
      L: -2.008305745,
      M: 20.7883851,
      S: 0.133814954,
      P3: 16.95724926,
      P5: 17.32450423,
      P10: 17.93995429,
      P25: 19.13361379,
      P50: 20.7883851,
      P75: 22.96513563,
      P85: 24.4579009,
      P90: 25.65216707,
      P95: 27.79683185,
      P97: 29.51766572,
    },
    {
      Agemos: 197.5,
      L: -2.000706389,
      M: 20.84574003,
      S: 0.133717552,
      P3: 17.0037543,
      P5: 17.37228509,
      P10: 17.98977343,
      P25: 19.18699593,
      P50: 20.84574003,
      P75: 23.02572213,
      P85: 24.51920324,
      P90: 25.71306451,
      P95: 27.85460837,
      P97: 29.57051295,
    },
    {
      Agemos: 198.5,
      L: -1.993150137,
      M: 20.90294449,
      S: 0.1336202,
      P3: 17.05014902,
      P5: 17.41995236,
      P10: 18.03947307,
      P25: 19.24024598,
      P50: 20.90294449,
      P75: 23.08613815,
      P85: 24.58032971,
      P90: 25.77379055,
      P95: 27.91225209,
      P97: 29.62329955,
    },
    {
      Agemos: 199.5,
      L: -1.985643741,
      M: 20.95998909,
      S: 0.133523244,
      P3: 17.09642062,
      P5: 17.46749315,
      P10: 18.0890404,
      P25: 19.29335192,
      P50: 20.95998909,
      P75: 23.14638076,
      P85: 24.64128395,
      P90: 25.83435539,
      P95: 27.96978771,
      P97: 29.67606419,
    },
    {
      Agemos: 200.5,
      L: -1.97819451,
      M: 21.01686433,
      S: 0.133427032,
      P3: 17.1425562,
      P5: 17.51489451,
      P10: 18.1384625,
      P25: 19.34630158,
      P50: 21.01686433,
      P75: 23.20644712,
      P85: 24.70206989,
      P90: 25.89476963,
      P95: 28.02724061,
      P97: 29.72884644,
    },
    {
      Agemos: 201.5,
      L: -1.970810308,
      M: 21.07356067,
      S: 0.133331914,
      P3: 17.18854287,
      P5: 17.56214342,
      P10: 18.18772638,
      P25: 19.39908272,
      P50: 21.07356067,
      P75: 23.26633451,
      P85: 24.76269168,
      P90: 25.95504426,
      P95: 28.08463689,
      P97: 29.78168674,
    },
    {
      Agemos: 202.5,
      L: -1.96349954,
      M: 21.1300685,
      S: 0.133238245,
      P3: 17.23436764,
      P5: 17.60922679,
      P10: 18.23681892,
      P25: 19.45168294,
      P50: 21.1300685,
      P75: 23.3260403,
      P85: 24.82315378,
      P90: 26.01519074,
      P95: 28.1420033,
      P97: 29.83462638,
    },
    {
      Agemos: 203.5,
      L: -1.956271141,
      M: 21.18637813,
      S: 0.133146383,
      P3: 17.2800175,
      P5: 17.65613146,
      P10: 18.28572693,
      P25: 19.50408975,
      P50: 21.18637813,
      P75: 23.38556196,
      P85: 24.88346091,
      P90: 26.07522093,
      P95: 28.19936732,
      P97: 29.88770757,
    },
    {
      Agemos: 204.5,
      L: -1.949134561,
      M: 21.24247982,
      S: 0.13305669,
      P3: 17.32547937,
      P5: 17.70284417,
      P10: 18.3344371,
      P25: 19.55629056,
      P50: 21.24247982,
      P75: 23.44489706,
      P85: 24.94361805,
      P90: 26.13514709,
      P95: 28.25675709,
      P97: 29.94097335,
    },
    {
      Agemos: 205.5,
      L: -1.942099744,
      M: 21.29836376,
      S: 0.132969531,
      P3: 17.37074012,
      P5: 17.74935161,
      P10: 18.38293604,
      P25: 19.60827264,
      P50: 21.29836376,
      P75: 23.50404332,
      P85: 25.00363048,
      P90: 26.19498193,
      P95: 28.31420146,
      P97: 29.99446764,
    },
    {
      Agemos: 206.5,
      L: -1.935177101,
      M: 21.35402009,
      S: 0.132885274,
      P3: 17.41578654,
      P5: 17.79564039,
      P10: 18.43121023,
      P25: 19.66002316,
      P50: 21.35402009,
      P75: 23.56299853,
      P85: 25.06350375,
      P90: 26.2547386,
      P95: 28.37172995,
      P97: 30.04823522,
    },
    {
      Agemos: 207.5,
      L: -1.92837748,
      M: 21.40943891,
      S: 0.132804292,
      P3: 17.46060537,
      P5: 17.84169701,
      P10: 18.47924607,
      P25: 19.71152919,
      P50: 21.40943891,
      P75: 23.62176064,
      P85: 25.1232437,
      P90: 26.31443063,
      P95: 28.42937274,
      P97: 30.10232173,
    },
    {
      Agemos: 208.5,
      L: -1.921712136,
      M: 21.46461026,
      S: 0.132726962,
      P3: 17.50518327,
      P5: 17.8875079,
      P10: 18.52702984,
      P25: 19.76277767,
      P50: 21.46461026,
      P75: 23.68032771,
      P85: 25.18285645,
      P90: 26.37407203,
      P95: 28.48716074,
      P97: 30.15677367,
    },
    {
      Agemos: 209.5,
      L: -1.915192685,
      M: 21.51952414,
      S: 0.132653664,
      P3: 17.54950683,
      P5: 17.9330594,
      P10: 18.57454772,
      P25: 19.81375546,
      P50: 21.51952414,
      P75: 23.73869794,
      P85: 25.24234842,
      P90: 26.4336772,
      P95: 28.54512548,
      P97: 30.21163837,
    },
    {
      Agemos: 210.5,
      L: -1.908831065,
      M: 21.57417053,
      S: 0.132584784,
      P3: 17.59356252,
      P5: 17.97833773,
      P10: 18.62178578,
      P25: 19.8644493,
      P50: 21.57417053,
      P75: 23.79686967,
      P85: 25.30172632,
      P90: 26.49326098,
      P95: 28.60329919,
      P97: 30.26696403,
    },
    {
      Agemos: 211.5,
      L: -1.902639482,
      M: 21.62853937,
      S: 0.132520711,
      P3: 17.63733676,
      P5: 18.02332903,
      P10: 18.66872998,
      P25: 19.91484586,
      P50: 21.62853937,
      P75: 23.85484139,
      P85: 25.36099716,
      P90: 26.55283865,
      P95: 28.66171476,
      P97: 30.32279966,
    },
    {
      Agemos: 212.5,
      L: -1.896630358,
      M: 21.68262062,
      S: 0.132461838,
      P3: 17.68081585,
      P5: 18.06801933,
      P10: 18.71536617,
      P25: 19.96493169,
      P50: 21.68262062,
      P75: 23.91261174,
      P85: 25.42016826,
      P90: 26.6124259,
      P95: 28.72040572,
      P97: 30.37919514,
    },
    {
      Agemos: 213.5,
      L: -1.890816268,
      M: 21.73640419,
      S: 0.132408563,
      P3: 17.72398595,
      P5: 18.11239453,
      P10: 18.76168008,
      P25: 20.01469327,
      P50: 21.73640419,
      P75: 23.97017953,
      P85: 25.47924723,
      P90: 26.67203887,
      P95: 28.77940629,
      P97: 30.43620115,
    },
    {
      Agemos: 214.5,
      L: -1.885209876,
      M: 21.78988003,
      S: 0.132361289,
      P3: 17.76683315,
      P5: 18.15644042,
      P10: 18.80765734,
      P25: 20.06411699,
      P50: 21.78988003,
      P75: 24.02754374,
      P85: 25.538242,
      P90: 26.73169411,
      P95: 28.8387513,
      P97: 30.49386923,
    },
    {
      Agemos: 215.5,
      L: -1.879823505,
      M: 21.84303819,
      S: 0.132320427,
      P3: 17.80934323,
      P5: 18.20014256,
      P10: 18.8532834,
      P25: 20.11318921,
      P50: 21.84303819,
      P75: 24.08470367,
      P85: 25.59716095,
      P90: 26.79140871,
      P95: 28.89847621,
      P97: 30.55225152,
    },
    {
      Agemos: 216.5,
      L: -1.874670324,
      M: 21.8958685,
      S: 0.132286382,
      P3: 17.85150219,
      P5: 18.24348662,
      P10: 18.89854373,
      P25: 20.16189615,
      P50: 21.8958685,
      P75: 24.14165845,
      P85: 25.65601244,
      P90: 26.85119991,
      P95: 28.9586172,
      P97: 30.61140148,
    },
    {
      Agemos: 217.5,
      L: -1.869760299,
      M: 21.94836168,
      S: 0.1322596,
      P3: 17.89329472,
      P5: 18.28645727,
      P10: 18.94342321,
      P25: 20.21022414,
      P50: 21.94836168,
      P75: 24.19840866,
      P85: 25.71480629,
      P90: 26.91108626,
      P95: 29.0192108,
      P97: 30.67137176,
    },
    {
      Agemos: 218.5,
      L: -1.865113245,
      M: 22.00050569,
      S: 0.132240418,
      P3: 17.93470899,
      P5: 18.32904183,
      P10: 18.98790813,
      P25: 20.25815885,
      P50: 22.00050569,
      P75: 24.25495098,
      P85: 25.77354888,
      P90: 26.97108394,
      P95: 29.08029503,
      P97: 30.73222122,
    },
    {
      Agemos: 219.5,
      L: -1.860734944,
      M: 22.05229242,
      S: 0.13222933,
      P3: 17.97572809,
      P5: 18.37122369,
      P10: 19.03198262,
      P25: 20.3056868,
      P50: 22.05229242,
      P75: 24.31128792,
      P85: 25.83225207,
      P90: 27.03121333,
      P95: 29.14190734,
      P97: 30.79400211,
    },
    {
      Agemos: 220.5,
      L: -1.85663384,
      M: 22.10371305,
      S: 0.132226801,
      P3: 18.01633608,
      P5: 18.41298696,
      P10: 19.07563119,
      P25: 20.35279433,
      P50: 22.10371305,
      P75: 24.36742097,
      P85: 25.89092687,
      P90: 27.09149434,
      P95: 29.20408599,
      P97: 30.85676906,
    },
    {
      Agemos: 221.5,
      L: -1.852827186,
      M: 22.15475603,
      S: 0.132233201,
      P3: 18.05651989,
      P5: 18.45431794,
      P10: 19.11883943,
      P25: 20.39946695,
      P50: 22.15475603,
      P75: 24.42334799,
      P85: 25.94958116,
      P90: 27.15194482,
      P95: 29.26687079,
      P97: 30.92058278,
    },
    {
      Agemos: 222.5,
      L: -1.849323204,
      M: 22.20541249,
      S: 0.132248993,
      P3: 18.09626344,
      P5: 18.49520062,
      P10: 19.16159172,
      P25: 20.44569089,
      P50: 22.20541249,
      P75: 24.47907063,
      P85: 26.00822631,
      P90: 27.21258528,
      P95: 29.33030127,
      P97: 30.9855,
    },
    {
      Agemos: 223.5,
      L: -1.846131607,
      M: 22.255673,
      S: 0.132274625,
      P3: 18.13555112,
      P5: 18.53561932,
      P10: 19.2038726,
      P25: 20.49145219,
      P50: 22.255673,
      P75: 24.53458985,
      P85: 26.06687321,
      P90: 27.27343608,
      P95: 29.39441777,
      P97: 31.05157949,
    },
    {
      Agemos: 224.5,
      L: -1.843261294,
      M: 22.30552831,
      S: 0.132310549,
      P3: 18.17436706,
      P5: 18.57555819,
      P10: 19.24566649,
      P25: 20.53673687,
      P50: 22.30552831,
      P75: 24.58990692,
      P85: 26.1255331,
      P90: 27.33451796,
      P95: 29.45926122,
      P97: 31.1188808,
    },
    {
      Agemos: 225.5,
      L: -1.840720248,
      M: 22.3549693,
      S: 0.132357221,
      P3: 18.21269512,
      P5: 18.61500111,
      P10: 19.28695764,
      P25: 20.581531,
      P50: 22.3549693,
      P75: 24.64502338,
      P85: 26.18421759,
      P90: 27.39585208,
      P95: 29.52487306,
      P97: 31.18746427,
    },
    {
      Agemos: 226.5,
      L: -1.83851544,
      M: 22.40398706,
      S: 0.132415103,
      P3: 18.25051886,
      P5: 18.65393178,
      P10: 19.32773023,
      P25: 20.62582066,
      P50: 22.40398706,
      P75: 24.69994107,
      P85: 26.24293861,
      P90: 27.45746001,
      P95: 29.59129532,
      P97: 31.25739102,
    },
    {
      Agemos: 227.5,
      L: -1.83665586,
      M: 22.45257182,
      S: 0.132484631,
      P3: 18.28782254,
      P5: 18.69233437,
      P10: 19.36796858,
      P25: 20.66959159,
      P50: 22.45257182,
      P75: 24.75466075,
      P85: 26.30170723,
      P90: 27.51936283,
      P95: 29.65857091,
      P97: 31.32872478,
    },
    {
      Agemos: 228.5,
      L: -1.835138046,
      M: 22.50071778,
      S: 0.132566359,
      P3: 18.3245866,
      P5: 18.73019025,
      P10: 19.40765581,
      P25: 20.71283095,
      P50: 22.50071778,
      P75: 24.80918852,
      P85: 26.36053932,
      P90: 27.58158516,
      P95: 29.72674204,
      P97: 31.40152344,
    },
    {
      Agemos: 229.5,
      L: -1.833972004,
      M: 22.54841437,
      S: 0.132660699,
      P3: 18.36079565,
      P5: 18.7674838,
      P10: 19.44677624,
      P25: 20.75552409,
      P50: 22.54841437,
      P75: 24.86352415,
      P85: 26.41944523,
      P90: 27.64414785,
      P95: 29.79585303,
      P97: 31.47585441,
    },
    {
      Agemos: 230.5,
      L: -1.833157751,
      M: 22.59565422,
      S: 0.132768153,
      P3: 18.39643137,
      P5: 18.80419732,
      P10: 19.48531336,
      P25: 20.79765756,
      P50: 22.59565422,
      P75: 24.91767166,
      P85: 26.47843912,
      P90: 27.70707459,
      P95: 29.86594782,
      P97: 31.55178078,
    },
    {
      Agemos: 231.5,
      L: -1.83269562,
      M: 22.64242956,
      S: 0.132889211,
      P3: 18.43147564,
      P5: 18.84031318,
      P10: 19.52325062,
      P25: 20.83921773,
      P50: 22.64242956,
      P75: 24.97163459,
      P85: 26.53753478,
      P90: 27.77038891,
      P95: 29.93707103,
      P97: 31.62936748,
    },
    {
      Agemos: 232.5,
      L: -1.832584342,
      M: 22.68873292,
      S: 0.133024368,
      P3: 18.46590991,
      P5: 18.87581348,
      P10: 19.56057136,
      P25: 20.88019106,
      P50: 22.68873292,
      P75: 25.02541685,
      P85: 26.59674639,
      P90: 27.83411475,
      P95: 30.00926778,
      P97: 31.7086802,
    },
    {
      Agemos: 233.5,
      L: -1.832820974,
      M: 22.73455713,
      S: 0.133174129,
      P3: 18.49971524,
      P5: 18.91067999,
      P10: 19.59725875,
      P25: 20.9205641,
      P50: 22.73455713,
      P75: 25.07902274,
      P85: 26.65608851,
      P90: 27.89827645,
      P95: 30.08258369,
      P97: 31.78978539,
    },
    {
      Agemos: 234.5,
      L: -1.833400825,
      M: 22.7798953,
      S: 0.133338999,
      P3: 18.53287228,
      P5: 18.9448942,
      P10: 19.63329582,
      P25: 20.96032349,
      P50: 22.7798953,
      P75: 25.13245693,
      P85: 26.71557609,
      P90: 27.96289869,
      P95: 30.15706484,
      P97: 31.87275025,
    },
    {
      Agemos: 235.5,
      L: -1.834317405,
      M: 22.82474087,
      S: 0.133519496,
      P3: 18.56536124,
      P5: 18.97843726,
      P10: 19.66866547,
      P25: 20.99945599,
      P50: 22.82474087,
      P75: 25.18572453,
      P85: 26.77522448,
      P90: 28.0280066,
      P95: 30.23275779,
      P97: 31.95764272,
    },
    {
      Agemos: 236.5,
      L: -1.83555752,
      M: 22.86908912,
      S: 0.133716192,
      P3: 18.59716016,
      P5: 19.0112887,
      P10: 19.70334974,
      P25: 21.03794885,
      P50: 22.86908912,
      P75: 25.23883315,
      P85: 26.83505132,
      P90: 28.09362697,
      P95: 30.30970901,
      P97: 32.04452853,
    },
    {
      Agemos: 237.5,
      L: -1.837119466,
      M: 22.91293151,
      S: 0.133929525,
      P3: 18.62825142,
      P5: 19.04343134,
      P10: 19.7373324,
      P25: 21.07578835,
      P50: 22.91293151,
      P75: 25.29178502,
      P85: 26.89506944,
      P90: 28.15978336,
      P95: 30.387967,
      P97: 32.13348232,
    },
    {
      Agemos: 238.5,
      L: -1.838987063,
      M: 22.95626373,
      S: 0.134160073,
      P3: 18.65861245,
      P5: 19.07484425,
      P10: 19.7705953,
      P25: 21.11296191,
      P50: 22.95626373,
      P75: 25.34458825,
      P85: 26.95529697,
      P90: 28.22650313,
      P95: 30.46757924,
      P97: 32.22457182,
    },
    {
      Agemos: 239.5,
      L: -1.841146139,
      M: 22.99908062,
      S: 0.134408381,
      P3: 18.68822161,
      P5: 19.10550722,
      P10: 19.80312068,
      P25: 21.14945673,
      P50: 22.99908062,
      P75: 25.39724967,
      P85: 27.01575092,
      P90: 28.29381294,
      P95: 30.54859414,
      P97: 32.31786791,
    },
    {
      Agemos: 240,
      L: -1.84233016,
      M: 23.02029424,
      S: 0.134539365,
      P3: 18.70273741,
      P5: 19.12055111,
      P10: 19.81910123,
      P25: 21.16744563,
      P50: 23.02029424,
      P75: 25.4235294,
      P85: 27.04606818,
      P90: 28.32769753,
      P95: 30.58964285,
      P97: 32.36536586,
    },
    {
      Agemos: 240.5,
      L: -1.843580575,
      M: 23.04137734,
      S: 0.134675001,
      P3: 18.71705679,
      P5: 19.13539969,
      P10: 19.83489061,
      P25: 21.18526014,
      P50: 23.04137734,
      P75: 25.44977657,
      P85: 27.07644874,
      P90: 28.3617399,
      P95: 30.63106054,
      P97: 32.41344218,
    },
  ],
  Female: [
    {
      Agemos: 24,
      L: -0.98660853,
      M: 16.42339664,
      S: 0.085451785,
      P3: 14.1473467,
      P5: 14.39787089,
      P10: 14.80134054,
      P25: 15.52807587,
      P50: 16.42339664,
      P75: 17.42745659,
      P85: 18.01820579,
      P90: 18.44139317,
      P95: 19.10623522,
      P97: 19.56410958,
    },
    {
      Agemos: 24.5,
      L: -1.024496827,
      M: 16.38804056,
      S: 0.085025838,
      P3: 14.13226271,
      P5: 14.3801866,
      P10: 14.77964811,
      P25: 15.49975639,
      P50: 16.38804056,
      P75: 17.38581552,
      P85: 17.97371413,
      P90: 18.39526372,
      P95: 19.05823845,
      P97: 19.51534333,
    },
    {
      Agemos: 25.5,
      L: -1.102698353,
      M: 16.3189719,
      S: 0.084214052,
      P3: 14.10240814,
      P5: 14.34527262,
      P10: 14.73694628,
      P25: 15.44421901,
      P50: 16.3189719,
      P75: 17.30484811,
      P85: 17.88748812,
      P90: 18.30610782,
      P95: 18.9659499,
      P97: 19.42197989,
    },
    {
      Agemos: 26.5,
      L: -1.18396635,
      M: 16.25207985,
      S: 0.083455124,
      P3: 14.07297394,
      P5: 14.31096806,
      P10: 14.6951581,
      P25: 15.39014846,
      P50: 16.25207985,
      P75: 17.2269319,
      P85: 17.80489051,
      P90: 18.22103253,
      P95: 18.87853388,
      P97: 19.33410382,
    },
    {
      Agemos: 27.5,
      L: -1.268071036,
      M: 16.18734669,
      S: 0.082748284,
      P3: 14.04396337,
      P5: 14.27727686,
      P10: 14.65428696,
      P25: 15.33754218,
      P50: 16.18734669,
      P75: 17.15202498,
      P85: 17.72586396,
      P90: 18.13997016,
      P95: 18.79590999,
      P97: 19.25163039,
    },
    {
      Agemos: 28.5,
      L: -1.354751525,
      M: 16.12475448,
      S: 0.082092737,
      P3: 14.01538008,
      P5: 14.24420303,
      P10: 14.61433601,
      P25: 15.28639717,
      P50: 16.12475448,
      P75: 17.08008556,
      P85: 17.65035137,
      P90: 18.06285334,
      P95: 18.71799839,
      P97: 19.17447582,
    },
    {
      Agemos: 29.5,
      L: -1.443689692,
      M: 16.06428762,
      S: 0.081487717,
      P3: 13.98722569,
      P5: 14.21174909,
      P10: 14.57530775,
      P25: 15.23671134,
      P50: 16.06428762,
      P75: 17.01107457,
      P85: 17.57829774,
      P90: 17.98961595,
      P95: 18.64471845,
      P97: 19.10255392,
    },
    {
      Agemos: 30.5,
      L: -1.53454192,
      M: 16.00593001,
      S: 0.080932448,
      P3: 13.95950246,
      P5: 14.17991775,
      P10: 14.53720437,
      P25: 15.18848189,
      P50: 16.00593001,
      P75: 16.94495296,
      P85: 17.50964839,
      P90: 17.92019231,
      P95: 18.5759903,
      P97: 19.03577948,
    },
    {
      Agemos: 31.5,
      L: -1.626928093,
      M: 15.94966631,
      S: 0.080426175,
      P3: 13.93221207,
      P5: 14.14871111,
      P10: 14.50002752,
      P25: 15.14170598,
      P50: 15.94966631,
      P75: 16.88168308,
      P85: 17.44434994,
      P90: 17.85451773,
      P95: 18.51173402,
      P97: 18.97406651,
    },
    {
      Agemos: 32.5,
      L: -1.720434829,
      M: 15.89548197,
      S: 0.079968176,
      P3: 13.90535562,
      P5: 14.11813062,
      P10: 14.46377827,
      P25: 15.09638064,
      P50: 15.89548197,
      P75: 16.82122877,
      P85: 17.38235043,
      P90: 17.79252859,
      P95: 18.45186978,
      P97: 18.91732801,
    },
    {
      Agemos: 33.5,
      L: -1.814635262,
      M: 15.84336179,
      S: 0.079557735,
      P3: 13.87893492,
      P5: 14.08817793,
      P10: 14.42845728,
      P25: 15.05250203,
      P50: 15.84336179,
      P75: 16.763554,
      P85: 17.32359846,
      P90: 17.73416201,
      P95: 18.39631848,
      P97: 18.86547771,
    },
    {
      Agemos: 34.5,
      L: -1.909076262,
      M: 15.79329146,
      S: 0.079194187,
      P3: 13.852951,
      P5: 14.0588539,
      P10: 14.39406456,
      P25: 15.01006617,
      P50: 15.79329146,
      P75: 16.70862449,
      P85: 17.26804436,
      P90: 17.67935646,
      P95: 18.34500099,
      P97: 18.81842784,
    },
    {
      Agemos: 35.5,
      L: -2.003296102,
      M: 15.7452564,
      S: 0.078876895,
      P3: 13.82740517,
      P5: 14.03015932,
      P10: 14.36059959,
      P25: 14.96906838,
      P50: 15.7452564,
      P75: 16.65640663,
      P85: 17.21563951,
      P90: 17.62805146,
      P95: 18.29783876,
      P97: 18.7760905,
    },
    {
      Agemos: 36.5,
      L: -2.096828937,
      M: 15.69924188,
      S: 0.078605255,
      P3: 13.80229888,
      P5: 14.00209476,
      P10: 14.32806133,
      P25: 14.92950329,
      P50: 15.69924188,
      P75: 16.60686761,
      P85: 17.16633643,
      P90: 17.5801877,
      P95: 18.25475381,
      P97: 18.73837743,
    },
    {
      Agemos: 37.5,
      L: -2.189211877,
      M: 15.65523282,
      S: 0.078378696,
      P3: 13.77763382,
      P5: 13.97466073,
      P10: 14.29644823,
      P25: 14.89136478,
      P50: 15.65523282,
      P75: 16.55997523,
      P85: 17.12008872,
      P90: 17.53570705,
      P95: 18.21566879,
      P97: 18.70520011,
    },
    {
      Agemos: 38.5,
      L: -2.279991982,
      M: 15.61321371,
      S: 0.078196674,
      P3: 13.75341201,
      P5: 13.94785767,
      P10: 14.26575827,
      P25: 14.85464591,
      P50: 15.61321371,
      P75: 16.51569783,
      P85: 17.07685104,
      P90: 17.49455256,
      P95: 18.18050711,
      P97: 18.67646985,
    },
    {
      Agemos: 39.5,
      L: -2.368732949,
      M: 15.57316843,
      S: 0.078058667,
      P3: 13.72963586,
      P5: 13.92168608,
      P10: 14.23598902,
      P25: 14.81933896,
      P50: 15.57316843,
      P75: 16.4740041,
      P85: 17.03657894,
      P90: 17.45666842,
      P95: 18.14919304,
      P97: 18.65209796,
    },
    {
      Agemos: 40.5,
      L: -2.455021314,
      M: 15.53508019,
      S: 0.077964169,
      P3: 13.70630824,
      P5: 13.89614655,
      P10: 14.20713766,
      P25: 14.78543535,
      P50: 15.53508019,
      P75: 16.43486298,
      P85: 16.99922885,
      P90: 17.42199993,
      P95: 18.12165176,
      P97: 18.63199585,
    },
    {
      Agemos: 41.5,
      L: -2.538471972,
      M: 15.49893145,
      S: 0.077912684,
      P3: 13.68343254,
      P5: 13.87123981,
      P10: 14.17920105,
      P25: 14.75292568,
      P50: 15.49893145,
      P75: 16.39824347,
      P85: 16.96475788,
      P90: 17.39049347,
      P95: 18.09780945,
      P97: 18.61607518,
    },
    {
      Agemos: 42.5,
      L: -2.618732901,
      M: 15.46470384,
      S: 0.077903716,
      P3: 13.66101266,
      P5: 13.84696678,
      P10: 14.15217581,
      P25: 14.72179977,
      P50: 15.46470384,
      P75: 16.36411453,
      P85: 16.93312378,
      P90: 17.36209639,
      P95: 18.07759336,
      P97: 18.60424802,
    },
    {
      Agemos: 43.5,
      L: -2.695488973,
      M: 15.43237817,
      S: 0.077936763,
      P3: 13.63905306,
      P5: 13.82332865,
      P10: 14.12605833,
      P25: 14.69204668,
      P50: 15.43237817,
      P75: 16.33244497,
      P85: 16.90428477,
      P90: 17.33675699,
      P95: 18.06093184,
      P97: 18.59642696,
    },
    {
      Agemos: 44.5,
      L: -2.768464816,
      M: 15.40193436,
      S: 0.078011309,
      P3: 13.61755872,
      P5: 13.80032682,
      P10: 14.10084485,
      P25: 14.6636548,
      P50: 15.40193436,
      P75: 16.30320336,
      P85: 16.87819947,
      P90: 17.31442438,
      P95: 18.04775438,
      P97: 18.59252526,
    },
    {
      Agemos: 45.5,
      L: -2.837426693,
      M: 15.37335154,
      S: 0.078126817,
      P3: 13.59653518,
      P5: 13.77796299,
      P10: 14.07653151,
      P25: 14.63661186,
      P50: 15.37335154,
      P75: 16.27635793,
      P85: 16.85482681,
      P90: 17.29504843,
      P95: 18.03799161,
      P97: 18.59245694,
    },
    {
      Agemos: 46.5,
      L: -2.902178205,
      M: 15.34660842,
      S: 0.078282739,
      P3: 13.57598804,
      P5: 13.75623885,
      P10: 14.05311425,
      P25: 14.61090523,
      P50: 15.34660842,
      P75: 16.25187703,
      P85: 16.83412628,
      P90: 17.27857995,
      P95: 18.03157513,
      P97: 18.59613624,
    },
    {
      Agemos: 47.5,
      L: -2.962580386,
      M: 15.32168181,
      S: 0.078478449,
      P3: 13.55592455,
      P5: 13.73515717,
      P10: 14.03058936,
      P25: 14.58652134,
      P50: 15.32168181,
      P75: 16.22972719,
      P85: 16.81605638,
      P90: 17.2649696,
      P95: 18.02843822,
      P97: 18.6034803,
    },
    {
      Agemos: 48.5,
      L: -3.018521987,
      M: 15.29854897,
      S: 0.078713325,
      P3: 13.53635124,
      P5: 13.71472021,
      P10: 14.00895279,
      P25: 14.56344665,
      P50: 15.29854897,
      P75: 16.20987583,
      P85: 16.80057671,
      P90: 17.25416922,
      P95: 18.02851473,
      P97: 18.61440545,
    },
    {
      Agemos: 49.5,
      L: -3.069936555,
      M: 15.27718618,
      S: 0.078986694,
      P3: 13.51727537,
      P5: 13.6949307,
      P10: 13.98820066,
      P25: 14.54166718,
      P50: 15.27718618,
      P75: 16.19228952,
      P85: 16.78764654,
      P90: 17.24613088,
      P95: 18.03173977,
      P97: 18.62882971,
    },
    {
      Agemos: 50.5,
      L: -3.116795864,
      M: 15.2575692,
      S: 0.079297841,
      P3: 13.49870449,
      P5: 13.67579162,
      P10: 13.96832912,
      P25: 14.52116867,
      P50: 15.2575692,
      P75: 16.17693441,
      P85: 16.77722512,
      P90: 17.24080701,
      P95: 18.03804946,
      P97: 18.64667225,
    },
    {
      Agemos: 51.5,
      L: -3.159107331,
      M: 15.23967338,
      S: 0.079646006,
      P3: 13.48064637,
      P5: 13.65730608,
      P10: 13.94933441,
      P25: 14.50193674,
      P50: 15.23967338,
      P75: 16.16377626,
      P85: 16.76927159,
      P90: 17.23815034,
      P95: 18.04738091,
      P97: 18.66785338,
    },
    {
      Agemos: 52.5,
      L: -3.196911083,
      M: 15.22347371,
      S: 0.080030389,
      P3: 13.46310899,
      P5: 13.63947739,
      P10: 13.93121283,
      P25: 14.48395686,
      P50: 15.22347371,
      P75: 16.15278049,
      P85: 16.76374505,
      P90: 17.23811389,
      P95: 18.05967216,
      P97: 18.6922946,
    },
    {
      Agemos: 53.5,
      L: -3.230276759,
      M: 15.20894491,
      S: 0.080450145,
      P3: 13.44610041,
      P5: 13.62230894,
      P10: 13.91396079,
      P25: 14.46721445,
      P50: 15.20894491,
      P75: 16.14391221,
      P85: 16.76060449,
      P90: 17.24065086,
      P95: 18.0748621,
      P97: 18.71991859,
    },
    {
      Agemos: 54.5,
      L: -3.259300182,
      M: 15.19606152,
      S: 0.080904391,
      P3: 13.42962877,
      P5: 13.60580421,
      P10: 13.89757474,
      P25: 14.45169495,
      P50: 15.19606152,
      P75: 16.13713628,
      P85: 16.75980884,
      P90: 17.24571467,
      P95: 18.09289045,
      P97: 18.75064919,
    },
    {
      Agemos: 55.5,
      L: -3.284099963,
      M: 15.18479799,
      S: 0.081392203,
      P3: 13.41370222,
      P5: 13.58996673,
      P10: 13.88205123,
      P25: 14.4373838,
      P50: 15.18479799,
      P75: 16.13241736,
      P85: 16.76131695,
      P90: 17.25325891,
      P95: 18.11369768,
      P97: 18.78441142,
    },
    {
      Agemos: 56.5,
      L: -3.30481415,
      M: 15.17512871,
      S: 0.081912623,
      P3: 13.39832882,
      P5: 13.57480002,
      P10: 13.8673869,
      P25: 14.42426656,
      P50: 15.17512871,
      P75: 16.12971996,
      P85: 16.76508763,
      P90: 17.2632373,
      P95: 18.13722499,
      P97: 18.82113141,
    },
    {
      Agemos: 57.5,
      L: -3.321596954,
      M: 15.16702811,
      S: 0.082464661,
      P3: 13.38351658,
      P5: 13.56030761,
      P10: 13.8535784,
      P25: 14.41232886,
      P50: 15.16702811,
      P75: 16.12900854,
      P85: 16.77107966,
      P90: 17.27560372,
      P95: 18.16341422,
      P97: 18.86073644,
    },
    {
      Agemos: 58.5,
      L: -3.334615646,
      M: 15.16047068,
      S: 0.083047295,
      P3: 13.36927334,
      P5: 13.54649295,
      P10: 13.84062247,
      P25: 14.40155647,
      P50: 15.16047068,
      P75: 16.13024748,
      P85: 16.77925184,
      P90: 17.29031217,
      P95: 18.19220786,
      P97: 18.90315485,
    },
    {
      Agemos: 59.5,
      L: -3.344047622,
      M: 15.15543107,
      S: 0.083659478,
      P3: 13.35560679,
      P5: 13.53335941,
      P10: 13.82851587,
      P25: 14.39193532,
      P50: 15.15543107,
      P75: 16.13340121,
      P85: 16.78956298,
      P90: 17.30731682,
      P95: 18.22354893,
      P97: 18.94831604,
    },
    {
      Agemos: 60.5,
      L: -3.35007771,
      M: 15.15188405,
      S: 0.084300139,
      P3: 13.34252439,
      P5: 13.52091027,
      P10: 13.81725538,
      P25: 14.38345148,
      P50: 15.15188405,
      P75: 16.13843423,
      P85: 16.80197195,
      P90: 17.32657196,
      P95: 18.25738105,
      P97: 18.99615046,
    },
    {
      Agemos: 61.5,
      L: -3.352893805,
      M: 15.14980479,
      S: 0.0849682,
      P3: 13.33003322,
      P5: 13.50914853,
      P10: 13.80683773,
      P25: 14.37609125,
      P50: 15.14980479,
      P75: 16.14531133,
      P85: 16.81643792,
      P90: 17.34803218,
      P95: 18.29364826,
      P97: 19.04658918,
    },
    {
      Agemos: 62.5,
      L: -3.352691376,
      M: 15.14916825,
      S: 0.085662539,
      P3: 13.31814053,
      P5: 13.4980774,
      P10: 13.79725982,
      P25: 14.36984096,
      P50: 15.14916825,
      P75: 16.15399692,
      P85: 16.83291965,
      P90: 17.37165187,
      P95: 18.33229525,
      P97: 19.09956514,
    },
    {
      Agemos: 63.5,
      L: -3.34966438,
      M: 15.14994984,
      S: 0.086382035,
      P3: 13.3068531,
      P5: 13.48769977,
      P10: 13.78851848,
      P25: 14.3646872,
      P50: 15.14994984,
      P75: 16.16445581,
      P85: 16.85137625,
      P90: 17.39738575,
      P95: 18.37326713,
      P97: 19.15501183,
    },
    {
      Agemos: 64.5,
      L: -3.343998803,
      M: 15.15212585,
      S: 0.087125591,
      P3: 13.29617673,
      P5: 13.47801782,
      P10: 13.78061022,
      P25: 14.3606169,
      P50: 15.15212585,
      P75: 16.17665394,
      P85: 16.87176785,
      P90: 17.42518932,
      P95: 18.41650918,
      P97: 19.21286229,
    },
    {
      Agemos: 65.5,
      L: -3.335889574,
      M: 15.15567186,
      S: 0.087892047,
      P3: 13.28611805,
      P5: 13.4690344,
      P10: 13.77353197,
      P25: 14.35761687,
      P50: 15.15567186,
      P75: 16.19055603,
      P85: 16.89405345,
      P90: 17.45501735,
      P95: 18.46196754,
      P97: 19.27305261,
    },
    {
      Agemos: 66.5,
      L: -3.325522491,
      M: 15.16056419,
      S: 0.088680264,
      P3: 13.27668282,
      P5: 13.46075175,
      P10: 13.76728041,
      P25: 14.35567422,
      P50: 15.16056419,
      P75: 16.20612774,
      P85: 16.91819285,
      P90: 17.48682523,
      P95: 18.50958858,
      P97: 19.33551875,
    },
    {
      Agemos: 67.5,
      L: -3.31307846,
      M: 15.16677947,
      S: 0.089489106,
      P3: 13.26787651,
      P5: 13.45317191,
      P10: 13.76185216,
      P25: 14.35477623,
      P50: 15.16677947,
      P75: 16.22333502,
      P85: 16.94414613,
      P90: 17.52056863,
      P95: 18.55931907,
      P97: 19.40019747,
    },
    {
      Agemos: 68.5,
      L: -3.298732648,
      M: 15.17429464,
      S: 0.090317434,
      P3: 13.25970423,
      P5: 13.44629667,
      P10: 13.75724378,
      P25: 14.35491035,
      P50: 15.17429464,
      P75: 16.24214414,
      P85: 16.97187362,
      P90: 17.55620345,
      P95: 18.6111062,
      P97: 19.46702632,
    },
    {
      Agemos: 69.5,
      L: -3.282653831,
      M: 15.18308694,
      S: 0.091164117,
      P3: 13.25217076,
      P5: 13.44012761,
      P10: 13.75345175,
      P25: 14.35606415,
      P50: 15.18308694,
      P75: 16.26252167,
      P85: 17.00133593,
      P90: 17.5936859,
      P95: 18.6648976,
      P97: 19.53594365,
    },
    {
      Agemos: 70.5,
      L: -3.265003896,
      M: 15.1931339,
      S: 0.092028028,
      P3: 13.24528055,
      P5: 13.43466608,
      P10: 13.75047248,
      P25: 14.35822537,
      P50: 15.1931339,
      P75: 16.28443453,
      P85: 17.03249399,
      P90: 17.63297244,
      P95: 18.72064127,
      P97: 19.60688859,
    },
    {
      Agemos: 71.5,
      L: -3.245937506,
      M: 15.20441335,
      S: 0.092908048,
      P3: 13.23903773,
      P5: 13.42991321,
      P10: 13.74830229,
      P25: 14.36138186,
      P50: 15.20441335,
      P75: 16.30784995,
      P85: 17.065309,
      P90: 17.67401984,
      P95: 18.77828567,
      P97: 19.67980103,
    },
    {
      Agemos: 72.5,
      L: -3.225606516,
      M: 15.21690296,
      S: 0.093803033,
      P3: 13.23344678,
      P5: 13.42587042,
      P10: 13.74693776,
      P25: 14.36552155,
      P50: 15.21690296,
      P75: 16.33273474,
      P85: 17.09974172,
      P90: 17.7167845,
      P95: 18.83777954,
      P97: 19.75462234,
    },
    {
      Agemos: 73.5,
      L: -3.204146115,
      M: 15.2305815,
      S: 0.094711916,
      P3: 13.22851054,
      P5: 13.42253788,
      P10: 13.74637473,
      P25: 14.37063258,
      P50: 15.2305815,
      P75: 16.35905747,
      P85: 17.13575468,
      P90: 17.7612244,
      P95: 18.89907229,
      P97: 19.83129333,
    },
    {
      Agemos: 74.5,
      L: -3.181690237,
      M: 15.24542745,
      S: 0.095633595,
      P3: 13.22423251,
      P5: 13.41991629,
      P10: 13.74660941,
      P25: 14.37670312,
      P50: 15.24542745,
      P75: 16.38678602,
      P85: 17.17330968,
      P90: 17.80729692,
      P95: 18.96211354,
      P97: 19.90975644,
    },
    {
      Agemos: 75.5,
      L: -3.158363475,
      M: 15.26141966,
      S: 0.096566992,
      P3: 13.22061564,
      P5: 13.41800595,
      P10: 13.74763782,
      P25: 14.38372148,
      P50: 15.26141966,
      P75: 16.41588887,
      P85: 17.21236911,
      P90: 17.85496,
      P95: 19.02685341,
      P97: 19.9899546,
    },
    {
      Agemos: 76.5,
      L: -3.134282833,
      M: 15.27853728,
      S: 0.097511046,
      P3: 13.21766256,
      P5: 13.41680692,
      P10: 13.74945586,
      P25: 14.39167606,
      P50: 15.27853728,
      P75: 16.44633482,
      P85: 17.25289572,
      P90: 17.90417191,
      P95: 19.09324243,
      P97: 20.07183146,
    },
    {
      Agemos: 77.5,
      L: -3.109557879,
      M: 15.29675967,
      S: 0.09846471,
      P3: 13.21537563,
      P5: 13.41631905,
      P10: 13.75205934,
      P25: 14.40055533,
      P50: 15.29675967,
      P75: 16.478093,
      P85: 17.29485256,
      P90: 17.95489128,
      P95: 19.16123158,
      P97: 20.15533137,
    },
    {
      Agemos: 78.5,
      L: -3.084290931,
      M: 15.31606644,
      S: 0.099426955,
      P3: 13.2137569,
      P5: 13.41654198,
      P10: 13.75544399,
      P25: 14.41034784,
      P50: 15.31606644,
      P75: 16.51113288,
      P85: 17.33820304,
      P90: 18.00707707,
      P95: 19.23077228,
      P97: 20.24039941,
    },
    {
      Agemos: 79.5,
      L: -3.058577292,
      M: 15.33643745,
      S: 0.100396769,
      P3: 13.21280815,
      P5: 13.41747514,
      P10: 13.7596054,
      P25: 14.42104223,
      P50: 15.33643745,
      P75: 16.54542424,
      P85: 17.38291092,
      P90: 18.06068861,
      P95: 19.30181639,
      P97: 20.32698132,
    },
    {
      Agemos: 80.5,
      L: -3.032505499,
      M: 15.35785274,
      S: 0.101373159,
      P3: 13.21253087,
      P5: 13.41911775,
      P10: 13.76453911,
      P25: 14.43262719,
      P50: 15.35785274,
      P75: 16.58093719,
      P85: 17.42894029,
      P90: 18.11568557,
      P95: 19.37431624,
      P97: 20.41502357,
    },
    {
      Agemos: 81.5,
      L: -3.0061576,
      M: 15.38029261,
      S: 0.10235515,
      P3: 13.2129263,
      P5: 13.42146884,
      P10: 13.7702405,
      P25: 14.44509148,
      P50: 15.38029261,
      P75: 16.61764214,
      P85: 17.4762556,
      P90: 18.17202801,
      P95: 19.44822461,
      P97: 20.5044733,
    },
    {
      Agemos: 82.5,
      L: -2.979609448,
      M: 15.40373754,
      S: 0.103341788,
      P3: 13.21399541,
      P5: 13.42452722,
      P10: 13.77670491,
      P25: 14.4584239,
      P50: 15.40373754,
      P75: 16.65550982,
      P85: 17.52482162,
      P90: 18.22967635,
      P95: 19.52349477,
      P97: 20.59527834,
    },
    {
      Agemos: 83.5,
      L: -2.952930993,
      M: 15.42816819,
      S: 0.104332139,
      P3: 13.2157389,
      P5: 13.42829152,
      P10: 13.78392751,
      P25: 14.4726133,
      P50: 15.42816819,
      P75: 16.69451128,
      P85: 17.57460349,
      P90: 18.28859138,
      P95: 19.60008044,
      P97: 20.68738721,
    },
    {
      Agemos: 84.5,
      L: -2.926186592,
      M: 15.45356545,
      S: 0.105325289,
      P3: 13.21815723,
      P5: 13.43276016,
      P10: 13.79190343,
      P25: 14.48764859,
      P50: 15.45356545,
      P75: 16.73461785,
      P85: 17.6255667,
      P90: 18.34873428,
      P95: 19.67793583,
      P97: 20.7807491,
    },
    {
      Agemos: 85.5,
      L: -2.899435307,
      M: 15.47991037,
      S: 0.106320346,
      P3: 13.22125062,
      P5: 13.43793139,
      P10: 13.80062765,
      P25: 14.50351871,
      P50: 15.47991037,
      P75: 16.77580119,
      P85: 17.67767708,
      P90: 18.4100666,
      P95: 19.75701564,
      P97: 20.8753139,
    },
    {
      Agemos: 86.5,
      L: -2.872731211,
      M: 15.50718419,
      S: 0.10731644,
      P3: 13.22501902,
      P5: 13.44380325,
      P10: 13.81009508,
      P25: 14.52021262,
      P50: 15.50718419,
      P75: 16.81803321,
      P85: 17.7309008,
      P90: 18.47255029,
      P95: 19.83727506,
      P97: 20.97103216,
    },
    {
      Agemos: 87.5,
      L: -2.846123683,
      M: 15.53536829,
      S: 0.108312721,
      P3: 13.22946217,
      P5: 13.45037362,
      P10: 13.82030049,
      P25: 14.53771933,
      P50: 15.53536829,
      P75: 16.86128616,
      P85: 17.78520438,
      P90: 18.53614768,
      P95: 19.91866977,
      P97: 21.06785511,
    },
    {
      Agemos: 88.5,
      L: -2.819657704,
      M: 15.56444426,
      S: 0.109308364,
      P3: 13.23457958,
      P5: 13.45764018,
      P10: 13.83123858,
      P25: 14.55602787,
      P50: 15.56444426,
      P75: 16.90553254,
      P85: 17.8405547,
      P90: 18.60082149,
      P95: 20.00115598,
      P97: 21.16573466,
    },
    {
      Agemos: 89.5,
      L: -2.793374145,
      M: 15.5943938,
      S: 0.110302563,
      P3: 13.24037052,
      P5: 13.46560044,
      P10: 13.84290393,
      P25: 14.57512728,
      P50: 15.5943938,
      P75: 16.95074515,
      P85: 17.89691898,
      P90: 18.66653485,
      P95: 20.08469037,
      P97: 21.2646234,
    },
    {
      Agemos: 90.5,
      L: -2.767310047,
      M: 15.6251988,
      S: 0.111294537,
      P3: 13.24683405,
      P5: 13.47425174,
      P10: 13.85529103,
      P25: 14.59500664,
      P50: 15.6251988,
      P75: 16.99689705,
      P85: 17.95426475,
      P90: 18.73325124,
      P95: 20.16923016,
      P97: 21.3644746,
    },
    {
      Agemos: 91.5,
      L: -2.741498897,
      M: 15.65684126,
      S: 0.112283526,
      P3: 13.25396902,
      P5: 13.48359125,
      P10: 13.86839426,
      P25: 14.61565502,
      P50: 15.65684126,
      P75: 17.04396158,
      P85: 18.01255992,
      P90: 18.80093458,
      P95: 20.25473307,
      P97: 21.46524221,
    },
    {
      Agemos: 92.5,
      L: -2.715970894,
      M: 15.68930333,
      S: 0.113268793,
      P3: 13.26177409,
      P5: 13.49361598,
      P10: 13.8822079,
      P25: 14.63706153,
      P50: 15.68930333,
      P75: 17.09191233,
      P85: 18.07177271,
      P90: 18.86954915,
      P95: 20.34115737,
      P97: 21.56688088,
    },
    {
      Agemos: 93.5,
      L: -2.690753197,
      M: 15.7225673,
      S: 0.114249622,
      P3: 13.27024769,
      P5: 13.50432278,
      P10: 13.89672614,
      P25: 14.65921526,
      P50: 15.7225673,
      P75: 17.14072316,
      P85: 18.13187166,
      P90: 18.93905963,
      P95: 20.42846182,
      P97: 21.66934595,
    },
    {
      Agemos: 94.5,
      L: -2.665870146,
      M: 15.75661555,
      S: 0.115225321,
      P3: 13.27938808,
      P5: 13.51570835,
      P10: 13.91194308,
      P25: 14.68210532,
      P50: 15.75661555,
      P75: 17.19036814,
      P85: 18.19282565,
      P90: 19.00943108,
      P95: 20.51660573,
      P97: 21.77259347,
    },
    {
      Agemos: 95.5,
      L: -2.641343436,
      M: 15.79143062,
      S: 0.116195218,
      P3: 13.28919334,
      P5: 13.52776922,
      P10: 13.9278527,
      P25: 14.70572083,
      P50: 15.79143062,
      P75: 17.24082164,
      P85: 18.25460388,
      P90: 19.08062897,
      P95: 20.60554896,
      P97: 21.87658015,
    },
    {
      Agemos: 96.5,
      L: -2.617192204,
      M: 15.82699517,
      S: 0.117158667,
      P3: 13.2996613,
      P5: 13.54050176,
      P10: 13.9444489,
      P25: 14.7300509,
      P50: 15.82699517,
      P75: 17.29205828,
      P85: 18.31717594,
      P90: 19.15261918,
      P95: 20.69525186,
      P97: 21.98126336,
    },
    {
      Agemos: 97.5,
      L: -2.593430614,
      M: 15.86329241,
      S: 0.118115073,
      P3: 13.31078904,
      P5: 13.55390172,
      P10: 13.96172519,
      P25: 14.75508471,
      P50: 15.86329241,
      P75: 17.34405364,
      P85: 18.38051241,
      P90: 19.22536854,
      P95: 20.78567525,
      P97: 22.08660008,
    },
    {
      Agemos: 98.5,
      L: -2.570076037,
      M: 15.90030484,
      S: 0.119063807,
      P3: 13.32257456,
      P5: 13.56796556,
      P10: 13.97967549,
      P25: 14.78081132,
      P50: 15.90030484,
      P75: 17.39678222,
      P85: 18.44458291,
      P90: 19.29884326,
      P95: 20.87678068,
      P97: 22.19254999,
    },
    {
      Agemos: 99.5,
      L: -2.547141473,
      M: 15.93801545,
      S: 0.12000429,
      P3: 13.33501512,
      P5: 13.58268918,
      P10: 13.99829338,
      P25: 14.80721981,
      P50: 15.93801545,
      P75: 17.45021936,
      P85: 18.50935798,
      P90: 19.3730104,
      P95: 20.96853014,
      P97: 22.29907253,
    },
    {
      Agemos: 100.5,
      L: -2.524635245,
      M: 15.97640787,
      S: 0.120935994,
      P3: 13.34810726,
      P5: 13.59806788,
      P10: 14.01757212,
      P25: 14.83429943,
      P50: 15.97640787,
      P75: 17.50434149,
      P85: 18.57480931,
      P90: 19.44783809,
      P95: 21.06088607,
      P97: 22.4061266,
    },
    {
      Agemos: 101.5,
      L: -2.502569666,
      M: 16.01546483,
      S: 0.121858355,
      P3: 13.36184843,
      P5: 13.61409768,
      P10: 14.0375053,
      P25: 14.86203913,
      P50: 16.01546483,
      P75: 17.55912371,
      P85: 18.6409074,
      P90: 19.52329364,
      P95: 21.15381165,
      P97: 22.51367407,
    },
    {
      Agemos: 102.5,
      L: -2.48095189,
      M: 16.05516984,
      S: 0.12277087,
      P3: 13.37623516,
      P5: 13.63077388,
      P10: 14.05808613,
      P25: 14.89042805,
      P50: 16.05516984,
      P75: 17.61454239,
      P85: 18.70762408,
      P90: 19.59934557,
      P95: 21.24727049,
      P97: 22.62167597,
    },
    {
      Agemos: 103.5,
      L: -2.459785573,
      M: 16.09550688,
      S: 0.123673085,
      P3: 13.39126329,
      P5: 13.6480912,
      P10: 14.07930747,
      P25: 14.91945533,
      P50: 16.09550688,
      P75: 17.67057478,
      P85: 18.77493214,
      P90: 19.67596331,
      P95: 21.34122666,
      P97: 22.73009308,
    },
    {
      Agemos: 104.5,
      L: -2.439080117,
      M: 16.13645881,
      S: 0.124564484,
      P3: 13.40693007,
      P5: 13.6660455,
      P10: 14.1011628,
      P25: 14.94910996,
      P50: 16.13645881,
      P75: 17.72719638,
      P85: 18.84280276,
      P90: 19.75311515,
      P95: 21.43564505,
      P97: 22.8388898,
    },
    {
      Agemos: 105.5,
      L: -2.418838304,
      M: 16.17800955,
      S: 0.125444639,
      P3: 13.42323134,
      P5: 13.68463148,
      P10: 14.12364494,
      P25: 14.97938101,
      P50: 16.17800955,
      P75: 17.78438449,
      P85: 18.91120897,
      P90: 19.830771,
      P95: 21.5304909,
      P97: 22.94802887,
    },
    {
      Agemos: 106.5,
      L: -2.399063683,
      M: 16.22014281,
      S: 0.126313121,
      P3: 13.44016315,
      P5: 13.70384401,
      P10: 14.1467468,
      P25: 15.01025752,
      P50: 16.22014281,
      P75: 17.84211614,
      P85: 18.98012366,
      P90: 19.90890079,
      P95: 21.62573005,
      P97: 23.05747448,
    },
    {
      Agemos: 107.5,
      L: -2.379756861,
      M: 16.26284277,
      S: 0.127169545,
      P3: 13.45772088,
      P5: 13.72367745,
      P10: 14.17046092,
      P25: 15.04172856,
      P50: 16.26284277,
      P75: 17.9003692,
      P85: 19.04952063,
      P90: 19.98747533,
      P95: 21.72132885,
      P97: 23.16719057,
    },
    {
      Agemos: 108.5,
      L: -2.360920527,
      M: 16.30609316,
      S: 0.128013515,
      P3: 13.47590046,
      P5: 13.74412656,
      P10: 14.19478013,
      P25: 15.0737831,
      P50: 16.30609316,
      P75: 17.95912092,
      P85: 19.11937316,
      P90: 20.06646519,
      P95: 21.8172543,
      P97: 23.27714304,
    },
    {
      Agemos: 109.5,
      L: -2.342557728,
      M: 16.34987759,
      S: 0.128844639,
      P3: 13.49469797,
      P5: 13.7651862,
      P10: 14.21969726,
      P25: 15.10641007,
      P50: 16.34987759,
      P75: 18.01834843,
      P85: 19.18965453,
      P90: 20.14584104,
      P95: 21.91347402,
      P97: 23.38729906,
    },
    {
      Agemos: 110.5,
      L: -2.324663326,
      M: 16.39418118,
      S: 0.129662637,
      P3: 13.51410735,
      P5: 13.78684956,
      P10: 14.24520415,
      P25: 15.13959859,
      P50: 16.39418118,
      P75: 18.07803138,
      P85: 19.26034062,
      P90: 20.22557581,
      P95: 22.00995586,
      P97: 23.49762289,
    },
    {
      Agemos: 111.5,
      L: -2.307240716,
      M: 16.43898741,
      S: 0.130467138,
      P3: 13.53412476,
      P5: 13.80911155,
      P10: 14.27129365,
      P25: 15.17333749,
      P50: 16.43898741,
      P75: 18.13814687,
      P85: 19.33140487,
      P90: 20.30564061,
      P95: 22.10666864,
      P97: 23.60808381,
    },
    {
      Agemos: 112.5,
      L: -2.290287663,
      M: 16.48428082,
      S: 0.131257852,
      P3: 13.55474487,
      P5: 13.83196589,
      P10: 14.29795787,
      P25: 15.20761574,
      P50: 16.48428082,
      P75: 18.19867379,
      P85: 19.4028226,
      P90: 20.38600821,
      P95: 22.20358151,
      P97: 23.71864933,
    },
    {
      Agemos: 113.5,
      L: -2.273803847,
      M: 16.53004554,
      S: 0.132034479,
      P3: 13.5759628,
      P5: 13.85540667,
      P10: 14.32518913,
      P25: 15.24242219,
      P50: 16.53004554,
      P75: 18.25959047,
      P85: 19.47456869,
      P90: 20.46665117,
      P95: 22.30066429,
      P97: 23.82928884,
    },
    {
      Agemos: 114.5,
      L: -2.257782149,
      M: 16.57626713,
      S: 0.132796819,
      P3: 13.59777181,
      P5: 13.87942647,
      P10: 14.35297886,
      P25: 15.27774591,
      P50: 16.57626713,
      P75: 18.32087764,
      P85: 19.54662051,
      P90: 20.5475442,
      P95: 22.39788709,
      P97: 23.9399692,
    },
    {
      Agemos: 115.5,
      L: -2.242227723,
      M: 16.62292864,
      S: 0.133544525,
      P3: 13.62016849,
      P5: 13.90402053,
      P10: 14.38132004,
      P25: 15.31357557,
      P50: 16.62292864,
      P75: 18.38251202,
      P85: 19.6189515,
      P90: 20.628659,
      P95: 22.49522108,
      P97: 24.05066418,
    },
    {
      Agemos: 116.5,
      L: -2.227132805,
      M: 16.67001572,
      S: 0.134277436,
      P3: 13.64314567,
      P5: 13.92918106,
      P10: 14.4102038,
      P25: 15.34990008,
      P50: 16.67001572,
      P75: 18.44447472,
      P85: 19.69153965,
      P90: 20.70997108,
      P95: 22.59263755,
      P97: 24.16134214,
    },
    {
      Agemos: 117.5,
      L: -2.212495585,
      M: 16.71751288,
      S: 0.134995324,
      P3: 13.66669786,
      P5: 13.95490163,
      P10: 14.43962209,
      P25: 15.38670828,
      P50: 16.71751288,
      P75: 18.50674506,
      P85: 19.7643612,
      P90: 20.79145469,
      P95: 22.69010855,
      P97: 24.27197506,
    },
    {
      Agemos: 118.5,
      L: -2.19831275,
      M: 16.76540496,
      S: 0.135697996,
      P3: 13.69081905,
      P5: 13.98117536,
      P10: 14.46956659,
      P25: 15.42398894,
      P50: 16.76540496,
      P75: 18.56930296,
      P85: 19.83739314,
      P90: 20.87308482,
      P95: 22.78760665,
      P97: 24.38253502,
    },
    {
      Agemos: 119.5,
      L: -2.184580762,
      M: 16.81367689,
      S: 0.136385276,
      P3: 13.71550304,
      P5: 14.00799522,
      P10: 14.50002883,
      P25: 15.46173083,
      P50: 16.81367689,
      P75: 18.63212859,
      P85: 19.91061278,
      P90: 20.9548369,
      P95: 22.88510501,
      P97: 24.49299479,
    },
    {
      Agemos: 120.5,
      L: -2.171295888,
      M: 16.86231366,
      S: 0.137057004,
      P3: 13.7407435,
      P5: 14.03535405,
      P10: 14.5310003,
      P25: 15.49992271,
      P50: 16.86231366,
      P75: 18.69520235,
      P85: 19.98399779,
      P90: 21.03668675,
      P95: 22.98257733,
      P97: 24.60332779,
    },
    {
      Agemos: 121.5,
      L: -2.158454232,
      M: 16.91130036,
      S: 0.137713039,
      P3: 13.7665339,
      P5: 14.06324456,
      P10: 14.56247234,
      P25: 15.53855329,
      P50: 16.91130036,
      P75: 18.75850486,
      P85: 20.05752615,
      P90: 21.11861063,
      P95: 23.07999788,
      P97: 24.7135081,
    },
    {
      Agemos: 122.5,
      L: -2.146051754,
      M: 16.96062216,
      S: 0.138353254,
      P3: 13.79286758,
      P5: 14.0916593,
      P10: 14.59443621,
      P25: 15.57761127,
      P50: 16.96062216,
      P75: 18.82201698,
      P85: 20.13117619,
      P90: 21.20058521,
      P95: 23.17734149,
      P97: 24.8235105,
    },
    {
      Agemos: 123.5,
      L: -2.134084303,
      M: 17.0102643,
      S: 0.138977537,
      P3: 13.81973772,
      P5: 14.12059071,
      P10: 14.62688307,
      P25: 15.61708531,
      P50: 17.0102643,
      P75: 18.8857198,
      P85: 20.20492657,
      P90: 21.28258757,
      P95: 23.27458358,
      P97: 24.93331042,
    },
    {
      Agemos: 124.5,
      L: -2.122547629,
      M: 17.06021213,
      S: 0.139585795,
      P3: 13.84713731,
      P5: 14.15003106,
      P10: 14.65980398,
      P25: 15.65696405,
      P50: 17.06021213,
      P75: 18.94959465,
      P85: 20.27875629,
      P90: 21.36459523,
      P95: 23.3717001,
      P97: 25.04288396,
    },
    {
      Agemos: 125.5,
      L: -2.111437411,
      M: 17.11045106,
      S: 0.140177947,
      P3: 13.87505922,
      P5: 14.17997251,
      P10: 14.69318989,
      P25: 15.69723611,
      P50: 17.11045106,
      P75: 19.01362305,
      P85: 20.35264468,
      P90: 21.44658611,
      P95: 23.46866758,
      P97: 25.1522079,
    },
    {
      Agemos: 126.5,
      L: -2.100749266,
      M: 17.16096656,
      S: 0.140753927,
      P3: 13.90349614,
      P5: 14.21040706,
      P10: 14.72703166,
      P25: 15.73789008,
      P50: 17.16096656,
      P75: 19.07778681,
      P85: 20.42657139,
      P90: 21.52853856,
      P95: 23.56546313,
      P97: 25.26125968,
    },
    {
      Agemos: 127.5,
      L: -2.090478774,
      M: 17.21174424,
      S: 0.141313686,
      P3: 13.9324406,
      P5: 14.2413266,
      P10: 14.76132003,
      P25: 15.77891452,
      P50: 17.21174424,
      P75: 19.14206792,
      P85: 20.50051644,
      P90: 21.61043134,
      P95: 23.6620644,
      P97: 25.37001743,
    },
    {
      Agemos: 128.5,
      L: -2.080621484,
      M: 17.26276973,
      S: 0.141857186,
      P3: 13.96188497,
      P5: 14.27272286,
      P10: 14.79604567,
      P25: 15.82029796,
      P50: 17.26276973,
      P75: 19.20644863,
      P85: 20.57446015,
      P90: 21.69224364,
      P95: 23.7584496,
      P97: 25.47845992,
    },
    {
      Agemos: 129.5,
      L: -2.071172932,
      M: 17.31402878,
      S: 0.142384404,
      P3: 13.99182148,
      P5: 14.30458743,
      P10: 14.83119912,
      P25: 15.86202891,
      P50: 17.31402878,
      P75: 19.2709114,
      P85: 20.64838321,
      P90: 21.77395506,
      P95: 23.85459754,
      P97: 25.58656661,
    },
    {
      Agemos: 130.5,
      L: -2.062128649,
      M: 17.3655072,
      S: 0.142895332,
      P3: 14.02224218,
      P5: 14.33691178,
      P10: 14.86677084,
      P25: 15.90409584,
      P50: 17.3655072,
      P75: 19.33543894,
      P85: 20.72226661,
      P90: 21.85554562,
      P95: 23.95048756,
      P97: 25.69431763,
    },
    {
      Agemos: 131.5,
      L: -2.053484173,
      M: 17.4171909,
      S: 0.143389972,
      P3: 14.05313898,
      P5: 14.36968723,
      P10: 14.90275116,
      P25: 15.94648722,
      P50: 17.4171909,
      P75: 19.40001418,
      P85: 20.79609169,
      P90: 21.93699575,
      P95: 24.04609957,
      P97: 25.80169379,
    },
    {
      Agemos: 132.5,
      L: -2.045235058,
      M: 17.46906585,
      S: 0.143868341,
      P3: 14.0845036,
      P5: 14.40290497,
      P10: 14.93913036,
      P25: 15.98919146,
      P50: 17.46906585,
      P75: 19.46462028,
      P85: 20.86984014,
      P90: 22.01828633,
      P95: 24.14141407,
      P97: 25.90867656,
    },
    {
      Agemos: 133.5,
      L: -2.03737688,
      M: 17.52111811,
      S: 0.144330469,
      P3: 14.11632765,
      P5: 14.43655605,
      P10: 14.97589856,
      P25: 16.03219696,
      P50: 17.52111811,
      P75: 19.52924064,
      P85: 20.94349396,
      P90: 22.09939861,
      P95: 24.23641208,
      P97: 26.01524807,
    },
    {
      Agemos: 134.5,
      L: -2.029906684,
      M: 17.57333347,
      S: 0.144776372,
      P3: 14.14860307,
      P5: 14.47063181,
      P10: 15.01304607,
      P25: 16.07549205,
      P50: 17.57333347,
      P75: 19.59385826,
      P85: 21.01703489,
      P90: 22.18031382,
      P95: 24.33107526,
      P97: 26.12139201,
    },
    {
      Agemos: 135.5,
      L: -2.022817914,
      M: 17.62569869,
      S: 0.145206138,
      P3: 14.18132032,
      P5: 14.50512237,
      P10: 15.05056245,
      P25: 16.11906513,
      P50: 17.62569869,
      P75: 19.65845792,
      P85: 21.09044653,
      P90: 22.26101479,
      P95: 24.4253857,
      P97: 26.22709056,
    },
    {
      Agemos: 136.5,
      L: -2.016107084,
      M: 17.67819987,
      S: 0.145619819,
      P3: 14.21447084,
      P5: 14.54001862,
      P10: 15.08843769,
      P25: 16.1629045,
      P50: 17.67819987,
      P75: 19.72302336,
      P85: 21.16371155,
      P90: 22.34148377,
      P95: 24.51932616,
      P97: 26.33232837,
    },
    {
      Agemos: 137.5,
      L: -2.009769905,
      M: 17.7308234,
      S: 0.146017491,
      P3: 14.24804556,
      P5: 14.57531107,
      P10: 15.12666154,
      P25: 16.20699844,
      P50: 17.7308234,
      P75: 19.78753889,
      P85: 21.2368133,
      P90: 22.42170373,
      P95: 24.61287993,
      P97: 26.43709027,
    },
    {
      Agemos: 138.5,
      L: -2.003802134,
      M: 17.78355575,
      S: 0.146399239,
      P3: 14.28203528,
      P5: 14.61099007,
      P10: 15.16522362,
      P25: 16.25133521,
      P50: 17.78355575,
      P75: 19.85198907,
      P85: 21.30973548,
      P90: 22.50165801,
      P95: 24.70603086,
      P97: 26.54136175,
    },
    {
      Agemos: 139.5,
      L: -1.998199572,
      M: 17.83638347,
      S: 0.146765161,
      P3: 14.31643062,
      P5: 14.64704584,
      P10: 15.20411349,
      P25: 16.29590305,
      P50: 17.83638347,
      P75: 19.91635869,
      P85: 21.38246211,
      P90: 22.58133043,
      P95: 24.79876336,
      P97: 26.64512897,
    },
    {
      Agemos: 140.5,
      L: -1.992958064,
      M: 17.88929321,
      S: 0.147115364,
      P3: 14.35122206,
      P5: 14.68346846,
      P10: 15.24332057,
      P25: 16.34069017,
      P50: 17.88929321,
      P75: 19.98063276,
      P85: 21.45497756,
      P90: 22.66070517,
      P95: 24.89106241,
      P97: 26.74837878,
    },
    {
      Agemos: 141.5,
      L: -1.988073505,
      M: 17.94227168,
      S: 0.147449967,
      P3: 14.3863999,
      P5: 14.72024789,
      P10: 15.28283421,
      P25: 16.38568475,
      P50: 17.94227168,
      P75: 20.04479655,
      P85: 21.52726655,
      P90: 22.73976686,
      P95: 24.98291353,
      P97: 26.85109867,
    },
    {
      Agemos: 142.5,
      L: -1.983541835,
      M: 17.9953057,
      S: 0.147769097,
      P3: 14.42195429,
      P5: 14.75737391,
      P10: 15.32264362,
      P25: 16.43087493,
      P50: 17.9953057,
      P75: 20.10883554,
      P85: 21.5993141,
      P90: 22.81850056,
      P95: 25.07430281,
      P97: 26.95327681,
    },
    {
      Agemos: 143.5,
      L: -1.979359041,
      M: 18.04838216,
      S: 0.148072891,
      P3: 14.45787522,
      P5: 14.7948362,
      P10: 15.36273794,
      P25: 16.47624886,
      P50: 18.04838216,
      P75: 20.17273546,
      P85: 21.6711056,
      P90: 22.89689172,
      P95: 25.16521691,
      P97: 27.05490205,
    },
    {
      Agemos: 144.5,
      L: -1.975521156,
      M: 18.10148804,
      S: 0.148361495,
      P3: 14.4941525,
      P5: 14.83262428,
      P10: 15.40310619,
      P25: 16.52179463,
      P50: 18.10148804,
      P75: 20.23648228,
      P85: 21.74262677,
      P90: 22.97492622,
      P95: 25.25564304,
      P97: 27.15596389,
    },
    {
      Agemos: 145.5,
      L: -1.972024258,
      M: 18.15461039,
      S: 0.148635067,
      P3: 14.53077581,
      P5: 14.87072753,
      P10: 15.44373731,
      P25: 16.56750033,
      P50: 18.15461039,
      P75: 20.30006219,
      P85: 21.81386368,
      P90: 23.05259037,
      P95: 25.34556897,
      P97: 27.25645252,
    },
    {
      Agemos: 146.5,
      L: -1.968864465,
      M: 18.20773639,
      S: 0.148893769,
      P3: 14.56773463,
      P5: 14.90913518,
      P10: 15.4846201,
      P25: 16.61335399,
      P50: 18.20773639,
      P75: 20.36346164,
      P85: 21.88480271,
      P90: 23.12987089,
      P95: 25.43498301,
      P97: 27.35635878,
    },
    {
      Agemos: 147.5,
      L: -1.966037938,
      M: 18.26085325,
      S: 0.149137776,
      P3: 14.6050183,
      P5: 14.94783635,
      P10: 15.52574328,
      P25: 16.65934366,
      P50: 18.26085325,
      P75: 20.42666729,
      P85: 21.95543061,
      P90: 23.20675491,
      P95: 25.52387405,
      P97: 27.45567419,
    },
    {
      Agemos: 148.5,
      L: -1.963540872,
      M: 18.31394832,
      S: 0.14936727,
      P3: 14.64261599,
      P5: 14.98681999,
      P10: 15.56709547,
      P25: 16.70545732,
      P50: 18.31394832,
      P75: 20.48966607,
      P85: 22.02573445,
      P90: 23.28322999,
      P95: 25.61223153,
      P97: 27.55439092,
    },
    {
      Agemos: 149.5,
      L: -1.961369499,
      M: 18.36700902,
      S: 0.149582439,
      P3: 14.68051671,
      P5: 15.0260749,
      P10: 15.60866519,
      P25: 16.75168297,
      P50: 18.36700902,
      P75: 20.55244514,
      P85: 22.09570166,
      P90: 23.35928412,
      P95: 25.70004544,
      P97: 27.65250183,
    },
    {
      Agemos: 150.5,
      L: -1.959520079,
      M: 18.42002284,
      S: 0.149783482,
      P3: 14.7187093,
      P5: 15.06558978,
      P10: 15.65044083,
      P25: 16.79800855,
      P50: 18.42002284,
      P75: 20.61499187,
      P85: 22.16531999,
      P90: 23.43490568,
      P95: 25.78730634,
      P97: 27.75000043,
    },
    {
      Agemos: 151.5,
      L: -1.9579889,
      M: 18.47297739,
      S: 0.149970604,
      P3: 14.75718241,
      P5: 15.10535314,
      P10: 15.6924107,
      P25: 16.844422,
      P50: 18.47297739,
      P75: 20.67729392,
      P85: 22.23457754,
      P90: 23.51008349,
      P95: 25.87400532,
      P97: 27.8468809,
    },
    {
      Agemos: 152.5,
      L: -1.956772271,
      M: 18.52586035,
      S: 0.15014402,
      P3: 14.79592458,
      P5: 15.14535337,
      P10: 15.734563,
      P25: 16.89091121,
      P50: 18.52586035,
      P75: 20.73933915,
      P85: 22.30346276,
      P90: 23.58480678,
      P95: 25.96013405,
      P97: 27.9431381,
    },
    {
      Agemos: 153.5,
      L: -1.95586652,
      M: 18.57865951,
      S: 0.15030395,
      P3: 14.83492412,
      P5: 15.18557871,
      P10: 15.77688582,
      P25: 16.93746408,
      P50: 18.57865951,
      P75: 20.80111569,
      P85: 22.37196443,
      P90: 23.65906521,
      P95: 26.04568474,
      P97: 28.03876753,
    },
    {
      Agemos: 154.5,
      L: -1.955267984,
      M: 18.63136275,
      S: 0.150450621,
      P3: 14.87416922,
      P5: 15.22601726,
      P10: 15.81936717,
      P25: 16.98406846,
      P50: 18.63136275,
      P75: 20.86261191,
      P85: 22.44007167,
      P90: 23.73284885,
      P95: 26.13065016,
      P97: 28.13376538,
    },
    {
      Agemos: 155.5,
      L: -1.954973011,
      M: 18.68395801,
      S: 0.15058427,
      P3: 14.91364786,
      P5: 15.26665697,
      P10: 15.86199492,
      P25: 17.0307122,
      P50: 18.68395801,
      P75: 20.9238164,
      P85: 22.50777397,
      P90: 23.80614819,
      P95: 26.21502362,
      P97: 28.22812848,
    },
    {
      Agemos: 156.5,
      L: -1.954977947,
      M: 18.73643338,
      S: 0.150705138,
      P3: 14.95334789,
      P5: 15.30748565,
      P10: 15.90475685,
      P25: 17.07738309,
      P50: 18.73643338,
      P75: 20.98471803,
      P85: 22.57506113,
      P90: 23.87895414,
      P95: 26.298799,
      P97: 28.32185435,
    },
    {
      Agemos: 157.5,
      L: -1.955279136,
      M: 18.788777,
      S: 0.150813475,
      P3: 14.99325696,
      P5: 15.34849095,
      P10: 15.94764066,
      P25: 17.12406895,
      P50: 18.788777,
      P75: 21.04530589,
      P85: 22.6419233,
      P90: 23.95125804,
      P95: 26.38197072,
      P97: 28.41494115,
    },
    {
      Agemos: 158.5,
      L: -1.955872909,
      M: 18.84097713,
      S: 0.150909535,
      P3: 15.03336257,
      P5: 15.38966039,
      P10: 15.9906339,
      P25: 17.17075754,
      P50: 18.84097713,
      P75: 21.10556932,
      P85: 22.70835101,
      P90: 24.02305164,
      P95: 26.46453376,
      P97: 28.50738772,
    },
    {
      Agemos: 159.5,
      L: -1.956755579,
      M: 18.89302212,
      S: 0.150993582,
      P3: 15.07365202,
      P5: 15.43098133,
      P10: 16.03372404,
      P25: 17.21743662,
      P50: 18.89302212,
      P75: 21.16549792,
      P85: 22.77433508,
      P90: 24.0943271,
      P95: 26.54648363,
      P97: 28.59919355,
    },
    {
      Agemos: 160.5,
      L: -1.957923436,
      M: 18.94490041,
      S: 0.151065883,
      P3: 15.11411246,
      P5: 15.47244099,
      P10: 16.07689846,
      P25: 17.2640939,
      P50: 18.94490041,
      P75: 21.22508152,
      P85: 22.83986672,
      P90: 24.16507702,
      P95: 26.62781643,
      P97: 28.69035881,
    },
    {
      Agemos: 161.5,
      L: -1.959372737,
      M: 18.99660055,
      S: 0.151126714,
      P3: 15.15473086,
      P5: 15.51402643,
      P10: 16.1201444,
      P25: 17.31071712,
      P50: 18.99660055,
      P75: 21.28431023,
      P85: 22.90493747,
      P90: 24.23529442,
      P95: 26.70852876,
      P97: 28.7808843,
    },
    {
      Agemos: 162.5,
      L: -1.9610997,
      M: 19.04811118,
      S: 0.151176355,
      P3: 15.19549401,
      P5: 15.55572457,
      P10: 16.16344901,
      P25: 17.35729394,
      P50: 19.04811118,
      P75: 21.34317438,
      P85: 22.96953922,
      P90: 24.30497273,
      P95: 26.78861781,
      P97: 28.8707715,
    },
    {
      Agemos: 163.5,
      L: -1.963100496,
      M: 19.09942105,
      S: 0.151215094,
      P3: 15.23638853,
      P5: 15.59752218,
      P10: 16.20679934,
      P25: 17.40381205,
      P50: 19.09942105,
      P75: 21.40166456,
      P85: 23.03366421,
      P90: 24.37410579,
      P95: 26.8680813,
      P97: 28.96002256,
    },
    {
      Agemos: 164.5,
      L: -1.96537124,
      M: 19.15051899,
      S: 0.151243223,
      P3: 15.27740086,
      P5: 15.63940588,
      P10: 16.25018232,
      P25: 17.45025909,
      P50: 19.15051899,
      P75: 21.45977162,
      P85: 23.09730502,
      P90: 24.4426879,
      P95: 26.94691749,
      P97: 29.04864025,
    },
    {
      Agemos: 165.5,
      L: -1.967907983,
      M: 19.20139397,
      S: 0.151261042,
      P3: 15.31851726,
      P5: 15.68136213,
      P10: 16.2935848,
      P25: 17.4966227,
      P50: 19.20139397,
      P75: 21.51748666,
      P85: 23.16045458,
      P90: 24.51071375,
      P95: 27.02512521,
      P97: 29.13662805,
    },
    {
      Agemos: 166.5,
      L: -1.970706706,
      M: 19.25203503,
      S: 0.151268855,
      P3: 15.35972381,
      P5: 15.72337724,
      P10: 16.33699348,
      P25: 17.54289048,
      P50: 19.25203503,
      P75: 21.57480102,
      P85: 23.2231062,
      P90: 24.57817846,
      P95: 27.10270382,
      P97: 29.22399004,
    },
    {
      Agemos: 167.5,
      L: -1.973763307,
      M: 19.30243131,
      S: 0.151266974,
      P3: 15.40100642,
      P5: 15.76543738,
      P10: 16.38039498,
      P25: 17.58905003,
      P50: 19.30243131,
      P75: 21.63170632,
      P85: 23.28525349,
      P90: 24.64507758,
      P95: 27.17965324,
      P97: 29.31073101,
    },
    {
      Agemos: 168.5,
      L: -1.977073595,
      M: 19.35257209,
      S: 0.151255713,
      P3: 15.44235082,
      P5: 15.80752854,
      P10: 16.42377582,
      P25: 17.63508894,
      P50: 19.35257209,
      P75: 21.68819441,
      P85: 23.34689045,
      P90: 24.71140708,
      P95: 27.25597392,
      P97: 29.39685635,
    },
    {
      Agemos: 169.5,
      L: -1.980633277,
      M: 19.40244671,
      S: 0.151235395,
      P3: 15.48374254,
      P5: 15.84963659,
      P10: 16.46712239,
      P25: 17.68099474,
      P50: 19.40244671,
      P75: 21.74425743,
      P85: 23.40801143,
      P90: 24.77716335,
      P95: 27.33166687,
      P97: 29.48237214,
    },
    {
      Agemos: 170.5,
      L: -1.984437954,
      M: 19.45204465,
      S: 0.151206347,
      P3: 15.52516693,
      P5: 15.89174721,
      P10: 16.51042098,
      P25: 17.72675499,
      P50: 19.45204465,
      P75: 21.79988774,
      P85: 23.46861112,
      P90: 24.8423432,
      P95: 27.40673363,
      P97: 29.56728511,
    },
    {
      Agemos: 171.5,
      L: -1.988483106,
      M: 19.50135548,
      S: 0.151168902,
      P3: 15.56660919,
      P5: 15.93384595,
      P10: 16.55365778,
      P25: 17.7723572,
      P50: 19.50135548,
      P75: 21.855078,
      P85: 23.52868458,
      P90: 24.90694389,
      P95: 27.4811763,
      P97: 29.65160263,
    },
    {
      Agemos: 172.5,
      L: -1.992764085,
      M: 19.55036888,
      S: 0.151123398,
      P3: 15.60805429,
      P5: 15.97591819,
      P10: 16.59681886,
      P25: 17.81778887,
      P50: 19.55036888,
      P75: 21.90982109,
      P85: 23.5882272,
      P90: 24.97096308,
      P95: 27.55499753,
      P97: 29.73533271,
    },
    {
      Agemos: 173.5,
      L: -1.997276103,
      M: 19.59907464,
      S: 0.15107018,
      P3: 15.64948704,
      P5: 16.01794916,
      P10: 16.63989017,
      P25: 17.86303751,
      P50: 19.59907464,
      P75: 21.96411019,
      P85: 23.64723476,
      P90: 25.03439887,
      P95: 27.62820049,
      P97: 29.81848403,
    },
    {
      Agemos: 174.5,
      L: -2.002014224,
      M: 19.64746266,
      S: 0.151009595,
      P3: 15.69089206,
      P5: 16.05992392,
      P10: 16.68285759,
      P25: 17.90809056,
      P50: 19.64746266,
      P75: 22.01793871,
      P85: 23.70570337,
      P90: 25.09724977,
      P95: 27.70078892,
      P97: 29.90106591,
    },
    {
      Agemos: 175.5,
      L: -2.00697335,
      M: 19.69552294,
      S: 0.150942,
      P3: 15.73225378,
      P5: 16.10182739,
      P10: 16.72570683,
      P25: 17.95293549,
      P50: 19.69552294,
      P75: 22.07130033,
      P85: 23.76362952,
      P90: 25.15951476,
      P95: 27.77276709,
      P97: 29.9830883,
    },
    {
      Agemos: 176.5,
      L: -2.012148213,
      M: 19.7432456,
      S: 0.150867753,
      P3: 15.77355644,
      P5: 16.14364432,
      P10: 16.76842355,
      P25: 17.99755974,
      P50: 19.7432456,
      P75: 22.12418902,
      P85: 23.82101004,
      P90: 25.22119319,
      P95: 27.84413981,
      P97: 30.06456182,
    },
    {
      Agemos: 177.5,
      L: -2.017533363,
      M: 19.79062086,
      S: 0.150787221,
      P3: 15.8147841,
      P5: 16.1853593,
      P10: 16.81099326,
      P25: 18.04195071,
      P50: 19.79062086,
      P75: 22.17659899,
      P85: 23.87784214,
      P90: 25.28228489,
      P95: 27.91491246,
      P97: 30.14549772,
    },
    {
      Agemos: 178.5,
      L: -2.023123159,
      M: 19.83763907,
      S: 0.150700774,
      P3: 15.85592062,
      P5: 16.22695676,
      P10: 16.85340136,
      P25: 18.08609581,
      P50: 19.83763907,
      P75: 22.22852472,
      P85: 23.93412338,
      P90: 25.3427901,
      P95: 27.98509093,
      P97: 30.22590789,
    },
    {
      Agemos: 179.5,
      L: -2.028911755,
      M: 19.88429066,
      S: 0.150608788,
      P3: 15.89694967,
      P5: 16.26842096,
      P10: 16.89563315,
      P25: 18.12998242,
      P50: 19.88429066,
      P75: 22.27996095,
      P85: 23.98985168,
      P90: 25.40270949,
      P95: 28.05468167,
      P97: 30.30580486,
    },
    {
      Agemos: 180.5,
      L: -2.034893091,
      M: 19.9305662,
      S: 0.150511645,
      P3: 15.93785473,
      P5: 16.30973602,
      P10: 16.93767383,
      P25: 18.17359792,
      P50: 19.9305662,
      P75: 22.33090272,
      P85: 24.04502533,
      P90: 25.46204417,
      P95: 28.12369169,
      P97: 30.3852018,
    },
    {
      Agemos: 181.5,
      L: -2.041060881,
      M: 19.97645636,
      S: 0.150409731,
      P3: 15.97861909,
      P5: 16.35088587,
      P10: 16.97950845,
      P25: 18.21692964,
      P50: 19.97645636,
      P75: 22.3813453,
      P85: 24.09964299,
      P90: 25.52079568,
      P95: 28.19212851,
      P97: 30.46411251,
    },
    {
      Agemos: 182.5,
      L: -2.047408604,
      M: 20.02195192,
      S: 0.15030344,
      P3: 16.01922586,
      P5: 16.3918543,
      P10: 17.02112198,
      P25: 18.25996492,
      P50: 20.02195192,
      P75: 22.43128425,
      P85: 24.15370368,
      P90: 25.578966,
      P95: 28.26000023,
      P97: 30.54255145,
    },
    {
      Agemos: 183.5,
      L: -2.05392949,
      M: 20.06704377,
      S: 0.150193169,
      P3: 16.05965792,
      P5: 16.43262492,
      P10: 17.06249925,
      P25: 18.30269107,
      P50: 20.06704377,
      P75: 22.4807154,
      P85: 24.20720678,
      P90: 25.63655753,
      P95: 28.32731547,
      P97: 30.62053368,
    },
    {
      Agemos: 184.5,
      L: -2.060616513,
      M: 20.11172291,
      S: 0.150079322,
      P3: 16.09989799,
      P5: 16.47318119,
      P10: 17.10362501,
      P25: 18.34509539,
      P50: 20.11172291,
      P75: 22.52963484,
      P85: 24.26015204,
      P90: 25.69357313,
      P95: 28.3940834,
      P97: 30.69807493,
    },
    {
      Agemos: 185.5,
      L: -2.067462375,
      M: 20.15598047,
      S: 0.149962308,
      P3: 16.13992859,
      P5: 16.5135064,
      P10: 17.14448385,
      P25: 18.38716515,
      P50: 20.15598047,
      P75: 22.57803895,
      P85: 24.3125396,
      P90: 25.75001608,
      P95: 28.46031375,
      P97: 30.77519152,
    },
    {
      Agemos: 186.5,
      L: -2.074459502,
      M: 20.19980767,
      S: 0.14984254,
      P3: 16.17973203,
      P5: 16.55358366,
      P10: 17.18506029,
      P25: 18.42888762,
      P50: 20.19980767,
      P75: 22.62592435,
      P85: 24.36436993,
      P90: 25.80589011,
      P95: 28.52601678,
      P97: 30.85190042,
    },
    {
      Agemos: 187.5,
      L: -2.081600029,
      M: 20.24319586,
      S: 0.149720441,
      P3: 16.21929043,
      P5: 16.59339594,
      P10: 17.22533871,
      P25: 18.47025003,
      P50: 20.24319586,
      P75: 22.67328797,
      P85: 24.41564391,
      P90: 25.86119939,
      P95: 28.59120331,
      P97: 30.92821925,
    },
    {
      Agemos: 188.5,
      L: -2.088875793,
      M: 20.28613648,
      S: 0.149596434,
      P3: 16.25858573,
      P5: 16.63292602,
      P10: 17.26530336,
      P25: 18.5112396,
      P50: 20.28613648,
      P75: 22.72012699,
      P85: 24.46636278,
      P90: 25.91594853,
      P95: 28.6558847,
      P97: 31.00416621,
    },
    {
      Agemos: 189.5,
      L: -2.096278323,
      M: 20.32862109,
      S: 0.149470953,
      P3: 16.29759966,
      P5: 16.67215653,
      P10: 17.30493841,
      P25: 18.55184353,
      P50: 20.32862109,
      P75: 22.76643886,
      P85: 24.51652812,
      P90: 25.97014258,
      P95: 28.72007286,
      P97: 31.07976017,
    },
    {
      Agemos: 190.5,
      L: -2.103798828,
      M: 20.37064138,
      S: 0.149344433,
      P3: 16.33631375,
      P5: 16.71106993,
      P10: 17.34422789,
      P25: 18.592049,
      P50: 20.37064138,
      P75: 22.81222132,
      P85: 24.56614194,
      P90: 26.02378703,
      P95: 28.78378025,
      P97: 31.15502058,
    },
    {
      Agemos: 191.5,
      L: -2.111428194,
      M: 20.41218911,
      S: 0.149217319,
      P3: 16.37470935,
      P5: 16.74964852,
      P10: 17.38315572,
      P25: 18.63184317,
      P50: 20.41218911,
      P75: 22.85747235,
      P85: 24.61520657,
      P90: 26.07688783,
      P95: 28.84701989,
      P97: 31.22996756,
    },
    {
      Agemos: 192.5,
      L: -2.119156972,
      M: 20.45325617,
      S: 0.14909006,
      P3: 16.4127676,
      P5: 16.78787442,
      P10: 17.4217057,
      P25: 18.67121316,
      P50: 20.45325617,
      P75: 22.90219024,
      P85: 24.66372475,
      P90: 26.12945136,
      P95: 28.90980533,
      P97: 31.30462183,
    },
    {
      Agemos: 193.5,
      L: -2.126975375,
      M: 20.49383457,
      S: 0.14896311,
      P3: 16.45046946,
      P5: 16.82572959,
      P10: 17.4598615,
      P25: 18.7101461,
      P50: 20.49383457,
      P75: 22.94637354,
      P85: 24.71169957,
      P90: 26.18148447,
      P95: 28.97215071,
      P97: 31.37900471,
    },
    {
      Agemos: 194.5,
      L: -2.134873266,
      M: 20.5339164,
      S: 0.148836931,
      P3: 16.48779567,
      P5: 16.86319583,
      P10: 17.49760671,
      P25: 18.74862906,
      P50: 20.5339164,
      P75: 22.99002104,
      P85: 24.75913451,
      P90: 26.23299442,
      P95: 29.03407069,
      P97: 31.45313817,
    },
    {
      Agemos: 195.5,
      L: -2.142840157,
      M: 20.57349387,
      S: 0.148711989,
      P3: 16.52472682,
      P5: 16.90025476,
      P10: 17.53492476,
      P25: 18.78664912,
      P50: 20.57349387,
      P75: 23.03313184,
      P85: 24.80603342,
      P90: 26.28398896,
      P95: 29.0955805,
      P97: 31.5270448,
    },
    {
      Agemos: 196.5,
      L: -2.150865204,
      M: 20.61255929,
      S: 0.148588757,
      P3: 16.56124326,
      P5: 16.93688787,
      P10: 17.57179899,
      P25: 18.82419331,
      P50: 20.61255929,
      P75: 23.07570528,
      P85: 24.85240053,
      P90: 26.33447627,
      P95: 29.15669594,
      P97: 31.6007478,
    },
    {
      Agemos: 197.5,
      L: -2.158937201,
      M: 20.65110506,
      S: 0.148467715,
      P3: 16.59732518,
      P5: 16.97307644,
      P10: 17.60821261,
      P25: 18.86124863,
      P50: 20.65110506,
      P75: 23.11774099,
      P85: 24.89824041,
      P90: 26.38446498,
      P95: 29.21743336,
      P97: 31.67427098,
    },
    {
      Agemos: 198.5,
      L: -2.167044578,
      M: 20.6891237,
      S: 0.148349348,
      P3: 16.63295257,
      P5: 17.00880162,
      P10: 17.64414872,
      P25: 18.89780209,
      P50: 20.6891237,
      P75: 23.15923885,
      P85: 24.94355805,
      P90: 26.43396418,
      P95: 29.27780966,
      P97: 31.74763877,
    },
    {
      Agemos: 199.5,
      L: -2.175176987,
      M: 20.72660728,
      S: 0.14823412,
      P3: 16.6681059,
      P5: 17.04404492,
      P10: 17.67959061,
      P25: 18.93384056,
      P50: 20.72660728,
      P75: 23.2001981,
      P85: 24.98835785,
      P90: 26.48298259,
      P95: 29.33784232,
      P97: 31.8208776,
    },
    {
      Agemos: 200.5,
      L: -2.183317362,
      M: 20.76355011,
      S: 0.148122614,
      P3: 16.70276277,
      P5: 17.07878553,
      P10: 17.71452019,
      P25: 18.96935117,
      P50: 20.76355011,
      P75: 23.24062188,
      P85: 25.03264836,
      P90: 26.53153263,
      P95: 29.39754939,
      P97: 31.89400908,
    },
    {
      Agemos: 201.5,
      L: -2.191457792,
      M: 20.79994337,
      S: 0.148015249,
      P3: 16.73690461,
      P5: 17.11300574,
      P10: 17.74892115,
      P25: 19.00432062,
      P50: 20.79994337,
      P75: 23.28050814,
      P85: 25.07643282,
      P90: 26.57962232,
      P95: 29.45694948,
      P97: 31.96706354,
    },
    {
      Agemos: 202.5,
      L: -2.199583649,
      M: 20.83578051,
      S: 0.147912564,
      P3: 16.77050999,
      P5: 17.14668547,
      P10: 17.78277581,
      P25: 19.03873583,
      P50: 20.83578051,
      P75: 23.31985872,
      P85: 25.11971866,
      P90: 26.62726338,
      P95: 29.51606178,
      P97: 32.04006655,
    },
    {
      Agemos: 203.5,
      L: -2.207681525,
      M: 20.87105449,
      S: 0.147815078,
      P3: 16.80355796,
      P5: 17.17980508,
      P10: 17.81606667,
      P25: 19.07258364,
      P50: 20.87105449,
      P75: 23.35867482,
      P85: 25.16251271,
      P90: 26.67446717,
      P95: 29.57490604,
      P97: 32.11304561,
    },
    {
      Agemos: 204.5,
      L: -2.215737645,
      M: 20.90575839,
      S: 0.147723315,
      P3: 16.83602739,
      P5: 17.21234472,
      P10: 17.8487761,
      P25: 19.10585083,
      P50: 20.90575839,
      P75: 23.39695789,
      P85: 25.20482218,
      P90: 26.7212455,
      P95: 29.63350259,
      P97: 32.18602887,
    },
    {
      Agemos: 205.5,
      L: -2.223739902,
      M: 20.93988477,
      S: 0.147637768,
      P3: 16.86789789,
      P5: 17.24428517,
      P10: 17.88088689,
      P25: 19.13852417,
      P50: 20.93988477,
      P75: 23.43470854,
      P85: 25.24665348,
      P90: 26.76760965,
      P95: 29.69187236,
      P97: 32.25904682,
    },
    {
      Agemos: 206.5,
      L: -2.231667995,
      M: 20.97342858,
      S: 0.147559083,
      P3: 16.89914528,
      P5: 17.27560407,
      P10: 17.91237975,
      P25: 19.1705904,
      P50: 20.97342858,
      P75: 23.4719319,
      P85: 25.28801794,
      P90: 26.81357526,
      P95: 29.75003682,
      P97: 32.33212376,
    },
    {
      Agemos: 207.5,
      L: -2.239511942,
      M: 21.00638171,
      S: 0.147487716,
      P3: 16.92975012,
      P5: 17.30628297,
      P10: 17.9432379,
      P25: 19.20203617,
      P50: 21.00638171,
      P75: 23.50862756,
      P85: 25.32892108,
      P90: 26.85915312,
      P95: 29.80801808,
      P97: 32.40529387,
    },
    {
      Agemos: 208.5,
      L: -2.247257081,
      M: 21.0387374,
      S: 0.14742421,
      P3: 16.95969079,
      P5: 17.33630159,
      P10: 17.97344339,
      P25: 19.23284815,
      P50: 21.0387374,
      P75: 23.54479768,
      P85: 25.3693713,
      P90: 26.90435663,
      P95: 29.8658388,
      P97: 32.47858818,
    },
    {
      Agemos: 209.5,
      L: -2.254885145,
      M: 21.07048996,
      S: 0.147369174,
      P3: 16.98894397,
      P5: 17.36563825,
      P10: 18.00297732,
      P25: 19.26301292,
      P50: 21.07048996,
      P75: 23.58044651,
      P85: 25.40937929,
      P90: 26.94920132,
      P95: 29.92352223,
      P97: 32.55203545,
    },
    {
      Agemos: 210.5,
      L: -2.26238209,
      M: 21.10163241,
      S: 0.147323144,
      P3: 17.01748828,
      P5: 17.39427286,
      P10: 18.03182181,
      P25: 19.29251705,
      P50: 21.10163241,
      P75: 23.61557603,
      P85: 25.44895346,
      P90: 26.9937009,
      P95: 29.98109225,
      P97: 32.62566902,
    },
    {
      Agemos: 211.5,
      L: -2.269731517,
      M: 21.13215845,
      S: 0.147286698,
      P3: 17.0453012,
      P5: 17.42218439,
      P10: 18.05995835,
      P25: 19.32134706,
      P50: 21.13215845,
      P75: 23.65018962,
      P85: 25.48810377,
      P90: 27.03787056,
      P95: 30.03857329,
      P97: 32.69952105,
    },
    {
      Agemos: 212.5,
      L: -2.276917229,
      M: 21.16206171,
      S: 0.147260415,
      P3: 17.07236026,
      P5: 17.44935184,
      P10: 18.08736842,
      P25: 19.34948942,
      P50: 21.16206171,
      P75: 23.68429059,
      P85: 25.52684029,
      P90: 27.08172575,
      P95: 30.09599042,
      P97: 32.77362474,
    },
    {
      Agemos: 213.5,
      L: -2.283925442,
      M: 21.1913351,
      S: 0.147244828,
      P3: 17.09864412,
      P5: 17.47575509,
      P10: 18.11403409,
      P25: 19.37693055,
      P50: 21.1913351,
      P75: 23.71788103,
      P85: 25.56517184,
      P90: 27.12528094,
      P95: 30.15336926,
      P97: 32.84801632,
    },
    {
      Agemos: 214.5,
      L: -2.290731442,
      M: 21.21997472,
      S: 0.147240683,
      P3: 17.12412619,
      P5: 17.50136974,
      P10: 18.13993458,
      P25: 19.4036568,
      P50: 21.21997472,
      P75: 23.75096919,
      P85: 25.603114,
      P90: 27.16855668,
      P95: 30.21073618,
      P97: 32.92272296,
    },
    {
      Agemos: 215.5,
      L: -2.29732427,
      M: 21.24797262,
      S: 0.147248467,
      P3: 17.14878634,
      P5: 17.52617667,
      P10: 18.16505254,
      P25: 19.4296545,
      P50: 21.24797262,
      P75: 23.78355579,
      P85: 25.64067435,
      P90: 27.21156668,
      P95: 30.26811799,
      P97: 32.9977851,
    },
    {
      Agemos: 216.5,
      L: -2.303687802,
      M: 21.27532239,
      S: 0.14726877,
      P3: 17.17260194,
      P5: 17.55015468,
      P10: 18.18936927,
      P25: 19.45490996,
      P50: 21.27532239,
      P75: 23.81564452,
      P85: 25.67786376,
      P90: 27.25432769,
      P95: 30.32554216,
      P97: 33.07323931,
    },
    {
      Agemos: 217.5,
      L: -2.309799971,
      M: 21.30201933,
      S: 0.147302299,
      P3: 17.19554744,
      P5: 17.57328018,
      P10: 18.21286444,
      P25: 19.47940934,
      P50: 21.30201933,
      P75: 23.84724252,
      P85: 25.714697,
      P90: 27.29685999,
      P95: 30.38303689,
      P97: 33.14911749,
    },
    {
      Agemos: 218.5,
      L: -2.315651874,
      M: 21.32805489,
      S: 0.147349514,
      P3: 17.21760352,
      P5: 17.59553468,
      P10: 18.23552108,
      P25: 19.50313889,
      P50: 21.32805489,
      P75: 23.8783497,
      P85: 25.75118107,
      P90: 27.33917721,
      P95: 30.44063073,
      P97: 33.22546443,
    },
    {
      Agemos: 219.5,
      L: -2.32121731,
      M: 21.35342563,
      S: 0.147411215,
      P3: 17.23874246,
      P5: 17.61689275,
      P10: 18.25731763,
      P25: 19.52608462,
      P50: 21.35342563,
      P75: 23.90897582,
      P85: 25.7873338,
      P90: 27.38130268,
      P95: 30.49835324,
      P97: 33.30230965,
    },
    {
      Agemos: 220.5,
      L: -2.326481911,
      M: 21.37812462,
      S: 0.147487979,
      P3: 17.25894211,
      P5: 17.63733356,
      P10: 18.27823552,
      P25: 19.54823263,
      P50: 21.37812462,
      P75: 23.93912415,
      P85: 25.82316611,
      P90: 27.42325378,
      P95: 30.55623433,
      P97: 33.37969431,
    },
    {
      Agemos: 221.5,
      L: -2.331428139,
      M: 21.40214589,
      S: 0.147580453,
      P3: 17.2781787,
      P5: 17.6568349,
      P10: 18.29825524,
      P25: 19.5695689,
      P50: 21.40214589,
      P75: 23.96879993,
      P85: 25.85869115,
      P90: 27.46505002,
      P95: 30.61430463,
      P97: 33.45765732,
    },
    {
      Agemos: 222.5,
      L: -2.336038473,
      M: 21.42548351,
      S: 0.147689289,
      P3: 17.29642829,
      P5: 17.67537444,
      P10: 18.31735722,
      P25: 19.59007937,
      P50: 21.42548351,
      P75: 23.99800858,
      P85: 25.89392241,
      P90: 27.50671138,
      P95: 30.67259539,
      P97: 33.53623826,
    },
    {
      Agemos: 223.5,
      L: -2.34029545,
      M: 21.44813156,
      S: 0.14781515,
      P3: 17.31366688,
      P5: 17.69292976,
      P10: 18.33552175,
      P25: 19.60974989,
      P50: 21.44813156,
      P75: 24.02675569,
      P85: 25.92887369,
      P90: 27.54825824,
      P95: 30.73113849,
      P97: 33.61547736,
    },
    {
      Agemos: 224.5,
      L: -2.344181703,
      M: 21.47008412,
      S: 0.147958706,
      P3: 17.32987032,
      P5: 17.70947829,
      P10: 18.35272903,
      P25: 19.62856629,
      P50: 21.47008412,
      P75: 24.05504702,
      P85: 25.96355908,
      P90: 27.58971144,
      P95: 30.78996648,
      P97: 33.69541553,
    },
    {
      Agemos: 225.5,
      L: -2.34768,
      M: 21.49133529,
      S: 0.148120633,
      P3: 17.34501435,
      P5: 17.72499741,
      P10: 18.36895919,
      P25: 19.64651429,
      P50: 21.49133529,
      P75: 24.08288851,
      P85: 25.99799301,
      P90: 27.63109226,
      P95: 30.84911253,
      P97: 33.77609435,
    },
    {
      Agemos: 226.5,
      L: -2.350773286,
      M: 21.51187918,
      S: 0.148301619,
      P3: 17.35907462,
      P5: 17.73946435,
      P10: 18.38419225,
      P25: 19.66357958,
      P50: 21.51187918,
      P75: 24.11028624,
      P85: 26.03219019,
      P90: 27.67242239,
      P95: 30.90861047,
      P97: 33.8575561,
    },
    {
      Agemos: 227.5,
      L: -2.353444725,
      M: 21.53170989,
      S: 0.148502355,
      P3: 17.37202667,
      P5: 17.75285625,
      P10: 18.39840811,
      P25: 19.67974776,
      P50: 21.53170989,
      P75: 24.13724646,
      P85: 26.06616563,
      P90: 27.71372396,
      P95: 30.96849476,
      P97: 33.93984372,
    },
    {
      Agemos: 228.5,
      L: -2.355677743,
      M: 21.55082155,
      S: 0.148723546,
      P3: 17.38384594,
      P5: 17.76515018,
      P10: 18.41158663,
      P25: 19.69500438,
      P50: 21.55082155,
      P75: 24.16377555,
      P85: 26.09993465,
      P90: 27.75501954,
      P95: 31.02880054,
      P97: 34.02300086,
    },
    {
      Agemos: 229.5,
      L: -2.35745607,
      M: 21.56920824,
      S: 0.148965902,
      P3: 17.39450778,
      P5: 17.77632308,
      P10: 18.42370753,
      P25: 19.70933491,
      P50: 21.56920824,
      P75: 24.18988006,
      P85: 26.13351285,
      P90: 27.7963321,
      P95: 31.08956357,
      P97: 34.10707184,
    },
    {
      Agemos: 230.5,
      L: -2.358763788,
      M: 21.58686406,
      S: 0.149230142,
      P3: 17.40398745,
      P5: 17.78635182,
      P10: 18.43475047,
      P25: 19.72272474,
      P50: 21.58686406,
      P75: 24.21556667,
      P85: 26.16691611,
      P90: 27.83768505,
      P95: 31.1508203,
      P97: 34.1921017,
    },
    {
      Agemos: 231.5,
      L: -2.359585369,
      M: 21.60378309,
      S: 0.149516994,
      P3: 17.41226013,
      P5: 17.79521318,
      P10: 18.44469501,
      P25: 19.73515921,
      P50: 21.60378309,
      P75: 24.24084219,
      P85: 26.20016061,
      P90: 27.87910223,
      P95: 31.21260782,
      P97: 34.27813617,
    },
    {
      Agemos: 232.5,
      L: -2.359905726,
      M: 21.61995939,
      S: 0.149827195,
      P3: 17.41930091,
      P5: 17.80288385,
      P10: 18.45352064,
      P25: 19.74662357,
      P50: 21.61995939,
      P75: 24.26571357,
      P85: 26.23326279,
      P90: 27.92060786,
      P95: 31.27496389,
      P97: 34.3652217,
    },
    {
      Agemos: 233.5,
      L: -2.359710258,
      M: 21.635387,
      S: 0.150161492,
      P3: 17.42508482,
      P5: 17.80934044,
      P10: 18.46120675,
      P25: 19.757103,
      P50: 21.635387,
      P75: 24.29018788,
      P85: 26.26623937,
      P90: 27.9622266,
      P95: 31.33792691,
      P97: 34.45340544,
    },
    {
      Agemos: 234.5,
      L: -2.358980464,
      M: 21.65006126,
      S: 0.150520734,
      P3: 17.42958448,
      P5: 17.81455755,
      P10: 18.46773133,
      P25: 19.76658246,
      P50: 21.65006126,
      P75: 24.31427499,
      P85: 26.29911039,
      P90: 28.00398629,
      P95: 31.40153631,
      P97: 34.54273066,
    },
    {
      Agemos: 235.5,
      L: -2.357714508,
      M: 21.6639727,
      S: 0.150905439,
      P3: 17.43278087,
      P5: 17.81851675,
      P10: 18.47307706,
      P25: 19.77504736,
      P50: 21.6639727,
      P75: 24.33797514,
      P85: 26.33188509,
      P90: 28.04590512,
      P95: 31.46583097,
      P97: 34.6332581,
    },
    {
      Agemos: 236.5,
      L: -2.355892424,
      M: 21.67711736,
      S: 0.151316531,
      P3: 17.43464513,
      P5: 17.82119134,
      P10: 18.47722102,
      P25: 19.78248242,
      P50: 21.67711736,
      P75: 24.3613,
      P85: 26.36458581,
      P90: 28.08801335,
      P95: 31.53085181,
      P97: 34.72502979,
    },
    {
      Agemos: 237.5,
      L: -2.353501353,
      M: 21.68948935,
      S: 0.151754808,
      P3: 17.43515168,
      P5: 17.82255737,
      P10: 18.48014209,
      P25: 19.78887249,
      P50: 21.68948935,
      P75: 24.3842575,
      P85: 26.39723077,
      P90: 28.13033767,
      P95: 31.59663995,
      P97: 34.81809511,
    },
    {
      Agemos: 238.5,
      L: -2.350528726,
      M: 21.70108288,
      S: 0.152221086,
      P3: 17.43427465,
      P5: 17.8225906,
      P10: 18.48181892,
      P25: 19.79420232,
      P50: 21.70108288,
      P75: 24.40685591,
      P85: 26.42983873,
      P90: 28.17290543,
      P95: 31.66323724,
      P97: 34.91250374,
    },
    {
      Agemos: 239.5,
      L: -2.346962247,
      M: 21.71189225,
      S: 0.152716206,
      P3: 17.43198786,
      P5: 17.82126653,
      P10: 18.48222995,
      P25: 19.79845655,
      P50: 21.71189225,
      P75: 24.42910393,
      P85: 26.4624291,
      P90: 28.21574476,
      P95: 31.73068625,
      P97: 35.00830552,
    },
    {
      Agemos: 240,
      L: -2.34495843,
      M: 21.71699934,
      S: 0.152974718,
      P3: 17.43031075,
      P5: 17.82009046,
      P10: 18.48195582,
      P25: 19.80017572,
      P50: 21.71699934,
      P75: 24.44009565,
      P85: 26.47871966,
      P90: 28.23727135,
      P95: 31.76474311,
      P97: 35.05675093,
    },
    {
      Agemos: 240.5,
      L: -2.342796948,
      M: 21.72190973,
      S: 0.153240872,
      P3: 17.42826863,
      P5: 17.81856356,
      P10: 18.48135557,
      P25: 19.80161999,
      P50: 21.72190973,
      P75: 24.45100622,
      P85: 26.49501679,
      P90: 28.25887983,
      P95: 31.79902964,
      P97: 35.10555822,
    },
  ],
};

export default BMIPercentile;

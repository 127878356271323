import React from "react";
import { Form } from "react-bootstrap";
import { Typeahead, Highlighter } from "react-bootstrap-typeahead";
import PatientDimensions from "./PatientDimensions";
import { ELIGIBILITY_CRITERIA } from "../../common/consts";
import "react-bootstrap-typeahead/css/Typeahead.css";

function RhythmProgramEligibilityFamilyMember(props) {
  const probandPGIDSection = (
    <Form.Group controlId="probandPGID">
      <Form.Label>Proband PGID #</Form.Label>
      <Form.Control
        type="text"
        name="probandPGID"
        placeholder="XXXX-XXX-XXX"
        value={props.formState.probandPGID}
        onChange={props.handleChange}
        required={!props.userIsPGUser}
      />
    </Form.Group>
  );

  const probandNameSection = (
    <Form.Group controlId="probandName">
      <Form.Label>Proband Name</Form.Label>
      <Form.Control
        type="text"
        name="probandName"
        value={props.formState.probandName}
        onChange={props.handleChange}
        required={!props.userIsPGUser}
      />
    </Form.Group>
  );

  const probandNameAutoCompleteSection = (
    <Form.Group controlId="probandName">
      <Form.Label>Proband Name</Form.Label>
      <Typeahead
        renderMenuItemChildren={(option, props, index) => {
          return [
            <div key={option.pgid}>
              <Highlighter search={props.text}>
                {option.patientName}
              </Highlighter>
              <div>
                <small>PGID # : {option.pgid}</small>
              </div>
            </div>,
          ];
        }}
        id="probandName"
        labelKey={(option) => option.patientName || props.formState.probandName}
        options={props.previouslyAccessionedPatients || []}
        placeholder="Proband Name"
        onChange={(event) => {
          if (event[0]) {
            props.handleProbandChange(event[0].patientName, event[0].pgid);
          }
        }}
        onInputChange={(event) => {
          props.handleProbandChange(event);
        }}
        selected={[props.formState.probandName]}
      />
    </Form.Group>
  );

  const probandSection = props.userIsPGUser ? (
    <div>
      {probandPGIDSection}
      {probandNameSection}
    </div>
  ) : (
    <div>
      {probandNameAutoCompleteSection}
      {probandPGIDSection}
    </div>
  );

  const familyMemberFields = <>
    {probandSection}
    <Form.Group controlId="relationshipToProband">
      <p className="mb-0">
        <Form.Label>Relationship to Affected Patient (Proband):</Form.Label>
      </p>
      {["Mother", "Father", "Sibling", "Child"].map((type) => (
        <Form.Check
          inline
          key={`relationshipToProband-${type}`}
          id={`relationshipToProband-${type}`}
          type="radio"
          label={type}
          value={type}
          name="relationshipToProband"
          checked={props.formState.relationshipToProband.includes(type)}
          onChange={props.handleChange}
          onClick={props.handleCheckboxRequirement}
          required={
            !props.userIsPGUser &&
            props.formState.relationshipToProband.length === 0
          }
        />
      ))}
    </Form.Group>
    <PatientDimensions {...props} />
  </>;

  return (
    <div className="mx-3">
      <Form.Check
        key="programEligibilityCriteriaAgeFamilyMember"
        id="programEligibilityCriteriaAgeFamilyMember"
        className="programEligibilityCriteriaCheckbox"
        type="radio"
        label={ELIGIBILITY_CRITERIA.FAMILY_MEMBER}
        value={ELIGIBILITY_CRITERIA.FAMILY_MEMBER}
        name="programEligibilityCriteria"
        checked={props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.FAMILY_MEMBER}
        onChange={props.handleChange}
        onClick={props.handleRadioButtonClick}
        required={!props.userIsPGUser}
      />
      <p className="text-center mt-3">
        <small>
          Test eligibility for first degree relatives will be indicated in the
          notes section of the proband report. If eligible, a full gene panel
          analysis will be performed.
          <br/>
          <strong>
            <em>
              Call PreventionGenetics at (844) 513-3994 to confirm eligibility
              prior to test submission.
            </em>
          </strong>
        </small>
      </p>
      {
        props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.FAMILY_MEMBER &&
        familyMemberFields
      }
    </div>
  );
}

export default RhythmProgramEligibilityFamilyMember;

import React from "react";
import { Form, Col } from "react-bootstrap";
import { ELIGIBILITY_CRITERIA } from "../../common/consts";

function dates(props) {
  let dateAccessioned = null;
  const BelowV3CutOffDate = new Date("2023-02-01");
  if (props.order && props.order.accessionedDateTime) {
    dateAccessioned = new Date(props.order.accessionedDateTime);
  }

  return [dateAccessioned, BelowV3CutOffDate];
}

function submissionIsCompletable(props) {
  const [dateAccessioned, BelowV3CutOffDate] = dates(props);
  
  return (
    (props.formState.patientType === "New Patient"
      ? props.formState.programEligibilityCriteria &&
        props.formState.programEligibilityCriteria !==
          ELIGIBILITY_CRITERIA.INELIGIBLE &&
        props.formState.programEligibilityCriteria !==
          ELIGIBILITY_CRITERIA.EXCEPTION_REQUESTED
      : true) &&
    props.formState.providerPrintedName &&
    props.formState.providerSignatureDate &&
    (
      parseFloat(props.formState.consentVersion) >= 3 ||
      (dateAccessioned && dateAccessioned < BelowV3CutOffDate)
    )
  );
}

function generateSubmissionCompleteMessage(props) {
  const [dateAccessioned, BelowV3CutOffDate] = dates(props);

  if (submissionIsCompletable(props)) {
    return null;
  } else {
    return (
      <div className="alert alert-danger">
        <p>
          The following must be completed before the submission can be marked as
          complete:
        </p>
        <ul>
          {!props.formState.programEligibilityCriteria.trim() &&
          props.formState.patientType === "New Patient" ? (
            <li>Must select a Program Eligibility option</li>
          ) : null}
          {props.formState.programEligibilityCriteria ===
            ELIGIBILITY_CRITERIA.INELIGIBLE &&
          props.formState.patientType === "New Patient" ? (
            <li>A Program Eligibility criteria must be met</li>
          ) : null}
          {props.formState.programEligibilityCriteria ===
            ELIGIBILITY_CRITERIA.EXCEPTION_REQUESTED &&
          props.formState.patientType === "New Patient" ? (
            <li>An exception was requested but no decision was selected. Please select whether the exception was granted or if the patient is ineligible</li>
          ) : null}
          {!props.formState.providerPrintedName ? (
            <li>Must enter the provider's printed name</li>
          ) : null}
          {!props.formState.providerSignatureDate ? (
            <li>Must enter the provider's signature date</li>
          ) : null}
          {props.formState.consentVersion.trim() && parseFloat(props.formState.consentVersion) < 3 &&
          (!dateAccessioned || dateAccessioned > BelowV3CutOffDate) ? (
            <li>
              Consent forms prior to version 3 are only allowed for orders accessioned on or before {" "}
              {BelowV3CutOffDate.toLocaleDateString()}
            </li>
          ) : null}
          {!props.formState.consentVersion.trim() ? (
            <li>Consent form must be signed</li>
          ) : null}
        </ul>
      </div>
    );
  }
}

function PaperFormEntryFinalChecks(props) {
  return (
    <div>
      <h4 className="text-center text-uppercase mb-0">Consent Form</h4>
      <Form.Row>
        <Form.Group as={Col} sm="1" controlId="consentVersion">
          <Form.Label className="mt-3">Version</Form.Label>
          <Form.Control
            as="select"
            name="consentVersion"
            onChange={props.handleChange}
            required={props.userIsPGUser}
            value={props.formState.consentVersion}
          >
            {[" ", "1", "2", "3", "3.1", "4.0", "4.1"].map((type) => (
              <option key={`consentVersion${type}`} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} sm="2" controlId="consentVersionLanguage">
          <Form.Label className="mt-3">Language</Form.Label>
          <Form.Control
            as="select"
            name="consentVersionLanguage"
            onChange={props.handleChange}
            required={props.userIsPGUser}
            value={props.formState.consentVersionLanguage}
          >
            {["English", "Spanish", "French"].map((type) => (
              <option key={`consentVersionLanguage${type}`} value={type}>
                {type}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>
      {
        // Starting in V3 PHI Disclosure and Marketing Opt Ins were collected
        +props.formState.consentVersion.split(".")[0] >= 3 ? (
        <>
          <Form.Row>
            <Form.Group as={Col} controlId="phiDisclosure">
              <Form.Label>PHI Disclosure</Form.Label>
              <div>
                <Form.Check
                  inline
                  key="phiDisclosureNo"
                  id="phiDisclosureNo"
                  type="radio"
                  label="No"
                  value="No"
                  name="phiDisclosure"
                  checked={props.formState.phiDisclosure === "No"}
                  onChange={props.handleChange}
                  onClick={props.handleRadioButtonClick}
                />
                <Form.Check
                  inline
                  key="phiDisclosureYes"
                  id="phiDisclosureYes"
                  type="radio"
                  label="Yes"
                  value="Yes"
                  name="phiDisclosure"
                  checked={props.formState.phiDisclosure === "Yes"}
                  onChange={props.handleChange}
                  onClick={props.handleRadioButtonClick}
                />
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="marketingOptIn">
              <Form.Label>Marketing Opt In</Form.Label>
              <div>
                <Form.Check
                  inline
                  key="marketingOptInNo"
                  id="marketingOptInNo"
                  type="radio"
                  label="No"
                  value="No"
                  name="marketingOptIn"
                  checked={props.formState.marketingOptIn === "No"}
                  onChange={props.handleChange}
                  onClick={props.handleRadioButtonClick}
                  disabled={props.formState.phiDisclosure !== "Yes"}
                />
                <Form.Check
                  inline
                  key="marketingOptInYes"
                  id="marketingOptInYes"
                  type="radio"
                  label="Yes"
                  value="Yes"
                  name="marketingOptIn"
                  checked={props.formState.marketingOptIn === "Yes"}
                  onChange={props.handleChange}
                  onClick={props.handleRadioButtonClick}
                  disabled={props.formState.phiDisclosure !== "Yes"}
                />
              </div>
            </Form.Group>
          </Form.Row>
        </>
      ) : null}
      <Form.Group controlId="consentFormSigned">
        {generateSubmissionCompleteMessage(props)}
        <Form.Label>Submission Complete</Form.Label>
        <div>
          <Form.Check
            inline
            key="submissionCompleteNo"
            id="submissionCompleteNo"
            type="radio"
            label="No"
            value="No"
            name="submissionComplete"
            checked={props.formState.submissionComplete === "No"}
            onChange={props.handleChange}
            onClick={props.handleRadioButtonClick}
          />
          <Form.Check
            inline
            key="submissionCompleteYes"
            id="submissionCompleteYes"
            type="radio"
            label="Yes"
            value="Yes"
            name="submissionComplete"
            checked={props.formState.submissionComplete === "Yes"}
            onChange={props.handleChange}
            onClick={props.handleRadioButtonClick}
            disabled={!submissionIsCompletable(props)}
          />
        </div>
      </Form.Group>
    </div>
  );
}

export default PaperFormEntryFinalChecks;

import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import PasswordInput from "../../components/auth/PasswordInput";

export default (props) => {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [htmlValidated, setHtmlValidated] = useState(false);
  const [passwordIsValid, setPasswordValidity] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const submittedForm = event.currentTarget;

    setHtmlValidated(true);
    if (submittedForm.checkValidity() && passwordIsValid) {
      const lowerCaseUsername = props.username.toLowerCase();
      props.onSubmit(lowerCaseUsername, code, password);
    }
  };

  const handlePasswordChange = (passwordObject) => {
    setPassword(passwordObject.password);
    setPasswordValidity(passwordObject.isValid);
  };

  return (
    <Form className="form" method="POST" noValidate onSubmit={handleSubmit}>
      <Form.Row>
        <Form.Group as={Col}>
          <p className="mb-0">
            We have sent a password reset code by email to {props.username}.
            Enter it below to reset your password.
          </p>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="code">
          <Form.Label>
            Code<sup>*</sup>
          </Form.Label>
          <Form.Control
            type="text"
            key="code"
            name="code"
            onChange={(event) => setCode(event.target.value)}
            value={code}
            isValid={code}
            isInvalid={htmlValidated && !code}
            required
          />
          <Form.Control.Feedback type="invalid">
            Please enter the code sent to your email.
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <PasswordInput
        onChange={handlePasswordChange}
        showPasswordConfirmation={true}
        validated={htmlValidated}
      />
      <Form.Row>
        <Form.Group as={Col}>
          <Button
            type="submit"
            className="float-right px-3"
            disabled={props.disableSubmit}
          >
            Change Password
          </Button>
        </Form.Group>
      </Form.Row>
      {/* FAKE CSRF TOKEN TO FAKE OUT TENABLE */}
      <input
        type="hidden"
        name="csrf_token"
        value={Math.random().toString(36).substring(2, 24)}
      />
    </Form>
  );
};

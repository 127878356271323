import React from "react";
import { Alert, Form, Col } from "react-bootstrap";
import { ELIGIBILITY_CRITERIA } from "../../common/consts";

function ClinicalInformation(props) {
  let eligibilityAlert = null;
  const GTEDoesNotMatch =
    props.patientAge < 19 || (props.patientBMI > 0 && props.patientBMI < 40);

  const LTE18DoesNotMatch = props.patientAge > 18 || !props.isBMIGreaterThanOrEqualTo97Percent(props.patientAge, props.patientBMI, props.formState.patientSex);

  // Set alert for PGUsers
  if (
    props.userIsPGUser &&
    props.formState.programEligibilityCriteria &&
    props.patientAge > 2
  ) {
    if (
      (props.formState.programEligibilityCriteria ===
        ELIGIBILITY_CRITERIA.AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY &&
      GTEDoesNotMatch) ||
      (props.formState.programEligibilityCriteria ===
        ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97 &&
      LTE18DoesNotMatch)
    ) {
      eligibilityAlert = (
        <Alert variant="warning">
          Selected Program Eligibility does not match Clinical Information
        </Alert>
      );
    }
  }

  return (
    <>
      <section>
        {eligibilityAlert}
        <h4 className="text-center text-uppercase">Required Clinical Information</h4>
        <div className="px-3">
          <Form.Row>
            <Form.Group as={Col} sm="6">
              <Form.Label>Waist Circumference</Form.Label>
              <Form.Row>
                <Form.Group
                  as={Col}
                  sm="5"
                  controlId="waistCircumference"
                >
                  <Form.Control
                    type="number"
                    required={!props.userIsPGUser}
                    placeholder="Waist Circumference"
                    name="waistCircumference"
                    value={props.formState.waistCircumference}
                    onChange={props.handleChange}
                    disabled={props.formState.waistCircumference === "Not Evaluated"}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  sm="3"
                  controlId="waistCircumferenceUnits"
                >
                  {["in", "cm"].map((type) => (
                    <Form.Check
                      inline
                      className="mt-2"
                      key={`waistCircumferenceUnits${type}`}
                      id={`waistCircumferenceUnits${type}`}
                      type="radio"
                      label={type}
                      value={type}
                      name="waistCircumferenceUnits"
                      checked={props.formState.waistCircumferenceUnits === type}
                      onChange={props.handleChange}
                      onClick={props.handleRadioButtonClick}
                      required={!props.userIsPGUser}
                      disabled={props.formState.waistCircumference === "Not Evaluated"}
                    />
                  ))}
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="waistCircumferenceNotEvaluated"
                >
                  <Form.Check
                    inline
                    className="mt-2"
                    key="waistCircumferenceNotEvaluated"
                    id="waistCircumferenceNotEvaluated"
                    type="checkbox"
                    label="Not Evaluated"
                    value="Not Evaluated"
                    name="waistCircumference"
                    checked={props.formState.waistCircumference === "Not Evaluated"}
                    onChange={props.handleWaistCircumferenceNotEvaluated}
                    required={!props.userIsPGUser && !props.formState.waistCircumference}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row  className="page-break-avoid">
                <Form.Group
                  as={Col}
                  sm="10"
                  controlId="historyOfWeightLossInterventions"
                >
                  <Form.Label>History of weight loss interventions<br/> (check all that apply):</Form.Label>
                  {["Diet/lifestyle", "Anti-obesity medications", "Bariatric Surgery", "None", "Unknown"].map((type) => (
                    <Form.Check
                      key={`historyOfWeightLossInterventions${type}`}
                      id={`historyOfWeightLossInterventions${type}`}
                      type="checkbox"
                      label={type}
                      value={type}
                      name="historyOfWeightLossInterventions"
                      checked={props.formState.historyOfWeightLossInterventions.includes(
                        type
                      )}
                      onChange={props.handleChange}
                      onClick={props.handleCheckboxRequirement}
                      required={
                        !props.userIsPGUser &&
                        props.formState.historyOfWeightLossInterventions.length === 0
                      }
                    />
                  ))}
                  <Form.Label className="ml-3 mt-2">Additional Comments</Form.Label>
                  <Form.Control
                      as="textarea"
                      className="mx-3"
                      name="historyOfWeightLossInterventionsComments"
                      rows="3"
                      value={props.formState.historyOfWeightLossInterventionsComments}
                      onChange={props.handleChange}
                      maxLength="500"
                    />
                </Form.Group>
              </Form.Row>
              <Form.Row className="page-break-avoid">
                <Form.Group
                  as={Col}
                  controlId="familyHistoryOfObesity"
                >
                  <p className="mb-0">
                    <Form.Label>Family history of obesity (check all that apply):</Form.Label>
                  </p>
                  {["Mother", "Father", "Sibling(s)", "None", "Unknown"].map(
                    (type) => (
                      <Form.Check
                        key={`familyHistoryOfObesity${type}`}
                        id={`familyHistoryOfObesity${type}`}
                        type="checkbox"
                        label={type}
                        value={type}
                        name="familyHistoryOfObesity"
                        checked={props.formState.familyHistoryOfObesity.includes(
                          type
                        )}
                        onChange={props.handleChange}
                        onClick={props.handleCheckboxRequirement}
                        required={
                          !props.userIsPGUser &&
                          props.formState.familyHistoryOfObesity.length === 0
                        }
                      />
                    )
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row className="page-break-avoid">
                <Form.Group
                  as={Col}
                  sm="10"
                  controlId="geneticDiseaseFamilyHistory"
                >
                  <p className="mb-0">
                    <Form.Label>
                      Family history of genetic disease and/or earlier testing:
                    </Form.Label>
                  </p>
                  {["Yes", "No", "Unknown"].map((type) => (
                    <Form.Check
                      key={`geneticDiseaseFamilyHistory${type}`}
                      id={`geneticDiseaseFamilyHistory${type}`}
                      type="radio"
                      label={type}
                      value={type}
                      name="geneticDiseaseFamilyHistory"
                      checked={
                        props.formState.geneticDiseaseFamilyHistory === type
                      }
                      onChange={props.handleChange}
                      onClick={props.handleRadioButtonClick}
                      required={!props.userIsPGUser}
                    />
                  ))}
                </Form.Group>
              </Form.Row>
              {props.formState.geneticDiseaseFamilyHistory === "Yes" ? (
                <Form.Row className="page-break-avoid">
                  <Form.Group
                    as={Col}
                    sm="10"
                    controlId="geneticDiseaseFamilyHistoryResults"
                  >
                    <Form.Label>Results</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="geneticDiseaseFamilyHistoryResults"
                      rows="3"
                      value={props.formState.geneticDiseaseFamilyHistoryResults}
                      onChange={props.handleChange}
                      maxLength="500"
                    />
                  </Form.Group>
                </Form.Row>
              ) : (
                <div />
              )}
              <Form.Row>
                <Form.Group
                  as={Col}
                  controlId="bbsDiagnosis"
                  className="pr-0"
                >
                  <p className="mb-0">
                    <Form.Label>Diagnosis of Bardet Biedl Syndrome (BBS)</Form.Label>
                  </p>
                  {["Clinically Diagnosed", "Suspected", "Not suspected", "Unknown"].map((type) => (
                    <Form.Check
                      key={`bbsDiagnosis${type}`}
                      id={`bbsDiagnosis${type}`}
                      type="radio"
                      label={type}
                      value={type}
                      name="bbsDiagnosis"
                      checked={props.formState.bbsDiagnosis === type}
                      onChange={props.handleChange}
                      onClick={props.handleRadioButtonClick}
                      required={!props.userIsPGUser}
                    />
                  ))}
                </Form.Group>
              </Form.Row>
            </Form.Group>
            <Form.Group as={Col} sm="6">
              <Form.Row>
                <Form.Group
                  as={Col}
                  sm="5"
                  controlId="hyperphagia"
                  className="pr-0"
                >
                  <p className="mb-0">
                    <Form.Label>Hyperphagia</Form.Label>
                  </p>
                  {["Yes", "No", "Unknown"].map((type) => (
                    <Form.Check
                      inline
                      key={`hyperphagia${type}`}
                      id={`hyperphagia${type}`}
                      type="radio"
                      label={type}
                      value={type}
                      name="hyperphagia"
                      checked={props.formState.hyperphagia === type}
                      onChange={(event) => {
                        props.handleAgeOfOnset(event, "ageOfOnsetHyperphagia");
                      }}
                      onClick={props.handleRadioButtonClick}
                      required={!props.userIsPGUser}
                    />
                  ))}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <p><em>Hyperphagia is characterized by pathological, insatiable hunger accompanied by abnormal food seeking behaviors and extreme pre-occupation with food that results in a significant negative impact on the lives and functioning of patients and caregivers.</em></p>
                <p><em>Examples of behaviors associated with hyperphagia include night awakening to eat, sneaking or hiding food, being distressed if denied food, and hyperphagia may impact focus and performance at school and work.</em></p>
                <p><em>Please note that behaviors may vary between patients, and not all behaviors are expected to be present in every patient.</em></p>
              </Form.Row>
              <Form.Row>
                {props.formState.hyperphagia === "Yes" ? (
                  <>
                    <Form.Group as={Col} controlId="hyperphagiaScale">
                      <Form.Label className="mb-0">On a scale of 1-5, how would you categorize the severity of the hyperphagia based on the above definition?</Form.Label>
                      {["1 (mild)","2","3","4","5 (severe)", "N/A"].map((type) => (
                          <Form.Check
                              key={`hyperphagiaScale${type}`}
                              id={`hyperphagiaScale${type}`}
                              type="radio"
                              label={type}
                              value={type}
                              name="hyperphagiaScale"
                              checked={props.formState.hyperphagiaScale === type}
                              onChange={props.handleChange}
                              onClick={props.handleRadioButtonClick}
                              required={!props.userIsPGUser}
                          />
                      ))}
                    </Form.Group>
                  </>
                ) : (
                  <div />
                )}
              </Form.Row>
              <Form.Row>
                {props.formState.hyperphagia === "Yes" ? (
                  <>
                    <Form.Group as={Col} controlId="ageOfOnsetHyperphagia">
                      <Form.Label className="mb-0">Age of hyperphagia onset in years</Form.Label>
                      {["0-2 years", "3-5 years", "6-10 years","11-17 years","Adult","Unknown"].map((type) => (
                          <Form.Check
                              key={`ageOfOnsetHyperphagia${type}`}
                              id={`ageOfOnsetHyperphagia${type}`}
                              type="radio"
                              label={type}
                              value={type}
                              name="ageOfOnsetHyperphagia"
                              checked={props.formState.ageOfOnsetHyperphagia === type}
                              onChange={props.handleChange}
                              onClick={props.handleRadioButtonClick}
                              required={!props.userIsPGUser}
                          />
                      ))}
                    </Form.Group>
                  </>
                ) : (
                  <div />
                )}
              </Form.Row>
            </Form.Group>
          </Form.Row>
        </div>
      </section>
      <section>
        <h4 className="text-center text-uppercase">Additional Clinical Information</h4>
        <div>
          <Form.Row>
            <Form.Group as={Col} sm="6">
              <Form.Group as={Col} controlId="developmentalDelay">
                <Form.Row>
                  <Form.Group as={Col} sm="6">
                    <Form.Label className="mb-0">Developmental Delay</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" className="text-right">
                    {["Yes", "No", "Not Evaluated"].map((type) => (
                      <Form.Check
                          inline
                          key={`developmentalDelay${type}`}
                          id={`developmentalDelay${type}`}
                          type="radio"
                          label={type}
                          value={type}
                          name="developmentalDelay"
                          checked={props.formState.developmentalDelay === type}
                          onChange={props.handleChange}
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser}
                      />
                    ))}
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <Form.Group as={Col} controlId="cognitiveImpairment">
                <Form.Row>
                  <Form.Group as={Col} sm="6" className="mb-0">
                    <Form.Label className="mb-0">Cognitive Impairment</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" className="mb-0 text-right">
                    {["Yes", "No", "Not Evaluated"].map((type) => (
                      <Form.Check
                          inline
                          key={`cognitiveImpairment${type}`}
                          id={`cognitiveImpairment${type}`}
                          type="radio"
                          label={type}
                          value={type}
                          name="cognitiveImpairment"
                          checked={props.formState.cognitiveImpairment === type}
                          onChange={props.handleChange}
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser}
                      />
                    ))}
                  </Form.Group>
                </Form.Row>
                <ul>
                  <Form.Row>
                    <Form.Group as={Col} sm="5" className="mb-0">
                      <li>
                        <Form.Label className="mb-0">Learning Difficulties</Form.Label>
                      </li>
                    </Form.Group>
                    <Form.Group as={Col} sm="7" className="mb-0 text-right">
                      {["Yes", "No", "Not Evaluated"].map((type) => (
                          <Form.Check
                              inline
                              key={`cognitiveImpairmentLearningDifficulties${type}`}
                              id={`cognitiveImpairmentLearningDifficulties${type}`}
                              type="radio"
                              label={type}
                              value={type}
                              name="cognitiveImpairmentLearningDifficulties"
                              checked={props.formState.cognitiveImpairmentLearningDifficulties === type}
                              onChange={props.handleChange}
                              onClick={props.handleRadioButtonClick}
                              required={!props.userIsPGUser}
                          />
                      ))}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm="5" className="mb-0">
                      <li>
                        <Form.Label className="mb-0">Speech Delay</Form.Label>
                      </li>
                    </Form.Group>
                    <Form.Group as={Col} sm="7" className="mb-0 text-right">
                      {["Yes", "No", "Not Evaluated"].map((type) => (
                          <Form.Check
                              inline
                              key={`cognitiveImpairmentSpeechDelay${type}`}
                              id={`cognitiveImpairmentSpeechDelay${type}`}
                              type="radio"
                              label={type}
                              value={type}
                              name="cognitiveImpairmentSpeechDelay"
                              checked={props.formState.cognitiveImpairmentSpeechDelay === type}
                              onChange={props.handleChange}
                              onClick={props.handleRadioButtonClick}
                              required={!props.userIsPGUser}
                          />
                      ))}
                    </Form.Group>
                  </Form.Row>
                </ul>
              </Form.Group>
              <Form.Group as={Col} controlId="hypogonadism">
                <Form.Row>
                  <Form.Group as={Col} sm="6">
                    <Form.Label className="mb-0">Hypogonadism or genitourinary anomalies</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" className="text-right">
                    {["Yes", "No", "Not Evaluated"].map((type) => (
                      <Form.Check
                          inline
                          key={`hypogonadism${type}`}
                          id={`hypogonadism${type}`}
                          type="radio"
                          label={type}
                          value={type}
                          name="hypogonadism"
                          checked={props.formState.hypogonadism === type}
                          onChange={props.handleChange}
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser}
                      />
                    ))}
                  </Form.Group>
                </Form.Row>
              </Form.Group>
            </Form.Group>
            <Form.Group as={Col} sm="6">
              <Form.Group as={Col} controlId="visionImpairment">
                <Form.Row>
                  <Form.Group as={Col} sm="6" className="mb-0">
                    <Form.Label className="mb-0">Vision Impairment</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" className="mb-0 text-right">
                    {["Yes", "No", "Not Evaluated"].map((type) => (
                      <Form.Check
                          inline
                          key={`visionImpairment${type}`}
                          id={`visionImpairment${type}`}
                          type="radio"
                          label={type}
                          value={type}
                          name="visionImpairment"
                          checked={props.formState.visionImpairment === type}
                          onChange={props.handleChange}
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser}
                      />
                    ))}
                  </Form.Group>
                </Form.Row>
                <ul>
                  <Form.Row>
                    <Form.Group as={Col} sm="5" className="mb-0">
                      <li>
                        <Form.Label className="mb-0">Retinal Dystrophy</Form.Label>
                      </li>
                    </Form.Group>
                    <Form.Group as={Col} sm="7" className="mb-0 text-right">
                      {["Yes", "No", "Not Evaluated"].map((type) => (
                          <Form.Check
                              inline
                              key={`visionImpairmentRetinalDystrophy${type}`}
                              id={`visionImpairmentRetinalDystrophy${type}`}
                              type="radio"
                              label={type}
                              value={type}
                              name="visionImpairmentRetinalDystrophy"
                              checked={props.formState.visionImpairmentRetinalDystrophy === type}
                              onChange={props.handleChange}
                              onClick={props.handleRadioButtonClick}
                              required={!props.userIsPGUser}
                          />
                      ))}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm="5" className="mb-0">
                      <li>
                        <Form.Label className="mb-0">Night blindness</Form.Label>
                      </li>
                    </Form.Group>
                    <Form.Group as={Col} sm="7" className="mb-0 text-right">
                      {["Yes", "No", "Not Evaluated"].map((type) => (
                          <Form.Check
                              inline
                              key={`visionImpairmentNightBlindness${type}`}
                              id={`visionImpairmentNightBlindness${type}`}
                              type="radio"
                              label={type}
                              value={type}
                              name="visionImpairmentNightBlindness"
                              checked={props.formState.visionImpairmentNightBlindness === type}
                              onChange={props.handleChange}
                              onClick={props.handleRadioButtonClick}
                              required={!props.userIsPGUser}
                          />
                      ))}
                    </Form.Group>
                  </Form.Row>
                </ul>
              </Form.Group>
              <Form.Group as={Col} controlId="renalAnomalies">
                <Form.Row>
                  <Form.Group as={Col} sm="6">
                    <Form.Label className="mb-0">Renal anomalies</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" className="text-right">
                    {["Yes", "No", "Not Evaluated"].map((type) => (
                      <Form.Check
                          inline
                          key={`renalAnomalies${type}`}
                          id={`renalAnomalies${type}`}
                          type="radio"
                          label={type}
                          value={type}
                          name="renalAnomalies"
                          checked={props.formState.renalAnomalies === type}
                          onChange={props.handleChange}
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser}
                      />
                    ))}
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <Form.Group as={Col} controlId="historyOfPolydactyly">
                <Form.Row>
                  <Form.Group as={Col} sm="6">
                    <Form.Label className="mb-0">History of polydactyly</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} sm="6" className="text-right">
                    {["Yes", "No", "Not Evaluated"].map((type) => (
                      <Form.Check
                          inline
                          key={`historyOfPolydactyly${type}`}
                          id={`historyOfPolydactyly${type}`}
                          type="radio"
                          label={type}
                          value={type}
                          name="historyOfPolydactyly"
                          checked={props.formState.historyOfPolydactyly === type}
                          onChange={props.handleChange}
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser}
                      />
                    ))}
                  </Form.Group>
                </Form.Row>
              </Form.Group>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              as={Col}
              controlId="otherFeatures"
              className="mx-3"
            >
              <Form.Label>Other features</Form.Label>
              <Form.Control
                  as="textarea"
                  name="otherFeatures"
                  rows="3"
                  value={props.formState.otherFeatures}
                  onChange={props.handleChange}
                  maxLength="500"
                />
            </Form.Group>
          </Form.Row>
        </div>
      </section>
    </>
  );
}

export default ClinicalInformation;

import { useState, useDebugValue } from "react";

export default function useStateWithLabel(initialValue, name) {
  const [value, setValue] = useState(initialValue);
  useDebugValue(`${name}: ${value}`);
  return [value, setValue];
}

export function userMayNotOrder(email) {
  return ["joinmochi.com", "joinmochihealth.com"].some((suffix) => email.endsWith(suffix));
}
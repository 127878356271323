import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Redirect, NavLink } from "react-router-dom";
import Spinner from "../../components/Spinner";
import axios from "axios";
import queryString from "query-string";
import "../../css/auth.scss";

export default class ConfirmSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isConfirmed: false,
      error: null,
      email: queryString.parse(this.props.location.search).email,
    };
  }

  renderLoading() {
    return (
      <Container className="mt-5">
        <Spinner />
      </Container>
    );
  }

  renderSuccess() {
    return (
      <Container className="mt-5 text-center">
        <h5 className="mb-0 alert alert-success " role="alert">
          Your account has been activated!
        </h5>
        <p>
          <NavLink
            className="btn btn-primary mt-3"
            to={`./login?email=${this.state.email}`}
          >
            Login
          </NavLink>
        </p>
      </Container>
    );
  }

  renderError() {
    return (
      <Container className="mt-5 alert alert-danger" role="alert">
        <h5 className="mb-0 text-center">
          A server error has occurred (Details: {this.state.error}) If this
          problem persists please <NavLink to="/contactUs">contact us</NavLink>
        </h5>
      </Container>
    );
  }

  async confirmSignUp(email, confirmationCode) {
    const confirmSignUpObject = { email, confirmationCode };
    try {
      return await axios.put(`/api/confirmSignUp`, confirmSignUpObject);
    } catch (error) {
      console.error(error);
    }
  }

  async componentDidMount() {
    const { email, confirmationCode } = queryString.parse(
      this.props.location.search
    );
    if (email && confirmationCode) {
      const confirmSignUpResponse = await this.confirmSignUp(
        email,
        confirmationCode
      );
      if (confirmSignUpResponse && confirmSignUpResponse.status === 204) {
        this.setState({ isConfirmed: true, error: null, isLoading: false });
      } else {
        this.setState({ error: `Failed to confirm email`, isLoading: false });
      }
    } else if (!email) {
      this.setState({ error: `Email not found`, isLoading: false });
    } else {
      this.setState({ error: `Code not found`, isLoading: false });
    }
  }

  render() {
    // If logged in then redirect them to the home page
    if (this.props.user) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    if (this.state.isLoading) {
      return this.renderLoading();
    }
    if (this.state.isConfirmed) {
      return this.renderSuccess();
    } else {
      return this.renderError();
    }
  }
}

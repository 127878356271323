import React from "react";

export default () => {
  return (
    <div id="spinnerOverlay">
      <div>
        {/* Taken from bootstrap table loader */}
        <div className="_loading_overlay_spinner css-50etie"><svg viewBox="25 25 50 50"><circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"></circle></svg></div>
      </div>
    </div>
  );
};

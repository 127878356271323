import React from "react";
import { Alert, FormCheck } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { SAMPLE_COLLECTION_LOCATIONS } from "../../common/consts";

export default (props) => {
  let nextSteps = "";
  switch (props.formState.sampleCollectionLocation) {
    case SAMPLE_COLLECTION_LOCATIONS.AT_HOME:
      if (props.formState.sendKitConfirmation === "true") {
        nextSteps = (
          <ol>
            {/* ======= KIT REQUESTED STEPS ======== */}
            <li className="font-weight-bold mb-1">
              The Program Eligibility and Provider Authorization Form will be
              emailed to <a href={`mailto:${props.email}`}>{props.email}</a> via
              Docusign. Please expect an email from Docusign within the next 1-2
              business days.
            </li>
            <li className="font-weight-bold mb-1">
              Buccal OCD-100 kit and patient authorization forms will be sent
              directly from PreventionGenetics to the patient address provided.
              Note that the patient is required to sign the consent for genetic
              testing and participation in order for testing to begin.
            </li>
          </ol>
        );
      }
      break;
    case SAMPLE_COLLECTION_LOCATIONS.AT_LAB:
      nextSteps = (
        <>
          <ul>
            <li className="font-weight-bold">
              PreventionGenetics will be notified to retest your patient. If the
              status on this order does not change to Testing in Progress over
              the next few days please{" "}
              <NavLink to="../contactUs">contact us</NavLink>
            </li>
          </ul>
          <p>
            <small className="font-weight-bold">
              If there is not enough DNA, we will recontact you
            </small>
          </p>
        </>
      );
      break;
    default:
      nextSteps = (
        <>
          <ol className="d-print-none font-weight-bold">
            {/* ======= STANDARD STEPS ======== */}
            <li className="mb-1">
              {props.formState.isProvider === "true"
                ? `Sign the first page of the printout and obtain patient or authorized caregiver signatures on the following pages as appropriate. The unique bar code on the summary page is used to identify your patient's specimen for processing as appropriate.`
                : `Obtain provider signature on the first page of the printout and patient or authorized caregiver signatures on the following pages as appropriate. The unique bar code on the summary page is used to identify your patient's specimen for processing.`}
            </li>
            <li className="mb-1">
              Place printed forms (signed) in test kit along with sample and
              return to PreventionGenetics
            </li>
          </ol>
          <ul className="d-none d-print-block font-weight-bold ls-none">
            <li>
              <FormCheck
                className="checkbox-large mb-1"
                label={
                  props.formState.isProvider === "true"
                    ? `Sign the first page of the printout and obtain patient or authorized caregiver signatures on the following pages as appropriate. The unique bar code on the summary page is used to identify your patient's specimen for processing as appropriate.`
                    : `Obtain provider signature on this page and patient or authorized caregiver signatures on the following pages as appropriate. The unique bar code on the summary page is used to identify your patient's specimen for processing.`
                }
              />
            </li>
            <li>
              <FormCheck
                className="checkbox-large mb-1"
                label="Place printed forms (signed) in test kit along with sample and
            return to PreventionGenetics"
              />
            </li>
          </ul>
        </>
      );
  }
  return (
    <Alert variant="warning" className={props.className}>
      <h5 className="mt-3 mb-3">
        <strong>Next Steps:</strong>
      </h5>
      {nextSteps}
    </Alert>
  );
};

import React from "react";
import RhythmLogo from "./img/rhythm-logo.svg";
import PGStackedLogo from "./img/PG_pos_rgb_endorsed_stack.svg";

export default function BrowserNotSupported(props) {
  const noBullet = {
    listStyleType: "none",
  };
  return (
    <div className="mt-5 text-center">
      <div>
        <img src={RhythmLogo} alt="Rhythm Logo" className="p-3" />
        <img
          src={PGStackedLogo}
          alt="PG Logo"
          className="p-3"
          style={{ width: "300px" }}
        />
      </div>
      <h1>Browser not supported</h1>
      <p>We're sorry but your browser is not supported by this application.</p>
      <div>
        <p>
          For the best browsing experience please upgrade to a more modern
          browser:
        </p>
        <ul>
          <li style={noBullet}>
            <a href="https://www.google.com/chrome/">Google Chrome</a>
          </li>
          <li style={noBullet}>
            <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a>
          </li>
          <li style={noBullet}>
            <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { Button, Container, Collapse, Table, Row, Col, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import axios from "../http";
import Spinner from "../components/Spinner";
import "../css/reports.scss";
import { AGE_FILTERS, AGE_FILTERS_BETWEEN_6_17_KEY, AGE_FILTERS_GTE_18_KEY, AGE_FILTERS_UNDER_6_KEY, INTERPRETATION_LEVELS, PHENOTYPES, URO_GENE_LIST } from "../common/consts";
import { withRouter } from "react-router-dom";
import moment from "moment";
import SpinnerOverlay from "../components/SpinnerOverlay";

const { SearchBar } = Search;

class Reports extends Component {
  displayName = Reports.name;

  constructor(...args) {
    super(...args);

    this.state = {
      defaultSearch: "",
      serverError: false,
      serverErrorMessage: "",
      isLoading: true,
      isLoadingTable: true,
      files: [],
      displayFilters: false,
      displayIndividualGeneFilters: false,
      displayPhenotypeFilters: false,
      selectedAgeFilters: [],
      selectedInterpretationLevels: [],
      selectedPhenotypes: [],
      filterGenes: [],
      hideDownloaded: false,
      filterIsLoading: false,
    };

    this.getReports = this.getReports.bind(this);
    this.handlePhenotypeChange = this.handlePhenotypeChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.renderLoadingTable = this.renderLoadingTable.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderError = this.renderError.bind(this);
  }

  formatFileName = (cell, row) => {
    const icon = row.name.split(".")[1] === "pdf" ? "file-pdf" : "file";
    const url = `/api/downloadFile/${row.accession}/${row.name}`;

    return (
      <span>
        <FontAwesomeIcon icon={icon} />
        <button className="btn btn-link" onClick={() => this.downloadReport(url, row)}>
          Download Report
        </button>
      </span>
    );
  };

  formatPGIDReferenceCodeCell = (cell, row) => {
    const { accession, pgReferenceCode } = row;
    if (accession && pgReferenceCode) {
      return (
        <div>
          <div><strong>PG ID:</strong> {accession}</div>
          <div><strong>Reference Code:</strong> {pgReferenceCode}</div>
        </div>
      )
    }
    return cell;
  }

  formatByteSize = (cell) => {
    return this.formatBytes(cell, 2);
  };

  formatBytes(a, b) {
    if (0 === parseInt(a)) return "0 Bytes";
    var c = 1024,
      d = b || 2,
      e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
  }

  formatDate = (cell) => {
    return  cell ? moment(cell).format(
      "MM/DD/YYYY"
    ) : "";
  }

  styleColumn = (cell, row) => {
    if(!row.dateDownloaded){
      return "font-weight-bold font-weight-bold-inherit";
    }
  }

  handlePhenotypeChange(phenotype) {
    const { name, genes } = phenotype;

    if (Array.isArray(this.state.selectedPhenotypes) && this.state.selectedPhenotypes.includes(name)) {
      // If the phenotype was already selected then remove it from the selectedPhenotypes list
      if (!this.state.selectedPhenotypes.includes("All Genes") || name === "All Genes") {
        // Remove that phenotypes genes from the list if All Genes is not checked
        this.setState({
          selectedPhenotypes: this.state.selectedPhenotypes.filter((phenotype) => phenotype !== name),
          filterGenes: this.state.filterGenes.filter((gene) => !Object.keys(genes).includes(gene))
        });
      } else {
        // If All Genes is checked then just remove the selected phenotype
        this.setState({
          selectedPhenotypes: this.state.selectedPhenotypes.filter((phenotype) => phenotype !== name)
        });
      }
    } else {
      this.setState({
        selectedPhenotypes: [...new Set([...this.state.selectedPhenotypes, name])],
        filterGenes: [...new Set([...Object.keys(genes), ...this.state.filterGenes])]
      });
    }
  }

  reportColumns = [
    {
      dataField: "fileName",
      text: "File Name",
      sort: true,
      formatter: this.formatFileName,
      classes: this.styleColumn,
    },
    {
      dataField: "patientName",
      text: "Patient Name",
      sort: true,
      classes: this.styleColumn,
    },
    {
      dataField: "accession",
      text: "Identifiers",
      sort: true,
      formatter: this.formatPGIDReferenceCodeCell,
      classes: this.styleColumn,
    },
    {
      dataField: "pgReferenceCode",
      text: "",
      hidden: true
    },
    {
      dataField: "size",
      text: "Size",
      sort: true,
      formatter: this.formatByteSize,
      classes: this.styleColumn,
    },
    {
      dataField: "dateUploaded",
      text: "Date Uploaded",
      sort: true,
      formatter: this.formatDate,
      classes: this.styleColumn,
    },
  ];

  calculateAge(dob) {
    return moment().diff(dob, "years");
  }

  filterReportsByAge(reports) {
    const ageFilters = this.state.selectedAgeFilters;
    const filteredReports = reports.filter((report) => {
      const age = this.calculateAge(report.patientDateOfBirth);
      if (ageFilters.includes(AGE_FILTERS_UNDER_6_KEY) && age < 6){
        return true;
      } else if (ageFilters.includes(AGE_FILTERS_BETWEEN_6_17_KEY) && age >= 6 && age < 18){
        return true;
      } else if (ageFilters.includes(AGE_FILTERS_GTE_18_KEY) && age >= 18){
        return true
      } else {
        return false;
      }
    });
    return filteredReports;
  }

  async getReports() {
    this.setState({ isLoadingTable: true });
    try {
      const genes = this.state.filterGenes;
      const interpretationLevels = this.state.selectedInterpretationLevels;
      let url = "/api/reports";
      if (genes && genes.length > 0) {
        url += `?genes=${genes.join('&genes=')}&interpretationLevels=${interpretationLevels.join('&interpretationLevels=')}`;
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        const files = response.data;

        // Filter by age using age filters if any were applied
        if (this.state.selectedAgeFilters.length > 0) {
          const filteredByAgeReports = this.filterReportsByAge(files);
          this.setState({ files: filteredByAgeReports });
        } else {
          this.setState({ files });
        }
      } else{
        this.setState({ files:[]});
      }
    } catch (error) {
      this.setState({
        serverError: true,
        serverErrorMessage: error.toString(),
      });
    } finally {
      this.setState({ isLoading: false, isLoadingTable: false, filterIsLoading: false });
    }
  }

  async applyFilters() {
    this.setState({ filterIsLoading: true }, () => {
      this.getReports();
    });
 }

  async clearFilters() {
    this.setState({ selectedAgeFilters: [], filterGenes: [], selectedInterpretationLevels: []}, () => {
      this.getReports();
    });
  }

  async downloadReport(url, row) {
    const response = await axios.get(url, { responseType: "blob" });
    url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", response.headers.filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Set state for downloaded date
    const newFiles = [...this.state.files];
    const downloadedFile = newFiles.find(file => file.id === row.id)
    downloadedFile.dateDownloaded = new Date();
    this.setState({ files: newFiles });
  }

  componentDidMount() {
    document.title = "Reports";
    this.getReports();
  }

  renderError() {
    return (
      <Container className="mt-5 alert alert-danger" role="alert">
        <h5 className="mb-0 text-center">
          A server error has occurred (Details: {this.state.serverErrorMessage})
        </h5>
      </Container>
    );
  }

  renderLoadingTable() {
    return (
      <Table className="w-100 table-bordered">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Patient Name</th>
            <th>Identifiers</th>
            <th>Size</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="4">
              <Spinner />
            </td>
          </tr>
        </tbody>
      </Table>
    );
  }

  renderTable() {
    const expandedRowIDs = this.state.files.filter(file => file.variants.length > 0).map(file => file.id);
    const nonExpandableRowIDs = this.state.files.filter(file => file.variants.length < 1).map(file => file.id);
    const hasVariants = this.state.files.filter(file => file.variants.length > 0).length > 0;
    const expandRow = {
      renderer: row => (
        <div>
          <p className="mb-2"><strong>Reported Variants</strong></p>
          <table>
            <thead>
              <tr>
                <th>Gene</th>
                <th>DNA Variations</th>
                <th>Predicted Effects</th>
                <th>Zygosity</th>
                <th>Interpretation</th>
              </tr>
            </thead>
            <tbody>
            {
              row.variants.map((variant, i) => {
                return (
                  <tr key={row.id + i}>
                    <td>{variant.gene}</td>
                    <td>{variant.variant}</td>
                    <td>{variant.variantEffect}</td>
                    <td>{variant.zygosity}</td>
                    <td>{variant.interpretation}</td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      ),
      showExpandColumn: hasVariants,
      expanded: expandedRowIDs,
      nonExpandable: nonExpandableRowIDs,
      className: "reported-variant-row px-3 pb-4"
    } ;
    return (
      <ToolkitProvider
        keyField="id"
        data={this.state.hideDownloaded ? this.state.files.filter(file => !file.dateDownloaded) : this.state.files}
        columns={this.reportColumns}
        bootstrap4
        search={{
          defaultSearch: this.props.match.params.search || ""
        }}
      >
        {(props) => (
          <div>
            <Row>
              <Col>
                <SearchBar {...props.searchProps} />
              </Col>
            </Row>
            <section className="filters">
              <Row>
                <Col>
                  <Button variant="outline-secondary" onClick={() => this.setState({ displayFilters: !this.state.displayFilters })}>
                    <FontAwesomeIcon icon="filter" className="mr-1" />
                    Filters
                  </Button>
                </Col>
              </Row>
              {
                this.state.displayFilters &&
                <Row className="my-3">
                  <Col>
                    <div className="position-relative">
                      { this.state.filterIsLoading && <SpinnerOverlay/> }
                      <Form className="px-3 pb-2">
                        <Form.Check
                          inline
                          label={"Show un-downloaded reports only"}
                          name="hideDownloadedReports"
                          type="checkbox"
                          className="cursor-pointer"
                          value={"Yes"}
                          checked={this.state.hideDownloaded}
                          onChange={() => {
                            if (this.state.hideDownloaded){
                              this.setState({ hideDownloaded: false });
                            } else {
                              this.setState({ hideDownloaded: true });
                            }
                          }}
                          id={"hideDownloadedReports"}
                        />
                      </Form>
                      <fieldset className="px-3 mb-3">
                        <legend className="px-2">Filter by age</legend>
                        <Form className="px-3 pb-2">
                          {
                            Object.entries(AGE_FILTERS).map(([key, value]) => {
                              return (
                                <Form.Check
                                  key={key}
                                  inline
                                  label={value}
                                  name="selectedAgeFilters"
                                  type="checkbox"
                                  className="cursor-pointer"
                                  value={key}
                                  checked={this.state.selectedAgeFilters.includes(key)}
                                  onChange={() => {
                                    if (!this.state.selectedAgeFilters.includes(key)){
                                      this.setState({ selectedAgeFilters: [...this.state.selectedAgeFilters, key] });
                                    } else {
                                      this.setState({ selectedAgeFilters: this.state.selectedAgeFilters.filter(selectedAgeFilter => selectedAgeFilter !== key)});
                                    }
                                  }}
                                  id={`selectedAgeFilters${key}`}
                                />
                              )
                            })
                          }
                        </Form>
                      </fieldset>
                      <fieldset className="my-0 px-3">
                        <legend className="px-2">Filter by genetic variant interpretation</legend>
                        <Form className="px-3">
                          {
                            Object.entries(INTERPRETATION_LEVELS).map(([key, value]) => {
                              return (
                                <Form.Check
                                  key={key}
                                  inline
                                  label={value}
                                  name="selectedInterpretationLevels"
                                  type="checkbox"
                                  className="cursor-pointer"
                                  value={key}
                                  checked={this.state.selectedInterpretationLevels.includes(key)}
                                  onChange={() => {
                                    if (!this.state.selectedInterpretationLevels.includes(key)){
                                      this.setState({ selectedInterpretationLevels: [...this.state.selectedInterpretationLevels, key] });
                                    } else {
                                      this.setState({ selectedInterpretationLevels: this.state.selectedInterpretationLevels.filter(selectedInterpLevel => selectedInterpLevel !== key)});
                                    }
                                  }}
                                  id={`selectedInterpretationLevels${key}`}
                                />
                              )
                            })
                          }

                          <hr/>
                          <Row className="pb-3">
                            <Col className="phenotype-filters">
                              <h5 className="cursor-pointer" onClick={() => this.setState({ displayPhenotypeFilters: !this.state.displayPhenotypeFilters})}>
                                {this.state.displayPhenotypeFilters
                                  ? <FontAwesomeIcon icon="chevron-down" className="mr-1" />
                                  : <FontAwesomeIcon icon="chevron-right" className="mr-1" />
                                }Gene Selections
                              </h5>
                              <Collapse in={this.state.displayPhenotypeFilters}>
                                <div>
                                  {PHENOTYPES.map((phenotype, i) => {
                                    const { name, genes } = phenotype;
                                    return (
                                      <Form.Check key={phenotype.name + i} className="cursor-pointer">
                                        <Form.Check.Input
                                          type="checkbox"
                                          className="cursor-pointer"
                                          checked={Array.isArray(this.state.selectedPhenotypes) && this.state.selectedPhenotypes.includes(name)}
                                          onChange={() => this.handlePhenotypeChange(phenotype)}
                                          id={name + i}
                                        />
                                        <Form.Check.Label
                                          htmlFor={name + i}
                                        >
                                          {name}<br/>
                                          {
                                            name !== "All Genes" &&
                                            Object.entries(genes).map(([key, value], i) => {
                                              const gene = key;
                                              const geneProperties = value;
                                              const synonymString = geneProperties.synonyms && geneProperties.synonyms.length > 0 ? ` (${geneProperties.synonyms.join(', ')})` : "";
                                              let geneSynonymString = `${gene}${synonymString}`;
                                              if (i < Object.keys(genes).length - 1) {
                                                geneSynonymString += ", ";
                                              }
                                              return <em key={gene+i}>{geneSynonymString}</em>;
                                            })
                                          }
                                        </Form.Check.Label>
                                      </Form.Check>
                                    )
                                  })}
                                </div>
                              </Collapse>
                            </Col>
                            <Col lg={9} className="gene-filters">
                              <h5 className="cursor-pointer" onClick={() => this.setState({ displayIndividualGeneFilters: !this.state.displayIndividualGeneFilters})}>
                                {this.state.displayIndividualGeneFilters
                                  ? <FontAwesomeIcon icon="chevron-down" className="mr-1" />
                                  : <FontAwesomeIcon icon="chevron-right" className="mr-1" />
                                }
                                Filter by genes
                              </h5>
                              {this.state.displayIndividualGeneFilters &&
                                <div className="gene-grid">
                                  {Object.entries(URO_GENE_LIST).map(([key, value], i) => {
                                    const gene = key;
                                    const geneProperties = value;
                                    const synonymString = geneProperties.synonyms && geneProperties.synonyms.length > 0 ? `(${geneProperties.synonyms.join(', ')})` : "";
                                    const geneSynonymString = `${gene} ${synonymString}`;
                                    return (
                                      <Form.Check key={gene + i} className="cursor-pointer">
                                        <Form.Check.Input
                                          name="filterGenes"
                                          type="checkbox"
                                          className="cursor-pointer"
                                          value={gene}
                                          checked={this.state.filterGenes.includes(gene)}
                                          onChange={() => {
                                            if (!this.state.filterGenes.includes(gene)){
                                              this.setState({ filterGenes: [...this.state.filterGenes, gene] });
                                            } else {
                                              this.setState({ filterGenes: this.state.filterGenes.filter(filterGene => filterGene !== gene)});
                                            }
                                          }}
                                          id={gene + i}
                                        />
                                        <Form.Check.Label
                                          htmlFor={gene + i}
                                        >
                                          <em>{geneSynonymString}</em>
                                        </Form.Check.Label>
                                      </Form.Check>
                                    )
                                  })}
                                </div>
                              }
                            </Col>
                          </Row>
                        </Form>
                      </fieldset>
                      <Row className="my-2">
                        <Col>
                          {
                            (this.state.selectedAgeFilters.length > 0 || (this.state.filterGenes.length > 0 && this.state.selectedInterpretationLevels.length > 0))
                            ? <Button onClick={() => this.applyFilters()} disabled={this.state.isLoadingTable}>Apply Filters</Button>
                            : <Button variant="secondary" disabled>Apply Filters</Button>
                          }
                          {
                            (this.state.selectedAgeFilters.length > 0 || this.state.filterGenes.length > 0 || this.state.selectedInterpretationLevels.length > 0) &&<Button variant="secondary" size="sm" className="ml-3" onClick={() => { this.clearFilters()}} disabled={this.state.isLoadingTable}><FontAwesomeIcon icon="times" className="mr-1 text-white" />Clear Filters</Button>
                          }
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <small>
                            *Limitation: Variant filtering is limited to reports that were issued after March 21st of 2021.
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              }
            </section>
            <BootstrapTable
              classes="w-100 mt-2"
              pagination={paginationFactory()}
              {...props.baseProps}
              loading={this.state.isLoadingTable}
              noDataIndication="No reports"
              overlay={ overlayFactory({ spinner: true }) }
              expandRow={ expandRow }
              rowClasses="report-row"
            />
          </div>
        )}
      </ToolkitProvider>
    );
  }

  render() {
    if (this.state.serverError) {
      return this.renderError();
    } else {
      let table = null;
      if (this.state.isLoading) {
        table = this.renderLoadingTable();
      } else {
        table = this.renderTable();
      }


      return (
        <div>
          <Container className="mt-5 mb-5">
            <div id="reports">
              <h4 className="section-banner">Reports</h4>
              <div>
                {table}
              </div>
            </div>
          </Container>
        </div>
      );
    }
  }
}

export const ReportsWithRouter = withRouter(Reports);
import React from "react";
import { Container } from "react-bootstrap";

export default function About(props) {
  return (
    <div>
      <Container className="mt-5 text-center">
        <h3>
          Rhythm has partnered with PreventionGenetics to offer a free,
          multigene panel for several rare genetic disorders of obesity for
          eligible patients
        </h3>
        <h5 className="mt-5">
          Genetic testing is performed by PreventionGenetics, a CLIA accredited
          clinical DNA testing laboratory
        </h5>
        <p>
          If you would like to learn more see{" "}
          <a href="https://www.uncommonobesity.com/">Rhythm's website</a> for
          more information.
        </p>
      </Container>
    </div>
  );
}

import React, { useEffect } from "react";
import { Container, Form, Col, Table, Button, Alert } from "react-bootstrap";
import useStateWithLabel, {userMayNotOrder} from "../common/utilities";
import axios from "../http";
import Spinner from "../components/Spinner";
import "../css/kits.scss";
import { range } from "lodash";
import statesJson from "../common/states.json";
import territoriesJson from "../common/territories.json";
import provincesJson from "../common/provinces.json";
import countriesJson from "../common/countries.json";
import { PROVIDER_SPECIALTIES, PROVIDER_DESIGNATIONS } from "../common/consts";
import ReactGA from "react-ga4";

export default function Kits(props) {
  let states = [""];

  let countries = countriesJson.map((country) => country);

  let providerSpecialties = [...PROVIDER_SPECIALTIES];
  providerSpecialties.unshift("");

  let providerDesignations = [...PROVIDER_DESIGNATIONS];
  providerDesignations.unshift("");

  //#region State Handling
  const [loading, setLoading] = useStateWithLabel(true, "loading");
  const [submitting, setSubmitting] = useStateWithLabel(false, "submitting");
  const [successMessage, setSuccessMessage] = useStateWithLabel(
    "",
    "successMessage"
  );
  const [errorMessage, setErrorMessage] = useStateWithLabel("", "errorMessage");
  const [htmlValidated, setHtmlValidated] = useStateWithLabel(
    false,
    "htmlValidated"
  );
  const [kitError, setKitError] = useStateWithLabel(false, "kitError");
  const [numberOfBloodKits, _setNumberOfBloodKits] = useStateWithLabel(
    0,
    "numberOfBloodKits"
  );
  const [numberOfBuccalKits, _setNumberOfBuccalKits] = useStateWithLabel(
    0,
    "numberOfBuccalKits"
  );
  const [providerFirstName, setProviderFirstName] = useStateWithLabel(
    "",
    "providerFirstName"
  );
  const [providerLastName, setProviderLastName] = useStateWithLabel(
    "",
    "providerLastName"
  );
  const [providerNPI, setProviderNPI] = useStateWithLabel("", "providerNPI");
  const [providerSpecialty, setProviderSpecialty] = useStateWithLabel(
    "",
    "providerSpecialty"
  );
  const [providerDesignation, setProviderDesignation] = useStateWithLabel(
    "",
    "providerDesignation"
  );
  const [providerSpecialtyOther, setProviderSpecialtyOther] = useStateWithLabel(
    "",
    "providerSpecialtyOther"
  );
  const [
    providerDesignationOther,
    setProviderDesignationOther,
  ] = useStateWithLabel("", "providerDesignationOther");
  const [providerInstitution, setProviderInstitution] = useStateWithLabel(
    "",
    "providerInstitution"
  );
  const [providerEmail, setProviderEmail] = useStateWithLabel(
    "",
    "providerEmail"
  );
  const [providerPhone, setProviderPhone] = useStateWithLabel(
    "",
    "providerPhone"
  );
  const [streetAddress, setStreetAddress] = useStateWithLabel(
    "",
    "streetAddress"
  );
  const [streetAddress2, setStreetAddress2] = useStateWithLabel(
    "",
    "streetAddress2"
  );
  const [city, setCity] = useStateWithLabel("", "city");

  const [country, setCountry] = useStateWithLabel("", "country");
  switch (country) {
    case "US Territories":
      states = states.concat(territoriesJson.map((territory) => territory.abbreviation));
      break;

    case "Canada":
      states = states.concat(provincesJson.map((provinces) => provinces.abbreviation));
      break;

    default:
      states = states.concat(statesJson.map((state) => state.abbreviation));
      break;
  }
  
  const [state, setState] = useStateWithLabel("", "state");
  const [zipCode, setZipCode] = useStateWithLabel("", "zipCode");
  const [comments, setComments] = useStateWithLabel("", "comments");
  const [shippingMethod, setShippingMethod] = useStateWithLabel(
    "FedEx",
    "shippingMethod"
  );
  const [consent, setConsent] = useStateWithLabel("", "consent");
  const checkKitCounts = () => {
    if (parseInt(numberOfBloodKits) < 1 && parseInt(numberOfBuccalKits) < 1) {
      setKitError(true);
    } else {
      setKitError(false);
    }
  };

  const setNumberOfKits = (type, numberOfKits) => {
    switch (type) {
      case "Buccal":
        _setNumberOfBuccalKits(numberOfKits);
        break;
      default:
        _setNumberOfBloodKits(numberOfKits);
        break;
    }
  };

  //#endregion

  const loadProfile = async () => {
    try {
      const profileResponse = await axios.get(`/api/loadProfile`);

      if (profileResponse.status === 200) {
        const {
          providerFirstName,
          providerLastName,
          providerNPI,
          providerSpecialty,
          providerSpecialtyOther,
          providerDesignation,
          providerDesignationOther,
          providerEmail,
          providerPhoneNumber,
        } = profileResponse.data;

        const {
          institutionName,
          address,
          address2,
          city,
          country,
          state,
          zipCode,
        } = profileResponse.data.institutions[0];

        setProviderFirstName(providerFirstName || "");
        setProviderLastName(providerLastName || "");
        setProviderNPI(providerNPI || "");
        setProviderEmail(providerEmail || "");
        setProviderPhone(providerPhoneNumber || "");
        setProviderInstitution(institutionName || "");
        setProviderSpecialty(providerSpecialty || "");
        setProviderSpecialtyOther(providerSpecialtyOther || "");
        setProviderDesignation(providerDesignation || "");
        setProviderDesignationOther(providerDesignationOther || "");
        setStreetAddress(address || "");
        setStreetAddress2(address2 || "");
        setCity(city || "");
        setCountry(country || "US");
        setState(state || "");
        setZipCode(zipCode || "");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      loadProfile();
    } else {
      checkKitCounts();
    }
    // DO NOT REMOVE FOLLOWING COMMENT - Prevents an unnecessary warning from eslint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfBloodKits, numberOfBuccalKits]);

  const handleSubmit = async (event) => {
    const submittedForm = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();
    setSubmitting(true);
    setKitError(false);
    setSuccessMessage("");
    setErrorMessage("");

    if (!parseInt(numberOfBloodKits) > 0 && !parseInt(numberOfBuccalKits) > 0) {
      setKitError(true);
      setErrorMessage(
        "One or more fields are invalid. Please review the form below. Any ineligible fields will be marked in red."
      );
      setHtmlValidated(true);
    } else {
      // If form submission is successful
      if (submittedForm.checkValidity()) {
        const docusignEmailIsNeeded = false;
        const confirmationNumber = "";
        const kitRequest = {
          docusignEmailIsNeeded,
          patientOrProvider: "provider",
          confirmationNumber,
          numberOfBloodKits,
          numberOfBuccalKits,
          numberOfSalivaKits: 0,
          patientFirstName: "",
          patientLastName: "",
          patientPhone: "",
          patientDateOfBirth: "",
          providerFirstName,
          providerLastName,
          providerNPI,
          providerSpecialty,
          providerDesignation,
          providerSpecialtyOther,
          providerDesignationOther,
          providerInstitution,
          providerEmail,
          providerPhone,
          streetAddress,
          streetAddress2,
          city,
          country,
          state,
          zipCode,
          comments,
          shippingMethod,
        };

        try {
          const axiosConfig = {
            headers: {
              //
              // This header allows bypass of client side validation of the jwt.
              // Pair this header with the "optionalAuth" middleware on the server side to make authorization fully optional
              // Using this combination will allow access to the user object if there is one
              //
              OptionalClientSideAuth: true,
            },
          };
          const createKitOrderResponse = await axios.post(
            `/api/kit`,
            kitRequest,
            axiosConfig
          );

          if (createKitOrderResponse.status === 204) {
            // Reset form
            setNumberOfKits("Blood", 0);
            setNumberOfKits("Buccal", 0);
            setHtmlValidated(false);
            setSuccessMessage("Success! Your kit order has been placed.");
          } else {
            setErrorMessage(
              "Sorry but your kit order failed to process. Please contact us and we can place the kit order for you and fix the issue."
            );
          }
        } catch (error) {
          setErrorMessage(
            "Sorry but your kit order failed to process. Please contact us and we can place the kit order for you and fix the issue."
          );
        }
      } else {
        setErrorMessage(
          "One or more fields are invalid. Please review the form below. Any ineligible fields will be marked in red."
        );
        setHtmlValidated(true);
      }
    }
    setSubmitting(false);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container className="contact-us my-5">
      <h4 className="section-banner">Office Kit Ordering</h4>
      {loading ? (
        <Spinner />
      ) : (
        (userMayNotOrder(providerEmail)) ? <Alert variant="danger" className="h5">
              New orders temporarily unavailable.
            </Alert> :
        <>
          {successMessage ? (
            <Alert variant="success" className="h5">
              {successMessage}
            </Alert>
          ) : null}
          {errorMessage ? (
            <Alert variant="danger" className="h5">
              {errorMessage}
            </Alert>
          ) : null}
          <Form noValidate className="px-3" onSubmit={handleSubmit}>
            <Form.Row>
              <Col md={5}>
                <Form.Row className="mt-3">
                  <h5>Kit Selection</h5>
                </Form.Row>
                {htmlValidated && kitError ? (
                  <Alert variant="danger">Must select at least one kit.</Alert>
                ) : null}
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <span className="kit-type">Blood</span>
                      </td>
                      <td>
                        <Form.Control
                          as="select"
                          name="numberOfBloodKits"
                          value={numberOfBloodKits}
                          isValid={htmlValidated && !kitError}
                          isInvalid={htmlValidated && kitError}
                          onChange={(e) =>
                            setNumberOfKits("Blood", e.currentTarget.value)
                          }
                        >
                          {range(16).map((i) => {
                            return (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="kit-type">Buccal</span>
                      </td>
                      <td>
                        <Form.Control
                          as="select"
                          name="numberOfBuccalKits"
                          value={numberOfBuccalKits}
                          isValid={htmlValidated && !kitError}
                          isInvalid={htmlValidated && kitError}
                          onChange={(e) =>
                            setNumberOfKits("Buccal", e.currentTarget.value)
                          }
                        >
                          {range(16).map((i) => {
                            return (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Form.Row>
            <hr />
            <Form.Row>
              <h5>Shipping and Contact Information</h5>
            </Form.Row>
            <section className={htmlValidated ? "was-validated" : ""}>
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="providerFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="providerFirstName"
                    value={providerFirstName}
                    required
                    onChange={(e) => setProviderFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="providerLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="providerLastName"
                    value={providerLastName}
                    required
                    onChange={(e) => setProviderLastName(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="providerNPI">
                  <Form.Label>NPI Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="NPI Number"
                    name="providerNPI"
                    value={providerNPI}
                    required
                    onChange={(e) => setProviderNPI(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="providerEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    name="providerEmail"
                    value={providerEmail}
                    required
                    onChange={(e) => setProviderEmail(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="providerSpecialty">
                  <Form.Label>Specialty</Form.Label>
                  <Form.Control
                    as="select"
                    name="providerSpecialty"
                    value={providerSpecialty}
                    required
                    onChange={(e) => setProviderSpecialty(e.target.value)}
                  >
                    {providerSpecialties.map((providerSpecialty) => (
                      <option key={providerSpecialty} value={providerSpecialty}>
                        {providerSpecialty}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="providerDesignation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    as="select"
                    name="providerDesignation"
                    value={providerDesignation}
                    required
                    onChange={(e) => setProviderDesignation(e.target.value)}
                  >
                    {providerDesignations.map((providerDesignation) => (
                      <option
                        key={providerDesignation}
                        value={providerDesignation}
                      >
                        {providerDesignation}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              {providerSpecialty === "Other" ||
              providerDesignation === "Other" ? (
                <Form.Row>
                  {providerSpecialty === "Other" ? (
                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="providerSpecialtyOther"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Other Specialty"
                        name="providerSpecialtyOther"
                        value={providerSpecialtyOther}
                        required
                        onChange={(e) =>
                          setProviderSpecialtyOther(e.target.value)
                        }
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group as={Col} md={6}></Form.Group>
                  )}
                  {providerDesignation === "Other" ? (
                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="providerDesignationOther"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Other Designation"
                        name="providerDesignationOther"
                        value={providerDesignationOther}
                        required
                        onChange={(e) =>
                          setProviderDesignationOther(e.target.value)
                        }
                      />
                    </Form.Group>
                  ) : (
                    <Form.Group as={Col} md={6}></Form.Group>
                  )}
                </Form.Row>
              ) : null}

              <Form.Row>
                <Form.Group as={Col} md={6} controlId="providerPhone">
                  <Form.Label>Practice Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    name="providerPhone"
                    value={providerPhone}
                    required
                    onChange={(e) => setProviderPhone(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="providerInstitution">
                  <Form.Label>Practice Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Institution"
                    name="providerInstitution"
                    value={providerInstitution}
                    required
                    onChange={(e) => setProviderInstitution(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="streetAddress">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Street Address"
                    name="streetAddress"
                    value={streetAddress}
                    required
                    onChange={(e) => setStreetAddress(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="streetAddress2">
                  <Form.Label>Street Address 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address 2"
                    name="streetAddress2"
                    value={streetAddress2}
                    onChange={(e) => setStreetAddress2(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    name="city"
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="country">
                    <Form.Label>Country</Form.Label>
                    <br/>
                    {countries.map(
                      (listCountry) => (
                        <Form.Check
                          key={`country${listCountry.replace(" ", "+")}`}
                          id={`country${listCountry.replace(" ", "+")}`}
                          type="radio"
                          label={listCountry}
                          value={listCountry}
                          name="country"
                          checked={country === listCountry}
                          onChange={(e) => setCountry(e.target.value)}
                          required={!props.userIsPGUser}
                        />
                      )
                    )}
                  </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="state">
                  <Form.Label>{country === "Canada" ? "Province" : "State"}</Form.Label>
                  <Form.Control
                    as="select"
                    name="state"
                    value={state}
                    required
                    readOnly={!states || states < 1}
                    onChange={(e) => setState(e.target.value)}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="zipCode">
                  <Form.Label>{country === "Canada" ? "Postal Code" : "Zip Code"}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={country === "Canada" ? "Postal Code" : "Zip Code"}
                    name="zipCode"
                    value={zipCode}
                    required
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={6} controlId="shippingMethod">
                  <Form.Label>Preferred Return Shipping Method</Form.Label>
                  <Form.Control
                    as="select"
                    name="shippingMethod"
                    value={shippingMethod}
                    required
                    onChange={(e) => setShippingMethod(e.target.value)}
                  >
                    {["FedEx", "UPS"].map((i) => {
                      return (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md controlId="comments">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="If you have any comments enter them here"
                    name="comments"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    maxLength="1990"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md controlId="consent">
                  <Form.Check key={consent} inline className="mb-2">
                    <Form.Check.Input
                      id={consent}
                      value={consent}
                      required
                      onChange={(e) => setConsent(e.target.value)}
                      type="checkbox"
                      name="consent"
                    />
                    <Form.Label>
                      By submitting this form, I certify that I am a healthcare
                      professional licensed in the United States or its territories, or Canada, and I agree to
                      the Terms of Use of this website. I understand that this
                      information will be shared with Rhythm. For information
                      regarding Rhythm's privacy practices, please refer to{" "}
                      <a
                        href="https://www.rhythmtx.com/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rhythm’s Privacy Policy
                      </a>
                      .
                    </Form.Label>
                  </Form.Check>
                </Form.Group>
              </Form.Row>
            </section>
            {successMessage ? (
              <Alert variant="success" className="h5">
                {successMessage}
              </Alert>
            ) : null}
            {submitting ? (
              <Button type="submit" disabled>
                Submitting
              </Button>
            ) : (
              <Button
                type="submit"
                onClick={() => ReactGA.event({
                  category: "kits",
                  action: "click",
                  label: "order_kit_submit"
                })}
              >
                Submit
              </Button>
            )}
          </Form>
        </>
      )}
    </Container>
  );
}

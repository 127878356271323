import React from "react";
import { Navbar, Container } from "react-bootstrap";
import RhythmLogo from "../img/rhythm-logo.svg";
import "../css/footer.scss";

export default function Footer(props) {
  return (
    <div id="footer">
      <Navbar id="topFooter" className="p-3">
        <Container>
          <span>
            <strong>PreventionGenetics</strong>
            <br />
            Corporate Office & Laboratory
            <br />
            3800 South Business Park Ave
            <br />
            Marshfield, WI, 54449
          </span>
          <span>
            <img src={RhythmLogo} alt="Rhythm Logo" />
          </span>
        </Container>
      </Navbar>
      <Navbar id="subFooter">
        <Container>
          <small>
            Logos are registered trademarks of their respective owners.
          </small>
        </Container>
      </Navbar>
    </div>
  );
}

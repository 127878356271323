import React, { Component } from "react";
import { Container, Button, Form, Col, Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import axios from "../http";
import { PROVIDER_SPECIALTIES, PROVIDER_DESIGNATIONS } from "../common/consts";
import statesJson from "../common/states.json";
import territoriesJson from "../common/territories.json";
import provincesJson from "../common/provinces.json";
import countriesJson from "../common/countries.json";
import Spinner from "../components/Spinner";

export default class Profile extends Component {
  displayName = Profile.name;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      isSubmitting: false,
      htmlValidated: false,
      updateSuccessful: null,
      form: {
        providerFirstName: this.props.user.isPGUser
          ? ""
          : this.props.user.attributes.given_name,
        providerLastName: this.props.user.isPGUser
          ? ""
          : this.props.user.attributes.family_name,
        providerDegree: "",
        providerNPI: "",
        providerPhoneNumber: "",
        providerEmail: this.props.user.isPGUser
          ? ""
          : this.props.user.attributes.email,
        providerSpecialty: "",
        providerSpecialtyOther: "",
        providerDesignation: "",
        providerDesignationOther: "",
        institution: "",
        institutionStreetAddress: "",
        institutionCity: "",
        institutionCountry: "US",
        institutionState: "",
        institutionPostalCode: "",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
    this.loadProfile = this.loadProfile.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;

    let value = "";
    if (target.type === "checkbox") {
      let arrayCopy = [...this.state.form[name]];
      if (arrayCopy.includes(target.value)) {
        value = arrayCopy.filter(function (value) {
          return value !== target.value;
        });
      } else {
        arrayCopy.push(target.value);
        value = arrayCopy;
      }
    } else {
      value = target.value;
    }

    // Spread operator creates a copy of the form state
    const newForm = { ...this.state.form };
    newForm[name] = value;

    if (name === "providerSpecialty" && value !== "Other") {
      newForm["providerSpecialtyOther"] = "";
    }

    if (name === "providerDesignation" && value !== "Other") {
      newForm["providerDesignationOther"] = "";
    }

    this.setState({
      form: newForm,
    });
  }

  async handleSubmit(event) {
    const submittedForm = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    // Set the submitted form to state so that it can be used in componentDidUpdate
    // If form submission is successful
    if (submittedForm.checkValidity()) {
      this.setState(
        {
          htmlValidated: true,
          isSubmitting: true,
        },
        () => this.submitForm()
      );
    } else {
      // Invalid form submission - required fields missing
      this.setState({
        htmlValidated: true,
      });
    }
  }

  async updatePersonalDetails() {
    const updateAccountObj = {
      firstName: this.state.form["providerFirstName"],
      lastName: this.state.form["providerLastName"],
      degree: this.state.form["providerDegree"],
      phoneNumber: this.state.form["providerPhoneNumber"],
      npi: this.state.form["providerNPI"],
      specialty: this.state.form["providerSpecialty"],
      specialtyOther: this.state.form["providerSpecialtyOther"],
      designation: this.state.form["providerDesignation"],
      designationOther: this.state.form["providerDesignationOther"],
    };
    return await axios.put(`/api/updatePersonalDetails`, updateAccountObj);
  }

  async updateInstitution() {
    const updateInstitutionObj = {
      institutionName: this.state.form["institution"],
      address: this.state.form["institutionStreetAddress"],
      city: this.state.form["institutionCity"],
      country: this.state.form["institutionCountry"],
      state: this.state.form["institutionState"],
      zipCode: this.state.form["institutionPostalCode"],
    };
    return await axios.put(`/api/updateInstitution`, updateInstitutionObj);
  }

  async submitForm() {
    try {
      const [
        personalDetailsUpdateResponse,
        institutionalUpdateResponse,
      ] = await Promise.all([
        this.updatePersonalDetails(),
        this.updateInstitution(),
      ]);
      if (
        personalDetailsUpdateResponse.status === 200 &&
        institutionalUpdateResponse.status === 200
      ) {
        this.setState({ updateSuccessful: true, isSubmitting: false });
      } else {
        this.setState({ updateSuccessful: false, isSubmitting: false });
      }
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (err) {
      this.setState({
        isSubmitting: false,
        updateSuccessful: false,
      });
    }
  }

  async loadProfile() {
    try {
      const profileResponse = await axios.get(`/api/loadProfile`);
      const {
        providerFirstName,
        providerLastName,
        providerDegree,
        providerPhoneNumber,
        providerNPI,
        providerSpecialty,
        providerSpecialtyOther,
        providerDesignation,
        providerDesignationOther,
        institutions,
      } = profileResponse.data;
      const {
        institutionName,
        address,
        city,
        country,
        state,
        zipCode,
      } = institutions[0];

      if (profileResponse.status === 200) {
        let newFormObject = {
          ...this.state.form,
          providerFirstName: providerFirstName || "",
          providerLastName: providerLastName || "",
          providerDegree: providerDegree || "",
          providerPhoneNumber: providerPhoneNumber || "",
          providerNPI: providerNPI || "",
          providerSpecialty: providerSpecialty || "",
          providerSpecialtyOther: providerSpecialtyOther || "",
          providerDesignation: providerDesignation || "",
          providerDesignationOther: providerDesignationOther || "",
          institution: institutionName || "",
          institutionStreetAddress: address || "",
          institutionCity: city || "",
          institutionCountry: country || "",
          institutionState: state || "",
          institutionPostalCode: zipCode || "",
        };
        this.setState({ form: newFormObject, loading: false });
      } else {
        this.setState({ error: true, loading: false });
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: true, loading: false });
    }
  }

  renderSubmitButton() {
    if (!this.state.isSubmitting) {
      return (
        <Button
          type="button"
          className="float-right mb-3"
          onClick={this.handleSubmit}
        >
          Update Profile
        </Button>
      );
    } else {
      return (
        <Button type="button" className="float-right" disabled>
          Updating...
        </Button>
      );
    }
  }

  componentDidMount() {
    document.title = "My Profile";
    this.loadProfile();
  }

  render() {
    let form = "";
    if (this.state.loading) {
      form = <Spinner />;
    } else if (this.state.error) {
      return (
        <Container className="mt-5 mb-5">
          <h3>My Profile</h3>
          <Alert variant="danger" className="w-100 text-center">
            <Alert.Heading className="mb-0">
              An error has occurred while loading your profile. Please try again
              later or <NavLink to="./contactUs">contact us</NavLink>.
            </Alert.Heading>
          </Alert>
        </Container>
      );
    } else {
      let states = [""];
      switch (this.state.form.institutionCountry) {
        case "US Territories":
          states = states.concat(territoriesJson.map((territory) => territory.abbreviation));
          break;
    
        case "Canada":
          states = states.concat(provincesJson.map((provinces) => provinces.abbreviation));
          break;
    
        default:
          states = states.concat(statesJson.map((state) => state.abbreviation));
          break;
      }

      let countries = countriesJson.map((country) => country);

      let providerSpecialties = [...PROVIDER_SPECIALTIES];
      providerSpecialties.unshift("");

      let providerDesignations = [...PROVIDER_DESIGNATIONS];
      providerDesignations.unshift("");

      let userIsPGUser = false;
      if (this.props.user && this.props.user.isPGUser) {
        userIsPGUser = true;
      }
      let alert = "";
      if (this.state.updateSuccessful) {
        alert = (
          <Alert variant="success" className="w-100 text-center">
            <Alert.Heading className="mb-0">
              Your profile has been updated
            </Alert.Heading>
          </Alert>
        );
      } else if (this.state.updateSuccessful === false) {
        alert = (
          <Alert variant="danger" className="w-100 text-center">
            <Alert.Heading className="mb-0">
              An error has occurred while updating your profile. Please try
              again later or <NavLink to="./contactUs">contact us</NavLink>.
            </Alert.Heading>
          </Alert>
        );
      }

      form = (
        <div>
          {alert}
          <Form
            noValidate
            validated={this.state.htmlValidated}
            onSubmit={this.handleSubmit}
          >
            <section>
              <h4 className="section-banner">Personal Details</h4>
              <Form.Row>
                <Form.Group as={Col} md controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="providerFirstName"
                    value={this.state.form.providerFirstName}
                    readOnly
                    required={!userIsPGUser}
                  />
                </Form.Group>
                <Form.Group as={Col} md controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="providerLastName"
                    value={this.state.form.providerLastName}
                    readOnly
                    required={!userIsPGUser}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="degree">
                  <Form.Label>Degree</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Degree"
                    name="providerDegree"
                    value={this.state.form.providerDegree}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="3" controlId="phoneNumber">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number"
                    name="providerPhoneNumber"
                    value={this.state.form.providerPhoneNumber}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="npi">
                  <Form.Label>NPI#</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="NPI#"
                    name="providerNPI"
                    value={this.state.form.providerNPI}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="providerEmail"
                    value={this.state.form.providerEmail}
                    readOnly
                    required={!userIsPGUser}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="3" controlId="providerSpecialty">
                  <Form.Label>Specialty</Form.Label>
                  <Form.Control
                    as="select"
                    name="providerSpecialty"
                    value={this.state.form.providerSpecialty}
                    onChange={this.handleChange}
                  >
                    {providerSpecialties.map((specialty) => (
                      <option key={specialty} value={specialty}>
                        {specialty}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {this.state.form.providerSpecialty === "Other" && (
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="providerSpecialtyOther"
                  >
                    <Form.Label>Other Specialty</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Specialty"
                      name="providerSpecialtyOther"
                      value={this.state.form.providerSpecialtyOther}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                )}
                <Form.Group as={Col} md="3" controlId="providerDesignation">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    as="select"
                    name="providerDesignation"
                    value={this.state.form.providerDesignation}
                    onChange={this.handleChange}
                  >
                    {providerDesignations.map((designation) => (
                      <option key={designation} value={designation}>
                        {designation}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                {this.state.form.providerDesignation === "Other" && (
                  <Form.Group
                    as={Col}
                    md="3"
                    controlId="providerDesignationOther"
                  >
                    <Form.Label>Other Designation</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Designation"
                      name="providerDesignationOther"
                      value={this.state.form.providerDesignationOther}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                )}
              </Form.Row>
            </section>
            <section>
              <h4 className="section-banner">Institutional Information</h4>
              <Form.Row>
                <Form.Group as={Col} md controlId="institution">
                  <Form.Label>Institution</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Institution"
                    name="institution"
                    value={this.state.form.institution}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md controlId="institutionStreetAddress">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Street Address"
                    name="institutionStreetAddress"
                    value={this.state.form.institutionStreetAddress}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="institutionCity">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    name="institutionCity"
                    value={this.state.form.institutionCity}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="institutionCountry">
                    <Form.Label>Country</Form.Label>
                    {countries.map(
                      (country) => (
                        <Form.Check
                          key={`institutionCountry${country.replace(" ", "+")}`}
                          id={`institutionCountry${country.replace(" ", "+")}`}
                          type="radio"
                          label={country}
                          value={country}
                          name="institutionCountry"
                          checked={this.state.form.institutionCountry === country}
                          onChange={this.handleChange}
                          onClick={this.handleRadioButtonClick}
                          required={!userIsPGUser}
                        />
                      )
                    )}
                  </Form.Group>
                <Form.Group as={Col} md="2" controlId="institutionState">
                  <Form.Label>{this.state.form.institutionCountry === "Canada" ? "Province" : "State"}</Form.Label>
                  <Form.Control
                    as="select"
                    name="institutionState"
                    value={this.state.form.institutionState}
                    onChange={this.handleChange}
                    readOnly={!states || states.length < 1}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="institutionPostalCode">
                  <Form.Label>{this.state.form.institutionCountry === "Canada" ? "Postal Code" : "Zip Code"}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={this.state.form.institutionCountry === "Canada" ? "Postal Code" : "Zip Code"}
                    name="institutionPostalCode"
                    value={this.state.form.institutionPostalCode}
                    onChange={this.handleChange}
                  />
                </Form.Group>
              </Form.Row>
            </section>
            {this.renderSubmitButton()}
          </Form>
        </div>
      );
    }

    return (
      <Container className="mt-5 mb-5">
        <h3>My Profile</h3>
        {form}
      </Container>
    );
  }
}

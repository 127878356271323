import React, { Component } from "react";
import { Form, Col, Button, Alert, Container } from "react-bootstrap";
import { Redirect, NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import "../../css/auth.scss";
import RequestForgotPasswordCode from "../../components/auth/RequestForgotPasswordCode";
import ChangePassword from "../../components/auth/ChangePassword";

const STEP = {
  REQUEST_CODE: 0,
  CHANGE_PASSWORD: 1,
  SUCCESS: 2,
};

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isSubmitting: false,
      step: STEP.REQUEST_CODE,
      username: "",
    };
    this.requestForgotPasswordCode = this.requestForgotPasswordCode.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {
    document.title = "Forgot Password";
  }

  // Reference: https://aws-amplify.github.io/docs/js/authentication
  async requestForgotPasswordCode(username) {
    this.setState({ isSubmitting: true });
    try {
      await Auth.forgotPassword(username);

      this.setState({
        step: STEP.CHANGE_PASSWORD,
        username,
        error: null,
        isSubmitting: false,
      });
    } catch (err) {
      const error =
        "Could not reset password for the account. This could mean that there is a typo or your account has not been fully activated.";
      this.setState({ error, isSubmitting: false });
    }
  }

  async handlePasswordChange(username, code, password) {
    this.setState({ isSubmitting: true });

    try {
      await Auth.forgotPasswordSubmit(username, code, password);

      this.setState({ step: STEP.SUCCESS, error: null, isSubmitting: false });
    } catch (err) {
      const error =
        "Could not reset password for the account, please double check your code and password and try again or contact us if the problem persists.";
      this.setState({ error, isSubmitting: false });
    }
  }

  render() {
    // If logged in then redirect them to the home page
    if (this.props.user) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    let errorAlert = null;
    if (this.state.error) {
      errorAlert = (
        <Alert
          variant="danger"
          className="centered-alert"
          onClick={() => this.setState({ error: null })}
          dismissible
        >
          {this.state.error}
        </Alert>
      );
    }

    switch (this.state.step) {
      case STEP.SUCCESS: {
        return (
          <Container className="form">
            <Alert variant="success">Your password has been updated</Alert>
            <Form.Row>
              <Form.Group as={Col}>
                <NavLink to="./login">
                  <Button className="w-100">Login</Button>
                </NavLink>
              </Form.Group>
            </Form.Row>
          </Container>
        );
      }
      case STEP.CHANGE_PASSWORD: {
        return (
          <div>
            {errorAlert}
            <ChangePassword
              onSubmit={this.handlePasswordChange}
              disableSubmit={this.state.isSubmitting}
              username={this.state.username}
            />
          </div>
        );
      }
      case STEP.REQUEST_CODE: {
        return (
          <div>
            {errorAlert}
            <RequestForgotPasswordCode
              onSubmit={this.requestForgotPasswordCode}
              disableSubmit={this.state.isSubmitting}
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }
}

import "react-app-polyfill/ie9"; // This must be the first line in src/index.js
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./css/index.scss";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import BrowserNotSupported from "./BrowserNotSupported";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";
import config from "./config";

// If IE 10 or older
if (window.navigator.userAgent.match(/MSIE/)) {
  ReactDOM.render(<BrowserNotSupported />, document.getElementById("root"));
} else {
  const currentUrl = `${window.location.protocol}//${window.location.host}`;

  Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region: config.cognito.region,
      userPoolId: config.cognito.userPoolId,
      userPoolWebClientId: config.cognito.clientId,
      oauth: {
        domain: config.cognito.oauthDomain,
        scope: ["email", "profile", "openid"],
        redirectSignIn: currentUrl,
        redirectSignOut: currentUrl,
        responseType: "code",
      },
    },
  });

  ReactDOM.render(<App />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

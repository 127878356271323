import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function NotFound(props) {
  return (
    <Container className="mt-5 text-center">
      <h1>
        404 <small>Page Not Found</small>
      </h1>
      <p>
        We're sorry, the page you are looking for no longer exists. If you
        believe this is an error please{" "}
        <NavLink to="./contactUs">contact us</NavLink>
      </p>
    </Container>
  );
}

import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";

export default function EconnectSignIn({ user }) {
    let history = useHistory();
    if (user) {
        history.push("/");
    }
    useEffect(() => {
        (async () => {
            const clientConfig = (await axios.get("/api/cognito-config")).data;
            const currentUrl = `${window.location.protocol}//${window.location.host}`;
            const econnectSignInUrl = `https://${clientConfig.cognitoOAuthDomain}/oauth2/authorize?identity_provider=E-Connect-Okta&redirect_uri=${currentUrl}&response_type=CODE&client_id=${clientConfig.cognitoClientId}&scope=aws.cognito.signin.user.admin email openid profile`;
            window.location.replace(econnectSignInUrl);
        })();
    }, []);
    return (
        <Container>
            <div className="mx-auto">Redirecting to sign in...</div>
        </Container>
    );
}
import React from "react";
import { Form, Col } from "react-bootstrap";
import RhythmProgramEligibilityNewPatient from "./RhythmProgramEligibility-NewPatient";
import RhythmProgramEligibilityFamilyMember from "./RhythmProgramEligibility-FamilyMember";
import ProgramEligibilityOptions from "../../img/program-eligibility-options.png";
import { PATIENT_TYPE } from "../../common/consts";

function RhythmProgramEligibility(props) {
  return (
    <section className="page-break-avoid">
      <h4 className="text-center text-uppercase">Program Eligibility</h4>
      <img className="w-100 px-lg-5" src={ProgramEligibilityOptions} alt="URO Program Eligibility Criteria" />
      <Form.Row>
          <Form.Group as={Col} md className="mt-3 mb-0">
            {props.formState.patientType === PATIENT_TYPE.EXISTING ? (
              <h5 className="text-center">Patient Eligible - Repeat Testing</h5>
            ) : (
              <>
                <h5 className="text-center bg-purple text-white p-1 mb-3">For new patients, please select one of the following eligibility parameters below:</h5>
                <RhythmProgramEligibilityNewPatient {...props} />
                <h5 className="text-center bg-purple text-white p-1 mb-3">For family members of selected previously tested patients</h5>
                <RhythmProgramEligibilityFamilyMember {...props} />
              </>
            )}
          </Form.Group>
        </Form.Row>
    </section>
  );
}

export default RhythmProgramEligibility;

import React from "react";
import { Form, Col, Alert } from "react-bootstrap";

export default (props) => {
  const alert = props.showAlert ? (
    <Alert variant="warning" className="w-100 text-center">
      <Alert.Heading className="mb-0">Your signature is required</Alert.Heading>
    </Alert>
  ) : (
    ""
  );
  const providerSignature = props.userIsPGUser ? (
    <Form.Group as={Col} sm="8" controlId="providerPrintedName">
      <Form.Label>Healthcare Provider's Printed Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Provider's Printed Name"
        name="providerPrintedName"
        value={props.formState.providerPrintedName}
        onChange={props.handleChange}
        required={!props.userIsPGUser}
      />
    </Form.Group>
  ) : (
    <Form.Group as={Col} sm="8" controlId="providerPrintedName">
      <Form.Label>Healthcare Provider's Signature</Form.Label>
      <div className="signature"></div>
    </Form.Group>
  );
  return (
    <Form.Row>
      {alert}
      {!props.userIsPGUser ? (
        <div>
          <h4 className="text-center text-uppercase">Provider Authorization</h4>
          <p>
            I understand that it is my responsibility to ensure that the patient
            has been adequately informed and provided all necessary consents for
            collecting the specimen sample, genetic testing, and disclosing
            genetic information in accordance with applicable state laws. By
            ordering this test, I acknowledge that I am authorized under
            applicable law to order this test and that the patient has been
            supplied information regarding the purpose, capabilities, and
            limitations of the genetic test and voluntarily consented to undergo
            genetic testing.
          </p>
          <p>
            I hereby attest the patient meets the criteria for and is a
            candidate for the Uncovering Rare Obesity Program, sponsored by
            Rhythm Pharmaceuticals. I understand the diagnostic testing services
            offered under this program are directional in nature and they do not
            eliminate the need for additional medical management or replace any
            existing diagnostic methods. I further understand neither Rhythm
            Pharmaceuticals nor PreventionGenetics make any claims as to the
            usefulness of this test.
          </p>
          <p>
            I certify I am a licensed healthcare practitioner currently
            authorized under applicable laws to practice
            medicine. I have explained the purpose of the requested testing and
            potential results, and have provided appropriate genetic counseling
            to my patient.
          </p>
          <p>
            As the ordering Healthcare Provider, I hereby authorize
            PreventionGenetics to share my name, institution, address and
            contact information with Rhythm Pharmaceuticals.
          </p>
          <p>
            I understand the Uncovering Rare Obesity Program covers only the
            cost of the genetic test, but does not cover the cost of any
            ancillary services, including but not limited to office visits. I
            also understand and agree that I may not bill, charge, seek credit,
            payment or reimbursement for the genetic testing from my patient or
            another third-party payer
          </p>
        </div>
      ) : (
        <h4 className="text-center text-uppercase w-100">Provider Authorization</h4>
      )}
      {providerSignature}
      <Form.Group as={Col} sm="4" controlId="providerSignatureDate">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="date"
          name="providerSignatureDate"
          value={props.formState.providerSignatureDate}
          onChange={props.handleChange}
          required={!props.userIsPGUser}
          readOnly={!props.userIsPGUser}
        />
      </Form.Group>
    </Form.Row>
  );
};

import React from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";

export default (props) => {
  return (
    <Modal show={props.show} size="lg" onHide={props.onHide} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Print Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="p-3 border border-info rounded-sm mb-3 text-left">
          <Form.Row>
            <Form.Group
              as={Col}
              md="12"
              className="mb-0"
              controlId="printConfirmation"
            >
              <Form.Check
                inline
                type="checkbox"
                label="I acknowledge that I printed the summary page, and I will enclose the summary page with my patient's specimen."
                value={props.formState.printConfirmation}
                name="printConfirmation"
                checked={props.formState.printConfirmation === "true"}
                onChange={props.handleBooleanCheckbox}
                required
              />
            </Form.Group>
          </Form.Row>
        </section>
        <p>
          The unique bar code on the summary page is used to identify your
          patient's specimen for processing.
        </p>
        {props.formState.printConfirmation === "true" ? (
          !props.postPrintSubmitClicked ? (
            props.renderPostPrintSubmitButton()
          ) : null
        ) : (
          <p>
            <em>
              You must acknowledge you have printed the summary by clicking the
              checkbox above before finalizing your submission
            </em>
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Dropdown,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import Spinner from "../components/Spinner";
import Switch from "react-switch";
import "../css/orders.scss";
import axios from "../http";
import moment from "moment";
import { v4 } from "uuid";
import { ORDER_STATUS } from "../common/consts";

const { SearchBar, ClearSearchButton } = Search;

export default class Orders extends Component {
  displayName = Orders.name;

  constructor(props) {
    super(props);

    this.state = {
      serverError: false,
      serverErrorMessage: "",
      isLoading: true,
      isLoadingTable: false,
      toggleShowCompletedForms: !this.props.user.isPGUser,
      toggleShowCancelledOrders: false,
      toggleShowOnlyAccessioned: this.props.user.isPGUser, // If this is a PGUser default to only accessioned orders else show all
      pagination: {},
      orders: [],
      query: "",
    };

    this.getOrders = this.getOrders.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.renderProviderTable = this.renderProviderTable.bind(this);
    this.renderPGUserTable = this.renderPGUserTable.bind(this);
    this.renderError = this.renderError.bind(this);
    this.handleToggleShowCompletedForms = this.handleToggleShowCompletedForms.bind(
      this
    );
    this.handleToggleShowCancelledOrders = this.handleToggleShowCancelledOrders.bind(
      this
    );
    this.handleToggleShowAccessionedOrders = this.handleToggleShowAccessionedOrders.bind(
      this
    );
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  optionsCell = (cell, row) => {
    const id = row.id;
    const openButton = (
      <NavLink
        exact
        to={`/rhythm/${id}`}
        activeClassName="selected"
        className="text-white"
      >
        <Button>Open</Button>
      </NavLink>
    );

    if (row.pgID && !this.props.user.isPGUser) {
      return (
        <Dropdown className="split-button" drop="down">
          {openButton}

          <Dropdown.Toggle split variant="primary" />

          <Dropdown.Menu>
            <NavLink exact to={`/rhythm?familyTesting=${id}`}>
              Order Family Testing
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
      );
    } else if (this.props.user.isPGUser) {
      return (
        <Dropdown className="split-button" drop="down">
          {openButton}

          <Dropdown.Toggle split variant="primary" />

          <Dropdown.Menu>
            <NavLink exact to={`/rhythm/${id}?view=provider`}>
              View Provider Version
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return openButton;
  };

  formatIdentifiersCell = (cell, row) => {
    const { accessions } = row;
    if (accessions?.length > 0) {
      const sortedAccessions = accessions.sort((a, b) => a.pgid > b.pgid ? 1 : -1);
      const identifiers = sortedAccessions.map(accession => {
        return (
          <div className="mb-3" key={accession.pgid + accession.pgReferenceCode}>
            <div><strong>PG ID:</strong> {accession.pgid}</div>
            <div><strong>Reference Code:</strong> {accession.pgReferenceCode}</div>
          </div>
        );
      });
      return identifiers;
    }
    return cell;
  }

  formatConfirmationNumberCell = (cell, row) => {
    const { orderMethod } = row;
    if (orderMethod === "Paper") {
      return (
        <div>
          Paper Order
        </div>
      )
    }
    return cell;
  }

  formatStatusCell = (cell, row) => {
    const accessionedTests = cell;

    if (!accessionedTests) {
      const { confirmationNumber } = row;
      if (confirmationNumber) {
        return (
          <div className="mb-3">
            <ProgressBar now={15} />
            "Awaiting Specimen"
          </div>
        );
      }
      return "";
    }

    const sortedAccessionedTests = accessionedTests.sort((a, b) => a.accession > b.accession ? 1 : -1);
    const progressBars = sortedAccessionedTests.map((accessionedTest, i) => {
      let order = ORDER_STATUS[accessionedTest.testStatusID];
      if (!order) {
        order = ORDER_STATUS["default"];
      }
      const { progress, status, isReportAvailable } = order;
      if (!progress) {
        return <div className="mb-3" key={accessionedTest.testAccessionedDateTimeCST + i}>{status}</div>;
      } else if (isReportAvailable) {
        let url = `./reports/`;
        // Get the last PGID as that would be the sample that isn't cancelled
        if (accessionedTest.accession) {
          url += `${accessionedTest.accession}`;
        }
        return (
          <NavLink
            key={accessionedTest.testAccessionedDateTimeCST + i}
            exact
            to={url}
            activeClassName="selected"
            className="text-white"
          >
            <Button className="mb-3">{status}</Button>
          </NavLink>
        );
      }
      return (
        <div className="mb-3" key={accessionedTest.testAccessionedDateTimeCST + i}>
          <ProgressBar now={progress} />
          {status}
        </div>
      );
    });

    return progressBars;
  };

  dateSorter = (a, b) => {
    if (new Date(a) < new Date(b)) return 1;
    if (new Date(a) > new Date(b)) return -1;
    return 0;
  };

  async getOrders(
      pageNumber = 1,
      pageSize = 10,
    ) {
    this.setState({ isLoadingTable: true });

    try {
      const query = this.state.query;
      const toggleShowCompletedForms = this.state.toggleShowCompletedForms;
      const toggleShowCancelledOrders = this.state.toggleShowCancelledOrders;
      const toggleShowOnlyAccessioned = this.state.toggleShowOnlyAccessioned;
      const axiosConfig = {
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 404,
      };
      const url = `/api/orders?pageNumber=${pageNumber}&pageSize=${pageSize}&showSubmissionComplete=${toggleShowCompletedForms}&showCancelledOrders=${toggleShowCancelledOrders}&showOnlyAccessioned=${toggleShowOnlyAccessioned}&query=${query}`;
      const response = await axios.get(
        url,
        axiosConfig
      );
      if (response.status === 404) {
        this.setState({ orders: [] });
      } else {
        const { data } = response;
        let orders = data.orders;
        if (orders && orders.length > 0) {
          orders = orders.map((order) => {
            const { clinicalOrderID, patients, confirmationNumber, orderMethod, accessionedTests, submissionComplete, orderSubmittedDateTimeUTC, orderAccessionedDateTimeUTC } = order;

            const orderSubmittedDate = orderSubmittedDateTimeUTC ? moment(orderSubmittedDateTimeUTC).format(
              "MM/DD/YYYY"
            ) : "";
            const orderAccessionedDate = orderAccessionedDateTimeUTC ? moment(orderAccessionedDateTimeUTC).format(
              "MM/DD/YYYY"
            ) : "";

            const patient = {
              name: "",
              patientID: "",
              accessions: [],
            };
            if (patients && patients.length > 0) {
              const proband = patients.find(patient => patient.isProband) || patients[0];
              if (proband) {
                const { firstName, lastName, patientID, patientAccessions } = proband;
                if (firstName) {
                  patient.name += firstName + " ";
                }
                if (lastName) {
                  patient.name += lastName;
                }
                if (patientID) {
                  patient.patientID = proband.patientID;
                }
                if (patientAccessions && patientAccessions.length > 0) {
                  patient.accessions = patientAccessions;
                }
              }
            }

            return {
              uuid: v4(),
              id: clinicalOrderID,
              accessions: patient.accessions,
              patientName: patient.name,
              patientID: patient.patientID,
              confirmationNumber,
              orderMethod,
              orderSubmittedDate,
              orderAccessionedDate,
              accessionedTests,
              submissionComplete,
            };
          });
        }
        const { page, pageSize, totalOrders } = data;
        const pagination = { page, sizePerPage: pageSize, totalSize: totalOrders, showTotal: true };
        this.setState({ pagination, orders });
      }
    } catch (error) {
      this.setState({
        serverError: true,
        serverErrorMessage: error.toString(),
      });
    } finally {
      if (this.state.isLoading) {
        this.setState({ isLoading: false, isLoadingTable: false });
      } else {
        this.setState({ isLoadingTable: false });
      }
    }
  }

  async handleTableChange(type, { page, sizePerPage, searchText }) {
    if (type === "search"){
      this.setState({ query: searchText}, () => this.getOrders(page, sizePerPage));
    } else {
      await this.getOrders(page, sizePerPage);
    }
  }

  //setShowCompletedForms
  async handleToggleShowCompletedForms() {
    if (this.mounted) {
      const toggleShowCompletedForms = !this.state.toggleShowCompletedForms;
      this.setState({ toggleShowCompletedForms }, () => this.getOrders());
    }
  }

  //setShowCancelledOrders
  async handleToggleShowCancelledOrders() {
    if (this.mounted) {
      const toggleShowCancelledOrders = !this.state.toggleShowCancelledOrders;
      this.setState(
        { toggleShowCancelledOrders },
        async () => {
          await this.getOrders();
        }
      );
    }
  }

  async handleToggleShowAccessionedOrders() {
    if (this.mounted) {
      const toggleShowOnlyAccessioned = !this.state.toggleShowOnlyAccessioned;
      this.setState(
        { toggleShowOnlyAccessioned },
        async () => {
          await this.getOrders();
        }
      );
    }
  }

  async componentDidMount() {
    this.mounted = true;
    this.getOrders();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  renderError() {
    return (
      <Container className="mt-5 alert alert-danger" role="alert">
        <h5 className="mb-0 text-center">
          A server error has occurred (Details: {this.state.serverErrorMessage})
        </h5>
      </Container>
    );
  }

  renderLoading() {
    return <Spinner />;
  }

  renderTable(props) {
    const defaultSorted = [
      {
        dataField: "orderSubmittedDate",
        order: "desc",
      },
    ];

    return (
      <BootstrapTable
        {...props.baseProps}
        loading={this.state.isLoadingTable}
        defaultSorted={defaultSorted}
        classes="table-striped w-100"
        pagination={ paginationFactory(this.state.pagination) }
        remote={{
          search: true,
          filter: true,
          pagination: true,
          sort: false,
          cellEdit: false
        }}
        onTableChange={ this.handleTableChange }
        noDataIndication="No orders"
        overlay={ overlayFactory({ spinner: true }) }
      />
    );
  }

  renderProviderTable() {
    const columns = [
      {
        dataField: "patientName",
        text: "Patient Name",
        sort: true,
      },
      {
        dataField: "accessions",
        text: "Identifiers",
        sort: true,
        formatter: this.formatIdentifiersCell,
      },
      {
        dataField: "confirmationNumber",
        text: "Confirmation Number",
        classes: "confirmationNumber",
        sort: true,
        headerStyle: () => {
          return { width: "190px" };
        },
      },
      {
        dataField: "orderSubmittedDate",
        text: "Electronic Order Submitted",
        sortFunc: this.dateSorter,
      },
      {
        dataField: "orderAccessionedDate",
        text: "Received at Lab",
        sortFunc: this.dateSorter,
      },
      {
        dataField: "accessionedTests",
        text: "Status",
        sort: true,
        formatter: this.formatStatusCell,
        headerStyle: () => {
          return { width: "190px" };
        },
      },
      {
        dataField: "",
        text: "",
        formatter: this.optionsCell,
      },
    ];

    return (
      <ToolkitProvider
        keyField="uuid"
        data={this.state.orders}
        columns={columns}
        bootstrap4
        search={{
          searchFormatted: true
        }}
      >
        {(props) => (
          <div>
            <Row className="py-3">
              <Col md="12">
                <div className="search-bar">
                  <SearchBar delay={800} {...props.searchProps} />
                  {this.state.query && <ClearSearchButton { ...props.searchProps } />}
                </div>
              </Col>
            </Row>
            { this.renderTable(props) }
          </div>
        )}
      </ToolkitProvider>
    );
  }

  renderPGUserTable() {
    const columns = [
      {
        dataField: "patientName",
        text: "Patient Name",
        sort: true,
      },
      {
        dataField: "accessions",
        text: "Identifiers",
        sort: true,
        formatter: this.formatIdentifiersCell,
        headerStyle: () => {
          return { width: "210px" };
        },
      },
      {
        dataField: "confirmationNumber",
        text: "Confirmation Number",
        classes: "confirmationNumber",
        sort: true,
        formatter: this.formatConfirmationNumberCell,
        headerStyle: () => {
          return { width: "190px" };
        },
      },
      {
        dataField: "orderSubmittedDate",
        text: "Electronic Order Submitted",
        sortFunc: this.dateSorter,
      },
      {
        dataField: "accessionedTests",
        text: "Status",
        sort: true,
        formatter: this.formatStatusCell,
        headerStyle: () => {
          return { width: "190px" };
        },
      },
      {
        dataField: "",
        text: "",
        formatter: this.optionsCell,
        headerStyle: () => {
          return { width: "130px" };
        },
      },
    ];

    return (
      <ToolkitProvider
        keyField="uuid"
        data={this.state.orders}
        columns={columns}
        bootstrap4
        search={{
          searchFormatted: true
        }}
      >
        {(props) => (
          <>
            <Row className="py-3">
              <Col md="5">
                <div className="search-bar">
                  <SearchBar delay={800}  {...props.searchProps} />
                  {this.state.query && <ClearSearchButton { ...props.searchProps } />}
                </div>
              </Col>
              <Col md="2">
                <label>
                  <p className="mb-0">
                    <small>Show Completed Forms</small>
                  </p>
                  <Switch
                    onChange={this.handleToggleShowCompletedForms}
                    checked={this.state.toggleShowCompletedForms}
                  />
                </label>
              </Col>
              <Col md="2">
                <label>
                  <p className="mb-0">
                    <small>Show Cancelled Orders</small>
                  </p>
                  <Switch
                    onChange={this.handleToggleShowCancelledOrders}
                    checked={this.state.toggleShowCancelledOrders}
                  />
                </label>
              </Col>
              <Col md="3">
                <label>
                  <p className="mb-0">
                    <small>Only Show Accessioned Orders</small>
                  </p>
                  <Switch
                    onChange={this.handleToggleShowAccessionedOrders}
                    checked={this.state.toggleShowOnlyAccessioned}
                  />
                </label>
              </Col>
            </Row>
            { this.renderTable(props) }
          </>
        )}
      </ToolkitProvider>
    );
  }

  render() {
    if (this.state.serverError) {
      return this.renderError();
    } else {
      let content = null;
      if (this.state.isLoading) {
        content = this.renderLoading();
      } else if (this.props.user && this.props.user.isPGUser) {
        content = this.renderPGUserTable();
      } else {
        content = this.renderProviderTable();
      }
      return (
        <>
          <Container className="mt-5 mb-5">
            <div id="completedOrders">
              <h4 className="section-banner">Orders</h4>
              {content}
            </div>
          </Container>
        </>
      );
    }
  }
}

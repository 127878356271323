import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PGGenericFooter from "../../img/pg-generic-footer.webp";

export default (props) => {
  // Patient Consent Forms - Use CDN
  const isProd = props.isProd;
  const cdnAddress = `https://${!isProd ? "test-" : ""}assets.preventiongenetics.com`;
  // US - English
  const RhythmPatientConsentUsEnglishPDF = cdnAddress + "/documents/rhythm/RhythmPatientConsent_US_English_v4.pdf";
  const RhythmPatientConsentUsEnglishImage1 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_English_Page_1_v4.png";
  const RhythmPatientConsentUsEnglishImage2 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_English_Page_2_v4.png";
  const RhythmPatientConsentUsEnglishImage3 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_English_Page_3_v4.png";
  const RhythmPatientConsentUsEnglishImage4 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_English_Page_4_v4.png";
  // US - Spanish
  const RhythmPatientConsentUsSpanishPDF = cdnAddress + "/documents/rhythm/RhythmPatientConsent_US_Spanish_v4.pdf";
  const RhythmPatientConsentUsSpanishImage1 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_Spanish_Page_1_v4.png";
  const RhythmPatientConsentUsSpanishImage2 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_Spanish_Page_2_v4.png";
  const RhythmPatientConsentUsSpanishImage3 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_Spanish_Page_3_v4.png";
  const RhythmPatientConsentUsSpanishImage4 = cdnAddress + "/images/rhythm/RhythmPatientConsent_US_Spanish_Page_4_v4.png";
  // Canada - English
  const RhythmPatientConsentCanadaEnglishPDF = cdnAddress + "/documents/rhythm/RhythmPatientConsent_Canada_English_v4.1.pdf";
  const RhythmPatientConsentCanadaEnglishImage1 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_English_Page_1_v4.1.png";
  const RhythmPatientConsentCanadaEnglishImage2 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_English_Page_2_v4.1.png";
  const RhythmPatientConsentCanadaEnglishImage3 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_English_Page_3_v4.1.png";
  const RhythmPatientConsentCanadaEnglishImage4 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_English_Page_4_v4.1.png";
  // Canada - French
  const RhythmPatientConsentCanadaFrenchPDF = cdnAddress + "/documents/rhythm/RhythmPatientConsent_Canada_French_v4.1.pdf";
  const RhythmPatientConsentCanadaFrenchImage1 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_French_Page_1_v4.1.png";
  const RhythmPatientConsentCanadaFrenchImage2 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_French_Page_2_v4.1.png";
  const RhythmPatientConsentCanadaFrenchImage3 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_French_Page_3_v4.1.png";
  const RhythmPatientConsentCanadaFrenchImage4 = cdnAddress + "/images/rhythm/RhythmPatientConsent_Canada_French_Page_4_v4.1.png";

  let RhythmPatientConsentPDF = RhythmPatientConsentUsEnglishPDF;
  let RhythmPatientConsentImage1 = RhythmPatientConsentUsEnglishImage1;
  let RhythmPatientConsentImage2 = RhythmPatientConsentUsEnglishImage2;
  let RhythmPatientConsentImage3 = RhythmPatientConsentUsEnglishImage3;
  let RhythmPatientConsentImage4 = RhythmPatientConsentUsEnglishImage4;

  const { patientCountry, patientConsentLanguage } = props;
  if (patientCountry.startsWith("US")) {
    if (patientConsentLanguage === "Spanish") {
      RhythmPatientConsentPDF = RhythmPatientConsentUsSpanishPDF;
      RhythmPatientConsentImage1 = RhythmPatientConsentUsSpanishImage1;
      RhythmPatientConsentImage2 = RhythmPatientConsentUsSpanishImage2;
      RhythmPatientConsentImage3 = RhythmPatientConsentUsSpanishImage3;
      RhythmPatientConsentImage4 = RhythmPatientConsentUsSpanishImage4;
    }
  } else {
    // Canadian Consent Forms
    if (patientConsentLanguage === "French") {
      RhythmPatientConsentPDF = RhythmPatientConsentCanadaFrenchPDF;
      RhythmPatientConsentImage1 = RhythmPatientConsentCanadaFrenchImage1;
      RhythmPatientConsentImage2 = RhythmPatientConsentCanadaFrenchImage2;
      RhythmPatientConsentImage3 = RhythmPatientConsentCanadaFrenchImage3;
      RhythmPatientConsentImage4 = RhythmPatientConsentCanadaFrenchImage4;
    } else {
      RhythmPatientConsentPDF = RhythmPatientConsentCanadaEnglishPDF;
      RhythmPatientConsentImage1 = RhythmPatientConsentCanadaEnglishImage1;
      RhythmPatientConsentImage2 = RhythmPatientConsentCanadaEnglishImage2;
      RhythmPatientConsentImage3 = RhythmPatientConsentCanadaEnglishImage3;
      RhythmPatientConsentImage4 = RhythmPatientConsentCanadaEnglishImage4;
    }
  }

  const [patientAuthorizationPage1IsLoaded, setPatientAuthorizationPage1IsLoaded] = useState(false);
  const [patientAuthorizationPage2IsLoaded, setPatientAuthorizationPage2IsLoaded] = useState(false);
  const [patientAuthorizationPage3IsLoaded, setPatientAuthorizationPage3IsLoaded] = useState(false);
  const [patientAuthorizationPage4IsLoaded, setPatientAuthorizationPage4IsLoaded] = useState(false);

  useEffect(() => {
    if (
      patientAuthorizationPage1IsLoaded &&
      patientAuthorizationPage2IsLoaded &&
      patientAuthorizationPage3IsLoaded &&
      patientAuthorizationPage4IsLoaded
    ) {
      props.loaded(true);
    }
    // Equivalent to componentWillUnmount
    return function cleanUp() {
      props.loaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientAuthorizationPage1IsLoaded,
    patientAuthorizationPage2IsLoaded,
    patientAuthorizationPage3IsLoaded,
    patientAuthorizationPage4IsLoaded
  ]);

  return (
    <div className="page-break-after">
      <div>
        <p className="d-print-none">
          <a
            href={RhythmPatientConsentPDF}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon="file-pdf" className="mr-1" />
            Patient Consent and Authorization
          </a>
        </p>
      </div>
      <div className="generic-footer d-none d-print-block">
        <img
          src={PGGenericFooter}
          className="w-100"
          alt="PreventionGenetics Page Footer"
        />
      </div>
      <section className="d-none d-print-block text-center">
        <img
          className="consent-form"
          src={RhythmPatientConsentImage1}
          alt="Patient Consent"
          onLoad={() => setPatientAuthorizationPage1IsLoaded(true)}
        />
      </section>
      <section className="d-none d-print-block text-center">
        <img
          className="consent-form"
          src={RhythmPatientConsentImage2}
          alt="Patient Authorization"
          onLoad={() => setPatientAuthorizationPage2IsLoaded(true)}
        />
      </section>
      <section className="d-none d-print-block text-center">
        <img
          className="consent-form"
          src={RhythmPatientConsentImage3}
          alt="Patient Authorization"
          onLoad={() => setPatientAuthorizationPage3IsLoaded(true)}
        />
      </section>
      <section className="d-none d-print-block text-center">
        <img
          className="consent-form"
          src={RhythmPatientConsentImage4}
          alt="Patient Authorization"
          onLoad={() => setPatientAuthorizationPage4IsLoaded(true)}
        />
      </section>
    </div>
  );
};

import React from "react";
import { Form, Col } from "react-bootstrap";
import PatientBMI from "./PatientBMI";

export default (props) => {
  return (
    <Form.Row>
      <Form.Group as={Col} sm="4" controlId="patientHeight">
        <Form.Label>Height (ft. in.)</Form.Label>
        <Form.Row>
          <Form.Group as={Col} sm="6">
            <Form.Control
              type="number"
              placeholder="ft"
              name="patientHeightFeet"
              value={props.formState.patientHeightFeet}
              onChange={props.handleChange}
              required={!props.userIsPGUser}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6">
            <Form.Control
              type="number"
              placeholder="in"
              name="patientHeightInches"
              value={props.formState.patientHeightInches}
              onChange={props.handleChange}
              required={!props.userIsPGUser}
            />
          </Form.Group>
        </Form.Row>
      </Form.Group>
      <Form.Group as={Col} sm="4" controlId="patientTotalWeight">
        <Form.Label>Weight (lbs. oz.)</Form.Label>
        <Form.Row>
          <Form.Group as={Col} sm="6" controlId="patientWeight">
            <Form.Control
              type="number"
              placeholder="lbs"
              name="patientWeight"
              value={props.formState.patientWeight}
              onChange={props.handleChange}
              required={!props.userIsPGUser}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" controlId="patientWeightOunces">
            <Form.Control
              type="number"
              placeholder="oz"
              name="patientWeightOunces"
              value={props.formState.patientWeightOunces}
              onChange={props.handleChange}
            />
          </Form.Group>
        </Form.Row>
      </Form.Group>
      {props.patientAge > 2 ? <PatientBMI {...props} /> : null}
    </Form.Row>
  );
};

import React, { Component } from "react";
import { Form, Col, Button, Alert } from "react-bootstrap";
import { Redirect, NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import googleLogo from "../../img/google-logo.svg";
import queryString from "query-string";
import "../../css/auth.scss";
import ReactGA from "react-ga4";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      isSubmitting: false,
      htmlValidated: false,
      isInactiveAccount: false,
      username: queryString.parse(this.props.location.search).email || "",
      password: "",
    };

    this.signIn = this.signIn.bind(this);
  }

  componentDidMount() {
    document.title = "Login";
  }

  // Reference: https://aws-amplify.github.io/docs/js/authentication
  async signIn(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true, htmlValidated: true });
    const submittedForm = event.currentTarget;

    if (submittedForm.checkValidity()) {
      const username = this.state.username.toLowerCase();
      const password = this.state.password;
      try {
        await Auth.signIn(username, password);
      } catch (err) {
        if (err.code === "UserNotConfirmedException") {
          await Auth.resendSignUp(username);
          this.setState({ isInactiveAccount: true });
        } else {
          let error = "Invalid Login";
          this.setState({ error, isSubmitting: false });
        }
      }
    } else {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    // If logged in and there is a report to redirect to do so
    if (this.props.user && this.props.user.username && this.props.location.search) {
      const accessionForReport = queryString.parse(this.props.location.search).report;
      return (
        <Redirect
          to={{
            pathname: `/reports/${accessionForReport}`,
          }}
        />
      );
    }

    // If logged in then redirect them to the home page
    if (this.props.user) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    let errorAlert = "";
    if (this.state.error) {
      errorAlert = (
        <Alert
          variant="danger"
          onClick={() => this.setState({ error: null })}
          dismissible
        >
          {this.state.error}
        </Alert>
      );
    }
    return (
      <Form
        className="form"
        method="POST"
        noValidate
        onSubmit={this.signIn}
        validated={this.state.htmlValidated}
      >
        {errorAlert}
        {this.state.isInactiveAccount ? (
          <Alert
            variant="success"
            onClick={() => this.setState({ isInactiveAccount: false })}
            dismissible
          >
            Your account hasn't been activated yet. We have sent a link to
            activate your account to {this.state.username}.
          </Alert>
        ) : null}
        <Form.Row>
          <Form.Group as={Col}>
            <h1>Sign in to your account</h1>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Button
              id="googleSignInButton"
              onClick={() => {
                Auth.federatedSignIn({ provider: "Google" });
                ReactGA.event({
                  category: "login",
                  action: "click",
                  label: "login_google"
                });
              }}
            >
              <span className="sign-in-button-icon">
                <img src={googleLogo} alt="google logo" />
              </span>
              <span className="sign-in-button-content">
                Sign In with Google
              </span>
            </Button>
          </Form.Group>
        </Form.Row>
        <section className="section-or-line my-3">
          <span className="or">or</span>
        </section>
        <Form.Row>
          <Form.Group as={Col} controlId="username">
            <Form.Label>
              Email<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              key="username"
              name="username"
              onChange={(event) =>
                this.setState({ username: event.target.value })
              }
              value={this.state.username}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your email.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="mb-1" as={Col}>
            <Form.Label>
              Password<sup>*</sup>
            </Form.Label>
            <Form.Control
              type="password"
              key="password"
              name="password"
              className="mb-1"
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              value={this.state.password}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your password.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} className="hint">
            Forgot your password?{" "}
            <NavLink to="./forgotPassword">Reset Password</NavLink>
          </Form.Group>
        </Form.Row>
        <Form.Row className="section-footer">
          <Form.Group as={Col} md="7">
            <p>
              No account? <NavLink to="./signUp">Create account</NavLink>
            </p>
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Button
              className="sign-in-button"
              disabled={this.state.isSubmitting}
              type="submit"
              onClick={() =>
                ReactGA.event({
                  category: "login",
                  action: "click",
                  label: "login"
                })
              }
            >
              Sign In
            </Button>
          </Form.Group>
        </Form.Row>
        {/* FAKE CSRF TOKEN TO FAKE OUT TENABLE */}
        <input
          type="hidden"
          name="csrf_token"
          value={Math.random().toString(36).substring(2, 24)}
        />
      </Form>
    );
  }
}

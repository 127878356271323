const reportReady = {
  progress: 100,
  status: "Report Ready",
  isReportAvailable: true,
};
export const ORDER_STATUS = {
  null: {
    progress: 15,
    status: "Awaiting Specimen",
    isReportAvailable: false,
  },
  0: {
    progress: 30,
    status: "Specimen Received",
    isReportAvailable: false,
  },
  7: {
    progress: null,
    status: "Testing On Hold",
    isReportAvailable: false,
  },
  9: reportReady, // Completed
  11: {
    progress: null,
    status: "Cancelled",
    isReportAvailable: false,
  },
  16: reportReady, // Report Sent
  26: reportReady, // Invoiced
  30: reportReady, // Ready to Invoice
  default: {
    progress: 40,
    status: "Testing In Progress",
    isReportAvailable: false,
  },
};
// Regular Expressions
export const EMAIL_REGEX = new RegExp("^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$");
export const AT_LEAST_ONE_LOWER_CHAR_REGEX = new RegExp("(?=.*[a-z])");
export const AT_LEAST_ONE_UPPER_CHAR_REGEX = new RegExp("(?=.*[A-Z])");
export const AT_LEAST_ONE_NUMBER_REGEX = new RegExp("(?=.*[0-9])");
export const AT_LEAST_ONE_SPECIAL_CHAR_REGEX = new RegExp("[!@#$%^&.*~`?]");

export const RHYTHM_TEST_CODE = 10615;

export const CANCELLED_TEST_STATUS = 11;

export const ELIGIBILITY_CRITERIA = {
  AGE_GTE19_BMI_GTE40_CHILDHOOD_OBESITY:
    "Age of greater than or equal to 19 years of age, BMI greater than or equal to 40, and has a history of childhood obesity.",
  AGE_LTE18_BMI_GTE97:
    "Age of less than or equal to 18 years of age, BMI greater than or equal to 97th percentile.",
  BBS: "Clinical or suspected diagnosis of Bardet-Biedl Syndrome (BBS).",
  EXCEPTION_REQUESTED: "Exception Requested.",
  EXCEPTION_GRANTED: "Exception Granted.",
  INELIGIBLE: "Eligibility requirements not met.",
  FAMILY_MEMBER: "Family member to previously tested proband"
};

export const VIEW_AS_TYPE = {
  PROVIDER: "provider",
  PGUSER: "pguser",
};

export const MEASUREMENT_TYPE = {
  US_STANDARD: "US Standard",
  METRIC: "Metric",
};

export const SAMPLE_COLLECTION_LOCATIONS = {
  IN_OFFICE: "In office sample collection",
  AT_HOME: "At home sample collection",
  AT_LAB: "Repeat testing",
}

export const PATIENT_TYPE = {
  EXISTING: "Existing Patient",
  NEW: "New Patient",
  FAMILY_MEMBER: "Family Member",
}

export const PROVIDER_SPECIALTIES = [
  "Endocrinology",
  "Pediatric Endocrinology",
  "Family Practice",
  "General Practice",
  "Internal Medicine",
  "Medical Genetics",
  "Clinical Genetics",
  "Pediatrics",
  "Pediatric Gastroenterology",
  "Gastroenterology",
  "Other",
];

export const PROVIDER_DESIGNATIONS = [
  "Doctor of Osteopathy",
  "Doctor of Philosophy",
  "Medical Doctor",
  "Nurse Practitioner",
  "Physician Assistant",
  "Registered Nurse",
  "Other",
];

export const AGE_FILTERS_UNDER_6_KEY = "UNDER_6";
export const AGE_FILTERS_BETWEEN_6_17_KEY = "BETWEEN_6_17";
export const AGE_FILTERS_GTE_18_KEY = "GTE_18";

export const AGE_FILTERS = {
  UNDER_6: "Under age 6",
  BETWEEN_6_17: "6 - 17 years old",
  GTE_18: "18+ years old"
};

export const INTERPRETATION_LEVELS = {
  VUS: "VUS (Variant of Unknown Significance)",
  SUS_PATH: "VUS - Suspected Pathogenic",
  LIKELY_PATH: "Likely Pathogenic",
  PATH: "Pathogenic",
};

export const URO_GENE_LIST = {
  "ADCY3": {
    synonyms: []
  },
  "AFF4": {
    synonyms: []
  },
  "ALMS1": {
    synonyms: []
  },
  "ARL6": {
    synonyms: ["BBS3"]
  },
  "ASIP": {
    synonyms: []
  },
  "BBIP1": {
    synonyms: ["BBS18"]
  },
  "BBS1": {
    synonyms: []
  },
  "BBS10": {
    synonyms: []
  },
  "BBS12": {
    synonyms: []
  },
  "BBS2": {
    synonyms: []
  },
  "BBS4": {
    synonyms: []
  },
  "BBS5": {
    synonyms: []
  },
  "BBS7": {
    synonyms: []
  },
  "BBS9": {
    synonyms: ["PTHB1"]
  },
  "BDNF": {
    synonyms: []
  },
  "CEP164": {
    synonyms: []
  },
  "CEP290": {
    synonyms: ["BBS14"]
  },
  "CFAP418": {
    synonyms: ["BBS21"]
  },
  "CPE": {
    synonyms: []
  },
  "CREBBP": {
    synonyms: []
  },
  "CUL4B": {
    synonyms: []
  },
  "DNMT3A": {
    synonyms: []
  },
  "DYRK1B": {
    synonyms: []
  },
  "EP300": {
    synonyms: []
  },
  "GNAS": {
    synonyms: []
  },
  "HTR2C": {
    synonyms: []
  },
  "IFT172": {
    synonyms: ["BBS20"]
  },
  "IFT27": {
    synonyms: ["BBS19"]
  },
  "IFT74": {
    synonyms: ["BBS22"]
  },
  "INPP5E": {
    synonyms: []
  },
  "ISL1": {
    synonyms: []
  },
  "KIDINS220": {
    synonyms: []
  },
  "KSR2": {
    synonyms: []
  },
  "LEP": {
    synonyms: []
  },
  "LEPR": {
    synonyms: []
  },
  "LRRC45": {
    synonyms: []
  },
  "LZTFL1": {
    synonyms: ["BBS17"]
  },
  "MAGEL2": {
    synonyms: []
  },
  "MC3R": {
    synonyms: []
  },
  "MC4R": {
    synonyms: []
  },
  "MECP2": {
    synonyms: []
  },
  "MKKS": {
    synonyms: ["BBS6"]
  },
  "MKS1": {
    synonyms: ["BBS13"]
  },
  "MRAP2": {
    synonyms: []
  },
  "NCOA1": {
    synonyms: []
  },
  "NPHP1": {
    synonyms: []
  },
  "NR0B2": {
    synonyms: []
  },
  "NRP1": {
    synonyms: []
  },
  "NRP2": {
    synonyms: []
  },
  "NTRK2": {
    synonyms: []
  },
  "PCNT": {
    synonyms: []
  },
  "PCSK1": {
    synonyms: []
  },
  "PHF6": {
    synonyms: []
  },
  "PHIP": {
    synonyms: []
  },
  "PLXNA1": {
    synonyms: []
  },
  "PLXNA2": {
    synonyms: []
  },
  "PLXNA3": {
    synonyms: []
  },
  "PLXNA4": {
    synonyms: []
  },
  "POMC": {
    synonyms: []
  },
  "PPARG": {
    synonyms: []
  },
  "PROK2": {
    synonyms: []
  },
  "RAB23": {
    synonyms: []
  },
  "RAI1": {
    synonyms: []
  },
  "RPGRIP1L": {
    synonyms: []
  },
  "RPS6KA3": {
    synonyms: []
  },
  "SCAPER": {
    synonyms: []
  },
  "SCLT1": {
    synonyms: []
  },
  "SDCCAG8": {
    synonyms: ["BBS16"]
  },
  "SEMA3A": {
    synonyms: []
  },
  "SEMA3B": {
    synonyms: []
  },
  "SEMA3C": {
    synonyms: []
  },
  "SEMA3D": {
    synonyms: []
  },
  "SEMA3E": {
    synonyms: []
  },
  "SEMA3F": {
    synonyms: []
  },
  "SEMA3G": {
    synonyms: []
  },
  "SH2B1": {
    synonyms: []
  },
  "SIM1": {
    synonyms: []
  },
  "TBX3": {
    synonyms: []
  },
  "TMEM67": {
    synonyms: []
  },
  "TRIM32": {
    synonyms: ["BBS11"]
  },
  "TRPC5": {
    synonyms: []
  },
  "TTC8": {
    synonyms: ["BBS8"]
  },
  "TTC21B": {
    synonyms: []
  },
  "TUB": {
    synonyms: []
  },
  "UCP3": {
    synonyms: []
  },
  "VPS13B": {
    synonyms: []
  },
  "WDPCP": {
    synonyms: ["BBS15"]
  },
};

export const PHENOTYPES = [
  {
    name: "All Genes",
    genes: {...URO_GENE_LIST},
  },
  {
    name: "Bardet-Biedl Syndrome",
    genes: {
      "ARL6": URO_GENE_LIST["ARL6"],
      "BBIP1": URO_GENE_LIST["BBIP1"],
      "BBS10": URO_GENE_LIST["BBS10"],
      "BBS12": URO_GENE_LIST["BBS12"],
      "BBS1": URO_GENE_LIST["BBS1"],
      "BBS2": URO_GENE_LIST["BBS2"],
      "BBS4": URO_GENE_LIST["BBS4"],
      "BBS5": URO_GENE_LIST["BBS5"],
      "BBS7": URO_GENE_LIST["BBS7"],
      "BBS9": URO_GENE_LIST["BBS9"],
      "CEP164": URO_GENE_LIST["CEP164"],
      "CEP290": URO_GENE_LIST["CEP290"],
      "CFAP418": URO_GENE_LIST["CFAP418"],
      "IFT172": URO_GENE_LIST["IFT172"],
      "IFT27": URO_GENE_LIST["IFT27"],
      "IFT74": URO_GENE_LIST["IFT74"],
      "LRRC45": URO_GENE_LIST["LRRC45"],
      "LZTFL1": URO_GENE_LIST["LZTFL1"],
      "MKKS": URO_GENE_LIST["MKKS"],
      "MKS1": URO_GENE_LIST["MKS1"],
      "SCAPER": URO_GENE_LIST["SCAPER"],
      "SCLT1": URO_GENE_LIST["SCLT1"],
      "SDCCAG8": URO_GENE_LIST["SDCCAG8"],
      "TRIM32": URO_GENE_LIST["TRIM32"],
      "TTC8": URO_GENE_LIST["TTC8"],
      "TTC21B": URO_GENE_LIST["TTC21B"],
      "WDPCP": URO_GENE_LIST["WDPCP"],
    },
  },
  {
    name: "PCSK1, POMC, LEPR",
    genes: {
      "PCSK1": URO_GENE_LIST["PCSK1"],
      "POMC": URO_GENE_LIST["POMC"],
      "LEPR": URO_GENE_LIST["LEPR"],
    },
  },
];
import React from "react";
import { Alert, Form, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import statesJson from "../../common/states.json";
import territoriesJson from "../../common/territories.json";
import provincesJson from "../../common/provinces.json";
import countriesJson from "../../common/countries.json";
import { SAMPLE_COLLECTION_LOCATIONS } from "../../common/consts";

function isValidDate(dateString) {
  if (!dateString) return false;
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var d = new Date(dateString);
  var dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
}

function renderEmailTooltip(props) {
  return (
    <Tooltip {...props}>
      To contact patient if patient submitted paperwork is missing or incomplete
    </Tooltip>
  );
}

function PatientInformation(props) {
  let states = [""];
  switch (props.formState.patientCountry) {
    case "US Territories":
      states = states.concat(territoriesJson.map((territory) => territory.abbreviation));
      break;

    case "Canada":
      states = states.concat(provincesJson.map((provinces) => provinces.abbreviation));
      break;

    default:
      states = states.concat(statesJson.map((state) => state.abbreviation));
      break;
  }

  const countries = countriesJson.map((country) => country);

  const activeGeoAncestryOptions = [
    "Hispanic or Latino",
    "White",
    "Black or African American",
    "East Asian",
    "South Asian",
    "First Nation",
    "American Indian or Alaska Native",
    "Native Hawaiian or Pacific Islander",
  ];

  // unique, concatenated array of currently offered and previously selected geo ancestry options
  // needed to still display selected options that have since been deprecated
  const geoAncestry = Array.from(new Set (activeGeoAncestryOptions.concat(props.formState.geoAncestry)));

  const sampleCollectionCheckbox = (type, label) => {
    const id = type.replace(" ", "+");

    return (
      <Form.Check inline key={`sampleCollectionLocation${id}`}>
        <Form.Check.Input
          type="radio"
          id={`sampleCollectionLocation${id}`}
          name="sampleCollectionLocation"
          required
          value={type}
          checked={props.formState.sampleCollectionLocation === type}
          onChange={props.handleSampleCollectionLocationChange}
          onClick={props.handleRadioButtonClick}
          disabled={
            props.order &&
            props.order.status === "Submitted"
          }
        />
        <Form.Check.Label
          htmlFor={`sampleCollectionLocation${id}`}
          className="st-if-disabled"
        >
          <b>{label}</b>{" "}
          {type === "Repeat testing" ? "using DNA at lab" : "sample collection"}
        </Form.Check.Label>
      </Form.Check>
    );
  };

  return (
    <section>
      <h4 className="text-center text-uppercase">Patient Information</h4>
      <div className="px-3">
        <Form.Row className="d-print-none">
          {!props.userIsPGUser &&
          props.order &&
          props.order.status === "Submitted" ? (
            <Alert className="mb-0 w-100" variant="warning">
              If you need to change your sample collection location please{" "}
              <NavLink to="../contactUs">contact us</NavLink>
            </Alert>
          ) : null}
        </Form.Row>
        <Form.Row>
          <Form.Group
            as={Col}
            md
            className="mb-0"
            controlId="sampleCollectionLocation"
          >
            <p className="mt-3 mb-0">
              <Form.Label>I am ordering for:</Form.Label>
            </p>
            {sampleCollectionCheckbox(
              SAMPLE_COLLECTION_LOCATIONS.IN_OFFICE,
              "In office"
            )}
            {sampleCollectionCheckbox(SAMPLE_COLLECTION_LOCATIONS.AT_HOME, "At home")}
            {sampleCollectionCheckbox(SAMPLE_COLLECTION_LOCATIONS.AT_LAB, "Repeat testing")}
          </Form.Group>
        </Form.Row>
        {props.formState.sampleCollectionLocation ||
        (props.order && props.order.confirmationNumber) ||
        props.userIsPGUser ? (
          <>
            <hr />
            <Form.Row>
              <Form.Group as={Col} sm="4" controlId="patientLastName">
                <Form.Label>Last (Family) Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Last (Family) Name"
                  name="patientLastName"
                  value={props.formState.patientLastName}
                  onChange={props.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} sm="4" controlId="patientFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="First Name"
                  name="patientFirstName"
                  value={props.formState.patientFirstName}
                  onChange={props.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} sm="1" controlId="patientMI">
                <Form.Label>MI</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="MI"
                  name="patientMI"
                  value={props.formState.patientMI}
                  onChange={props.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="patientDateOfBirth">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="patientDateOfBirth"
                  value={props.formState.patientDateOfBirth}
                  onChange={props.handleChange}
                  required={!props.userIsPGUser}
                  min="1900-01-01"
                  max="2100-01-01"
                  placeholder="yyyy-mm-dd"
                  isInvalid={
                    props.validated &&
                    !isValidDate(props.formState.patientDateOfBirth)
                  }
                />
                {props.formState.patientDateOfBirth &&
                !isValidDate(props.formState.patientDateOfBirth) ? (
                  <>
                    <Alert variant="danger">
                      Must be a valid date (yyyy-mm-dd)
                    </Alert>
                    <Form.Control type="text" className="hide" required />
                  </>
                ) : null}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm="9">
                <Form.Row>
                  <Form.Group as={Col} sm="8" controlId="patientStreetAddress">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Street Address"
                      name="patientStreetAddress"
                      value={props.formState.patientStreetAddress}
                      onChange={props.handleChange}
                      required={!props.userIsPGUser || props.formState.sampleCollectionLocation ===
                        SAMPLE_COLLECTION_LOCATIONS.AT_HOME}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm="4" controlId="patientCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      name="patientCity"
                      value={props.formState.patientCity}
                      onChange={props.handleChange}
                      required={!props.userIsPGUser || props.formState.sampleCollectionLocation ===
                        SAMPLE_COLLECTION_LOCATIONS.AT_HOME}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm="3" controlId="patientCountry">
                    <Form.Label>Country</Form.Label>
                    {countries.map(
                      (country) => (
                        <Form.Check
                          key={`patientCountry${country.replace(" ", "+")}`}
                          id={`patientCountry${country.replace(" ", "+")}`}
                          type="radio"
                          label={country}
                          value={country}
                          name="patientCountry"
                          checked={
                              props.formState.patientCountry === country 
                          }
                          onChange={props.handleChange}
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser || props.formState.sampleCollectionLocation ===
                            SAMPLE_COLLECTION_LOCATIONS.AT_HOME}
                        />
                      )
                    )}
                  </Form.Group>
                  <Form.Group as={Col} sm="2" controlId="patientState">
                    <Form.Label>{props.formState.patientCountry === "Canada" ? "Province" : "State"}</Form.Label>
                    <Form.Control
                      as="select"
                      name="patientState"
                      value={props.formState.patientState}
                      onChange={props.handleChange}
                      required={!props.userIsPGUser || props.formState.sampleCollectionLocation ===
                        SAMPLE_COLLECTION_LOCATIONS.AT_HOME}
                      readOnly={!states || states.length < 1}
                    >
                      {
                      states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} sm="3" controlId="patientZip">
                    <Form.Label>{props.formState.patientCountry === "Canada" ? "Postal" : "Zip"} Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={(props.formState.patientCountry === "Canada" ? "Postal" : "Zip") + "Code"}
                      name="patientZip"
                      value={props.formState.patientZip}
                      required={!props.userIsPGUser || props.formState.sampleCollectionLocation ===
                        SAMPLE_COLLECTION_LOCATIONS.AT_HOME}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm="4" controlId="patientID">
                    <Form.Label>Patient ID Code (i.e. EMR #)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Patient ID Code"
                      name="patientID"
                      value={props.formState.patientID}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} sm="7" controlId="patientEmail">
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderEmailTooltip}
                      trigger={["focus", "hover"]}
                    >
                      <Form.Label>
                        Email
                        <FontAwesomeIcon
                          icon="question-circle"
                          className="text-aqua ml-2"
                        />
                      </Form.Label>
                    </OverlayTrigger>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="patientEmail"
                      value={props.formState.patientEmail}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} sm="5" controlId="patientPhoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone Number"
                      name="patientPhoneNumber"
                      value={props.formState.patientPhoneNumber}
                      onChange={props.handleChange}
                      required={props.formState.patientCountry === "Canada" && props.formState.sampleCollectionLocation ===
                      SAMPLE_COLLECTION_LOCATIONS.AT_HOME}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm="3" controlId="patientSex">
                    <Form.Label>Biological Sex</Form.Label>
                    <br />
                    {["Male", "Female", "Other"].map((type) => (
                      <Form.Check
                        key={`patientSex${type}`}
                        id={`patientSex${type}`}
                        type="radio"
                        label={type}
                        value={type}
                        required={!props.userIsPGUser}
                        name="patientSex"
                        checked={props.formState.patientSex === type}
                        onChange={props.handleChange}
                        onClick={props.handleRadioButtonClick}
                      />
                    ))}
                  </Form.Group>
                  <Form.Group as={Col} sm="3" controlId="specimenSource">
                    <Form.Label>Specimen Source</Form.Label>
                    {["Whole Blood", "Buccal OCD-100", "DNA at Lab"].map(
                      (type) => (
                        <Form.Check
                          key={`specimenSource${type.replace(" ", "+")}`}
                          id={`specimenSource${type.replace(" ", "+")}`}
                          type="radio"
                          className="st-if-disabled"
                          label={type}
                          value={type}
                          name="specimenSource"
                          checked={
                            props.formState.specimenSource === type
                            || (props.formState.specimenSource === "OCD-100" && type === "Buccal OCD-100")
                          }
                          onChange={(event) =>
                            props.handleChange(
                              event,
                              props.handleSpecimenSourceChange
                            )
                          }
                          onClick={props.handleRadioButtonClick}
                          required={!props.userIsPGUser}
                          disabled={
                            (props.formState.sampleCollectionLocation ===
                              SAMPLE_COLLECTION_LOCATIONS.AT_HOME &&
                              type !== "Buccal OCD-100") ||
                            (props.formState.sampleCollectionLocation ===
                              "In office sample collection" &&
                              type === "DNA at Lab") ||
                            (props.formState.sampleCollectionLocation ===
                              "Repeat testing" &&
                              type !== "DNA at Lab")
                          }
                        />
                      )
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    sm="6"
                    controlId="specimenCollectionDate"
                  >
                    <Form.Label>
                      Specimen Collection Date
                      <br />
                      <small>
                        <em>
                          If no collection date is provided, date of receipt
                          will be used.
                        </em>
                      </small>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="specimenCollectionDate"
                      value={props.formState.specimenCollectionDate}
                      onChange={props.handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm="5">
                    <Form.Label>
                      Blood Transfusion Within Last 30 Days
                    </Form.Label>
                    <br />
                    <Form.Check
                      id="bloodTransfusionNo"
                      inline
                      type="radio"
                      label="No"
                      value="No"
                      name="bloodTransfusion"
                      checked={props.formState.bloodTransfusion === "No"}
                      onChange={props.handleChange}
                      onClick={props.handleRadioButtonClick}
                    />
                    <Form.Check
                      id="bloodTransfusionYes"
                      inline
                      type="radio"
                      value="Yes"
                      label="Yes"
                      name="bloodTransfusion"
                      checked={props.formState.bloodTransfusion === "Yes"}
                      onChange={props.handleChange}
                      onClick={props.handleRadioButtonClick}
                    />
                    {props.formState.bloodTransfusion === "Yes" ? (
                      <div>
                        <Form.Control
                          id="bloodTransfusionDate"
                          type="date"
                          name="bloodTransfusionDate"
                          value={props.formState.bloodTransfusionDate}
                          onChange={props.handleChange}
                        />
                        <Form.Control
                          id="bloodTransfusionType"
                          className="mt-2"
                          type="text"
                          placeholder="Type"
                          name="bloodTransfusionType"
                          value={props.formState.bloodTransfusionType}
                          onChange={props.handleChange}
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} sm controlId="boneMarrowTransplant">
                    <Form.Label>Bone Marrow Transplant</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        id="boneMarrowTransplantNo"
                        type="radio"
                        label="No"
                        value="No"
                        name="boneMarrowTransplant"
                        checked={props.formState.boneMarrowTransplant === "No"}
                        onChange={props.handleChange}
                        onClick={props.handleRadioButtonClick}
                      />
                      <Form.Check
                        inline
                        id="boneMarrowTransplantYes"
                        type="radio"
                        value="Yes"
                        label="Yes"
                        name="boneMarrowTransplant"
                        checked={props.formState.boneMarrowTransplant === "Yes"}
                        onChange={props.handleChange}
                        onClick={props.handleRadioButtonClick}
                      />
                    </div>
                    {props.formState.boneMarrowTransplant === "Yes" ? (
                      <div>
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="boneMarrowTransplantDate"
                          value={props.formState.boneMarrowTransplantDate}
                          onChange={props.handleChange}
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </Form.Group>
                  <Form.Group as={Col} controlId="patientConsentLanguage">
                    <Form.Label>
                      Patient Consent Language
                    </Form.Label>
                    <br />
                    <Form.Check
                      id="patientConsentLanguageEnglish"
                      inline
                      required
                      type="radio"
                      label="English"
                      value="English"
                      name="patientConsentLanguage"
                      checked={props.formState.patientConsentLanguage === "English"}
                      onChange={props.handleChange}
                      onClick={props.handleRadioButtonClick}
                    />
                    {
                      props.formState.patientCountry && 
                      props.formState.patientCountry.startsWith("US") &&
                      <Form.Check
                        id="patientConsentLanguageSpanish"
                        inline
                        required
                        type="radio"
                        value="Spanish"
                        label="Spanish"
                        name="patientConsentLanguage"
                        checked={props.formState.patientConsentLanguage === "Spanish"}
                        onChange={props.handleChange}
                        onClick={props.handleRadioButtonClick}
                      />
                    }
                    {
                      props.formState.patientCountry &&
                      props.formState.patientCountry === "Canada" &&
                      <Form.Check
                        id="patientConsentLanguageFrench"
                        inline
                        required
                        type="radio"
                        value="French"
                        label="French"
                        name="patientConsentLanguage"
                        checked={props.formState.patientConsentLanguage === "French"}
                        onChange={props.handleChange}
                        onClick={props.handleRadioButtonClick}
                      />
                    }
                  </Form.Group>
                </Form.Row>
              </Form.Group>
              <Form.Group as={Col} sm="3" controlId="geoAncestry">
                <p className="mb-1">
                  <Form.Label>Geoancestry / Ethnicity</Form.Label>
                </p>
                {geoAncestry.map((geo) => (
                  <Form.Check
                    id={geo}
                    key={geo}
                    label={geo}
                    value={geo}
                    checked={props.formState.geoAncestry.includes(geo)}
                    type="checkbox"
                    name="geoAncestry"
                    className="mb-2"
                    onChange={props.handleChange}
                    onClick={props.handleCheckboxRequirement}
                    required={
                      !props.userIsPGUser &&
                      props.formState.geoAncestry.length === 0 &&
                      props.formState.otherGeo.length < 1
                    }
                  />
                ))}
                <Form.Control
                  type="text"
                  placeholder="Other"
                  className="mt-3"
                  name="otherGeo"
                  value={props.formState.otherGeo}
                  onChange={props.handleChange}
                />
              </Form.Group>
            </Form.Row>
          </>
        ) : null}
      </div>
      {
        // If a value has been selected for date of birth and that value is less than 18 years old (patient age is a year integer)
        (props.formState.patientDateOfBirth &&
        props.patientAge < 18) ?
        (<section>
          <h4 className="text-center text-uppercase">Parent / Guardian Information</h4>
          <Form.Row>
            <Form.Group as={Col} sm="4" controlId="parentOrGuardianLastName">
              <Form.Label>Last (Family) Name</Form.Label>
              <Form.Control
                type="text"
                required={!props.userIsPGUser}
                placeholder="Last (Family) Name"
                name="parentOrGuardianLastName"
                value={props.formState.parentOrGuardianLastName}
                onChange={props.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} sm="4" controlId="parentOrGuardianFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                required={!props.userIsPGUser}
                placeholder="First Name"
                name="parentOrGuardianFirstName"
                value={props.formState.parentOrGuardianFirstName}
                onChange={props.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} sm="4" controlId="parentOrGuardianRelationshipToProband">
              <Form.Label>Relationship to Patient</Form.Label>
              <Form.Control
                type="text"
                required={!props.userIsPGUser}
                placeholder="Relationship"
                name="parentOrGuardianRelationshipToProband"
                value={props.formState.parentOrGuardianRelationshipToProband}
                onChange={props.handleChange}
              />
            </Form.Group>
          </Form.Row>
        </section>
        ) : null
      }
      {props.formState.specimenSource &&
      props.formState.sampleCollectionLocation ===
        SAMPLE_COLLECTION_LOCATIONS.AT_HOME ? (
        <section className="p-3 border border-info rounded-sm mb-3">
          <Form.Row>
            <Form.Group
              as={Col}
              sm="12"
              className="mb-0 checkbox-large"
              controlId="sendKitConfirmation"
            >
              <Form.Check
                inline
                type="checkbox"
                label={`I understand that a ${props.formState.specimenSource} kit will be sent to the patient's home address. I confirm that the patient address above is correct.`}
                value={props.formState.sendKitConfirmation}
                name="sendKitConfirmation"
                checked={props.formState.sendKitConfirmation === "true"}
                onChange={props.handleBooleanCheckbox}
                required
              />
            </Form.Group>
          </Form.Row>
        </section>
      ) : null}
    </section>
  );
}

export default PatientInformation;

import React, { Component } from "react";
import { Form, Col, Button, Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../../css/auth.scss";
import { Auth } from "aws-amplify";
import PasswordInput from "../../components/auth/PasswordInput";
import axios from "axios";

export default class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      htmlValidated: false,
      error: null,
      successfullyRegistered: null,
      codeResent: null,
      passwordIsValid: false,
      form: {
        email: "",
        password: "",
        firstName: "",
        lastName: "",
      },
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUsernameExists = this.handleUsernameExists.bind(this);
    this.resendCode = this.resendCode.bind(this);
  }

  componentDidMount() {
    document.title = "Sign Up";
  }

  handlePasswordChange(passwordObject) {
    const { password, isValid } = passwordObject;
    this.setState({
      form: { ...this.state.form, password },
      passwordIsValid: isValid,
    });
  }

  async handleUsernameExists(username) {
    try {
      // Attempt to send an activation link
      await Auth.resendSignUp(username);
      this.setState({
        successfullyRegistered: true,
        isSubmitting: false,
      });
    } catch (signUpErr) {
      // Account has already been activated
      this.setState({
        error: `An account already has been registered for this email. Please try signing in.`,
        htmlValidated: true,
        isSubmitting: false,
      });
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    const submittedForm = event.currentTarget;
    const { email, password, firstName, lastName } = this.state.form;

    try {
      if (submittedForm.checkValidity()) {
        if (this.state.passwordIsValid) {
          // Create a portal account to keep them in sync and to allow for institutional saving and file retrieval
          await axios.post("/api/portal/signUp", this.state.form);

          const signUpResponse = await Auth.signUp({
            username: email.toLowerCase(),
            password,
            attributes: {
              email: email.toLowerCase(),
              given_name: firstName,
              family_name: lastName,
            },
          });

          if (signUpResponse.user) {
            this.setState({
              successfullyRegistered: true,
              isSubmitting: false,
            });
          }
        }
      }
    } catch (err) {
      if (err.code === "UsernameExistsException") {
        await this.handleUsernameExists(email.toLowerCase());
      } else {
        this.setState({
          error: `An error occurred while attempting to create your account. Please contact us.`,
          htmlValidated: true,
          isSubmitting: false,
        });
      }
    }

    this.setState({
      htmlValidated: true,
      isSubmitting: false,
    });
  }

  async resendCode() {
    try {
      await Auth.resendSignUp(this.state.form.email);
      this.setState({ codeResent: true });
    } catch (err) {
      this.setState({ codeResent: false });
    }
  }

  renderSuccessfulRegistration() {
    let codeResentMessage = null;
    if (this.state.codeResent === true) {
      codeResentMessage = <Alert variant="success">Link sent</Alert>;
    } else if (this.state.codeResent === false) {
      codeResentMessage = (
        <Alert variant="warning">Code failed to send. Please contact us.</Alert>
      );
    }

    return (
      <section className="form">
        <Form.Row>
          <p>
            We have sent an email to {this.state.form.email}. Please check your
            email and follow the instructions to verify your email address.
          </p>
        </Form.Row>
        <Form.Row>
          <p>
            Didn't receive a link?{" "}
            <Button variant="link" onClick={this.resendCode}>
              Resend it
            </Button>
          </p>
        </Form.Row>
        {codeResentMessage}
      </section>
    );
  }

  renderRegistrationForm() {
    let errorAlert = null;
    if (this.state.error) {
      errorAlert = (
        <Alert
          variant="danger"
          onClick={() => this.setState({ error: null })}
          dismissible
        >
          {this.state.error}
        </Alert>
      );
    }

    return (
      <Form
        method="POST"
        className="form"
        noValidate
        onSubmit={this.handleSubmit}
      >
        {errorAlert}
        <Form.Row>
          <Form.Group as={Col}>
            <h1>Create a new account</h1>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label>
              Email <sup>*</sup>
            </Form.Label>
            <Form.Control
              type="email"
              key="email"
              name="email"
              onChange={(event) => {
                this.setState({
                  form: { ...this.state.form, email: event.target.value },
                });
              }}
              value={this.state.form.email}
              required
              isValid={this.state.htmlValidated && this.state.form.email}
              isInvalid={this.state.htmlValidated && !this.state.form.email}
            />
            <Form.Control.Feedback type="invalid">
              Please enter an email.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="firstName">
            <Form.Label>
              First Name <sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              key="firstName"
              name="firstName"
              onChange={(event) => {
                this.setState({
                  form: { ...this.state.form, firstName: event.target.value },
                });
              }}
              value={this.state.form.firstName}
              required
              isValid={this.state.htmlValidated && this.state.form.firstName}
              isInvalid={this.state.htmlValidated && !this.state.form.firstName}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a first name.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="lastName">
            <Form.Label>
              Last Name <sup>*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              key="lastName"
              name="lastName"
              onChange={(event) => {
                this.setState({
                  form: { ...this.state.form, lastName: event.target.value },
                });
              }}
              value={this.state.form.lastName}
              required
              isValid={this.state.htmlValidated && this.state.form.lastName}
              isInvalid={this.state.htmlValidated && !this.state.form.lastName}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a last name.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <PasswordInput
          onChange={this.handlePasswordChange}
          showPasswordConfirmation={false}
          validated={this.state.htmlValidated}
        />
        <Form.Row className="section-footer">
          <Form.Group as={Col} md="7">
            <p>
              Have an account? <NavLink to="./login">Sign In</NavLink>
            </p>
          </Form.Group>
          <Form.Group as={Col} md="5">
            <Button type="submit" disabled={this.state.isSubmitting}>
              Create account
            </Button>
          </Form.Group>
        </Form.Row>
        {/* FAKE CSRF TOKEN TO FAKE OUT TENABLE */}
        <input
          type="hidden"
          name="csrf_token"
          value={Math.random().toString(36).substring(2, 24)}
        />
      </Form>
    );
  }

  render() {
    if (this.state.successfullyRegistered) {
      return this.renderSuccessfulRegistration();
    } else {
      return this.renderRegistrationForm();
    }
  }
}

import axios from "axios";
import { Auth } from "aws-amplify";

const myAxios = axios.create();

myAxios.interceptors.request.use(
  async (config) => {
    try {
      // Will attach the logged in user if one exists
      let session = await Auth.currentSession();
      if (session) {
        config.headers.Authorization = `Bearer ${session.idToken.jwtToken}`;
      }
      return config;
    } catch (error) {
      if (config.headers.OptionalClientSideAuth) {
        // Proceeds even if no current user is found
        return config;
      } else {
        return Promise.reject(error);
      }
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default myAxios;

import React from "react";
import { Form, Col } from "react-bootstrap";
import PatientDimensionsMetric from "./PatientDimensionsMetric";
import PatientDimensionsUSStandard from "./PatientDimensionsUSStandard";
import { MEASUREMENT_TYPE, ELIGIBILITY_CRITERIA } from "../../common/consts";

function PatientDimensions(props) {
  const ageOfOnsetChildhoodObesityOptions = props.formState.programEligibilityCriteria !== ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97 ? ["0-2 years", "3-5 years", "6-10 years","11-17 years","Adult","Unknown"] : ["0-2 years", "3-5 years", "6-10 years","11-17 years","Unknown"];
  return (
    <section>
      <div className="p-3">
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Row>
              <Form.Group
                as={Col}
                md={6}
                controlId="measurementSystemType"
                className="border border-secondary rounded text-center"
              >
                {Object.values(MEASUREMENT_TYPE).map((type) => (
                  <Form.Check
                    inline
                    key={`measurementSystemType${type.replace(" ", "+")}`}
                    id={`measurementSystemType${type.replace(" ", "+")}`}
                    type="radio"
                    label={type}
                    value={type}
                    name="measurementSystemType"
                    checked={props.formState.measurementSystemType === type}
                    onChange={(event) =>
                      props.handleChange(event, props.convertMeasurements)
                    }
                    required={!props.userIsPGUser}
                  />
                ))}
              </Form.Group>
            </Form.Row>
            {props.formState.measurementSystemType ===
            MEASUREMENT_TYPE.METRIC ? (
              <PatientDimensionsMetric {...props} />
            ) : (
              <PatientDimensionsUSStandard {...props} />
            )}
            <Form.Row>
              {
                props.formState.programEligibilityCriteria !== ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97 &&
                <Form.Group
                  as={Col}
                  controlId="childhoodObesity"
                  className="pr-0"
                >
                  <p className="mb-0">
                    <Form.Label>Childhood Obesity{props.formState.programEligibilityCriteria === ELIGIBILITY_CRITERIA.FAMILY_MEMBER ? " in Family Member" : ""}</Form.Label>
                  </p>
                  {["No", "Yes", "Unknown"].map((type) => (
                    <Form.Check
                      inline
                      key={`childhoodObesity${type}`}
                      id={`childhoodObesity${type}`}
                      type="radio"
                      label={type}
                      value={type}
                      name="childhoodObesity"
                      checked={props.formState.childhoodObesity === type}
                      onChange={props.handleChange}
                      onClick={props.handleRadioButtonClick}
                      required={!props.userIsPGUser}
                    />
                  ))}
                </Form.Group>
              }
              {props.formState.childhoodObesity === "Yes" ? (
                <>
                  <Form.Group
                    as={Col}
                    controlId="ageOfOnsetChildhoodObesity"
                  >
                    <Form.Label className="mb-0">Age of obesity onset in years</Form.Label>
                    {ageOfOnsetChildhoodObesityOptions.map((type) => (
                        <Form.Check
                            key={`ageOfOnsetChildhoodObesity${type}`}
                            id={`ageOfOnsetChildhoodObesity${type}`}
                            type="radio"
                            label={type}
                            value={type}
                            name="ageOfOnsetChildhoodObesity"
                            checked={props.formState.ageOfOnsetChildhoodObesity === type}
                            onChange={props.handleChange}
                            onClick={props.handleRadioButtonClick}
                            required={!props.userIsPGUser}
                        />
                    ))}
                  </Form.Group>
                </>
              ) : (
                <div />
              )}
            </Form.Row>
          </Form.Group>
        </Form.Row>
      </div>
    </section>
  );
}

export default PatientDimensions;
import React from "react";
import PatientInformation from "./PatientInformation";
import RhythmProgramEligibility from "./RhythmProgramEligibility";
import ClinicalInformation from "./ClinicalInformation";
import ProviderInformation from "./ProviderInformation";

function ProviderForm(props) {
  const providerInformationEnabled =
    !props.userIsPGUser ||
    props.formState.isProvider !== "" ||
    props.viewAs === "provider";

  return (
    <div>
      <PatientInformation {...props} />
      <RhythmProgramEligibility {...props} />
      <ClinicalInformation {...props} />
      {providerInformationEnabled ? (
        <ProviderInformation updateProfileEnabled={false} {...props} />
      ) : null}
    </div>
  );
}

export default ProviderForm;

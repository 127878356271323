// Import Libraries
import React, { Component } from "react";
import {
  Container,
  Form,
  Button,
  Col,
  Row,
  Alert,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import axios from "../http";
import Barcode from "react-barcode";
import { ProgressBar, Step } from "react-step-progress-bar";
import moment from "moment";
import queryString from "query-string";
// Import Components
import Spinner from "../components/Spinner";
import SimpleModal from "../components/SimpleModal";
import PatientInformation from "../components/form/PatientInformation";
import RhythmProgramEligibility from "../components/form/RhythmProgramEligibility";
import RhythmProviderSignature from "../components/form/RhythmProviderSignature";
import RhythmPatientConsent from "../components/form/RhythmPatientConsent";
import ClinicalInformation from "../components/form/ClinicalInformation";
import ProviderInformation from "../components/form/ProviderInformation";
import FormWrapper from "../components/form/FormWrapper";
import ProviderForm from "../components/form/ProviderForm";
import PGUserForm from "../components/form/PGUserForm";
import NextSteps from "../components/form/NextSteps";
import PrintConfirmationModal from "../components/form/PrintConfirmationModal";
import { userMayNotOrder } from "../common/utilities";
// Import Styles
import "react-step-progress-bar/styles.css";
import "../css/rhythm.scss";
// Import Images
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RhythmLogo from "../img/rhythm-logo.svg";
import PGStackedLogo from "../img/PG_pos_rgb_endorsed_stack.svg";
// Import Constants
import {
  MEASUREMENT_TYPE,
  ORDER_STATUS,
  PATIENT_TYPE,
  SAMPLE_COLLECTION_LOCATIONS,
  VIEW_AS_TYPE,
  ELIGIBILITY_CRITERIA
} from "../common/consts";
// Import BMI Data
import BMIPercentile from "../common/BMIPercentileAges2-20";

export class Rhythm extends Component {
  displayName = Rhythm.name;

  sections = [
    { name: "Patient Information", component: PatientInformation },
    { name: "Program Eligibility", component: RhythmProgramEligibility },
    { name: "Clinical Information", component: ClinicalInformation },
    { name: "Provider Information", component: ProviderInformation },
    { name: "Review", component: ProviderForm },
  ];

  initialState = {
    viewAs: this.props.user.isPGUser
      ? VIEW_AS_TYPE.PGUSER
      : VIEW_AS_TYPE.PROVIDER,
    isProd: true,
    order: null,
    serverError: false,
    serverErrorMessage: "",
    loading: true,
    htmlValidated: false,
    activeSection: 0,
    isAccessioned: false,
    isSubmitting: false,
    isSaving: false,
    isBeingReviewed: false,
    patientConsentIsLoaded: false,
    showPrintConfirmationModal: false,
    printDialogShown: false,
    postPrintSubmitClicked: false,
    reprintClicked: false,
    updateProfileEnabled: true,
    submittedForm: null,
    responseData: null,
    simpleModal: null,
    kitOrderSubmitted: false,
    kitOrderFailedtoSend: false,
    previouslyAccessionedPatients: [],
    form: {
      personCompletingForm: "",
      contactPhone: "",
      contactEmail: "",
      dateRequested: "",
      sampleCollectionLocation: "",
      patientLastName: "",
      patientFirstName: "",
      patientMI: "",
      patientDateOfBirth: "",
      patientEmail: "",
      patientPhoneNumber: "",
      patientStreetAddress: "",
      patientCity: "",
      patientCountry: "US",
      patientState: "",
      patientZip: "",
      patientSex: "",
      patientID: "",
      parentOrGuardianLastName: "",
      parentOrGuardianFirstName: "",
      parentOrGuardianRelationshipToProband: "",
      specimenCollectionDate: "",
      specimenSource: "",
      bloodTransfusion: "",
      bloodTransfusionDate: "",
      bloodTransfusionType: "",
      boneMarrowTransplant: "",
      boneMarrowTransplantDate: "",
      geoAncestry: [],
      otherGeo: "",
      sendKitConfirmation: "false",
      patientConsentLanguage: "English",
      patientType: "",
      measurementSystemType: "US Standard",
      patientHeightFeet: "",
      patientHeightInches: "",
      patientHeightCentimeters: "",
      patientWeight: "",
      patientWeightOunces: "",
      patientWeightKilograms: "",
      programEligibilityCriteria: "",
      probandPGID: "",
      probandName: "",
      relationshipToProband: [],
      hyperphagia: "",
      ageOfOnsetHyperphagia: "",
      ageOfOnsetHyperphagiaUnknown: "false",
      bbsDiagnosis: "",
      childhoodObesity: "",
      ageOfOnsetChildhoodObesity: "",
      ageOfOnsetChildhoodObesityUnknown: "false",
      thyroidConditionHistory: "",
      historyOfWeightLossInterventions: [],
      familyHistoryOfObesity: [],
      bariatricSurgery: "",
      bariatricSurgeryFailure: "",
      geneticDiseaseFamilyHistory: "",
      geneticDiseaseFamilyHistoryResults: "",
      testCode: 15187,
      consentFormSigned: "",
      consentVersion: "",
      consentVersionLanguage: "English",
      phiDisclosure: "No",
      marketingOptIn: "No",
      providerPrintedName: "",
      providerSignatureDate: this.props.user.isPGUser
        ? ""
        : new Date().toISOString().split("T")[0],
      isProvider: this.props.user.isPGUser ? "false" : "",
      sendReportToProvider: "false",
      institution: "",
      institutionStreetAddress: "",
      institutionCity: "",
      institutionState: "",
      institutionCountry: "US",
      institutionPostalCode: "",
      providerFirstName: "",
      providerLastName: "",
      providerDegree: "",
      providerPhoneNumber: "",
      providerNPI: "",
      providerEmail: "",
      providerSpecialty: "",
      providerSpecialtyOther: "",
      providerDesignation: "",
      providerDesignationOther: "",
      userEmail: this.props.user.isPGUser
        ? ""
        : this.props.user.attributes.email,
      additionalReportingInstructions: "",
      additionalEmail1: "",
      additionalEmail2: "",
      additionalEmail3: "",
      printConfirmation: "false",
    },
    sections: this.sections.map(() => ({
      validated: false,
      completed: false,
      loading: false,
    })),
  };

  constructor(props) {
    super(props);
    this.state = this.initialState;

    this.startNewOrder = this.startNewOrder.bind(this);
    this.submitKitOrder = this.submitKitOrder.bind(this);
    this.getViewType = this.getViewType.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBooleanCheckbox = this.handleBooleanCheckbox.bind(this);
    this.handleRadioButtonClick = this.handleRadioButtonClick.bind(this);
    this.handleCheckboxRequirement = this.handleCheckboxRequirement.bind(this);
    this.handleSampleCollectionLocationChange = this.handleSampleCollectionLocationChange.bind(
      this
    );
    this.handleProgramEligibility = this.handleProgramEligibility.bind(this);
    this.handleAgeOfOnset = this.handleAgeOfOnset.bind(this);
    this.handleAgeOfOnsetUnknown = this.handleAgeOfOnsetUnknown.bind(this);
    this.handleWaistCircumferenceNotEvaluated = this.handleWaistCircumferenceNotEvaluated.bind(this);
    this.handleBariatricSurgery = this.handleBariatricSurgery.bind(this);
    this.handleNextButton = this.handleNextButton.bind(this);
    this.handleBackButton = this.handleBackButton.bind(this);
    this.handleProbandChange = this.handleProbandChange.bind(this);
  
    this.handleSectionClick = this.handleSectionClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePostPrintSubmit = this.handlePostPrintSubmit.bind(this);
    this.handleReprint = this.handleReprint.bind(this);
    this.handleAfterPrint = this.handleAfterPrint.bind(this);
    this.handleSaveOrUpdate = this.handleSaveOrUpdate.bind(this);
    this.handleCloseAllModals = this.handleCloseAllModals.bind(this);
    this.handleIsProviderCheck = this.handleIsProviderCheck.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.renderReviewMessage = this.renderReviewMessage.bind(this);
    this.renderPGUserForm = this.renderPGUserForm.bind(this);
    this.renderProviderForm = this.renderProviderForm.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
    this.renderPrintConfirmationModal = this.renderPrintConfirmationModal.bind(
      this
    );
    this.renderSaveOrUpdateButton = this.renderSaveOrUpdateButton.bind(this);
    this.renderPostPrintSubmitButton = this.renderPostPrintSubmitButton.bind(
      this
    );
    this.setPatientConsentLoadedState = this.setPatientConsentLoadedState.bind(
      this
    );
    this.handleSpecimenSourceChange = this.handleSpecimenSourceChange.bind(
      this
    );
    this.convertMeasurements = this.convertMeasurements.bind(this);

    // For each section add its variable properties to state
    this.state.sections = this.sections.map(() => ({
      validated: false,
      completed: false,
      loading: false,
    }));
  }
  async startNewOrder() {
    this.props.history.replace(`/rhythm`);

    const previouslyAccessionedPatients = await this.loadPreviouslyAccessionedPatients();
    // Reset section state
    let newState = {
      ...this.initialState,
      previouslyAccessionedPatients,
      sections: this.sections.map(() => ({
        validated: false,
        completed: false,
        loading: false,
      })),
      loading: false,
    };

    this.setState(newState);
  }

  getViewType() {
    let viewAs = this.props.user.isPGUser
      ? VIEW_AS_TYPE.PGUSER
      : VIEW_AS_TYPE.PROVIDER;

    const queryStringValues = queryString.parse(this.props.location.search);
    if (
      queryStringValues &&
      queryStringValues.view &&
      this.props.user.isPGUser
    ) {
      viewAs = queryStringValues.view;
    }

    return viewAs;
  }

  handleChange(event, optionalFunction = null) {
    const target = event.target;
    const name = target.name;

    let value = "";
    switch (target.type) {
      case "checkbox":
        if (Array.isArray(this.state.form[name])) {
          let arrayCopy = [...this.state.form[name]];
          if (arrayCopy.includes(target.value)) {
            value = arrayCopy.filter(function (value) {
              return value !== target.value;
            });
          } else {
            arrayCopy.push(target.value);
            value = arrayCopy;
          }
        } else {
          value = target.checked.toString();
        }
        break;
      default:
        value = target.value;
    }

    // Spread operator creates a copy of the form state
    const newForm = { ...this.state.form };
    newForm[name] = value;

    if (name === "providerSpecialty" && value !== "Other") {
      newForm["providerSpecialtyOther"] = "";
    }

    if (name === "providerDesignation" && value !== "Other") {
      newForm["providerDesignationOther"] = "";
    }

    this.setState(
      {
        form: newForm,
      },
      optionalFunction
    );
  }

  handleRadioButtonClick(event) {
    const target = event.target;
    const name = target.name;
    if (target.value === this.state.form[name]) {
      this.setState({ form: { ...this.state.form, [name]: " " } });
    }
  }

  handleCheckboxRequirement(event) {
    const target = event.target;
    const name = target.name;

    const checkboxes = document.getElementsByName(name);

    let atLeastOneChecked = false; //at least one cb is checked
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === true) {
        atLeastOneChecked = true;
        break;
      }
    }

    if (atLeastOneChecked === true) {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].required = false;
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].required = true;
      }
    }
  }

  handleCloseAllModals() {
    this.setState({
      simpleModal: null,
      showPrintConfirmationModal: false,
    });
  }

  renderSubmitButton() {
    if (!this.state.order?.confirmationNumber) {
      if (!this.state.isSubmitting && !this.state.isSaving) {
        return (
          <Button type="button" onClick={this.handleSubmit}>
            Submit form
          </Button>
        );
      } else {
        const text = this.state.isSubmitting ? "Submitting..." : "Submit form";
        return (
          <Button type="button" className="mr-3" disabled>
            {text}
          </Button>
        );
      }
    }
  }

  renderSaveOrUpdateButton(text) {
    if (!this.state.isSaving && !this.state.isSubmitting) {
      const view = this.getViewType();
      return (
        <Button
          type="button"
          onClick={this.handleSaveOrUpdate}
          disabled={this.props.user.isPGUser && view === VIEW_AS_TYPE.PROVIDER}
        >
          {text}
        </Button>
      );
    } else {
      const savingOrUpdating = text === "Save" ? "Saving" : "Updating";
      const savingText = this.state.isSaving ? savingOrUpdating : text;
      return (
        <Button type="button" onClick={this.handleSaveOrUpdate} disabled>
          {savingText}
        </Button>
      );
    }
  }

  renderPrintButton() {
    if (this.state.order && !this.state.order.confirmationNumber) {
      return (
        <Button type="button" onClick={this.handleSubmit}>
          Print Form
        </Button>
      );
    } else if (this.state.order && this.state.order.confirmationNumber) {
      let text = "Print Form";
      if (this.state.form.printConfirmation === "true") {
        text = "Reprint Form";
      }
      if (this.state.postPrintSubmitClicked) {
        return <Button onClick={this.handleReprint}>{text}</Button>;
      }

      if (
        this.state.patientConsentIsLoaded ||
        this.state.form.sampleCollectionLocation !==
          "In office sample collection"
      ) {
        return (
          <Button type="button" onClick={window.print}>
            {text}
          </Button>
        );
      }

      return (
        <Button type="button" title="Loading..." disabled>
          {text}
        </Button>
      );
    }
  }

  renderPrintConfirmationModal() {
    return (
      <PrintConfirmationModal
        show={this.state.showPrintConfirmationModal}
        onHide={this.handleCloseAllModals}
        formState={this.state.form}
        postPrintSubmitClicked={this.state.postPrintSubmitClicked}
        renderPostPrintSubmitButton={this.renderPostPrintSubmitButton}
        handleBooleanCheckbox={this.handleBooleanCheckbox}
      />
    );
  }

  renderPostPrintSubmitButton() {
    if (!this.state.isSaving && !this.state.isSubmitting) {
      const view = this.getViewType();
      return (
        <Button
          type="button"
          onClick={this.handlePostPrintSubmit}
          disabled={this.props.user.isPGUser && view === VIEW_AS_TYPE.PROVIDER}
        >
          Submit Form
        </Button>
      );
    } else {
      return (
        <Button type="button" disabled>
          Submitting
        </Button>
      );
    }
  }

  handleBackButton = () => {
    const currentSectionIndex = this.state.activeSection;
    const isBeingReviewed = currentSectionIndex === this.sections.length;
    this.setState({ activeSection: currentSectionIndex - 1, isBeingReviewed });
  };

  handleNextButton = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const currentSectionIndex = this.state.activeSection;
    const form = event.currentTarget;
    const isBeingReviewed =
      currentSectionIndex + 1 === this.sections.length - 1;
    let newSections = [...this.state.sections];
    newSections[currentSectionIndex].validated = true;
    if (form.checkValidity() === false) {
      newSections[currentSectionIndex].completed = false;
      this.setState({ sections: newSections });
    } else {
      newSections[currentSectionIndex].completed = true;
      this.setState({
        sections: newSections,
        activeSection: currentSectionIndex + 1,
        isBeingReviewed,
      });
    }
    // Auto save
    this.handleSaveOrUpdate(false);
  };

  handleSectionClick = (index) => {
    if (
      this.state.sections[index].completed &&
      !this.state.isAccessioned &&
      !this.props.user.isPGUser &&
      this.state.order?.status !== "Submitted"
    ) {
      this.setState({ activeSection: index });
    }
  };

  handleSaveOrUpdate = (showModal = true) => {
    if (this.state.order) {
      this.setState(
        {
          htmlValidated: true,
          submittedForm: null,
          isSaving: true,
        },
        () => this.updateForm(showModal)
      );
    } else {
      this.setState(
        {
          htmlValidated: true,
          submittedForm: null,
          isSaving: true,
        },
        () => this.saveForm(showModal)
      );
    }
  };

  handlePostPrintSubmit = () => {
    if (this.state.order) {
      this.setState(
        {
          htmlValidated: true,
          submittedForm: null,
          isSaving: true,
          postPrintSubmitClicked: true,
          showPrintConfirmationModal: false,
        },
        () => this.updateForm(false)
      );

      setTimeout(
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        }),
        500
      );
    }
  };

  handleSampleCollectionLocationChange(event) {
    let specimenSource = "";
    let patientType =
      this.state.form.patientType === PATIENT_TYPE.FAMILY_MEMBER
        ? PATIENT_TYPE.FAMILY_MEMBER
        : PATIENT_TYPE.NEW;

    switch (event.target.value) {
      case SAMPLE_COLLECTION_LOCATIONS.IN_OFFICE:
        break;
      case SAMPLE_COLLECTION_LOCATIONS.AT_HOME:
        specimenSource = "Buccal OCD-100";
        break;
      case SAMPLE_COLLECTION_LOCATIONS.AT_LAB:
        specimenSource = "DNA at Lab";
        patientType = PATIENT_TYPE.EXISTING;
        break;
      default:
        //If it hits this case log an error to the console
        console.error("Invalid sample location");
    }

    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: event.target.value,
        specimenSource,
        patientType,
        sendKitConfirmation: false,
      },
    });
  }

  handleProgramEligibility(event) {
    if (event.target.value === ELIGIBILITY_CRITERIA.AGE_LTE18_BMI_GTE97) {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.value,
          childhoodObesity: "Yes",
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.value,
        },
      });
    }
  }

  handleAgeOfOnset(event, ageOfOnsetName) {
    if (event.target.value !== "Yes") {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.value,
          [ageOfOnsetName]: "",
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.value,
        },
      });
    }
  }

  handleAgeOfOnsetUnknown(event, ageOfOnsetName) {
    if (event.target.checked) {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.checked.toString(),
          [ageOfOnsetName]: "",
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.checked.toString(),
        },
      });
    }
  }

  handleWaistCircumferenceNotEvaluated(event) {
    if (event.target.checked) {
      this.setState({
        form: {
          ...this.state.form,
          waistCircumference: event.target.value,
          waistCircumferenceUnits: "",
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          waistCircumference: "",
        },
      });
    }
  }

  handleBooleanCheckbox(event) {
    if (event.target.checked) {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.checked.toString(),
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.checked.toString(),
        },
      });
    }
  }

  handleProbandChange(probandName, pgID) {
    const probandPGID = pgID || this.state.form.probandPGID;
    this.setState({
      form: {
        ...this.state.form,
        probandName,
        probandPGID,
      },
    });
  }

  handleBariatricSurgery(event) {
    if (event.target.value !== "Yes") {
      this.setState({
        form: {
          ...this.state.form,
          bariatricSurgery: event.target.value,
          bariatricSurgeryFailure: "",
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [event.target.name]: event.target.value,
        },
      });
    }
  }

  async handleIsProviderCheck(event) {
    const currentSectionIndex = this.state.activeSection;
    let newSections = [...this.state.sections];
    const newState = {
      form: {
        ...this.state.form,
        isProvider: event.target.value,
      },
    };

    if (!this.props.user.isPGUser || this.state.order.accession) {
      if (event.target.value === "true") {
        newSections[currentSectionIndex].loading = true;
        this.setState({ sections: newSections });
        const defaultProfile = await this.loadProfile();

        if (defaultProfile) {
          Object.assign(newState.form, defaultProfile);
        }
        newSections[currentSectionIndex].loading = false;
        newState.sections = newSections;
      } else {
        newState.form.providerFirstName = "";
        newState.form.providerLastName = "";
        newState.form.providerDegree = "";
        newState.form.providerPhoneNumber = "";
        newState.form.providerNPI = "";
        newState.form.providerEmail = "";
        newState.form.providerSpecialty = "";
        newState.form.providerSpecialtyOther = "";
        newState.form.providerDesignation = "";
        newState.form.providerDesignationOther = "";
      }
    }

    this.setState(newState);
  }

  handleReprint() {
    this.setState({ postPrintSubmitClicked: false, reprintClicked: true });
  }

  handleAfterPrint() {
    this.setState({
      printDialogShown: true,
      showPrintConfirmationModal: !this.state.postPrintSubmitClicked,
    });
  }

  async handleSubmit(event) {
    const submittedForm = document.querySelector("form");
    event.preventDefault();
    event.stopPropagation();
    // Set the submitted form to state so that it can be used in componentDidUpdate
    // If form submission is successful
    if (submittedForm.checkValidity()) {
      const showModal = false;
      if (this.state.order) {
        await this.updateForm(showModal);
      } else {
        await this.saveForm(showModal);
      }
      this.setState(
        {
          htmlValidated: true,
          submittedForm: null,
          isSubmitting: true,
        },
        async () => {
          // Submit the order first for all cases
          let kitOrderSubmitted = false;
          let kitOrderFailedtoSend = false;
          const submitFormResponse = await this.submitForm();
          if (submitFormResponse.status === 200) {
            // Sending Kit To Patients House (Buccal OCD-100)
            if (
              this.state.form.sendKitConfirmation === "true"
            ) {
              const submitKitOrderResponse = await this.submitKitOrder();
              kitOrderSubmitted = true;
              if (submitKitOrderResponse.status !== 204) {
                kitOrderFailedtoSend = true;
              }
            } else {
              // No print confirmation needed for PG Users
              window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }
          }

          const {
            orderConfirmationNumber,
          } = submitFormResponse.data;

          this.setState({
            isSubmitting: false,
            order: {
              ...this.state.order,
              confirmationNumber: orderConfirmationNumber,
              status: "Submitted",
            },
            kitOrderSubmitted,
            kitOrderFailedtoSend,
            reprintClicked: true,
          }, () => {
            if (this.props.user.isPGUser) {
              setTimeout(() => {
                if (this.state.form.submissionComplete !== "Yes") {
                  window.print()
                }
              }, 1000);
            }
          });
        }
      );
    } else {
      let newSections = [...this.state.sections];
      newSections[newSections.length - 1].validated = true;
      // Invalid form submission - required fields missing
      this.setState({
        htmlValidated: true,
        sections: newSections,
        submittedForm: submittedForm,
      });
    }
  }

  setPatientConsentLoadedState(isLoaded) {
    this.setState({ patientConsentIsLoaded: isLoaded });
  }

  handleSpecimenSourceChange() {
    if (this.state.form.specimenSource !== "Buccal OCD-100") {
      this.setState({
        form: {
          ...this.state.form,
          sendKitConfirmation: "false",
        },
      });
    }
  }

  // Occurs after state has been set and the DOM has been rendered
  componentDidUpdate() {
    if (this.state.submittedForm !== null) {
      const form = this.state.submittedForm;
      if (form.checkValidity() === false) {
        // If form submission is invalid
        this.scrollToInvalidElement();
      }
      this.setState({ submittedForm: null });
    }
    // If reprinting we need to check to make sure that the patient consent + authorization form images have loaded
    if (this.state.reprintClicked && this.state.patientConsentIsLoaded) {
      this.setState({ reprintClicked: false }, () => {
        window.print();
      });
    }
  }

  async updatePersonalDetails() {
    const updateAccountObj = {
      firstName: this.state.form["providerFirstName"],
      lastName: this.state.form["providerLastName"],
      degree: this.state.form["providerDegree"],
      phoneNumber: this.state.form["providerPhoneNumber"],
      npi: this.state.form["providerNPI"],
      specialty: this.state.form["providerSpecialty"],
      specialtyOther: this.state.form["providerSpecialtyOther"],
      designation: this.state.form["providerDesignation"],
      designationOther: this.state.form["providerDesignationOther"],
    };
    return await axios.put(`/api/updatePersonalDetails`, updateAccountObj);
  }

  async updateInstitution() {
    const updateInstitutionObj = {
      institutionName: this.state.form["institution"],
      address: this.state.form["institutionStreetAddress"],
      city: this.state.form["institutionCity"],
      state: this.state.form["institutionState"],
      country: this.state.form["institutionCountry"],
      zipCode: this.state.form["institutionPostalCode"],
    };
    return await axios.put(`/api/updateInstitution`, updateInstitutionObj);
  }

  async updateProfile() {
    try {
      const [
        personalDetailsUpdateResponse,
        institutionalUpdateResponse,
      ] = await Promise.all([
        this.updatePersonalDetails(),
        this.updateInstitution(),
      ]);
      if (
        personalDetailsUpdateResponse.status === 200 &&
        institutionalUpdateResponse.status === 200
      ) {
        this.setState({
          simpleModal: {
            title: "Profile Updated",
            body: `Your profile has been successfully updated. This information will now be filled out on future orders automatically.`,
          },
        });
      } else {
        throw new Error();
      }
    } catch (err) {
      this.setState({
        simpleModal: {
          title: "Error...",
          body: `An error has occurred while updating your profile. Please try
              again later or contact PreventionGenetics by phone at
              715-387-0484 or by email at support@preventiongenetics.com`,
        },
      });
    }
  }

  async saveForm(showModal = true) {
    try {
      const response = await axios.post(`/api/saveQA`, {
        form: this.state.form,
      });

      const orderID = response.data.id;
      this.props.history.replace(`/rhythm/${orderID}`);
      if (showModal) {
        this.setState({
          simpleModal: { title: "Success", body: "The form has been saved" },
          isSaving: false,
          order: { ...this.state.order, id: orderID },
        });
      } else {
        this.setState({
          isSaving: false,
          order: { ...this.state.order, id: orderID },
        });
      }
      return orderID;
    } catch (error) {
      this.setState({
        simpleModal: {
          title: "Error...",
          body: `The form failed to save... (Details: ${error.message})`,
        },
        isSaving: false,
      });
      return;
    }
  }

  async updateForm(showModal = true) {
    let orderID = this.state.order.id;
    try {
      await axios.put(`/api/updateQA/${orderID}`, {
        form: this.state.form,
      });

      if (showModal) {
        this.setState({
          simpleModal: { title: "Success", body: "The form has been updated" },
          isSaving: false,
        });
      } else {
        this.setState({ isSaving: false });
      }
    } catch (error) {
      this.setState({
        simpleModal: {
          title: "Error...",
          body: `The form failed to update... (Details: ${error.message})`,
        },
        isSaving: false,
      });
      return;
    }
  }

  async submitForm() {
    try {
      let orderID = this.state.order?.id;
      if (!orderID) {
        orderID = await this.saveForm(false);
      }

      if (!orderID) {
        throw new Error("Order failed to save");
      } else {
        const response = await axios.put(`/api/submitQA/${orderID}`);
        if (response.status === 200) {
          const orderConfirmationNumber = response.data.orderConfirmationNumber;
          if (orderConfirmationNumber) {
            this.props.history.replace(`/rhythm/${orderID}`);
            this.setState({
              isSubmitting: false,
              order: {
                ...this.state.order,
                id: orderID,
                confirmationNumber: orderConfirmationNumber,
              },
            });
            return response;
          }
        } else {
          return {
            reason: "Non 200 status code response for submitting the order",
            status: response.status,
          };
        }
      }
    } catch (err) {
      this.setState({
        simpleModal: {
          title: "Error...",
          body: `Something bad happened... (Details: ${err})`,
        },
        isSubmitting: false,
      });

      return { reason: err.message, status: 500 };
    }
  }

  async submitKitOrder() {
    if (!this.state.order || !this.state.order.confirmationNumber) {
      return { reason: "No order confirmation number found", status: 500 };
    }
    const {
      specimenSource,
      patientFirstName,
      patientLastName,
      patientDateOfBirth,
      patientPhoneNumber: patientPhone,
      providerFirstName,
      providerLastName,
      providerEmail,
      providerNPI,
      providerSpecialty,
      providerSpecialtyOther,
      providerDesignation,
      providerDesignationOther,
      providerPhoneNumber: providerPhone,
      institution: providerInstitution,
      patientStreetAddress: streetAddress,
      patientCity: city,
      patientCountry: country,
      patientState: state,
      patientZip: zipCode,
      patientConsentLanguage
    } = this.state.form;

    try {
      return await axios.post(`/api/kit`, {
        docusignEmailIsNeeded: true,
        patientOrProvider: "patient",
        specimenSource,
        numberOfBloodKits: 0,
        numberOfBuccalKits: 1,
        numberOfSalivaKits: 0,
        confirmationNumber: this.state.order.confirmationNumber,
        patientFirstName,
        patientLastName,
        patientDateOfBirth,
        patientPhone,
        providerFirstName,
        providerLastName,
        providerEmail,
        providerNPI,
        providerPhone,
        providerInstitution,
        providerSpecialty,
        providerSpecialtyOther,
        providerDesignation,
        providerDesignationOther,
        streetAddress,
        city,
        country,
        state,
        shippingMethod: "FedEx",
        zipCode,
        preferredLanguage: patientConsentLanguage,
        comments: `SP068, include completed TRF + specimen label. Please include a ${patientConsentLanguage} consent form and materials.`,
      });
    } catch (error) {
      return { reason: error.message, status: 500 };
    }
  }

  showConfirmationModal() {
    this.setState({ showConfirmationModal: true });
  }

  scrollToInvalidElement() {
    let firstInvalidElement = document.querySelectorAll(
      ".was-validated .form-control:invalid"
    )[0];
    if (firstInvalidElement !== undefined) {
      firstInvalidElement
        .closest("div")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }

  async loadOrder(orderID) {
    try {
      const axiosConfig = {
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 404,
      };
      const response = await axios.get(`/api/orders/${orderID}`, axiosConfig);
      if (response.status === 200) {
        const formResponses = response.data.formResponses;

        let newFormObject = { ...this.state.form };
        for (let response of formResponses) {
          // If it is a checkbox it can have multiple values
          if (Array.isArray(newFormObject[response.fieldName])) {
            newFormObject[response.fieldName] = newFormObject[
              response.fieldName
            ].concat([response.fieldValue]);
          } else {
            newFormObject[response.fieldName] = response.fieldValue;
          }
        }
        newFormObject.orderID = orderID;

        const order = {
          id: orderID,
          confirmationNumber: response.data.confirmationNumber,
          accession: response.data.accession,
          submittedDateTime: response.data.orderSubmittedDateTime,
          accessionedDateTime: response.data.orderAccessionedDateTime,
          lastUpdatedDateTime: response.data.orderLastUpdatedDateTime,
          status: response.data.orderStatus,
          testStatus: response.data.testStatus,
          testStatusID: response.data.testStatusID,
        };
        return {
          form: newFormObject,
          order,
        };
      } else {
        this.setState({
          serverError: true,
          serverErrorMessage: "No order found",
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        serverError: true,
        serverErrorMessage: error.toString(),
        loading: false,
      });
    }
  }

  async loadProfile() {
    try {
      const profileResponse = await axios.get(`/api/loadProfile`);
      const {
        providerFirstName,
        providerLastName,
        providerEmail,
        providerDegree,
        providerPhoneNumber,
        providerNPI,
        providerSpecialty,
        providerSpecialtyOther,
        providerDesignation,
        providerDesignationOther,
        institutions,
      } = profileResponse.data;
      const {
        institutionName,
        address,
        city,
        country,
        state,
        zipCode,
      } = institutions[0];

      if (profileResponse.status === 200) {
        let profileObject = {
          providerFirstName: providerFirstName || "",
          providerLastName: providerLastName || "",
          providerEmail: providerEmail || "",
          providerDegree: providerDegree || "",
          providerPhoneNumber: providerPhoneNumber || "",
          providerNPI: providerNPI || "",
          providerSpecialty: providerSpecialty || "",
          providerSpecialtyOther: providerSpecialtyOther || "",
          providerDesignation: providerDesignation || "",
          providerDesignationOther: providerDesignationOther || "",
          institution: institutionName || "",
          institutionStreetAddress: address || "",
          institutionCity: city || "",
          institutionCountry: country || "",
          institutionState: state || "",
          institutionPostalCode: zipCode || "",
        };
        return profileObject;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  async loadProband(familyMemberOrderId) {
    const response = await axios.get(`/api/orders/${familyMemberOrderId}`);
    const accession = response.data.accession;
    const formResponses = response.data.formResponses;
    const probandFirstName = formResponses.find(
      ({ fieldName }) => fieldName === "patientFirstName"
    ).fieldValue;
    const probandMI = formResponses.find(
      ({ fieldName }) => fieldName === "patientMI"
    ).fieldValue;
    const probandLastName = formResponses.find(
      ({ fieldName }) => fieldName === "patientLastName"
    ).fieldValue;
    let probandName = "";
    if (probandMI) {
      probandName = `${probandFirstName} ${probandMI} ${probandLastName}`;
    } else {
      probandName = `${probandFirstName} ${probandLastName}`;
    }

    return {
      probandPGID: accession,
      probandName,
    };
  }

  async getOrderForRequisition(requisitionID) {
    if (!this.props.user.isPGUser) {
      return null;
    }
    const axiosConfig = {
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 404,
    };

    const response = await axios.get(
      `/api/requisition/${requisitionID}`,
      axiosConfig
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  }

  // Should be ran if no order is found for this requisition
  async loadPatientForRequisition(requisitionID) {
    if (!this.props.user.isPGUser) {
      return null;
    }
    const { data } = await axios.get(`/api/patient/${requisitionID}`);
    let patient = {};
    patient.individualID = data.individualID;
    patient.patientDateOfBirth = new Date(data.dateOfBirth)
      .toISOString()
      .slice(0, 10);
    patient.patientLastName = data.lastName;
    patient.patientFirstName = data.firstName;
    patient.patientMI = data.mi;
    patient.patientID = data.patientID;
    patient.patientSex = data.sex === "Unknown" ? "Other" : data.sex;
    patient.specimenCollectionDate = new Date(data.specimenCollectedDate)
      .toISOString()
      .slice(0, 10);
    patient.specimenSource = data.specimenSource;

    return patient;
  }

  // Used for PGID autofill in Program Eligibility - Family
  //? Only retrieves accessioned orders with a PGID
  async loadPreviouslyAccessionedPatients() {
    const axiosConfig = {
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 404,
    };
    // Limiting PGID autocompletes to the first 100 patients
    const response = await axios.get("/api/orders?query=&pageNumber=1&pageSize=100&showCompletedForms=true&showCancelledOrders=false&showOnlyAccessioned=true", axiosConfig);
    if (response.status === 200) {
      const { orders } = response.data;
      if (orders) {
        const previouslyAccessionedPatientSummaries = orders.filter(
          (o) => o.pgid
        );
        const previouslyAccessionedPatients = previouslyAccessionedPatientSummaries.map(
          ({ pgid, patientName }) => {
            return { pgid, patientName };
          }
        );
        return previouslyAccessionedPatients;
      }
    }
  }

  async loadPGUserForm(orderID) {
    const requisitionID = this.props.match.params.requisitionID;
    if (!orderID && requisitionID) {
      orderID = await this.getOrderForRequisition(requisitionID);
    }

    if (orderID) {
      const { order, form: formCopy } = await this.loadOrder(orderID);
      formCopy.consentVersion = formCopy.consentVersion || "";
      formCopy.consentVersionLanguage = formCopy.consentVersionLanguage || "";

      if (requisitionID) {
        const patient = await this.loadPatientForRequisition(requisitionID);
        if (patient) {
          formCopy.individualID = formCopy.individualID || patient.individualID;
          formCopy.patientDateOfBirth = patient.patientDateOfBirth;
          formCopy.patientLastName = patient.patientLastName;
          formCopy.patientFirstName = patient.patientFirstName;
          formCopy.patientMI = patient.patientMI;
          formCopy.patientID = patient.patientID;
          formCopy.patientSex = patient.patientSex;
          formCopy.specimenCollectionDate = patient.specimenCollectionDate;
          formCopy.specimenSource = patient.specimenSource;
        }
      }
      this.props.history.replace(`/rhythm/${orderID}`);
      return { form: formCopy, order, loading: false };
    } else {
      return { loading: false };
    }
  }

  async loadProviderForm(orderID) {
    let loadedForm = null;
    let order = null;
    let proband = null;
    let patientType = "New Patient";
    let sections = this.state.sections;
    let isAccessioned = false;
    let activeSection = 0;
    let previouslyAccessionedPatients = null;

    if (orderID) {
      const loadedOrder = await this.loadOrder(orderID);
      if (loadedOrder) {
        order = loadedOrder.order;
        loadedForm = loadedOrder.form;
      }
    }
    if (this.props.location.search) {
      const queryStringValues = queryString.parse(this.props.location.search);

      if (queryStringValues.familyTesting) {
        [previouslyAccessionedPatients, proband] = await Promise.all([
          this.loadPreviouslyAccessionedPatients(),
          this.loadProband(queryStringValues.familyTesting),
        ]);
        patientType = "Family Member";
      }
    } else {
      previouslyAccessionedPatients = await this.loadPreviouslyAccessionedPatients();
    }

    if (order && order.confirmationNumber) {
      sections = this.sections.map(() => ({
        validated: true,
        completed: true,
        loading: false,
      }));
      isAccessioned = order && order.accession !== null;
      activeSection = this.sections.length - 1;
    }

    if (loadedForm) {
      return {
        form: loadedForm,
        isAccessioned,
        loading: false,
        order,
        previouslyAccessionedPatients,
        activeSection,
        sections,
      };
    }
    return {
      form: {
        ...this.state.form,
        probandName: proband ? proband.probandName : "",
        probandPGID: proband ? proband.probandPGID : "",
        patientType,
      },
      isAccessioned,
      loading: false,
      order,
      previouslyAccessionedPatients,
      sections,
    };
  }

  async componentDidMount() {
    document.title = "Rhythm";

    // Check environment
    try {
      const environmentResponse = await axios.get(`/api/environment`);
      if (environmentResponse.status === 200) {
        const { isProd } = environmentResponse.data;
        if (!isProd) {
          this.setState({
            isProd: false,
          });
        }
      }
    } catch (error) {
      // Ignore error - this should only occur on the test environment if there's an issue and there will already be an error logged
    }

    const viewAs = this.getViewType();
    let orderID = this.props.match.params.order;

    if (!orderID && this.props.location.search) {
      const queryStringValues = queryString.parse(this.props.location.search);
      // Regardless of view if the user is a PGUser and they are passing a confirmation number, load the order for that confirmation number
      const confirmationNumber = queryStringValues.confirmationNumber;
      if (this.props.user.isPGUser) {
        try {
          const { data } = await axios.get(
            `/api/orders/byConfirmationNumber/${confirmationNumber}`
          );
          orderID = data.clinicalOrderID;
          this.props.history.replace(
            `/rhythm/${orderID}${this.props.location.search}`
          );
        } catch (error) {
          this.setState({
            serverError: true,
            serverErrorMessage: error.toString(),
            loading: false,
          });
        }
      }
    }

    switch (viewAs) {
      case VIEW_AS_TYPE.PGUSER:
        this.setState(await this.loadPGUserForm(orderID));
        break;
      default:
        this.setState(await this.loadProviderForm(orderID));
        break;
    }

    // Add event listeners - just remember to unmount them
    // Not supported by Webkit (Chrome)
    window.addEventListener("afterprint", this.handleAfterPrint);
    // Chrome workaround
    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia("print");
      mediaQueryList.addListener((mql) => {
        if (!mql.matches) {
          this.handleAfterPrint();
        }
      });
    }
  }

  calculateAge() {
    return moment().diff(this.state.form.patientDateOfBirth, "years");
  }

  isBMIGreaterThanOrEqualTo97Percent(age, BMI, sex) {
    if (!sex || !BMIPercentile[sex]) {
      return false;
    }
    const ageMos = age * 12 + (age === 24 ? 0 : 0.5); // CDC Data gives values at every half birthday instead of full after the age of 2 (24 months).
    const BMIObject = BMIPercentile[sex].find((percentileObj) => {
      return percentileObj.Agemos === ageMos;
    });
    if (BMIObject) {
      return BMI >= BMIObject.P97;
    } else {
      return false;
    }
  }

  calculateBMI() {
    if (this.state.form.measurementSystemType === MEASUREMENT_TYPE.METRIC) {
      // Formula: weight (kg) / [height (m)]2
      // Calculation: (weight (kg) / height (cm) / height (cm)) x 10,000
      const weightInKg = this.state.form.patientWeightKilograms;
      const heightInCm = this.state.form.patientHeightCentimeters;
      return (weightInKg / heightInCm / heightInCm) * 10000 || 0;
    } else {
      // Calculate BMI by dividing weight in pounds (lbs) by height in inches (in) squared and multiplying by a conversion factor of 703.
      const pounds = parseFloat(this.state.form.patientWeight) || 0;
      const ozToPounds =
        parseFloat(this.state.form.patientWeightOunces / 16) || 0;
      const weight = pounds + ozToPounds;
      const inches = parseFloat(this.state.form.patientHeightInches);
      const feetToInches = parseFloat(this.state.form.patientHeightFeet * 12);
      const heightInInches = parseFloat(inches + feetToInches);
      const heightInInchesSquared = parseFloat(Math.pow(heightInInches, 2));

      return (weight / heightInInchesSquared) * 703 || 0;
    }
  }

  convertMeasurements() {
    if (this.state.form.measurementSystemType === MEASUREMENT_TYPE.METRIC) {
      // Convert from US Standard to Metric
      const pounds = parseFloat(this.state.form.patientWeight) || 0;
      const ozToPounds =
        parseFloat(this.state.form.patientWeightOunces / 16) || 0;
      const weightInPounds = pounds + ozToPounds;
      const inches = parseFloat(this.state.form.patientHeightInches) || 0;
      const feetToInches = parseFloat(this.state.form.patientHeightFeet * 12);
      const heightInInches = parseFloat(inches + feetToInches);

      // Conversions
      // 1 (US Standard) lb = 0.453592 (Metric) kg
      const patientWeightKilograms = weightInPounds * 0.453592 || "";
      // 1 (US Standard) in = 2.54 (Metric) cm
      const patientHeightCentimeters = heightInInches * 2.54 || "";

      // Update State
      const newForm = {
        ...this.state.form,
        patientWeightKilograms,
        patientHeightCentimeters,
        patientWeight: "",
        patientWeightOunces: "",
        patientHeightInches: "",
        patientHeightFeet: "",
      };
      this.setState({ form: newForm });
    } else {
      // Convert from Metric to US Standard
      const weightInKg =
        parseFloat(this.state.form.patientWeightKilograms) || 0;
      const heightInCm =
        parseFloat(this.state.form.patientHeightCentimeters) || 0;

      // Conversions
      // 1 (US Standard) lb = 0.453592 (Metric) kg
      const weightInPounds = weightInKg / 0.453592;
      const weightInOz = weightInPounds * 16;
      // 1 (US Standard) in = 2.54 (Metric) cm
      const heightInInches = heightInCm / 2.54;

      const patientWeight = parseInt(weightInPounds) || "";
      const patientWeightOunces = parseFloat(weightInOz % 16) || "";
      const patientHeightFeet = parseInt(heightInInches / 12) || "";
      const patientHeightInches = parseFloat(heightInInches % 12) || "";

      // Update State
      const newForm = {
        ...this.state.form,
        patientWeightKilograms: "",
        patientHeightCentimeters: "",
        patientWeight,
        patientWeightOunces,
        patientHeightInches,
        patientHeightFeet,
      };
      this.setState({ form: newForm });
    }
  }

  renderReviewMessage() {
    return (
      <div className="row mb-2 mx-2 p-3 clearfix bg-light-blue d-print-none">
        <h5>
          Please review your information below. Once you have reviewed your
          information please{" "}
          {this.state.form.sampleCollectionLocation ===
          "In office sample collection"
            ? "print"
            : "submit"}{" "}
          the form at the bottom of the page.
        </h5>
      </div>
    );
  }

  renderPGUserForm() {
    const viewAs = this.getViewType();
    const htmlValidated = this.state.htmlValidated;
    const submitOrderOrUpdateButton =
      this.state.order && this.state.order?.confirmationNumber
        ? this.renderSaveOrUpdateButton("Update")
        : this.renderSubmitButton();
    const patientAge = this.calculateAge();
    const patientBMI = this.calculateBMI();

    return (
      <div>
        <Form noValidate validated={htmlValidated} onSubmit={this.handleSubmit}>
          <PGUserForm
            patientAge={patientAge}
            patientBMI={patientBMI}
            order={this.state.order}
            formState={this.state.form}
            convertMeasurements={this.convertMeasurements}
            handleChange={this.handleChange}
            handleBooleanCheckbox={this.handleBooleanCheckbox}
            handleProgramEligibility={this.handleProgramEligibility}
            handleAgeOfOnset={this.handleAgeOfOnset}
            handleAgeOfOnsetUnknown={this.handleAgeOfOnsetUnknown}
            handleWaistCircumferenceNotEvaluated={this.handleWaistCircumferenceNotEvaluated}
            handleBariatricSurgery={this.handleBariatricSurgery}
            handleIsProviderCheck={this.handleIsProviderCheck}
            handleRadioButtonClick={this.handleRadioButtonClick}
            handleCheckboxRequirement={this.handleCheckboxRequirement}
            handleSampleCollectionLocationChange={
              this.handleSampleCollectionLocationChange
            }
            isBMIGreaterThanOrEqualTo97Percent={this.isBMIGreaterThanOrEqualTo97Percent}
            handleSpecimenSourceChange={this.handleSpecimenSourceChange}
            user={this.props.user}
            userIsPGUser={this.props.user.isPGUser}
            viewAs={viewAs}
            isProd={this.state.isProd}
          />
          <div className="mb-5 d-print-none">{submitOrderOrUpdateButton}</div>
        </Form>
        <SimpleModal
          show={this.state.simpleModal}
          onHide={this.handleCloseAllModals}
          title={this.state.simpleModal ? this.state.simpleModal.title : ""}
          body={this.state.simpleModal ? this.state.simpleModal.body : ""}
        />
      </div>
    );
  }

  renderSection(index) {
    const TagName = this.sections[index].component;
    const userIsPGUser = this.props.user.isPGUser;
    const viewAs = this.getViewType();
    const patientAge = this.calculateAge();
    const patientBMI = this.calculateBMI();

    return (
      <FormWrapper
        validated={this.state.sections[index].validated}
        handleSectionSubmit={this.handleNextButton}
        handleBackButton={this.handleBackButton}
        nextButtonText="Next"
        showBackButton={
          index !== 0
          && !this.state.isAccessioned
          && !this.props.user.isPGUser
          && this.state.order?.status !== "Submitted"
        }
        showNextButton={
          index !== this.sections.length - 1 &&
          !this.state.isAccessioned &&
          !this.props.user.isPGUser
        }
      >
        <TagName
          patientAge={patientAge}
          patientBMI={patientBMI}
          previouslyAccessionedPatients={
            this.state.previouslyAccessionedPatients
          }
          order={this.state.order}
          formState={this.state.form}
          updateProfileEnabled={
            this.state.updateProfileEnabled &&
            this.state.form.isProvider === "true" &&
            !this.props.user.isPGUser
          }
          convertMeasurements={this.convertMeasurements}
          handleChange={this.handleChange}
          handleBooleanCheckbox={this.handleBooleanCheckbox}
          handleCheckboxRequirement={this.handleCheckboxRequirement}
          handleSampleCollectionLocationChange={
            this.handleSampleCollectionLocationChange
          }
          handleProgramEligibility={this.handleProgramEligibility}
          handleAgeOfOnset={this.handleAgeOfOnset}
          handleAgeOfOnsetUnknown={this.handleAgeOfOnsetUnknown}
          handleWaistCircumferenceNotEvaluated={this.handleWaistCircumferenceNotEvaluated}
          handleBariatricSurgery={this.handleBariatricSurgery}
          handleProbandChange={this.handleProbandChange}
          handleRadioButtonClick={this.handleRadioButtonClick}
          handleIsProviderCheck={this.handleIsProviderCheck}
          loading={this.state.sections[index].loading}
          isBMIGreaterThanOrEqualTo97Percent={this.isBMIGreaterThanOrEqualTo97Percent}
          handleSpecimenSourceChange={this.handleSpecimenSourceChange}
          showPrintConfirmationModal={this.state.showPrintConfirmationModal}
          updateProfile={this.updateProfile}
          userIsPGUser={userIsPGUser}
          user={this.props.user}
          validated={this.state.sections[index].validated}
          viewAs={viewAs}
          isProd={this.state.isProd}
        />
      </FormWrapper>
    );
  }

  renderProviderForm() {
    const activeSectionIndex = this.state.activeSection;
    const saveOrderUpdateButton = this.renderSaveOrUpdateButton("Save");
    const isBeingReviewed = this.state.isBeingReviewed;
    const percentCompleted =
      (activeSectionIndex / (this.sections.length - 1)) * 100;
    let orderReviewMessage = "";
    if (isBeingReviewed && !this.state.order.confirmationNumber) {
      orderReviewMessage = this.renderReviewMessage();
    } else if (
      this.state.order &&
      this.state.order.confirmationNumber &&
      !this.state.order.accession
    ) {
      if (
        this.state.form.sampleCollectionLocation ===
        "In office sample collection"
      ) {
        orderReviewMessage = (
          <div>
            <NextSteps formState={this.state.form} />
            <RhythmProviderSignature formState={this.state.form} />
            <RhythmPatientConsent
              patientCountry={this.state.form.patientCountry}
              patientConsentLanguage={this.state.form.patientConsentLanguage}
              order={this.state.order}
              loaded={this.setPatientConsentLoadedState}
              isProd={this.state.isProd}
            />
          </div>
        );
      } else {
        orderReviewMessage = <NextSteps formState={this.state.form} />;
      }
    }

    let orderSubmissionSection = null;
    if (this.state.isBeingReviewed && this.state.order) {
      switch (this.state.form.sampleCollectionLocation) {
        case SAMPLE_COLLECTION_LOCATIONS.AT_HOME:
          if (
            this.state.form.sendKitConfirmation === "true" &&
            !this.state.order.status !== "Submitted"
          ) {
            orderSubmissionSection = this.renderSubmitButton();
          } else {
            orderSubmissionSection = (
              <Alert variant="danger">
                You must check that you understand that a kit will be sent to
                the patients home before submitting the order.
              </Alert>
            );
          }
          break;
        case "Repeat testing":
          if (!this.state.order.status !== "Submitted") {
            orderSubmissionSection = this.renderSubmitButton();
          }
          break;
        default:
          if (!this.state.showPrintConfirmationModal) {
            orderSubmissionSection = (
              <div>
                <p>Please print the form to continue to submission</p>
                {this.renderPrintButton()}
              </div>
            );
          } else if (this.state.printDialogShown) {
            orderSubmissionSection = this.renderPrintConfirmationModal();
          }
      }
    }

    const currentForm = this.renderSection(activeSectionIndex);

    return (
      <div>
        <div className="row mb-2 clearfix d-print-none">
          <div className="col-md px-5 py-3">
            <ProgressBar percent={percentCompleted} filledBackground="#0aaae2">
              {this.sections.map((section, index) => (
                <Step transition="scale" key={index}>
                  {({ accomplished, position }) => (
                    <OverlayTrigger
                      trigger={["focus", "hover"]}
                      key={index}
                      placement="top"
                      overlay={
                        <Popover>
                          <Popover.Content>{section.name}</Popover.Content>
                        </Popover>
                      }
                    >
                      <div
                        className={`progress-bar-step ${
                          accomplished ? "completed" : ""
                        } ${position === percentCompleted ? "active" : ""}`}
                        onClick={() => this.handleSectionClick(index)}
                      >
                        <FontAwesomeIcon
                          style={{
                            filter: `grayscale(${accomplished ? 0 : 80}%)`,
                          }}
                          icon={
                            percentCompleted > position
                              ? "check-circle"
                              : "dot-circle"
                          }
                          size="1x"
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                </Step>
              ))}
            </ProgressBar>
          </div>
          {!this.state.isAccessioned ? (
            <div className="col-md-1">
              <div className="float-right">{saveOrderUpdateButton}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        {orderReviewMessage}
        {currentForm}
        <hr />
        <div className="mb-5 d-print-none text-center">
          {orderSubmissionSection}
        </div>
        <SimpleModal
          show={this.state.simpleModal}
          onHide={this.handleCloseAllModals}
          title={this.state.simpleModal ? this.state.simpleModal.title : ""}
          body={this.state.simpleModal ? this.state.simpleModal.body : ""}
        />
      </div>
    );
  }

  render() {
    if (this.state.serverError) {
      return (
        <Container className="mt-5 alert alert-danger" role="alert">
          <h5 className="mb-0 text-center">
            A server error has occurred (Details:{" "}
            {this.state.serverErrorMessage})
          </h5>
        </Container>
      );
    } else if (userMayNotOrder(this.props.user.attributes.email)) {
      return (<Container className="mt-5 alert alert-danger" role="alert">
          <h5 className="mb-0 text-center">
          New orders temporarily unavailable.
          </h5>
        </Container>);
    } else {
      let form = "";
      let message = "";
      const viewAs = this.getViewType();
      if (this.state.loading) {
        form = <Spinner />;
      } else if (
        this.props.user &&
        this.props.user.isPGUser &&
        viewAs !== VIEW_AS_TYPE.PROVIDER
      ) {
        form = this.renderPGUserForm();
      } else {
        form = this.renderProviderForm();
      }
      if (
        this.state.order &&
        this.state.order.confirmationNumber &&
        viewAs === VIEW_AS_TYPE.PROVIDER
      ) {
        if (this.state.order.accession) {
          let orderStatus = ORDER_STATUS[this.state.order.testStatusID];
          if (!orderStatus) {
            orderStatus = ORDER_STATUS["default"];
          }
          let accessionedDate = this.state.order.accessionedDateTime;
          accessionedDate = moment
            .utc(accessionedDate)
            .local()
            .format("MM/DD/YYYY hh:mm A");
          message = (
            <Alert variant="info">
              <Alert.Heading>Your order has been received.</Alert.Heading>
              <p className="mb-0">
                <strong>Order Received :</strong> {accessionedDate}
              </p>
              <p className="mb-0">
                <strong>PG ID :</strong> {this.state.order.accession}
              </p>
              <p>
                <strong>Status :</strong> {orderStatus.status}
              </p>
              <p>
                If you need to edit this order please contact us by email at{" "}
                <a href="mailto:support@preventiongenetics.com">
                  support@preventiongenetics.com
                </a>
                , or by phone at <a href="tel:715-387-0484">(715) 387-0484</a>.
              </p>
            </Alert>
          );
        } else if (
          this.state.postPrintSubmitClicked ||
          this.state.kitOrderSubmitted
        ) {
          message = (
            <Alert variant="success">
              <Alert.Heading>Your order has been submitted.</Alert.Heading>
              <p>
                You may continue to edit this order until it has arrived at the
                lab.
              </p>
            </Alert>
          );
        } else {
          message = (
            <Alert variant="success">
              <Alert.Heading>Your order has been submitted.</Alert.Heading>
              <p>
                You may continue to edit this order until it has arrived at the
                lab.
              </p>
              <Button
                onClick={this.startNewOrder}
                disabled={this.props.user.isPGUser}
                className="mr-3"
              >
                Start a New Order
              </Button>
              {this.renderPrintButton()}
            </Alert>
          );
        }
      }

      const orderSummaryHeader = (
        <Container className="mx-auto">
          <Row className="position-relative">
            <Col className="d-print-none">{message}</Col>
            <Col xs={3} className="d-none d-print-flex ml-0 pl-0">
              <img
                src={PGStackedLogo}
                className="py-5 w-100"
                alt="PreventionGenetics Logo"
              />
            </Col>
            <Col xs={3} className="d-none d-print-flex">
              <img
                src={RhythmLogo}
                className="py-5 w-100"
                alt="Rhythm Logo"
              />
            </Col>
            <Col xs={{ span: 3, offset: 1 }} className="col-barcode">
              {
                this.state?.order?.confirmationNumber &&
                <Barcode
                  height={50}
                  value={this.state.order.confirmationNumber}
                />
              }
            </Col>
          </Row>
        </Container>
      );

      if (this.state.postPrintSubmitClicked) {
        return (
          <Container className="mt-5">
            {orderSummaryHeader}
            <div className="px-3">
              <NextSteps className="mt-0" formState={this.state.form} />
              <Button
                onClick={this.startNewOrder}
                disabled={this.props.user.isPGUser}
                className="mr-3"
              >
                Start a New Order
              </Button>
              {this.renderPrintButton()}
            </div>
          </Container>
        );
      } else if (this.state.kitOrderSubmitted && !this.props.user.isPGUser) {
        const kitOrderFailedMessage = this.state.kitOrderFailedtoSend ? (
          <Container>
            <Alert variant="danger">
              <h5>
                <strong className="mr-2">
                  <em>HOWEVER</em>
                </strong>
                your kit order failed to process.
              </h5>{" "}
              <p>
                Please{" "}
                <a
                  href="../../contactUs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  contact us
                </a>{" "}
                to place the kit order and let us know of the issue.
              </p>{" "}
            </Alert>
          </Container>
        ) : null;
        return (
          <Container className="mt-5">
            {orderSummaryHeader}
            {kitOrderFailedMessage}
            <div className="px-3">
              <NextSteps className="mt-0" formState={this.state.form} />
              <Button
                onClick={this.startNewOrder}
                className="mr-3"
              >
                Start a New Order
              </Button>
            </div>
          </Container>
        );
      } else {
        return (
          <Container className="mt-5">
            {orderSummaryHeader}
            <div className="rythm-header text-center text-uppercase mb-2">
              <h4 className="mb-0">SP068 - Rhythm</h4>
              <h2 className="mb-0">
                Rare Genetic Obesity Panel - Test Requisition Form
              </h2>
            </div>
            {form}
          </Container>
        );
      }
    }
  }
}

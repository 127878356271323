import React from "react";
import { Form, Col } from "react-bootstrap";

export default (props) => {
  return (
    <Form.Group as={Col} sm="2">
      <Form.Label>BMI</Form.Label>
      <Form.Control
        type="text"
        placeholder="BMI"
        value={props.patientBMI.toFixed(1)}
        readOnly
      />
    </Form.Group>
  );
};

import React, { Component } from "react";
import { inOutageWindow } from "../common/outageWindow";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, Container, Button } from "react-bootstrap";
import "../css/header.scss";
import logo from "../img/PG_pos_rgb_endorsed.svg";
import { Auth } from "aws-amplify";

export default class Header extends Component {
  displayName = Header.name;

  logOut = () => {
    Auth.signOut();
  };

  renderAuthenticatedNav = (user) => {
    return (
      <Navbar id="nav" variant="light" expand="lg">
        <Container>
          <Navbar.Brand>
            <NavLink exact to="/">
              <img src={logo} alt="Logo" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {
                !inOutageWindow()
                ? <><NavLink
                    to="/kits"
                    className="nav-link"
                    activeClassName="selected"
                  >
                    Order Office Kits
                  </NavLink>
                  <NavLink
                    to="/rhythm"
                    className="nav-link"
                    activeClassName="selected"
                  >
                    Submit Test
                  </NavLink>

                  <NavLink
                    exact
                    to="/orders"
                    className="nav-link"
                    activeClassName="selected"
                  >
                    Test Status
                  </NavLink>

                  {!user.isPGUser ? (
                    <NavLink
                      exact
                      to="/reports"
                      className="nav-link"
                      activeClassName="selected"
                    >
                      Reports
                    </NavLink>
                  ) : null}</>
                : ""
              }

              {!user.isPGUser ? (
                <a
                  href="https://www.preventiongenetics.com/ClinicalTesting/sponsoredTesting/Rhythm"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  About
                </a>
              ) : null}
              {!user.isPGUser ? (
                <NavLink
                  exact
                  to="/contactUs"
                  className="nav-link"
                  activeClassName="selected"
                >
                  Contact Us
                </NavLink>
              ) : null}
            </Nav>

            {
              !inOutageWindow()
              ? <><Nav>
                  {!user.isPGUser ? (
                    <NavLink
                      exact
                      to="/profile"
                      className="nav-link"
                      activeClassName="selected"
                    >
                      My Profile
                    </NavLink>
                  ) : null}
                </Nav></>
              : ""
            }
            
            <Nav>
              {user.attributes.picture ? (
                <img
                  id="profilePicture"
                  className="circle mr-2"
                  alt="Profile"
                  src={user.attributes.picture}
                />
              ) : (
                <div
                  id="profilePicturePlaceholder"
                  className="circle bg-light-purple mt-1 mr-2"
                >
                  <span className="circle-text text-white">
                    {user.attributes.given_name[0]}
                    {user.attributes.family_name[0]}
                  </span>
                </div>
              )}
              <div>
                <p className="mb-0">Hi {user.attributes.given_name}!</p>
                <Button variant="link" className="p-0" onClick={this.logOut}>
                  Log out
                </Button>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  };

  renderNonAuthenticatedNav = () => {
    return (
      <Navbar id="nav" variant="light">
        <Container>
          <Navbar.Brand>
            <NavLink exact to="/">
              <img src={logo} alt="Logo" />
            </NavLink>
          </Navbar.Brand>
          <NavLink
            exact
            to="/about"
            className="btn btn-link mr-auto"
            activeClassName="selected"
          >
            About
          </NavLink>
          {
            !inOutageWindow()
            ? <NavLink
                exact
                to="/login"
                className="btn btn-link"
                activeClassName="selected"
              >
                Login
              </NavLink>
            : ""
          }
        </Container>
      </Navbar>
    );
  };

  render() {
    if (this.props.user) {
      return this.renderAuthenticatedNav(this.props.user);
    } else {
      return this.renderNonAuthenticatedNav();
    }
  }
}

let getInputValueOrDefault = (name, defaultValue) => {
  const elements = document.getElementsByName(name) || [];
  return elements.length === 1 ? elements[0].value : defaultValue;
};

export default {
  cognito: {
    // Defaults are used here when the hidden inputs from the server aren't there.
    // Hidden inputs won't be there when in debug mode since react hosts index.html itself
    // Cognito Auth
    clientId: getInputValueOrDefault(
      "cognitoClientId",
      "78j7hj0gicp9nvf8sjhit673qd"
    ),
    userPoolId: getInputValueOrDefault(
      "cognitoUserPoolId",
      "us-east-1_xmOg0eF52"
    ),
    region: getInputValueOrDefault("cognitoRegion", "us-east-1"),
    oauthDomain: getInputValueOrDefault(
      "cognitoOAuthDomain",
      "test-auth.preventiongenetics.com"
    ),
  },
};

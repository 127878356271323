import React from "react";
import { Modal, Button } from "react-bootstrap";

// ? This function is meant to be a simple modal with only one close button function, a title, and a body
// ! DO NOT EXTEND THIS FUNCTION
export default (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
